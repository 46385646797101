<template>
  <div>
    <v-menu offset-y v-model="menu" rounded="3xl">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          color="primary"
          v-on="on"
          :loading="isToneLoading"
          @click="handleButtonClick"
        >
          <img src="@/assets/img/shorthand.svg" alt="translate" width="22px" />
          <span class="d-none d-sm-flex" v-if="btnText">
            {{ btnText }}
          </span>
        </v-btn>
      </template>
      <v-list
        class="px-6 py-4"
        dense
        v-if="hasCredits"
      >
        <v-list-item-title class="text-h6">{{ header }}</v-list-item-title>
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl m"
          @click.prevent="changePostTone('ctFormal')"
        >
          <img
            src="@/assets/img/formal.svg"
            alt="translate"
            width="10px"
            height="12px"
            class="mr-3"
          />Make it formal</v-list-item
        >
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl mt-2"
          @click="changePostTone('ctCasual')"
          ><img
            src="@/assets/img/casual.svg"
            alt="translate"
            width="12px"
            height="22px"
            class="mr-3"
          />Make it casual</v-list-item
        >
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl mt-2"
          @click="changePostTone('formatBetter')"
        >
          <img
            src="@/assets/img/format.svg"
            alt="translate"
            width="12px"
            height="22px"
            class="mr-3"
          />
          Format better
        </v-list-item>
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl mt-2"
          @click="changePostTone('ctShorten')"
        >
          <img
            src="@/assets/img/shorten.svg"
            alt="translate"
            width="12px"
            height="22px"
            class="mr-3"
          />Shorten it</v-list-item
        >
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl mt-2"
          @click="changePostTone('ctExpand')"
        >
          <img
            src="@/assets/img/expand.svg"
            alt="translate"
            width="12px"
            height="22px"
            class="mr-3"
          />
          Expand it</v-list-item
        >
        <v-list-item
          class="w-[243px] h-12 bg-slate-100 rounded-3xl mt-2"
          @click="addEmojisToActiveTab()"
        >
          <img
            src="@/assets/img/emoji.svg"
            alt="translate"
            width="12px"
            height="22px"
            class="mr-3"
          />
          Add emojis
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialoge"
      class="overflow-hidden"
      max-width="600"
      v-if="creditsExhausted"
    >
      <v-card>
        <v-card-title>
          You have run out of Fly AI Writer credits. Please <br />
          renew or upgrade to proceed
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeCredits">Upgrade</v-btn>
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import openaiMixin from "@/mixins/openai.mixin";

export default {
  data() {
    return {
      menu: false,
      dialog: false,
      dialoge: false,
      openaiChangedTone: [],
      textWithEmoji: "",
      withEmoji: true,
      operation: "",
      isToneLoading: false,
      loaderMessage: "",
    };
  },
  mixins: [openaiMixin],
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    btnText: {
      type: String,
      default: "",
    },
    header: {
      type: String,
      default: "",
    },
    icon: String,
    allConnectedPlatforms: Array,
    connectionAppsList: Array,
    text: String,
    platformEditors: Object,
    activeItem: String,
    language: {
      type: String,
      default: "English",
    },
  },
  computed: {
    hasCredits() {
      return this.checkCredits() > 0;
    },
    creditsExhausted() {
      return this.checkCredits() === 0;
    },
  },
  methods: {
    checkCredits() {
      let totalCredits = 0;
      if (this.currentUser.fly_ai_text_credits) {
        totalCredits += this.currentUser.fly_ai_text_credits;
      }
      if (this.currentUser.addon_credits && this.currentUser.addon_credits.ai_writer_actions) {
        totalCredits += this.currentUser.addon_credits.ai_writer_actions;
      }
      if (this.currentUser.addon_monthly_credits && this.currentUser.addon_monthly_credits.ai_writer_actions) {
        totalCredits += this.currentUser.addon_monthly_credits.ai_writer_actions;
      }
      return totalCredits;
    },
    handleButtonClick() {
      if (this.creditsExhausted) {
        this.dialoge = true;
      } else {
        this.menu = !this.menu;
      }
    },
    upgradeCredits() {
      this.$router.push("/subscription");

      this.dialoge = false;
    },
    closeDialog() {
      // Close the dialog
      this.dialoge = false;
    },
    async changePostTone(tone) {
      let vm = this;
      // console.log("hi");
      this.operation = tone;
      if (tone === "ctFormal") {
        vm.loaderMessage =
          "Sit back and relax while I make the post formal for you";
      }
      if (tone === "ctCasual") {
        vm.loaderMessage =
          "Sit back and relax while I make the post casual for you";
      }
      if (tone === "ctShorten") {
        vm.loaderMessage =
          "Sit back and relax while I shorten the post for you";
      }
      if (tone === "formatBetter") {
        vm.loaderMessage = "Sit back and relax while I format the post for you";
      }
      if (tone === "ctExpand") {
        vm.loaderMessage = "Sit back and relax while I expand the post for you";
      }
      vm.openaiChangedTone = [];
      await this.changeTone();
      if (vm.openaiChangedTone?.length) {
        // console.log("openaiChangedTone:", vm.openaiChangedTone)
        vm.$emit("openaiChangedTone", vm.openaiChangedTone);
      }
    },
    async addEmojisToActiveTab() {
      let vm = this;
      vm.loaderMessage = "Sit back and relax while I add emojis to the post";
      vm.textWithEmoji = "";
      await vm.addEmojisToContent();
      if (vm.textWithEmoji?.length) {
        vm.$emit("textWithEmoji", vm.textWithEmoji);
      }
    },
  },
};
</script>

<style></style>
