<template>
  <div class="flex justify-center items-center bg-slate-100 h-full w-full">
    <div class="flex flex-col w-full max-w-[1285px] p-6 bg-white rounded-3xl mt-8 mx-2">
      <form-wrapper @submit="submit()" class="flex flex-col items-center">
        <h2 class="text-2xl text-neutral-900 mb-8">Your Profile</h2>
        <div class="text-center text-neutral-700">
          Profile Picture
        </div>
        <div class="flex justify-center my-4">
          <profile-upload v-model="form.photo_url" class="w-[105px] h-[105px]"></profile-upload>
        </div>
        <!-- Responsive Two-column layout -->
        <div class="flex flex-col md:flex-row w-full">
          <!-- Left Column (Responsive) -->
          <div class="flex flex-col w-full md:w-1/2 md:pr-4 mb-4 md:mb-0 -mt-4">
            <!-- Name Input -->
            <div class="mt-1 text-xs text-neutral-700">Name</div>
            <ProfileInputVue
              label="Name"
              rules="required"
              v-model="form.display_name"
            />
            <!-- Email Input -->
            <div class="mt-4 text-xs text-neutral-700">Email</div>
            <ProfileInputVue
              v-model="form.email"
              label="Email"
              rules="email"
            />
            <!-- Select Category -->
            <div class="mt-4 text-xs text-neutral-700">Select Category</div>
            <v-select
              v-model="form.user_type"
              :items="items"
              class="pt-2 pr-2 whitespace-nowrap text-slate-500 w-full relative rounded bg-gray-100 box-border h-[46px] border-[1px] border-solid border-stroke pl-2"
              dense
            ></v-select>
          </div>
          <!-- Right Column (Responsive) -->
          <div class="flex flex-col w-full md:w-1/2 md:pl-4">
            <!-- Phone Number Input -->
            <div class="-mt-2 text-xs text-neutral-700">Phone Number</div>
            <vue-tel-input-vuetify
  v-model="form.phone_number"
  mode="international"
  label=""
  class="w-full relative rounded bg-gray-100 box-border h-[46px] border-[1px] border-solid border-stroke gap-3  "
></vue-tel-input-vuetify>

            <!-- Country Select Field -->
            <div class="mt-4 text-xs text-neutral-700">Country</div>
            <ProfileSelectfield
              :items="countryOptions"
              v-model="form.country_code"
            />
          </div>
        </div>
        <!-- Save Button -->
        <v-btn
          type="submit"
          large
          :loading="loading"
          @click="showProfileUpdatedSnackbar"
          text
          class="w-[139.30px] h-12 bg-[#1773EA] rounded-xl mt-2"
          color="white"
        >
          Save
        </v-btn>
      </form-wrapper>
      <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" bottom right>
        Profile updated successfully!
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import SignupSelect from "@/components/ui/form/SignupSelect.vue";
import axios from "axios";
import AppLayout from "@/components/ui/AppLayout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import { searchClient } from "@/utils/algolia.utils";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import Textarea from "@/components/ui/form/Textarea.vue";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { GeoPoint, doc, getDoc, updateDoc } from "firebase/firestore";
import { colUsers, storage } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import ProfileUpload from "@/components/ui/form/ProfileUpload";
import ProfileInputVue from "@/components/ui/form/ProfileInput.vue";
import ProfileAddress from "@/components/ui/form/ProfileAddress.vue";
import countries from "i18n-iso-countries";
import SelectField from "@/components/ui/form/SelectField.vue";
import ProfileSelectfield from "@/components/ui/form/ProfileSelectfield.vue";

export default {
  components: {
    AppLayout,
    InputField,
    AddressField,
    ComboBox,
    FormWrapper,
    Textarea,
    PleaseWait,
    ProfileUpload,
    ProfileInputVue,
    ProfileAddress,
    SignupSelect,
    SelectField,
    ProfileSelectfield,
  },
  data() {
    return {
      countryCodes: [],
      items: [
        "Individual",
        "Journalist",
        "Social media influencer",
        "Advisor / Consultant / Coach",
        "Small / Medium Business",
        "Community Head",
        "Enterprise",
        "Car Dealer",
        "Student",
        "PR/Media Agency",
        "Other",
      ],
      loading: false,
      isLoading: true,
      location: "",
      native: "",
      form: {
        photo_url: "",
        bio: "",
        display_name: "",
        email: "",
        phone_number: "",
        country_code: "",
        user_type: "",
        Title: "",
        interests: [],
        professionalskills: [],
        residential: null,
        native: null,
      },
      countryOptions: null,
      snackbar: false,
      snackbarTimeout: 2000,
    };
  },
  methods: {
    initCountries() {
      countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
      this.countryOptions = Object.entries(countries.getNames("en")).map(
        ([code, name]) => ({
          name: name,
          value: code,
        })
      );
    },
    async fetchCountryCodes() {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        this.countryCodes = response.data
          .map((country) => ({
            text: `${country.name.common} (${country.cca2})`, // use cca2 for country code
            value: country.cca2, // use cca2 for value
          }))
          .filter((item) => item.value); // filter out any undefined or null values
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    },
    showProfileUpdatedSnackbar() {
      this.snackbar = true;
    },
    onAddress(e) {
      if (e) {
        this.location = e.Address || "";
        this.form.residential = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
      }
    },
    onNativeAddress(e) {
      if (e) {
        this.native = e.Address || "";
        this.form.native = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
      }
    },
    fetchInterests(val) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const index = searchClient.initIndex("interests_master");
        try {
          let res = await index.search(val, {
            cacheable: true,
          });
          let list = res.hits.map((i) => i.interestname);
          resolve(list);
        } catch (error) {
          reject(error);
        }
      });
    },
    async initProfile() {
      const vm = this;
      try {
        vm.isLoading = true;
        let profile = (await getDoc(doc(colUsers, vm.uid))).data() || {};
        Object.keys(vm.form).forEach((key) => {
          if (profile[key] != undefined) {
            vm.form[key] = profile[key];
          }
        });
        if (vm.form.residential) {
          vm.location = vm.form.residential.Address || "";
        }
        if (vm.form.native) {
          vm.native = vm.form.native.Address || "";
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let photo = vm.form.photo_url;
        let data = { ...vm.form };
        if (photo.startsWith("data:image")) {
          let profileRef = ref(storage, `users/${vm.uid}/uploads/profile.png`);
          await uploadString(profileRef, photo, "data_url");
          data.photo_url = await getDownloadURL(profileRef);
        }
        await updateDoc(doc(colUsers, vm.uid), {
          ...data,
        });
        vm.loading = false;
        vm.showProfileUpdatedSnackbar();
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.initProfile();
    this.fetchCountryCodes();
    this.initCountries();
  },
};
</script>

<style></style>
