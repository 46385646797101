<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
    <template v-if="btnText" v-slot:activator="{ on }">
      <v-btn
        :icon="!isSmallAndUp"
        :outlined="isSmallAndUp"
        color="primary"
        v-on="on"
      >
        <v-icon :class="{ 'mr-2': $vuetify.breakpoint.smAndUp }" size="20"
          >mdi-auto-fix</v-icon
        >
        <span v-if="isSmallAndUp"> Generate image from AI </span>
      </v-btn>
    </template>
    <template v-else v-slot:activator="{ on }">
      <v-list-item v-on="on">Generate image from text</v-list-item>
    </template>
    <form-wrapper ref="imgForm" @submit="submit()">
      <v-card
        class="pa-2"
        v-if="currentUser && currentUser.fly_ai_image_credits > 0"
      >
        <v-card-title class="primary--text pb-3 pt-3 d-flex justify-between">
          <div
            class="d-flex gap-2 items-center"
            :class="!isSmallAndUp ? 'w-[77%]' : 'w-[80%]'"
          >
            <v-btn icon depressed v-if="steps == '2'" @click="steps = 1"
              ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
            >
            <img
              src="../../assets/img/icons8-ai.gif"
              :width="isSmallAndUp ? '30px' : '25px'"
              :height="isSmallAndUp ? '30px' : '25px'"
              max-height="100%"
            />
            <span v-if="steps === 1 && btnText">{{ btnText }}</span>
            <span v-if="steps === 2 && btnText">AI generated image</span>
            <span
              v-if="!btnText"
              :class="!isSmallAndUp ? 'text-base' : ''"
              class=""
              >{{ title }}</span
            >
          </div>
          <!-- <v-spacer /> -->
          <v-btn
            icon
            color="red"
            @click="handleDialogClose()"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-stepper v-model="steps" flat>
            <v-stepper-items>
              <v-stepper-content class="pt-2" step="1">
                <Textarea
                  placeholder="Describe the image you wish to generate from AI. Ex: Astronaut riding a horse"
                  v-model="imgForm.text"
                />
              </v-stepper-content>
              <v-stepper-content class="pt-2" step="2">
                <template v-if="responseImageUrl">
                  <v-row class="mx-auto d-flex justify-center">
                    <v-col cols="12">
                      <div
                        class="d-flex flex-col mb-2 rounded-md w-full border p-4 gap-4"
                      >
                        <v-img :src="responseImageUrl">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <div
                          class="d-flex flex-row justify-end w-full flex-wrap gap-2"
                        >
                          <v-btn
                            @click="selectImage(responseImageUrl)"
                            outlined
                            color="primary"
                            height="30px"
                            :loading="loading"
                            :class="!isSmallAndUp ? 'w-full' : ''"
                          >
                            Add to editor
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="mr-2 pt-4">
          <v-spacer></v-spacer>
          <v-btn text v-if="steps == '1'" color="primary" outlined type="submit"
            >Generate</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="pa-2" v-else>
        <v-card-title>
          You have run out of Fly Image AI credits. Please renew or upgrade to
          <br />
          proceed.
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeAndCloseDialog()"
            >Upgrade</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <upload-progress-dialog ref="progress" />
    </form-wrapper>
  </v-dialog>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import openaiMixin from "@/mixins/openai.mixin";
import Textarea from "../ui/form/Textarea.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import UploadProgressDialog from "../UploadProgressDialog.vue";
import { getDate } from "@/utils/common.utils";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { colGallery, storage } from "@/utils/firebase.utils";
import { doc, setDoc } from "firebase/firestore";

import { MAX_IMAGE_SIZE } from "@/utils/image.utils";

export default {
  components: { Textarea, FormWrapper, UploadProgressDialog },
  data() {
    return {
      url: "",
      responseImageUrl: "",
      image_url: "",
      steps: 1,
      dialog: false,
      loading: false,
      rules: [rulesConstants.required],
      imgForm: {
        text: "",
      },
    };
  },
  mixins: [openaiMixin],
  props: {
    btnText: String,
  },
  computed: {
    title() {
      let title = "";
      if (this.steps == 1) {
        title = "Generate image from text";
      } else {
        title = "AI generated image";
      }
      return title;
    },
  },
  methods: {
    upgradeAndCloseDialog() {
      this.dialog = false;
      this.$router.push("/subscription");
    },
    closeDialog() {
      this.dialog = false;
    },
    async selectImage(item) {
      let vm = this;
      this.loading = true;
      vm.$refs.progress.total = 1;
      vm.$refs.progress.loader = true;
      let image = await this.downloadImage(item);
      // console.log("selectImage image:", image)
      if (image) {
        this.$emit("uploader", true);
        let uid = vm.authUser.default_uid || vm.uid;
        let blob = await fetch(image).then((res) => res.blob());
        let imageSize = blob.size;
        if (blob.size > MAX_IMAGE_SIZE) {
          let { base64, size } = await this.compressImage(image);
          image = base64;
          imageSize = size;
          console.log("File Compressed....");
        }
        let id = new Date().getTime().toString();
        let imgData = {
          created_at: getDate(),
          updated_at: getDate(),
          type: "image",
          size: imageSize,
          uid: vm.currentUID,
          name: this.currentUID + "/" + id,
          json: {},
        };
        let imageRef = ref(storage, `gallery/${vm.currentUID}/${id}.png`);
        await uploadString(imageRef, image, "data_url");
        imgData.url = await getDownloadURL(imageRef);
        imgData.thumbnail = imgData.url;
        // console.log("imgData:", imgData);
        this.url = imgData.url;
        await setDoc(doc(colGallery, id), imgData);
        this.$snackbar.show("Image saved to fly gallery!");
        vm.$refs.progress.loader = false;
        this.$emit("uploader", false);

        let img_data = {
          ...imgData,
          id,
        };

        this.$emit("addGeneratedImage", img_data);
        this.responseImageUrl = "";
      }
      this.loading = true;

      this.dialog = false;
      this.steps = 1;
      this.imgForm = {
        text: "",
      };
    },
    handleDialogClose() {
      this.dialog = false;
      this.steps = 1;
      this.imgForm = {
        text: "",
      };
      this.openaiContents = [];
    },
    submit() {
      if (this.currentUser.fly_ai_image_credits === 0) {
        this.dialog = true; // Show the overlay
        return;
      }

      // Continue with image generation logic
      this.generateImage();
      // this.generateImageCaption();
    },
    genImg() {
      this.generateImage();
    },
    genCap() {
      this.generateImageCaption();
      // this.steps = 2;
    },
  },
};
</script>

<style></style>
