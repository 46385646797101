<template>
  <div>
    <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
      <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
        <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
          <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
            <img src="@/assets/img/shorthand.svg" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
            <div class="flex items-center gap-1">
              <p class="text-base md:text-[22px] font-bold">Content AI <span class="md:hidden">></span></p>
              <p class="text-[#8056DE] text-sm font-semibold md:hidden">&nbsp;{{ selectedTabName }}</p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-start mb-5 ml-[79px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
          <div class="items-center gap-2 px-4 -ml-2 d-flex">
            <div class="flex items-center gap-1">
              <p class="text-xs md:text-[18px] text-gray-600">Social media post ideas for your brand</p>
            </div>
          </div>
        </div>
        <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl">
          <div class="md:py-[28px] xl:w-[1285px] flex gap-4 font-semibold w-full">
            <div class="bg-[#F2F6FA] py-2 px-2 md:px-0 flex gap-1 md:gap-4 md:p-1 md:rounded-full w-full">
              <button
                class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
                :class="tab == 0 ? 'bg-[#8056DE] text-white ' : 'bg-white text-[#636B8C]'" @click="tab = 0">
                <img src="../../assets/img/contentai-icons/icons8-mind-map-50 (1).png" alt="delete"
                  class="w-[27px] h-[27px] md:mr-2" :class="tab == 0 ? 'hidden ' : ''" />
                <img src="../../assets/img/contentai-icons/icons8-mind-map-50.png" alt="delete"
                  class="w-[27px] h-[27px] md:mr-2" :class="tab == 0 ? '' : 'hidden'" />
                <div class="hidden md:block">Ideas</div>
              </button>
              <button
                class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
                :class="tab == 1 ? 'bg-[#8056DE] text-white ' : 'bg-white text-[#636B8C]'" @click="tab = 1">
                <img src="../../assets/img/contentai-icons/icons8-blogger-50 (1).png" alt="delete"
                  class="w-[27px] h-[27px] md:mr-2" :class="tab == 1 ? 'hidden' : ''" />
                <img src="../../assets/img/contentai-icons/icons8-blogger-50.png" alt="delete"
                  class="w-[27px] h-[27px] md:mr-2" :class="tab == 1 ? '' : 'hidden'" />
                <div class="hidden md:block">Generated Content</div>
              </button>
            </div>
          </div>
          <div class="bg-[#F2F6FA] pb-2 pt-2 md:pt-[33px] px-2 lg:px-5 md:rounded-3xl">
            <div v-if="tab == 0">
              <div class="card mb-4">
                <div class="row flex justify-between">
                  <div class="col">
                    <p>{{ topics.length }} topics with {{ ideasGenerated }} ideas generated</p>
                  </div>
                  <div :class="{ 'flex-col': isSmallScreen, 'flex-row': !isSmallScreen }" class="flex items-center ">
                    <div class="flex gap-2 justify-end">
                      <button @click=" updateDescription" class="btn">Suggest Topics</button>
                      <button @click="showEditDescriptionDialog = true" class="btn">
                        <v-icon color="white">mdi-pencil</v-icon>
                      </button>
                      <button @click="showAddTopicDialog = true" class="btn add_topics" >
                        <v-icon color="white">mdi-plus</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="aiSuggestions.length > 0" class="card mb-4">
                <div class=" text-slate-500 font-semibold text-xl">AI Suggested Topics</div>
                <v-list>
                  <v-list-item v-for="(suggestion, index) in aiSuggestions" :key="index">
                    <v-list-item-title>{{ suggestion.topic }}</v-list-item-title>
                    <v-list-item-action>
                      <a @click="addTopic(suggestion)" class="link">Add</a>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <v-dialog v-model="showEditDescriptionDialog" max-width="600px">
                <v-card>
                  <v-card-title>Edit Business Description</v-card-title>
                  <v-card-text>
                    <v-text-field label="Business / Brand Name" outlined v-model="BrandName"></v-text-field>
                    <v-text-field label="Website Link URL" outlined v-model="websiteLink"></v-text-field>
                    <p class="pb-3">OR</p>
                    <v-text-field label="Brief Description" outlined v-model="briefDescription"></v-text-field>
                    <v-checkbox label="Replace previous topics" v-model="replaceTopics"
                      @click="replaceDialog = (true && replaceTopics)"></v-checkbox>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn @click="updateDescription" class="primary">Update Description</v-btn>
                    <v-btn @click="showEditDescriptionDialog = false; replaceTopics = false"
                      class="error">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="showAddTopicDialog" max-width="600px">
                <v-card>
                  <div class="d-flex justify-space-between">
                    <v-card-title>
                      Add Topic
                    </v-card-title>
                    <v-icon class="close_dialog mr-2 w-10 h-10" @click="cancelAddTopic">mdi-close</v-icon>
                  </div>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="Describe your topic" v-model="findTopic" outlined full-width></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-container fluid>
                    <v-col class="d-flex flex-column flex-md-row justify-end">
                      <v-btn color="primary" @click="findIdeas">Find Ideas</v-btn>
                    </v-col>
                  </v-container>
                </v-card>
              </v-dialog>
              <v-dialog v-model="regenerateSuggestionDialog" max-width="600px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Add Suggestions</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="regenerateSuggestionDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea v-model="addedSuggestions" outlined label="Add your suggestions" rows="2"></v-textarea>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn class="primary"
                      @click="reGenerateText(toneUpdate, 'regeneratePostforIdea', generatedText, currentTopicIdea, generatedText.length, typeUpdate, addedSuggestions)">Regenerate</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class="layout-columns">
                <div class="column" v-for="(columnTopics, columnIndex) in splitTopics" :key="columnIndex">
                  <div v-for="(topic, index) in columnTopics" :key="index" class="card mb-4">
                    <div class="card-title">
                      <span>{{ topic.title }}</span>
                      <v-icon @click="confirmDelete('topic',topic,columnIndex,'')"
                        class="btn-icon align-top-del">mdi-delete</v-icon>
                    </div>
                    <v-list>
                      <v-list-item v-for="(idea, ideaIndex) in topic.ideas.slice(0, topic.showIdeas)" :key="ideaIndex"
                        class="flex justify-between items-center px-0">
                        <v-list-item-title class="wrap-text">
                          {{ idea }}
                          <a @click="showGenerateContentDialog(idea, ideaIndex); ideaToUpdate = { topic, ideaIndex }"
                            class="link">Generate</a>
                          <v-divider v-if="ideaIndex < topic.ideas.slice(0, topic.showIdeas).length - 1"
                            class=" my-2 "></v-divider>
                        </v-list-item-title>
                        <v-icon @click="confirmDelete('idea',topic,ideaIndex,'')"
                          class="btn-icon align-top ">mdi-close</v-icon>
                      </v-list-item>
                    </v-list>
                    <a v-if="topic.showIdeas < topic.ideas.length && topic.showIdeas < 10" @click="getMoreIdeas(topic)"
                      class="link-get">Show More Ideas</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="layout-columns">
                <div class="column" v-for="(content, index) in splitGenerated" :key="index">
                  <div class="card mb-4">
                    <div class="card-title">
                      <span>{{ content.title }}</span>
                      <v-icon @click="confirmDelete('content','',index,content) "
                        class="btn-icon align-top-del">mdi-delete</v-icon>
                    </div>
                    <div class="card-content">
                      <p>{{ content.content }}</p>
                      <p><strong>Tone:</strong> {{ capitalizeFirstletter(content.tone) }}</p>
                      <p><strong>Post Type:</strong> {{ capitalizeFirstletter(content.postType) }}</p>
                      <!-- Displaying the Created At timestamp -->
                    </div>
                    <div class="flex justify-between mt-2">
                      <v-btn class="CreateWithPost primary" @click="CreateWithPost(content.content)">Post with
                        Fly</v-btn>
                      <p class="flex justify-end items-center">{{ formatTimestamp(content.createdAt) }}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">{{ completedSteps }} of {{ totalSteps }} steps completed</div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{ item.name }}</router-link>
          </label>
          <router-link :to="item.route">
    <v-icon color="white cursor-pointer">mdi-chevron-right</v-icon>
  </router-link>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with a member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">Dismiss</button>
      </div>
    </div>
    <v-dialog v-model="showGenerateContentDialogFlag" max-width="630px" persistent>
      <v-card>
        <div class="flex justify-between">
          <v-card-title>
            Write Idea-Based Content
          </v-card-title>
          <v-icon class="close_dialog mr-2 w-10 h-10" @click="cancelGeneration">mdi-close</v-icon>
        </div>
        <v-card-text class="dialog-content">
          <div class="flex">
            <v-textarea label="Working on:" outlined v-model="currentTopicIdea"
              class="pt-4 darkened-text wrap-generateText" :disabled="!editable" rows="3"
              persistent-placeholder></v-textarea>
            <v-icon v-if="!editable" @click="editable = true" class="edit-icon">mdi-pencil</v-icon>
            <v-icon v-if="editable" @click="editable = false" class="edit-icon">mdi-check</v-icon>
          </div>
          <div v-if="!generatingText">
            <v-select multiple :items="socialNetworks" label="Target Social Networks (optional)"
              v-model="selectedNetworks" chips deletable-chips append-outer-icon="mdi-check"
              @click:append-outer="closeSelectDropdown" :menu-props="{ closeOnClick: true }"></v-select>
          </div>
          <div v-if="generatingText" class="mt-4">
            <v-textarea class="generatedText" v-if="!textLoading" label="Generated Text" v-model="generatedText"
              outlined></v-textarea>
            <div v-if="textLoading" class="skeleton-loading"></div>
            <div v-if="!textLoading && IdeaBasedtextGenerated" class="flex gap-2 my-2 flex-wrap">
              <v-btn @click="shortenText">
                <img src="@/assets/img/shorten.svg" alt="">
                Shorten
              </v-btn>
              <v-btn @click="expandText">
                <img src="@/assets/img/expand.svg" alt="">
                Expand
              </v-btn>
              <v-menu v-model="toneMenu" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    {{ toneUpdate || 'Change Tone' }}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="toneOption in toneOfVoice" :key="toneOption" @click="changeTone(toneOption)">
                    <v-list-item-title>{{ toneOption }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu v-model="typeMenu" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    {{ typeUpdate || 'Post Type' }}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="typeOption in postTypes" :key="typeOption" @click="changeType(typeOption)">
                    <v-list-item-title>{{ typeOption }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="flex flex-row specify_btn">
            <v-select class="custom-select" :items="postTypes" v-if="!generatingText"
              label="Default Type (Announcement)" v-model="postType"></v-select>
            <v-select class="custom-select" :items="toneOfVoice" v-if="!generatingText"
              label="Default Tone (Informative)" v-model="tone"></v-select>
          </div>
          <!-- <v-checkbox v-if="!generatingText" label="Use Business Description"
            v-model="useBusinessDescription"></v-checkbox> -->
        </v-card-text>
        <div class="flex justify-between flex-wrap">
          <v-menu offset-y v-if="!textLoading && IdeaBasedtextGenerated && generatingText">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="info self-start max-w-sm ml-5 mt-2">
                Regenerate
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="reGenerateText(toneUpdate, 'regeneratePostforIdea', generatedText, currentTopicIdea, generatedText.length, typeUpdate, null)">
                <v-list-item-title>Auto Regenerate</v-list-item-title>
              </v-list-item>
              <v-list-item @click="regenerateSuggestionDialog = true">
                <v-list-item-title>Regenerate with Suggestion</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-card-actions class="flex justify-end flex-wrap">
            <v-btn class="primary" v-if="!generatingText" @click="generateText(currentTopicIdea, tone, postType)">
              Generate Text
            </v-btn>
            <v-btn class="primary" v-else-if="!textLoading"
              @click="CreateWithPost(generatedText); SaveGenerated(currentTopicIdea)">
              Post with Fly
            </v-btn>
            <v-btn class="secondary" v-if="generatingText && !textLoading" @click="SaveGenerated(currentTopicIdea)">
              Save
            </v-btn>
            <v-btn v-if="generatingText" @click="goBack">Back</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="messageDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ messageTitle }}</v-card-title>
        <v-card-text>{{ messageContent }}</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="closeMessageDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title>Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="confirmDelete">Yes</v-btn>
          <v-btn @click="showDeleteDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="replaceDialog" max-width="500">
      <v-card>
        <v-card-title>Replace All</v-card-title>
        <v-card-text>Are you sure you wish to replace all existing topics? This is an irreversible action.</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="replaceTopics = true; replaceDialog = false">Yes</v-btn>
          <v-btn @click="replaceTopics = false; replaceDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, functions, auth } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, setDoc, addDoc, deleteDoc, collection, getDocs, query, where, updateDoc } from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";
import openaiMixin from "@/mixins/openai.mixin";

export default {
  data() {
    return {
      showEditDescriptionDialog: false,
      showAddTopicDialog: false,
      showGenerateContentDialogFlag: false,
      showDeleteDialog: false,
      editable: false,
      websiteLink: '',
      findTopic: "",
      briefDescription: 'This is a brief description of the business.',
      replaceTopics: false,
      replaceDialog: false,
      generatedText: '',
      IdeaBasedtextGenerated: false,
      topics: [],
      textLoading: false,
      generatingText: false,
      ideasGenerated: 0,
      aiSuggestions: [],
      toneMenu: false,
      regenButton: false,
      typeMenu: false,
      selectedAction: "",
      BrandName: '',
      currentTopicIdea: '',
      toneOfVoice: ['Conversational', 'Inspirational', 'Informative', 'Interactive', 'Questioning', 'Explanatory'],
      postTypes: ['Announcement', 'Poll', 'Question', 'Promo', 'Guide'],
      socialNetworks: ['Facebook', 'Twitter', 'LinkedIn', 'Pinterest', 'TikTok', 'Instagram', 'Youtube', 'Reddit'],
      selectedNetwork: '',
      socialNetworksMenu: false,
      selectedNetworks: [],
      tone: '',
      packageError: false,
      toneUpdate: '',
      regenerateSuggestionDialog: false,
      addedSuggestions: '',
      typeUpdate: '',
      ideaBeforeUpdate: '',
      postType: '',
      useBusinessDescription: false,
      tab: 0,
      messageDialog: false,
      messageTitle: '',
      messageContent: '',
      redirect: false,
      itemToDelete: null,
      ideaToUpdate: null,
      generatedContents: [],
      isSmallScreen: false,
      isOnboardingVisible: true,
      dismissed: true,
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
    };
  },
  mixins: [openaiMixin],
  mounted() {
    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreen);
  },
  async beforeMount() {
    try {
      // Run Firestore queries in parallel
      const [topicsSnapshot, generatedContentsSnapshot, businessDataSnapshot] = await Promise.all([
        getDocs(collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas')),
        getDocs(collection(db, 'ContentAiTopics', this.currentUID, 'generated_contents', this.WID, 'generated_contents')),
        getDoc(doc(db, 'users', this.currentUID, "fly_workspaces", this.WID))
      ]);

      // Process topics data
      const tempTopics = topicsSnapshot.docs.map(doc => {
        let temp = { id: doc.id, ...doc.data() };
        this.ideasGenerated += temp.ideas.length;
        return temp;
      });
      this.topics = [...this.topics, ...tempTopics];

      // Process generated content data
      const tempGeneratedContents = generatedContentsSnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      this.generatedContents = [...this.generatedContents, ...tempGeneratedContents];

      // Process business data
      if (businessDataSnapshot.exists()) {
        const businessData = businessDataSnapshot.data();
        this.briefDescription = businessData?.businessDescription || '';
        this.websiteLink = businessData?.websiteLink || '';
        this.BrandName = businessData?.brandName || '';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  computed: {
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    selectedTabName() {
      return this.tab === 0 ? 'Ideas' : 'Generated Content';
    },
    splitTopics() {
      const columns = [[], [], []];
      this.topics.sort((a, b) => b.createdAt - a.createdAt);
      this.topics.forEach((topic, index) => {
        columns[index % 3].push(topic);
      });
      return columns;
    },
    splitGenerated() {
      this.generatedContents = this.generatedContents.sort((a, b) => b.createdAt - a.createdAt);
      return this.generatedContents;
    }
  },
  watch: {
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
  },
  methods: {
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed = data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },
    capitalizeFirstletter(item){
      return item.charAt(0).toUpperCase() + item.slice(1);
    },
    formatTimestamp(timestamp) {
      if (!timestamp || typeof timestamp.seconds !== 'number' || typeof timestamp.nanoseconds !== 'number') {
        const d = new Date();
        return formatDistanceToNow(d, { addSuffix: true });
      }
      const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      return formatDistanceToNow(date, { addSuffix: true });
    },
    checkScreen() {
      this.isSmallScreen = window.matchMedia('(max-width: 438px)').matches;
    },
    goBack() {
      this.generatingText = !this.generatingText;
      return;
    },
    closeSelectDropdown() {
      this.socialNetworksMenu = false;
    },
    async reGenerateText(tone, operation, text, idea, maxChar, postType, suggestion) {
      this.regenerateSuggestionDialog = false;
      this.generatingText = true;
      this.textLoading = true;
      let payload = {
        uid: this.currentUID,
        wid: this.WID,
        postType: postType,
        suggestions: suggestion,
        operation: operation,
        textContent: text,
        tone: tone,
        rephrase: true,
        maxChar: maxChar,
      }
      if (idea !== this.ideaBeforeUpdate) {
        payload = {
          uid: this.currentUID,
          wid: this.WID,
          operation: 'generatePostforIdea',
          textContent: idea,
          generateText: this.generatedText,
          tone: tone,
          postType: postType,
          maxChar: maxChar,
        }
        this.ideaBeforeUpdate = idea;
      }
      const result = await this.getContentAiGenerate(payload);
      this.textLoading = false;
      this.generatedText = result;
      this.IdeaBasedtextGenerated = true;
    },
    async shortenText() {
      let maxChar = Math.floor(this.generatedText.length * 0.7);
      if (maxChar < 50) maxChar = 50;
      await this.reGenerateText(null, 'ctShortening', this.generatedText, this.currentTopicIdea, maxChar, null, null);
    },
    async expandText() {
      let maxChar = Math.floor(this.generatedText.length * 1.3);
      if (maxChar > 500) maxChar = 500;
      await this.reGenerateText(null, 'ctExpanding', this.generatedText, this.currentTopicIdea, maxChar, null, null);

    },
    async changeTone(tone) {
      this.toneUpdate = tone;
      this.toneMenu = false;
    },
    async changeType(type) {
      this.typeUpdate = type;
      this.typeMenu = false;
    },
    isValidUrl(url) {
      const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/[^\s]*)?$/;
      return urlPattern.test(url);
    },
    correctURL(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }
      url = url.replace(/^(https?:\/\/)(www\.)?/i, '$1www.');

      return url;
    },
    async checkInFirestore(description, url) {
      let dataExits = false;
      let querySnapshot;
      if (description !== '') {
        const q = query(collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas'), where('description', '==', description));
        querySnapshot = await getDocs(q);
      } else if (url !== '') {
        const q = query(collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas'), where('url', '==', url));
        querySnapshot = await getDocs(q);
      }

      if (!querySnapshot.empty) {
        dataExits = querySnapshot.docs.map(doc => doc.data());
      }
      return dataExits || this.aiSuggestions.length > 0;
    },
    async updateDescription() {
      this.showEditDescriptionDialog = false;
      this.briefDescription = this.briefDescription.trim();
      try {
        if(this.replaceTopics){
          this.aiSuggestions = [];
        }
        const exists = await this.checkInFirestore(this.briefDescription, this.websiteLink);
        let alreadyPresentData = [];
        if (exists) {
          this.$loader.show({
            message: "Sit back and relax while I generate topic ideas for you",
            type: "ai",
          });
          if (exists.length > 0) {
            exists.forEach((item) => {
              alreadyPresentData.push(item.title);
            });
          }
          if (this.aiSuggestions.length > 0) {
            this.aiSuggestions?.forEach((item) => {
              alreadyPresentData.push(item.topic);
            })
          }
          let payload;
          if (this.briefDescription!=='') {
            payload = {
              operation: "generateTopicsAndIdeas",
              textContent: `${this.briefDescription}`,
              topics: `${alreadyPresentData.join(", ")}`,
              tone: 'Informative',
              maxSuggestions: 5,
            }
            await this.addThroughDescription(payload);
          }
          else if (this.websiteLink !== '') {
            await this.addThroughLink(this.websiteLink, alreadyPresentData);
          }
          // const result = await this.getTopicsIdeas(payload);
          // if (!result.topics) {
          //   this.packageError = true;
          // }
          // this.appendToAiSuggestions(result.topics, this.briefDescription, '');
        }
        else if (this.briefDescription !== '') {
          let payload = {
            operation: "generateTopicsAndIdeas",
            textContent: `${this.briefDescription}`,
            topics: `${alreadyPresentData.join(", ")}`,
            tone: 'Informative',
            maxSuggestions: 5,
          }
          await this.addThroughDescription(payload);
        } else if (this.websiteLink !== '') {
          await this.addThroughLink(this.websiteLink, alreadyPresentData);
        }
        if (this.websiteLink !== '' || this.briefDescription !== '') {
          const businessRef = doc(db, 'users', this.currentUID, "fly_workspaces", this.WID);
          await updateDoc(businessRef, {
            brandName: this.BrandName,
            websiteLink: this.websiteLink,
            businessDescription: this.briefDescription
          });
          if (this.replaceTopics) {
            this.replaceTopics = false;
            let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
            const querySnapshot = await getDocs(topicsRef);
            const deletePromises = querySnapshot.docs.map(docSnapshot => deleteDoc(doc(db, topicsRef.path, docSnapshot.id)));
            await Promise.all(deletePromises);
            this.topics = [];
            this.ideasGenerated = 0;
          }
        }
      } catch (error) {
        console.log("Update Description in content AI error", error);
        if (this.websiteLink == '' && this.briefDescription == '') this.showMessageDialog("Failed", "Enter description Or URL", true);
        else if (this.packageError) {
          this.packageError = false;
          this.showMessageDialog("Failed", "Your ai credits has exhausted", true);
        }
        else this.showMessageDialog("Failed", "Unable to update description", true);
      }
      this.$loader.hide();
    },
    async addThroughLink(link, alreadyPresentData) {
      this.websiteLink = link
      if (!this.isValidUrl(this.websiteLink)) {
        this.showMessageDialog("Failed", "Please enter a valid URL in this format (https://example.com)", true);
        return;
      }
      this.websiteLink = this.correctURL(this.websiteLink);
      this.$loader.show({
        message: "Sit back and relax while I generate topic ideas for you",
        type: "ai",
      });
      let payload = {
        operation: "generateURLContent",
        url: this.websiteLink,
        uid: this.currentUID,
        wid: this.WID,
      }
      let aiActions = httpsCallable(functions, "aiActions", {
        timeout: 540000,
      });
      let { result } = (await aiActions(payload)).data;
      if (!result) {
        this.packageError = true;
      }
      if (Array.isArray(result)) result = result[0];
      let title = result.title;
      let description = result.description;
      let payloadTextContent = `Title:${title},Description:${description}`
      payload = {
        operation: "generateTopicsAndIdeas",
        textContent: payloadTextContent,
        topics: `${alreadyPresentData.join(", ")}`,
        tone: 'Informative',
        url:this.websiteLink||'',
        maxSuggestions: 5,
      }
      result = await this.getTopicsIdeas(payload);
      this.appendToAiSuggestions(result.topics, '');
    },
    async addThroughDescription(payload) {
      const result = await this.getTopicsIdeas(payload);
      if (!result.topics) {
        this.packageError = true;
      }
      this.appendToAiSuggestions(result.topics, this.briefDescription);
    },
    appendToAiSuggestions(data, description) {
      const suggestions = data.map(topic => ({
        ...topic,
        description: description,
        url: topic.url||'',
        createdAt: new Date(),
        showIdeas: 3
      }));
      this.aiSuggestions = [...this.aiSuggestions, ...suggestions];
    },
    async findIdeas() {
      this.showAddTopicDialog = false;
      if (this.findTopic != '') {
        this.$loader.show({
          message: "Sit back and relax while I generate topic idea for you",
          type: "ai",
        });
        const payload = {
          operation: "generateTopicsAndIdeas",
          textContent: this.findTopic,
          tone: 'Informative',
          maxSuggestions: 1,
        }
        const result = await this.getTopicsIdeas(payload);
        let tempTopic = result.topics[0];
        const q = query(collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas'), where('title', '==', tempTopic.topic));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          await deleteDoc(docRef);
          let topicRemove = this.topics.find(t => t.title == tempTopic.topic);
          this.topics = this.topics.filter(t => t.title !== tempTopic.topic);
          this.ideasGenerated -= topicRemove.ideas.length;
        }
        tempTopic.description = this.findTopic;
        await this.addTopic(tempTopic);
      }
      this.$loader.hide();
      this.$snackbar.show("New Topic Added To List");
      this.findTopic = '';
    },
    cancelAddTopic() {
      this.showAddTopicDialog = false;
    },
    async addTopic(suggestion) {
      let newTopic = {
        title: suggestion.topic,
        ideas: suggestion.ideas,
        createdAt: new Date(),
        url: suggestion.url || '',
        description: suggestion.description || '',
        showIdeas: 3
      };
      let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
      this.aiSuggestions = this.aiSuggestions.filter(s => s !== suggestion);
      let newTopicId = await addDoc(topicsRef, newTopic);
      this.topics.unshift({ id: newTopicId.id, ...newTopic });
      this.showAddTopicDialog = false;
      this.ideasGenerated += newTopic.ideas.length;
    },
    async deleteTopic(topic) {
      let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
      const topicDoc = doc(topicsRef, topic.id);
      this.ideasGenerated -= topic.ideas.length;
      this.topics = this.topics.filter(t => t !== topic);
      await deleteDoc(topicDoc);
    },
    getMoreIdeas(topic) {
      const additionalIdeas = Math.min(topic.ideas.length - topic.showIdeas, 3);
      topic.showIdeas += additionalIdeas;
      if (topic.showIdeas >= topic.ideas.length) {
        topic.showIdeas = topic.ideas.length;
      }
    },
    showGenerateContentDialog(idea, ideaIndex) {
      this.currentTopicIdea = idea;
      this.showGenerateContentDialogFlag = true;
    },
    async generateText(idea, tone, postType) {
      this.ideaBeforeUpdate = idea;
      if (this.briefDescription !== '') {
        idea = `${idea}, ${this.briefDescription}`
      }
      if (tone == '') {
        tone = 'Informative'
      }
      let mchar = 320;
      if (this.selectedNetworks.includes('Twitter')) {
        mchar = 280;
      }
      this.generatingText = true;
      this.textLoading = true;
      const payload = {
        uid: this.currentUID,
        wid: this.WID,
        operation: "generatePostforIdea",
        textContent: idea,
        tone: tone,
        targetSocialMedia: `${this.selectedNetworks.join(", ")}`,
        maxChar: mchar,
        postType: postType || "Announcement",
      }
      const result = await this.getContentAiGenerate(payload);
      this.textLoading = false;
      this.generatedText = result;
      this.IdeaBasedtextGenerated = true;
      const generateContentStep = this.onboardingSteps.find(step => step.key === "is_content_ai_generated");
  if (generateContentStep && !generateContentStep.completed) {
    generateContentStep.completed = true;
    await this.updateOnboardingStepsInFirestore(); // Update the onboarding steps in Firestore
  }
    },
    async SaveGenerated(idea) {
      let newFormat = this.generatedText;
      this.$loader.show({ message: "Saving...." });
      if (Array.isArray(this.generatedText)) {
        newFormat = this.generatedText[0];
      }
      const content = {
        title: idea,
        content: newFormat,
        tone: this.tone || 'informative',
        postType: this.postType || "announcement",
        tags: [],
        createdAt: new Date(),
      };
      const generatedContentsRef = collection(db, 'ContentAiTopics', this.currentUID, 'generated_contents', this.WID, 'generated_contents');
      let newGeneratedId = await addDoc(generatedContentsRef, content);
      this.$loader.hide();
      this.generatedContents.push({ id: newGeneratedId.id, ...content });
      this.cancelGeneration();
      this.$snackbar.show("The idea has been saved");
    },
    async CreateWithPost(text) {
      if (Array.isArray(text)) {
        text = text[0];
      }
      if (text !== '') {
        this.$router.push({
          name: "create-post", params: {
            text: text
          }
        })
      }
      this.cancelGeneration();
    },
    async updateIdea() {
      if (this.ideaToUpdate !== null) {
        let { topic, ideaIndex } = this.ideaToUpdate;
        topic.ideas[ideaIndex] = this.currentTopicIdea;
        let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
        const topicDoc = doc(topicsRef, topic.id);
        await setDoc(topicDoc, topic);
      }
    },
    async cancelGeneration() {
      this.showGenerateContentDialogFlag = false;
      await this.updateIdea();
      this.IdeaBasedtextGenerated = false;
      this.generatingText = false;
      this.selectedNetworks = [];
      this.postType = '';
      this.tone = '';
      this.generatedText = '';
      this.tone = '';
      this.toneUpdate = '';
      this.addedSuggestions = '';
      this.ideaToUpdate = null;
    },
    addNetwork(selectedItems) {
      this.selectedNetworks = [];
      selectedItems.forEach(item => {
        this.selectedNetworks.push(item);
      });
    },
    addPostType(selectedItem) {
      this.postType = selectedItem;
    },
    addTone(selectedItem) {
      this.tone = selectedItem;
    },
    async deleteIdea(topic, ideaIndex) {
      topic.ideas.splice(ideaIndex, 1);
      this.ideasGenerated--;
      let topicsRef = collection(db, 'ContentAiTopics', this.currentUID, 'ideas', this.WID, 'ideas');
      const topicDoc = doc(topicsRef, topic.id);
      await setDoc(topicDoc, topic);
    },
    showMessageDialog(title, message, redirect = false) {
      this.messageTitle = title;
      this.messageContent = message;
      this.messageDialog = true;
      this.redirect = redirect;
    },
    closeMessageDialog() {
      this.messageDialog = false;
    },
    async confirmDelete(type,topic,ideaIndex,content) {
      // const { type, topic, ideaIndex, content } = this.itemToDelete;
      this.$loader.show({
            message: "Deleting...",
          });
      if (type === 'topic') {
        await this.deleteTopic(topic);
      } else if (type === 'idea') {
        await this.deleteIdea(topic, ideaIndex);
      } else if (type === 'content') {
        await this.deleteContent(content);
      }
      this.$loader.hide();
      this.showDeleteDialog = false;
    },
    async deleteContent(content) {
      const generatedContentsDoc = doc(db, 'ContentAiTopics', this.currentUID, 'generated_contents', this.WID, 'generated_contents', content.id);
      await deleteDoc(generatedContentsDoc);
      this.generatedContents = this.generatedContents.filter(c => c.id !== content.id);
    }
  },
};
</script>

<style scoped>
.layout-columns {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.wrap-generateText {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: 6rem; */
}


.align-top-textGen {
  align-items: first baseline;
}

.dialog-content {
  overflow-y: auto;
  max-height: 60vh;
}

.column {
  flex: 1;
  min-width: 300px;
  max-width: 100%;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.btn-icon {
  font-size: 22px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.btn-icon:hover {
  background-color: #d3d3d3;
  border-radius: 50%;
}

.align-top {
  align-self: flex-start;
  font-size: 15px;
}

.align-top-del {
  padding-top: 3px;
  align-self: flex-start;
}

.row {
  justify-content: space-between;
  align-items: center;
}




.mb-4 {
  margin-bottom: 1rem;
}

.btn {
  background: #8056de;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.btn:hover {
  background: #6840b0;
}

.link {
  cursor: pointer;
  color: #8056de;
  text-decoration: none;
}

.link-get {
  cursor: pointer;
  color: blue;
}

.card-list {
  margin-bottom: 1rem;
}

.specify_btn {
  display: flex;
  align-items: center;
  gap: 4rem;

}

.card-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.flex-grow {
  flex-grow: 1;
}


.flex-gap {
  display: flex;
  gap: 8px;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.optional-text {
  color: #636B8C;
  font-size: 0.875rem;
}

.selected-networks {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.selected-network {
  display: flex;
  align-items: center;
  background: #e0e0e0;
  padding: 0.5rem;
  border-radius: 9999px;
}

.remove-icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.darkened-text {
  color: #333;
  font-weight: 500;
}

.darkened-text>>>.v-label {
  font-size: 1.1rem !important;
  /* Adjust the size as needed */

  font-weight: bold;
}

.edit-icon {
  width: 1.5rem;
  height: 1.5rem;
  align-items: baseline;
  cursor: pointer;
  margin-left: 8px;
}

.skeleton-loading {
  width: 100%;
  height: 96px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@media (min-width: 768px) {
  .column {
    max-width: calc(33.333% - 1rem);
  }
}

@media (max-width: 767px) {
  .column {
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .column {
    max-width: 100%;
  }

  .specify_btn {
    gap: 0.3rem;
  }

}
</style>
