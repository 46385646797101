<template>
    <v-container class="text-center">
        <v-card class="mx-auto" max-width="400">
            <v-card-title class="headline">Accept Invitation</v-card-title>
            <v-card-text>
                <p>You have been invited to join the workspace.</p>
                <div v-for="item in workspaces" class="flex flex-start">
                    <p class="font-semibold">Workspace Name: {{ item.name }}</p>
                </div>
                <v-btn color="primary mt-3" @click="acceptInvitation" :loading="loading">Accept Invitation</v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { functions, db, auth } from "@/utils/firebase.utils";

export default {
    data() {
        return {
            loading: false,
            workspaces:[],
        };
    },
    async mounted(){
        let sender_id = this.$route.query.sender_id;
        let uid = this.$route.query.uid; 
        const teamMemberDocRef = doc(db, "users", sender_id, "team", uid);
        const teamMemberDoc = await getDoc(teamMemberDocRef);

        if(!teamMemberDoc.exists()){
            window.location.href = "/";
            return ;
        }
        const data = teamMemberDoc.data().workSpaceToBeAdded;
        data.workspaceData.map((w)=>{
            this.workspaces.push(w);
        })


    },
    methods: {
        isValidEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },
        async acceptInvitation() {
            this.loading = true;
            const sender_id = this.$route.query.sender_id; // Get sender_id from URL
            let uid = this.$route.query.uid; // Get uid from URL

            try {
                const teamMemberDocRef = doc(db, "users", sender_id, "team", uid);
                const teamMemberDoc = await getDoc(teamMemberDocRef);

                if (!teamMemberDoc.exists()) {
                    this.$router.push('/');
                    
                    return; // Early return if the document does not exist
                }
                if(this.isValidEmail(uid)) uid = `${auth.currentUser.uid}__${uid}`;
                const acceptInvite = httpsCallable(functions, 'acceptInvitationFunction');
                const response = await acceptInvite({ sender_id, uid });
                if (response.data.success) {
                    this.$store.dispatch('acceptTeamInvite', true);
                    this.$router.push({ name: 'calendar', params: { invite_id: sender_id } });
                } else {
                    this.$alert.show("Failed to accept the invitation. Please try again.");
                }
            } catch (error) {
                console.error("Error accepting invitation:", error);
                this.$alert.show("Failed to accept the invitation. Please try again.");
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.v-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
