<template>
  <div
    class="video-container"
    @mouseenter="playVideo"
    @mouseleave="pauseVideo"
    @click="toggleVideoSelection"
  >
    <video
      style="width: 100% !important; height: 100% !important"
      ref="video"
      controls
      class="object-cover"
      @volumechange="handleVolumeChange"
      playsinline
      preload="auto"
      @loadedmetadata="handleLoadedMetadata"
    >
      <source :src="videoSrc" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: String,
    videoId: String,
    playOnHover: Boolean,
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    muted() {
      return this.$store.getters.muted;
    },
  },
  data() {
    return {
      playTimeout: null,
    };
  },
  mounted() {
    if (this.autoplay) {
      this.observeVideo();
    }
    this.$refs.video.muted = this.muted;
  },
  methods: {
    handleVolumeChange() {
      let muted = this.$refs.video.muted;
      let videos = document.querySelectorAll("video");
      Array.from(videos).forEach((i) => {
        i.muted = muted;
      });
      this.$store.commit("SET_MUTED", muted);
    },
    observeVideo() {
      const options = {
        root: null, // use the viewport as the root
        rootMargin: "0px",
        threshold: 1, // 50% of the video should be in the viewport
      };

      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );
      observer.observe(this.$refs.video);
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let videoElements = document.querySelectorAll("video");
          for (let i = 0; i < videoElements.length; i++) {
            videoElements[i].pause(); // or videojs(videoElements[i]).pause()
          }
          setTimeout(() => {
            if (this.$refs.video) this.$refs.video.play();
          }, 100);
        } else {
          let videoElements = document.querySelectorAll("video");
          for (let i = 0; i < videoElements.length; i++) {
            videoElements[i].pause(); // or videojs(videoElements[i]).pause()
          }
        }
      });
    },
    playVideo() {
      if (this.playOnHover && this.$refs.video) {
        const playPromise = this.$refs.video.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started
            })
            .catch((error) => {
              // Auto-play was prevented or playback failed to start
              console.error("Playback failed to start:", error);
            });
        }
      }
    },
    pauseVideo() {
      if (this.playOnHover && this.$refs.video) {
        // No need to check the promise here since pause is synchronous
        this.$refs.video.pause();
      }
    },
    toggleVideoSelection() {
      this.$emit("toggle-selection"); // Emit a custom event to toggle selection
    },
    handleLoadedMetadata() {
      const videoDuration = this.$refs.video.duration;
      this.$emit("fetchedDuration", {
        videoId: this.videoId,
        duration: videoDuration,
      });
    },
  },
};
</script>
<style scoped>
.video-container {
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
}
</style>
