import Konva from "konva";
export default {
  methods: {
    async addBackground({ stage, layer, url }) {
      return new Promise((resolve) => {
        var konvaImg = new Konva.Image({
          width: stage.width(),
          height: stage.height(),
        });

        // Load the image
        var img = new Image();
        img.onload = function () {
          let scaleFactor = Math.max(
            stage.width() / img.width,
            stage.height() / img.height
          );
          let newWidth = img.width * scaleFactor;
          let newHeight = img.height * scaleFactor;
          let x = stage.width() / 2 - newWidth/2 ;
          let y = stage.height() / 2 - newHeight/2 ;
          konvaImg.width(newWidth);
          konvaImg.height(newHeight);
          konvaImg.x(x);
          konvaImg.y(y);
          konvaImg.image(img);
          layer.add(konvaImg);
          layer.batchDraw();
          resolve();
        };
        img.onerror = function () {
          resolve();
        };
        img.src = url;
      });
    },
    async addLogo({ layer, y = 50, stage, form, url }) {
      return new Promise((resolve) => {
        let height = 75;
        let width = 75;
        var konvaImg = new Konva.Image({
          width,
          height,
          x: 15,
          y,
        });
        if (form.logoPosition == "Top Right") {
          konvaImg.x(stage.width() - width - 15);
          konvaImg.y(15);
        }
        // Load the image
        var img = new Image();
        img.onload = function () {
          var imgAspectRatio = img.width / img.height;

          // Calculate the aspect ratio of the stage
          var stageAspectRatio = width / height;

          // Adjust image size based on aspect ratios
          if (imgAspectRatio > stageAspectRatio) {
            konvaImg.width(width);
            konvaImg.height(width / imgAspectRatio);
          } else {
            konvaImg.height(height);
            konvaImg.width(height * imgAspectRatio);
          }
          konvaImg.image(img);
          layer.add(konvaImg);
          layer.batchDraw();
          resolve();
        };
        img.crossOrigin = "Anonymous";
        img.onerror = function () {
          resolve();
        };
        img.src = url;
      });
    },
    async getOverlay({ url }) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const quality = 0.9; // Adjust quality between 0 and 1
          const base64 = canvas.toDataURL("image/jpeg", quality);          
          resolve(base64);
        };
        img.onerror = function () {
          reject(new Error("Failed to load image."));
        };
        img.src = url;
      });
    },
  },
};
