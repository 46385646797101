<template>
  <div class="text-center">
    <v-dialog v-model="loader" persistent :max-width="300">
      <v-card color="primary" dark>
        <v-card-text>
          <div class="white--text font-weight-medium">
            {{ currentIndex }} of {{ total }} Uploaded
          </div>
          <v-progress-linear
            color="white"
            v-model="progress"
            class="mb-0 mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loader: false,
      progress: 50,
      total: 0,
      currentIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 70px;
}
.v-card__text {
  padding-top: 17px !important;
}
</style>
