<template>
  <div style="width: 50%">
    <button
      block
      depressed
      text
      color="primary"
      @touchstart.prevent="dialog = true"
      @click.prevent="dialog = true"
    >
      <v-icon color="primary" class="p-2 d-flex">mdi-pencil</v-icon>
    </button>
    <v-dialog
      persistent
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      v-if="dialog"
    >
      <dialog-card :isSubmit="false" @close="dialog = false">
        <update-gallery-video :id="id" @onEdit="onEdit" />
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogCard from "../ui/DialogCard.vue";
import UpdateGalleryVideo from "@/pages/fly/UpdateGalleryVideo.vue";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { DialogCard, UpdateGalleryVideo },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onEdit(event) {
      this.dialog = false;
      this.$emit("onEdit", event);
    },
  },
};
</script>

<style></style>
