<template>
  <div>
    <label class="input-label" v-if="label">{{ label }}</label>
    <v-radio-group
      v-model="input"
      :row="row"
      class="mt-0"
      :dense="dense"
      outlined
      @change="$emit('input', input)"
      v-bind="$attrs"
      :rules="getRules"
    >
      <v-radio
        v-for="(item, i) in items"
        :key="i"
        :value="item.value"
        :disabled="item.disabled === true"
        :label="item.name"
      >
      </v-radio>
      <template v-slot:append>
        <v-tooltip bottom v-if="help">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ help }}</span>
        </v-tooltip>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  mixins: [inputMixin],
};
</script>
