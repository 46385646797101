<template>
    
  
  <div class="flex gap-0 bg-slate-100 max-md:flex-wrap">
    <div  v-if="showProductServiceForm"  class="flex flex-col flex-1 max-md:max-w-full">
    <div
    class="flex flex-col  self-center pt-7 pr-20 pb-12 pl-6 mt-8 w-full bg-white rounded-3xl max-w-[1285px] max-md:px-5 max-md:max-w-full"
  >
    <div
      class="flex flex-col px-5 py-6 max-w-full rounded-xl bg-slate-100 w-[624px]"
    >
      <div
        class="flex gap-5 justify-between max-w-full whitespace-nowrap w-[336px]"
      >
        <div class="flex flex-col">
          <div class="text-base text-gray-950">Product / Service Details</div>
         
        </div>
      
      </div>
      <div class="self-start mt-10 text-xs text-slate-500 max-md:mt-10">
          Name
        </div>
      <div
        class="flex gap-5 justify-between mt-3 text-xs text-neutral-900 max-md:flex-wrap max-md:max-w-full"
      >
      
        
        <input type="text"
        v-model="catalogue.name"
          class="grow justify-center items-start py-3.5 pr-16 pl-3.5 whitespace-nowrap bg-white rounded-xl max-md:pr-5"
       />
       
      
      </div>
      <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
        Description
      </div>
      <textarea 
      v-model.trim="catalogue.description"
        class="items-start pt-3.5 pr-16 pb-20 pl-3.5 mt-3 text-xs whitespace-pre-wrap bg-white rounded-xl text-neutral-900 max-md:pr-5 max-md:max-w-full"
      >
       
      </textarea>
      <div class="mt-7 text-xs text-slate-500 max-md:max-w-full">Images</div>
<div class="flex flex-col justify-center items-start py-3.5 pr-16 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full">
  <input type="file" 
         ref="fileInput" 
         multiple 
         accept="image/*" 
         @change="handleFileChange" 
         hidden>
  <div class="flex gap-2">
    <img v-for="(image, index) in catalogue.images" :key="index"
         :src="image"
         class="aspect-square w-[82px] cursor-pointer"
         :alt="'Image ' + (index + 1)"
         @click.stop="openFileInput">
    <img v-if="catalogue.images.length === 0"
         loading="lazy"
         src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
         class="aspect-square w-[82px] cursor-pointer"
         @click.stop="openFileInput">
    <img v-else
         loading="lazy"
         src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
         class="aspect-square w-[82px] cursor-pointer"
         @click.stop="openFileInput">
  </div>
</div>

      <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
        Price / Fee
      </div>
      <input type="text"
      v-model="catalogue.price"
        class="justify-center items-start py-4 pr-16 pl-3.5 mt-3 text-xs whitespace-nowrap bg-white rounded-xl text-neutral-900 max-md:pr-5 max-md:max-w-full"
      >
     
      
      <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
        Key Features & Highlights
      </div>
      <div v-for="(feature, index) in catalogue.key_features_highlights" :key="index" class="mt-6">
  <input type="text"
         v-model="catalogue.key_features_highlights[index]"
         class="justify-center items-start py-4 pr-16 pl-3.5 mt-3 text-xs whitespace-nowrap bg-white rounded-xl text-neutral-900 max-md:pr-5 w-3/4" />
  <button v-if="index === catalogue.key_features_highlights.length - 1" @click="addKeyFeature" class="ml-2"> <v-icon>mdi-plus</v-icon></button>
</div>

       
      
      <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
        Product & Service Description Web Page
      </div>
      <input type="text"
      @input="validateProductServicePage"
      v-model="catalogue.product_service_description_web_page"
        class="justify-center items-start py-4 pr-16 pl-3.5 mt-3 text-xs whitespace-nowrap bg-white rounded-xl text-neutral-700 max-md:pr-5 max-md:max-w-full"
      />
      <div v-if="productServicePageError" class="text-red-500 text-xs">{{ productServicePageError }}</div>

    </div>
    <div
      class="flex gap-5 justify-between mt-6 mb-36 ml-9 max-w-full text-base font-semibold whitespace-nowrap w-[588px] max-md:flex-wrap max-md:mb-10"
    >
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">
  {{ snackbar.message }}
</v-snackbar>
    <div v-if="typeof catalogue.index === 'undefined'" class=" cursor-pointer justify-center px-8 py-1.5 text-white bg-violet-500 rounded-3xl max-md:px-5" @click="saveCatalogueItem">
  Save
</div>
<div v-else class="justify-center px-8 py-1.5 text-white bg-violet-500 rounded-3xl max-md:px-5" @click="updateCatalogueItem">
  Update
</div>
     
      <div class="w-[114px] h-[38px] justify-center items-center inline-flex cursor-pointer" @click="toggleProductServiceForm">
    <div class="w-[114px] h-[38px] relative">
        <div class="w-[40.44px] h-[19.04px] left-[36.38px] top-[8.17px] absolute text-slate-500 text-base font-semibold font-['Poppins']">Back</div>
        <div class="w-[114px] h-[38px] left-0 top-0 absolute bg-white bg-opacity-0 rounded-3xl border-2 border-slate-200"></div>
    </div>
</div>

      
    </div>
  </div>
  </div>
  <div v-else-if="!hasProductsOrServices" class="flex justify-center items-center self-center px-16 py-12 mt-8 w-full bg-white rounded-3xl max-w-[1285px] max-md:px-5 max-md:max-w-full">
      <div class="flex flex-col mt-44 mb-96 max-w-full w-[287px] max-md:my-10">
        <div class="text-2xl font-medium text-center text-gray-950">
          No Product / Service listed
        </div>
        <div class="justify-center self-center px-8 py-3.5 mt-8 text-base font-semibold text-white bg-violet-500 rounded-3xl max-md:px-5"  @click="toggleProductServiceForm">
          Add
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col flex-1 max-md:max-w-full ">
  <div class="flex flex-col self-center px-6 pt-6 pb-12 mt-8 w-full bg-white rounded-3xl max-w-[1285px] max-md:px-5 max-md:max-w-full">
    <div class="flex items-center gap-2 mb-4 text-2xl font-medium">
      <router-link to="/brand-info" class="text-blue-500 ">{{ businessDetails.businessName }}</router-link>
      <span>&gt;</span>
      <span>Product / Service Catalogue</span>
    </div>
    <div class="grid grid-cols-12 gap-4 py-4 mt-6 w-full text-xs bg-slate-100 rounded-md max-md:px-5 max-md:max-w-full">
      <div class="col-span-3 pl-6 font-semibold text-gray-950">NAME</div>
      <div class="col-span-2 font-medium text-slate-500">DATE</div>
      <div class="col-span-2 font-medium text-slate-500">PRICE / FEE</div>
      <div class="col-span-2 font-medium text-slate-500">EDIT</div>
      <div class="col-span-2 font-medium text-slate-500">DELETE</div>
    </div>
    <div v-for="(item, index) in businessDetails.productsServicesCatalogue" :key="index" class="mt-5 grid grid-cols-12 gap-4">
      <div class="col-span-3 flex gap-4">
        <img v-if="item.images.length > 0" :src="item.images[0]" class="rounded-full aspect-square w-[45px]" />
        <div class="flex flex-col my-auto">
          <div class="text-xs text-neutral-900">{{ item.name }}</div>
         
        </div>
      </div>
      <div class="col-span-2 flex flex-col my-auto">
        <div class="text-xs text-neutral-900">{{ item.date }}</div>
        <div class="mt-1.5 text-xs font-medium text-slate-500">Start Date</div>
      </div>
      <div class="col-span-2 text-xs text-neutral-900 my-auto">{{ item.price }}</div>
      <div class="col-span-2">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/06ee5264bd62b2ac50aa1780ff2c2899eb357221c6cca830e18d6e17c615ae18?"
          class="aspect-square w-[31px] cursor-pointer"
          @click="editCatalogueItem(item, index)"

        />
      </div>
      <div class="col-span-2">
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">
  {{ snackbar.message }}
</v-snackbar>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9a5d890c6d08dea1f52cf227c70c999888ff09f7c05a69080f1f770b69c7285?"
          class="aspect-square w-[31px] cursor-pointer"
          @click="deleteCatalogueItem(index)"
        />
      </div>
    </div>
    <div class="flex justify-end px-6 mt-8">
      <div
        class="px-8 py-3.5 text-white bg-violet-500 rounded-3xl cursor-pointer"
        @click="toggleProductServiceForm"
      >
        Add
      </div>
    </div>
  </div>
</div>



  </div>

  </template>
  <script>
  import { v4 as uuidv4 } from "uuid";
  import "firebase/firestore";
  import { storage, flyplans } from "@/utils/firebase.utils";
  import {
    getDownloadURL,
    ref,
    uploadString,
    uploadBytes,
  } from "firebase/storage";
  import {
    doc,
    updateDoc,
    collection,
    addDoc,
    getDocs,
    getDoc,
  } from "firebase/firestore";
  import { httpsCallable } from "firebase/functions";
  import { auth, colUsers, db, functions } from "@/utils/firebase.utils.js";
  
  export default {
    components: {},
    data() {
      return {
        step: 1,
        snackbar: {
      show: false,
      message: '',
      timeout: 3000, 
      color: 'success', 
    },
        showProductServiceForm: false,
        brandCollectionExists: false,
        imageModal: false,
        selectedImage: "",
        showColorPicker: false,
        showSecondaryColorPicker: false,
        catalogueDialog: false,
        ageGroups: [
        { label: 'Under 18', value: 'under_18' },
        { label: '18-24', value: '18_24' },
        { label: '25-34', value: '25_34' },
        { label: '35-44', value: '35_44' },
        { label: '45-54', value: '45_54' },
        { label: '55-64', value: '55_64' },
        { label: '65+', value: '65_plus' },
        {label: 'All', value: 'all'}
      ],
      genders: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Non-binary', value: 'non_binary' },
        { label: 'All', value: 'all' }
      ],
      incomeLevels: [
        { label: 'Under $20k', value: 'under_20k' },
        { label: '$20k to $39k', value: '20k_39k' },
        { label: '$40k to $59k', value: '40k_59k' },
        { label: '$60k to $79k', value: '60k_79k' },
        { label: '$80k to $99k', value: '80k_99k' },
        { label: '$100k to $149k', value: '100k_149k' },
        { label: '$150k to $199k', value: '150k_199k' },
        { label: '$200k+', value: '200k_plus' },
        { label: 'All', value: 'all' }
      ],
      educationLevels: [
        { label: 'Some high school', value: 'some high school' },
        { label: 'High school graduate', value: 'high school graduate' },
        { label: 'Some college', value: 'some college' },
        { label: 'College graduate', value: 'college graduate' },
        { label: 'Postgraduate', value: 'postgraduate' },
        { label: 'All', value: 'all' }
      ],
      employmentStatuses: [
        { label: 'Full-time', value: 'full-time' },
        { label: 'Part-time', value: 'part-time' },
        { label: 'Self-employed', value: 'self-employed' },
        { label: 'Job Seeker', value: 'job seeker' },
        { label: 'Student', value: 'student' },
        { label: 'Unable to work', value: 'unable to work' },
        { label: 'All', value: 'all' }
      ],
      maritalStatuses: [
        { label: 'Single', value: 'single' },
        { label: 'In a relationship', value: 'in a elationship' },
        { label: 'Married', value: 'married' },
        { label: 'Separated', value: 'separated' },
        { label: 'Divorced', value: 'divorced' },
        { label: 'Widowed', value: 'widowed' },
        { label: 'All', value: 'all' }
      ],
      ethnicitiesRaces: [
        { label: 'Asian', value: 'asian' },
        { label: 'Black/African descent', value: 'black/african descent' },
        { label: 'Hispanic/Latin', value: 'hispanic latin' },
        { label: 'Middle Eastern', value: 'middle eastern' },
        { label: 'Native American', value: 'native american' },
        { label: 'Pacific Islander', value: 'pacific islander' },
        { label: 'Two or more races', value: 'two or mre races'},
        { label: 'White/Caucasian', value: 'white/caucasian' },
        { label: 'All', value: 'all' }
      ],
      interestsHobbies: [
        { label: 'Sports', value: 'sports' },
        { label: 'Music', value: 'music' },
        { label: 'Arts', value: 'arts' },
        { label: 'Travel', value: 'travel' },
        { label: 'Technology', value: 'technology' },
        { label: 'Cooking', value: 'cooking' },
        { label: 'All', value: 'all' }],
        householdSizes: [
        { label: '1 person', value: '1 person' },
        { label: '2 people', value: '2 people' },
        { label: '3-4 people', value: '3-4 people' },
        { label: '5 or more people', value: '5 or more people' },
        { label: 'All', value: 'all' }],
        numberOfChildrenOptions: [
        { label: 'None', value: 'none' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4 or more', value: '4 or more' },
        { label: 'All', value: 'all' }],
        homeOwnershipOptions: [
        { label: 'Own', value: 'own' },
        { label: 'Rent', value: 'rent' },
        { label: 'Live with parents/family', value: 'live with parents family' },
        { label: 'All', value: 'all' }],
        disabilityStatusOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'Prefer not to say', value: 'prefer_not_to_say' },
      ],
      selectedDisabilityStatus: [],
      selectedAgeGroups: [],
      selectedHouseholdSizes: [],
      selectedInterestsHobbies: [],
      selectedMaritalStatuses: [], 
      selectedEmploymentStatuses: [],
      selectedGenders: [],
      selectedIncomeLevels: [],
      selectedEducationLevels: [], 
      selectedEthnicitiesRaces: [],
      selectedNumberOfChildren: [],
      selectedHomeOwnership: [],
      
        catalogue: {
          name: "",
          description: "",
          images: [],
        
          price: 0,
          postFrequency: "",
          key_features_highlights: [""],
          product_service_description_web_page:""
          // Add other fields as needed
        },
        brandImages: {
          logoOriginal: null,
          logoDark: null,
          logoTransparent: null,
        },
        businessDetails: {
          businessName: "",
          Tagline: "",
          website: "",
          pictures_sample_flyers: [],
          placeOfBusiness: "",
          businessDescription: "",
          highlights: [""],
          brandvalues: [""],
          tone_of_voice: [""],
          contenttheme: [""],
          unique_selling_proposition: "",
          purpose: "",
          logoOriginal: null,
          logoDark: null,
          logoTransparent: null,
          primaryColor: "",
          secondaryColor: "",
          primaryFont: "",
          secondaryFont: "",
          productsServicesCatalogue: [],
        },
        fontOptions: [
          { text: "Font 1", value: "font1" },
          { text: "Font 2", value: "font2" },
        ],
      };
    },
    computed: {
    hasProductsOrServices() {
      return this.businessDetails.productsServicesCatalogue.length > 0;
    },
  },
    async created() {
      // try {
      //   const userId = auth.currentUser.uid;
      //   const userDocRef = doc(colUsers, userId,"fly_workspaces", this.WID);
      //   const brandCollectionRef = collection(userDocRef, 'brand');
      //   const brandDocSnapshot = await getDocs(brandCollectionRef);
      //   if (brandDocSnapshot.empty) {
      //     console.error("Brand document not found");
      //     return;
      //   }
      //   const brandDoc = brandDocSnapshot.docs[0].data();
      //   console.log("brnad",brandDoc)
      //   this.businessDetails.productsServicesCatalogue = brandDoc.products_services_catalogue || [];
      // } catch (error) {
      //   console.error("Error fetching business details from Firestore:", error);
      // }
    },
   
    watch: {
      WID() {
        this.getbrand();
      },
    },
    
    methods: {
        // In your methods:
validateProductServicePage() {
  if (!this.catalogue.product_service_description_web_page.startsWith('https://')) {
    this.productServicePageError = 'URL must start with "https://"';
    if (this.catalogue.product_service_description_web_page && !this.catalogue.product_service_description_web_page.startsWith('http')) {
      this.catalogue.product_service_description_web_page = 'https://' + this.catalogue.product_service_description_web_page;
    }
  } else {
    this.productServicePageError = '';
  }
},

        addKeyFeature() {
    this.catalogue.key_features_highlights.push("");
  },
        toggleProductServiceForm() {
      this.showProductServiceForm = !this.showProductServiceForm;
    },
      goToProductServicePage() {
      this.$router.push({ name: 'productservice' });
    },
      addHighlight() {
        this.businessDetails.highlights.push('');
      },
      addbrandvalues() {
        this.businessDetails.brandvalues.push("");
      },
      addcontent() {
        this.businessDetails.contenttheme.push("");
      },
      addtonevoice() {
        this.businessDetails.tone_of_voice.push("");
      },
      // Y
     
      previewImages(event) {
        const input = event.target;
        const preview = document.getElementById("sample_flyers_preview");
        const files = input.files;
  
        // Clear the preview container before adding new images
        preview.innerHTML = "";
  
        // Iterate through the selected files and create image previews
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
  
          reader.onload = function (e) {
            const img = document.createElement("img");
            img.src = e.target.result;
            img.classList.add("preview-image");
            preview.appendChild(img);
          };
  
          reader.readAsDataURL(file);
        }
      },
  
      openFileDialog(refName) {
        // Trigger click event on the file input to open the file dialog
        this.$refs[refName].click();
      },
      openFileInput() {
    this.$refs.fileInput.click();
  },
  handleFileChange(event) {
  const files = event.target.files;
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.catalogue.images.push(e.target.result); // Append the new image to the array
      };
      reader.readAsDataURL(file);
    }
  }
},
  
      nextStep() {
        if (this.step < 5) {
          this.step++;
        }
      },
      prevStep() {
        if (this.step > 1) {
          this.step--;
        }
      },
      editCatalogueItem(item, index) {
    this.catalogue = { ...item, index }; // Store the item data and its index
    this.showProductServiceForm = true;
  },
  async updateCatalogueItem() {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
    const brandCollectionRef = collection(userDocRef, "brand");
    const brandDocSnapshot = await getDocs(brandCollectionRef);
    const brandDoc = brandDocSnapshot.docs[0];

    // Update the item in the productsServicesCatalogue array
    this.businessDetails.productsServicesCatalogue[this.catalogue.index] = { ...this.catalogue };
    delete this.catalogue.index; // Remove the index property before saving

    // Update Firestore document
    await updateDoc(brandDoc.ref, {
      products_services_catalogue: this.businessDetails.productsServicesCatalogue,
    });

    this.showProductServiceForm = false; // Hide the form after updating
    this.snackbar.message = "Catalogue item updated successfully!";
  this.snackbar.color = 'success'; // Set the color to indicate success
  this.snackbar.show = true; 
  },
      async deleteImageFromCatalogue(itemIndex, imageIndex) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
  
          if (brandDocSnapshot.empty) {
            console.error("Brand document not found");
            return;
          }
  
          const brandDoc = brandDocSnapshot.docs[0];
          const updatedCatalogue = [
            ...brandDoc.data().products_services_catalogue,
          ];
  
          // Remove the image from the images array of the corresponding item
          updatedCatalogue[itemIndex].images.splice(imageIndex, 1);
  
          // Update Firestore document with the modified catalogue
          await updateDoc(brandDoc.ref, {
            products_services_catalogue: updatedCatalogue,
          });
  
          // Update local data to reflect changes in UI
          this.$set(
            this.businessDetails,
            "productsServicesCatalogue",
            updatedCatalogue
          );
  
          alert("Image deleted successfully!");
        } catch (error) {
          console.error("Error deleting image:", error);
          alert("An error occurred while deleting the image.");
        }
      },
      async deleteCatalogueItem(index) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
  
          if (brandDocSnapshot.empty) {
            console.error("Brand document not found");
            return;
          }
  
          const brandDoc = brandDocSnapshot.docs[0];
          const updatedCatalogue = [
            ...brandDoc.data().products_services_catalogue,
          ];
          updatedCatalogue.splice(index, 1); // Remove item at the specified index
  
          // Update Firestore document with the modified catalogue
          await updateDoc(brandDoc.ref, {
            products_services_catalogue: updatedCatalogue,
          });
  
          // Update local data to reflect changes in UI
          this.$set(
            this.businessDetails,
            "productsServicesCatalogue",
            updatedCatalogue
          );
  
          this.snackbar.message = "Catalogue item deleted successfully!";
    this.snackbar.color = 'success'; // Set the color to indicate success
    this.snackbar.show = true;
        } catch (error) {
          console.error("Error deleting catalogue item:", error);
          alert("An error occurred while deleting the catalogue item.");
        }
      },
      openImageModal(image) {
        this.selectedImage = image;
        this.imageModal = true;
      },
      uploadImage(itemIndex) {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
  
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            // Handle the uploaded image file
            // For example, you can upload the file to your storage and update the item.images array
            // Here's a simplified example assuming you have a function to upload images:
            this.uploadImageToStorage(file, itemIndex);
          }
        };
  
        input.click();
      },
  
      async uploadImageToStorage(file, itemIndex) {
        try {
          const userId = auth.currentUser.uid;
          const storageRef = ref(
            storage,
            `users/${userId}/catalogue/${new Date().getTime()}_${Math.random()}.png`
          );
  
          // Upload the image to storage
          await uploadBytes(storageRef, file);
  
          // Get the download URL
          const imageUrl = await getDownloadURL(storageRef);
  
          // Update the item.images array with the new image URL
          this.businessDetails.productsServicesCatalogue[itemIndex].images.push(
            imageUrl
          );
  
          // Update the Firestore document with the modified array
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
  
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            const updatedCatalogue = [
              ...brandDoc.data().products_services_catalogue,
            ];
            updatedCatalogue[itemIndex].images.push(imageUrl);
  
            await updateDoc(brandDoc.ref, {
              products_services_catalogue: updatedCatalogue,
            });
  
            alert("Image uploaded successfully!");
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          alert("An error occurred while uploading the image.");
        }
      },
      toggleSecondaryColorPicker() {
        this.showSecondaryColorPicker = !this.showSecondaryColorPicker;
      },
      toggleColorPicker() {
        this.showColorPicker = !this.showColorPicker;
      },
  
      openCataloguePopup() {
        this.catalogueDialog = true;
      },
      closeCataloguePopup() {
        this.catalogueDialog = false;
      },
      async saveCatalogueItem() {
  try {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);

    // Fetch the current brand document
    const brandCollectionRef = collection(userDocRef, "brand");
    const querySnapshot = await getDocs(brandCollectionRef);
    const brandDoc = querySnapshot.docs[0]; // Assuming there's only one brand document per user

    if (!brandDoc) {
      // Handle the case where the brand document doesn't exist
      alert("Brand document not found");
      return;
    }

    // Process and upload each image
    const uploadedImages = await Promise.all(
  this.catalogue.images.map(async (imageFile) => {
    const storageRef = ref(
      storage,
      `users/${auth.currentUser.uid}/catalogue/${new Date().getTime()}_${Math.random()}.png`
    );
    if (imageFile instanceof File) {
      // If it's a file, upload it directly.
      await uploadBytes(storageRef, imageFile);
    } else {
      // If it's a base64 string, convert it to a Blob before uploading.
      const blob = await fetch(imageFile).then(res => res.blob());
      await uploadBytes(storageRef, blob);
    }
    return await getDownloadURL(storageRef);
  })
);


// Update the catalogue images with the uploaded images' URLs


    // const uploadVideo = this.catalogue.video ? await Promise.all(
    //   this.catalogue.video.map(async (videoFile) => {
    //     const storageRef = ref(
    //       storage,
    //       `users/${auth.currentUser.uid}/catalogue/${new Date().getTime()}_${Math.random()}.mp4`
    //     );
    //     await uploadBytes(storageRef, videoFile);
    //     return getDownloadURL(storageRef);
    //   })
    // ) : [];

    // Ensure all fields have valid values
    const catalogueItem = {
      name: this.catalogue.name || "",
      description: this.catalogue.description || "",
      images: uploadedImages || [],
      price: this.catalogue.price || 0,
      postFrequency: this.catalogue.postFrequency || "",
      key_features_highlights: this.catalogue.key_features_highlights || [], 
      product_service_description_web_page:this.catalogue.product_service_description_web_page// Ensure this field is an array
    };

    // Concatenate the existing productsServicesCatalogue array with the new catalogueItem
    const updatedCatalogue = [
      ...brandDoc.data().products_services_catalogue,
      catalogueItem,
    ];

    // Update the existing brand document to include the updated productsServicesCatalogue array
    await updateDoc(brandDoc.ref, {
      products_services_catalogue: updatedCatalogue,
    });

    this.snackbar.message = "Catalogue item added successfully!";
  this.snackbar.color = 'success'; // Set the color to indicate success
  this.snackbar.show = true; 
    this.getbrand();
  } catch (error) {
    console.error("Error saving catalogue item in Firestore:", error);
    alert("An error occurred while saving the catalogue item.");
  }

  // Reset the catalogue object after saving
  this.catalogue = {
    name: "",
    description: "",
    images: [],
    video: [],
    price: 0,
    postFrequency: "",
    key_features_highlights: [], 
    product_service_description_web_page:"" // Reset this field to an empty array
  };
},

      addRandomColor() {
        // Generate a random color (you can modify this logic as needed)
        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
          16
        )}`;
  
        // Assign the random color to the primaryColor property
        this.businessDetails.primaryColor = randomColor;
      },
      addRandomColor2() {
        // Generate a random color (you can modify this logic as needed)
        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
          16
        )}`;
  
        // Assign the random color to the primaryColor property
        this.businessDetails.secondaryColor = randomColor;
      },
      async submitBusinessInfo() {
        try {
          const userId = auth.currentUser.uid;
          console.log("WID:", this.WID);
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
  
          // Check if the user already has a businessDetails document
          const existingBrandDocs = await getDocs(brandCollectionRef);
  
          if (!existingBrandDocs.empty) {
            // If documents already exist, inform the user or handle it as needed
            alert(
              "You have already added business details.you can add multiple Products/Services Catalogue."
            );
            return;
          }
  
          // Continue with adding business details if no existing documents are found
  
          const logoOriginalFile = this.businessDetails.logoOriginal;
          // console.log("logoOriginalFile: ", logoOriginalFile);
          if (logoOriginalFile) {
            const storageRef = ref(
              storage,
              `users/${userId}/logo_original/${new Date().getTime()}.png`
            );
            if (logoOriginalFile instanceof File) {
              await uploadBytes(storageRef, logoOriginalFile);
            } else {
              await uploadString(storageRef, logoOriginalFile, "data_url");
            }
            this.businessDetails.logoOriginal = await getDownloadURL(storageRef);
            // console.log('getDownloadURL:', this.businessDetails.logoOriginal)
          }
  
          if (this.businessDetails.logoDark) {
            const storageRef = ref(
              storage,
              `users/${userId}/logo_dark/${new Date().getTime()}.png`
            );
            if (this.businessDetails.logoDark instanceof File) {
              await uploadBytes(storageRef, this.businessDetails.logoDark);
            } else {
              await uploadString(storageRef, this.businessDetails.logoDark, "data_url");
            }
            this.businessDetails.logoDark = await getDownloadURL(storageRef);
          }
          if (this.businessDetails.logoTransparent) {
            const storageRef = ref(
              storage,
              `users/${userId}/logo_transparent/${new Date().getTime()}.png`
            );
            if (this.businessDetails.logoTransparent instanceof File) {
              await uploadBytes(storageRef, this.businessDetails.logoTransparent);
            } else {
              await uploadString(storageRef, this.businessDetails.logoTransparent, "data_url");
            }
           
            this.businessDetails.logoTransparent = await getDownloadURL(
              storageRef
            );
          }
  
          await addDoc(brandCollectionRef, {
            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
              age_groups: this.selectedAgeGroups,
              genders: this.selectedGenders, 
              income_levels: this.selectedIncomeLevels,
              education_levels: this.selectedEducationLevels, 
              employment_statuses: this.selectedEmploymentStatuses,
              marital_statuses: this.selectedMaritalStatuses, 
              ethnicities_races: this.selectedEthnicitiesRaces,
              interests_hobbies: this.selectedInterestsHobbies, 
              household_sizes: this.selectedHouseholdSizes,
              number_of_children: this.selectedNumberOfChildren,
              home_ownership: this.selectedHomeOwnership,
              disability_status: this.selectedDisabilityStatus,
          });
          this.brandCollectionExists = true;
  
          alert("Business information saved successfully!");
        } catch (error) {
          console.error("Error saving business information in Firestore:", error);
          alert("An error occurred while saving the business information.");
        }
      },
      async getbrand() {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
  
          if (brandDocSnapshot.empty) {
            this.brandCollectionExists = false;
            console.error("Brand document not found");
            // Clear businessDetails object properties
            this.businessDetails.businessName = "";
            this.businessDetails.Tagline = "";
            this.businessDetails.website = "";
            this.bussinessDetails.highlights = [];
            this.businessDetails.brandvalues = [];
            this.businessDetails.contenttheme = [];
            this.businessDetails.tone_of_voice = [];
            this.businessDetails.unique_selling_proposition = "";
            this.businessDetails.purpose = "";
            this.businessDetails.placeOfBusiness = "";
            this.businessDetails.businessDescription = "";
            this.businessDetails.logoOriginal = null;
            this.businessDetails.logoDark = null;
            this.businessDetails.logoTransparent = null;
            this.businessDetails.primaryColor = "";
            this.businessDetails.secondaryColor = "";
            this.businessDetails.primaryFont = "";
            this.businessDetails.secondaryFont = "";
            this.businessDetails.productsServicesCatalogue = [];
            return;
          }
          this.brandCollectionExists = true;
          const brandDoc = brandDocSnapshot.docs[0].data();
          // console.log("brnad", brandDoc);
          this.businessDetails.businessName = brandDoc.business_name || "";
          this.businessDetails.Tagline = brandDoc.tagline || "";
          this.businessDetails.purpose = brandDoc.purpose || "";
          this.businessDetails.highlights = brandDoc.highlights || [];
          this.businessDetails.brandvalues = brandDoc.brand_values || [];
          this.businessDetails.contenttheme = brandDoc.content_themes || [];
          this.businessDetails.tone_of_voice = brandDoc.tone_of_voice || [];
  
          this.businessDetails.unique_selling_proposition =
            brandDoc.unique_selling_proposition || "";
          this.businessDetails.website = brandDoc.website || "";
          this.businessDetails.placeOfBusiness = brandDoc.place_business || "";
          this.businessDetails.businessDescription =
            brandDoc.business_description || "";
          this.businessDetails.logoOriginal = brandDoc.logo_original || null;
          this.businessDetails.logoDark = brandDoc.logo_dark || null;
          this.businessDetails.logoTransparent =
            brandDoc.logo_transparent || null;
          this.businessDetails.primaryColor = brandDoc.primary_color || "";
          this.businessDetails.secondaryColor = brandDoc.secondary_color || "";
          this.businessDetails.primaryFont = brandDoc.primary_font || "";
          this.businessDetails.secondaryFont = brandDoc.secondary_font || "";
          this.businessDetails.productsServicesCatalogue =
            brandDoc.products_services_catalogue || [];
          this.businessDetails.productsServicesCatalogue =
            brandDoc.products_services_catalogue || [];
          this.selectedAgeGroups = brandDoc.age_groups || [];
          this.selectedGenders = brandDoc. genders || [];
          this.selectedIncomeLevels = brandDoc.income_levels || [];
          this.selectedEducationLevels = brandDoc.education_levels || [];
          this.selectedEmploymentStatuses = brandDoc.employment_statuses || [];
          this.selectedMaritalStatuses = brandDoc.marital_statuses || [];
          this.selectedEthnicitiesRaces = brandDoc. ethnicities_races || [];
          this.selectedInterestsHobbies = brandDoc.interests_hobbies || [];
          this.selectedHouseholdSizes = brandDoc.household_sizes || [];
          this.selectedNumberOfChildren = brandDoc.number_of_children || [];
          this.selectedHomeOwnership = brandDoc.home_ownership || [];
          this.selectedDisabilityStatus = brandDoc.disability_status || [];
          
  
        } catch (error) {
          console.error("Error fetching business details from Firestore:", error);
        }
        console.log('businessDetails.logoOriginal: ' + this.businessDetails.logoOriginal);
      },
    },
    mounted() {
      this.getbrand();
    },
  };
  </script>