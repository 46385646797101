import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
export default {
  data() {
    return {
      isCaptionLoading: false,
    };
  },

  computed: {
    tones() {
      return require("@/assets/constants/openai.tones.json");
    },
    languages() {
      return require("@/assets/constants/translate.lang.json");
    },
  },
  methods: {
    async getContentAiGenerate(data) {
      const vm = this;
      let aiActions = httpsCallable(functions, "aiActions", {
        timeout: 540000,
      });
      let { result } = (await aiActions(data)).data;
      return result.output;
    },
    async getTopicsIdeas(data) {
      const vm = this;
      vm.$loader.show({
        message: "Sit back and relax while I generate topic ideas for you",
        type: "ai",
      });
      let aiActions = httpsCallable(functions, "aiActions", {
        timeout: 540000,
      });
      let { result } = (await aiActions(data)).data;
      vm.$loader.hide();
      return result.output;
    },
    async handleGenerateCarListingPost(data) {
      const vm = this;
      vm.$loader.show({
        message: "Sit back and relax while I generate post for you",
        type: "ai",
      });
      let aiActions = httpsCallable(functions, "aiActions", {
        timeout: 540000,
      });
      let { result } = (await aiActions(data)).data;
      let output = result.output;
      if (output instanceof Array) {
        vm.carlistingResponse = output;
      } else {
        vm.carlistingResponse = [output];
      }

      vm.$loader.hide();
    },
    async generateHashtags() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];
      if (!text) {
        vm.$alert.show("Content is required to generate the hashtags");
        return;
      }
      try {
        vm.$loader.show({
          message: "Sit back and relax while I generate hashtags for you",
          type: "ai",
        });
        let data = {
          operation: "generateHashtags",
          textContent: text,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        // let tags = [];
        if (result && result.output) {
          vm.openaiHashtags = [];
          vm.allOpenaiHashtags = [];
          vm.openaiHashtags = Array.isArray(result.output)
            ? result.output
            : [result.output];
          vm.allOpenaiHashtags = [...vm.openaiHashtags];
        }
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    async hashtagReachFromURL(hashtagUrl, tag) {
      const vm = this;
      try {
        vm.loading = true;
        let data = {
          operation: "generateHashtagReach",
          url: hashtagUrl,
          uid: this.currentUID,
          wid: this.WID,
          tag: tag,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        // console.log(result);
        vm.loading = false;
        return result;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async generatePostContent() {
      const vm = this;
      if (!vm.captionForm.text) {
        vm.$alert.show("Content is required to generate post ideas");
        return;
      }
      try {
        vm.$loader.show({
          message: "Sit back and relax while I generate post ideas for you",
          type: "ai",
        });
        vm.isCaptionLoading = true;
        vm.openaiCaptions = [];
        let data = {
          operation: "generateContent",
          textContent: vm.captionForm.text,
          uid: this.currentUID,
          wid: this.WID,
          ...vm.captionForm,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let captions = result.output;
        if (captions instanceof Array) {
          vm.openaiPostContent = captions;
        } else {
          vm.openaiPostContent = [captions];
        }
        this.steps = 2;
        vm.isCaptionLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isCaptionLoading = false;
        vm.handleError(error);
      }
    },
    async generateCaptions() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];
      if (!text) {
        vm.$alert.show("Content is required to generate the title");
        return;
      }
      try {
        vm.$loader.show({
          message: "Sit back and relax while I generate title for you",
          type: "ai",
        });
        vm.isCaptionLoading = true;
        vm.openaiCaptions = [];
        let data = {
          operation: "generateCaption",
          textContent: text,
          uid: this.currentUID,
          wid: this.WID,
          ...vm.captionForm,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let captions = result.output;
        if (captions instanceof Array) {
          vm.openaiCaptions = captions;
        } else {
          vm.openaiCaptions = [captions];
        }
        this.steps = 2;
        vm.isCaptionLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isCaptionLoading = false;
        vm.handleError(error);
      }
    },
    async changePostTone() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];
      if (!text) {
        vm.$alert.show("Content is required to change tone");
        return;
      }
      try {
        vm.$loader.show({
          message: "Sit back and relax while I generate changed tone for you",
          type: "ai",
        });
        vm.isToneLoading = true;
        vm.openaiContents = [];
        let data = {
          operation: "changeTone",
          textContent: text,
          maxSuggestions: 1,
          uid: this.currentUID,
          wid: this.WID,
          ...vm.toneForm,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let output = result.output;
        if (output instanceof Array) {
          vm.openaiContents = output;
        } else {
          vm.openaiContents = [output];
        }
        this.steps = 2;
        vm.isToneLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isToneLoading = false;
        vm.handleError(error);
      }
    },
    async changeTone() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];
      if (!text) {
        vm.$alert.show("Content is required to change tone");
        return;
      }
      try {
        vm.$loader.show({
          message: vm.loaderMessage,
          type: "ai",
        });
        vm.isToneLoading = true;
        vm.openaiChangedTone = [];
        let data = {
          operation: vm.operation,
          textContent: text,
          language: vm.language,
          uid: this.currentUID,
          wid: this.WID,
        };
        // console.log("data: ", data);
        let aiActions = httpsCallable(
          functions,
          "aiActions",
          { timeout: 540000 },
          { timeout: 300000 }
        );
        let { result } = (await aiActions(data)).data;
        let output = result.output;
        // console.log("output:", output)
        if (output instanceof Array) {
          vm.openaiChangedTone = output;
        } else {
          vm.openaiChangedTone = [output];
        }
        vm.isToneLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isToneLoading = false;
        vm.handleError(error);
      }
    },
    async translate() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];

      if (!text) {
        vm.$alert.show("Content is required to translate post");
        return;
      }
      try {
        vm.loading = true;
        vm.$loader.show({
          message: "Sit back and relax while I translate post for you",
          type: "ai",
        });
        vm.openaiCaptions = [];
        let data = {
          operation: "translate",
          textContent: text,
          language: vm.language,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        vm.translatedText = result.output;
        this.steps = 2;
        vm.loading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    async postFromURL() {
      const vm = this;
      try {
        vm.loading = true;
        vm.$loader.show({
          message: "Sit back and relax while I generate post for you",
          type: "ai",
        });
        vm.detail = null;
        let data = {
          operation: "generateURLContent",
          url: vm.url,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let detail = result;
        if (detail.image) {
          detail.image = await this.downloadImage(detail.image);
        }
        vm.detail = detail;
        // console.log(detail);
        this.dialog = false;
        this.outputDialog = true;
        vm.$loader.hide();
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async downloadImage(url) {
      // eslint-disable-next-line no-async-promise-executor
      let corsImageProxy = httpsCallable(functions, "corsImageProxy");
      const data = { url };
      let response = await corsImageProxy(data);
      return response.data.data;
    },
    async summarisePost() {
      const vm = this;
      try {
        vm.loading = true;
        vm.$loader.show({
          message: "Sit back and relax while I generate post for you",
          type: "ai",
        });
        vm.openaiContents = [];
        let data = {
          operation: "generateSummary",
          ...vm.form,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        vm.openaiContents = result.output;
        this.steps = 2;
        vm.$loader.hide();
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async generateImage() {
      const vm = this;
      if (!vm.imgForm.text) {
        vm.$alert.show("Description is required to generate the image");
        return;
      }
      try {
        vm.loading = true;
        vm.$loader.show({
          message: "Sit back and relax while I generate image for you",
          type: "ai",
        });
        this.responseImageUrl = "";
        let data = {
          operation: "generateImage",
          textContent: vm.imgForm.text,
          uid: this.currentUID,
          wid: this.WID,
        };

        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;

        this.responseImageUrl = result.output;
        this.steps = 2;
        vm.loading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.loading = false;
        vm.handleError(error);
      }
    },
    async generateImageCaption() {
      const vm = this;
      try {
        vm.$loader.show({
          message:
            "Sit back and relax while I generate captions from your image",
          type: "ai",
        });
        vm.isCaptionLoading = true;
        let data = {
          operation: "generateImageCaption",
          image_url: vm.imgUrl,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let captions = result.output;
        if (captions instanceof Array) {
          vm.openaiCaptions = captions;
        } else {
          vm.openaiCaptions = [captions];
        }
        vm.isCaptionLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isCaptionLoading = false;
        vm.handleError(error);
      }
    },
    async generateImageHashtags() {
      const vm = this;
      try {
        vm.$loader.show({
          message:
            "Sit back and relax while I generate hashtags from your image",
          type: "ai",
        });
        vm.isCaptionLoading = true;
        let data = {
          operation: "generateImageHashtags",
          image_url: vm.imgUrl,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        let hashtags = result.output;
        if (hashtags instanceof Array) {
          vm.openaiHashtags = [...vm.openaiHashtags, ...hashtags];
          // vm.openaiHashtags = hashtags;
        } else {
          vm.openaiHashtags.push(hashtags);
        }
        // Scroll to the bottom of the page with smooth scrolling
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }, 3);
        vm.isCaptionLoading = false;
        vm.$loader.hide();
      } catch (error) {
        vm.isCaptionLoading = false;
        vm.handleError(error);
      }
    },
    async addEmojisToContent() {
      const vm = this;
      let text =
        vm.activeItem === "PostText"
          ? vm.form.text
          : vm.platformEditors[vm.activeItem];
      if (!text) {
        vm.$alert.show("Content is required to add emojis");
        return;
      }
      try {
        vm.isToneLoading = true;
        vm.$loader.show({
          message:
            "Sit back and relax while I add relevant emojis to your post",
          type: "ai",
        });
        vm.textWithEmoji = "";
        let data = {
          operation: "addEmojis",
          textContent: text,
          language: vm.language,
          uid: this.currentUID,
          wid: this.WID,
        };
        let aiActions = httpsCallable(functions, "aiActions", {
          timeout: 540000,
        });
        let { result } = (await aiActions(data)).data;
        vm.textWithEmoji = result.output;
        vm.$loader.hide();
        vm.isToneLoading = false;
      } catch (error) {
        vm.handleError(error);
        vm.isToneLoading = false;
      }
    },
  },
};
