<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Reddit Settings
      </div>
      <div class="px-7 py-3">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="Subreddit"
              @input="$emit('input', form)"
              v-model="form.subreddit"
              :items="subreddits"
            ></select-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectField from "@/components/ui/form/SelectField.vue";
import Autocomplete from "@/components/ui/form/Autocomplete.vue";


export default {
  components: { SelectField, Autocomplete },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    subreddits: Array,
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
  },

  data() {
    return {
      form: {
        subreddit: [],
      },
    };
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "reddit");
      return platform || {};
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] !== undefined) {
          this.form[key] = this.value[key];
        }
      });
      if (this.subreddits && this.subreddits.length === 1) {
        this.form.subreddit = this.subreddits[0].name;
        this.$emit("input", this.form);
      }
    },
    updateConfiguration() {
      this.form.subreddit = this.configuration.subreddit;
    },
  },
  beforeMount() {
    this.initForm();
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.updateConfiguration();
    }
  },
};
</script>

<style></style>
