<template>
  <!-- <v-dialog v-model="dialog" max-width="400px" max-heght="231px" persistent>
    <v-card class="pa-5">
      <div class="flex items-end justify-end">
        <v-icon @click="install()" color="red" size="30">mdi-close</v-icon>
      </div>

      <v-card-title class="justify-center pa-0"> Welcome onboard!</v-card-title>
      <v-card-text class="my-2 text-center text-[#636B8C] pa-0">
        Add Fly to your home screen for quick access.
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="#8056DE"
          class="mx-auto w-[221px] text-none"
          @click="install()"
          block
          dark
          depressed
        >
          Add to home screen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->

  <div
    v-if="dialog"
    class="absolute top-0 w-full h-screen flex flex-col bg-black bg-opacity-40 z-[99] items-center justify-center"
  >
    <!-- <div>
      <div
        class="w-full bg-white md:w-[376px] relative md:h-[276px] px-4 md:px-0 rounded-3xl shadow-md flex flex-col justify-center items-center"
      >
        <div class="flex justify-end w-full mt-4 mr-4">
          <v-icon
            color="red"
            class="absolute right-0 cursor-pointer"
            first-letter:
            @click="closePopup()"
            >mdi-close</v-icon
          >
        </div>

        <img src="../assets/img/complete-signup/robo.svg" class="mt-3" alt="" />
        <p class="text-xl font-bold text-neutral-900">Welcome onboard!</p>
        <p class="text-slate-500 text-sm font-normal font-['Poppins'] my-4">
          Add Fly to your home screen for quick access.
        </p>
        <div class="pb-5 mt-2">
          <button
            class="w-[221px] text-white font-semibold h-12 bg-violet-500 rounded-3xl"
            @click="install()"
          >
            Add to Home Screen
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    install() {
      this.$emit("install");
      this.dialog = false;
      this.$emit("closePopup");
      this.dialog = false;
    },
    closePopup() {
      this.dialog = false;
      this.$emit("closePopup");
    },
  },
};
</script>

<style></style>
