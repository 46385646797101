<template>
    <div>
      <label class="input-label" v-if="label">{{ label }}</label>
      <v-textarea
        v-model="input"
        :dense="dense"
        :class="[
        label ? 'mt-1' : '',
        'pt-3',
        ,
        'pr-5',
        'pl-5',
        'mt-2.5',
        'whitespace-nowrap',
        'bg-slate-100',
        'rounded-[32px]',
        'text-slate-500',
        'max-md:pr-5',
      ]"
        v-bind="$attrs"
        rows="3"
       
        :rules="getRules"
        @input="$emit('input', input)"
      />
    </div>
  </template>
  
  <script>
  import inputMixin from "@/mixins/input.mixin.js";
  export default {
    mixins: [inputMixin],
  };
  </script>
  