<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Facebook Settings
      </div>
      <div class="px-7 py-3">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            
            <AutocompleteFacebook
              label="Pages"
              chips
              deletable-chips
              small-chips
              @input="$emit('input', form)"
              v-model="form.fb_pages"
              :items="pages"
              multiple
              :menu-props="menuProps"
            ></AutocompleteFacebook>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col v-if="post?.videos?.length > 0" cols="12" class="py-0">
            <v-btn
              depressed
              class="primary"
              height="40px"
              @click="$refs.image.click()"
              variant="outlined"
            >
              <v-icon>mdi-image</v-icon>
              <span class=""> Add Thumbnail </span>
            </v-btn>
            <input
              accept="image/*"
              type="file"
              class="d-none"
              ref="image"
              @input="$emit('input', form)"
              @change="onImageChange"
            />
          </v-col>
          <v-col
            v-if="form.fb_thumb || thumbnail"
            cols="12"
            class="py-2 px-3 d-flex flex-col gap-2"
          >
            <div class="d-flex w-1/2 flex-row justify-between">
              <span>{{ form.fb_thumb?.name }}</span>
              <v-icon @click="removeThumb" class="cursor-pointer" color="red"
                >mdi-close</v-icon
              >
            </div>
            <v-img class="w-1/2" :src="thumbnail"></v-img>
          </v-col>
        </v-row> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AutocompleteFacebook from "@/components/ui/form/AutocompleteFacebook.vue";
export default {
  components: { AutocompleteFacebook },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    configuration: {
      type: Object,
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    pages: Array,
    connectedApps: {
      type: Array,
      default: () => [],
    },
    postTypeConfiguration: Object,
  },
  data() {
    return {
      groupInstructionsDialog: false,
      facebookHelpUrl:
        "https://www.facebook.com/help/www/261149227954100?paipv=0&eav=AfaF9aSCwLi57TYsd9I80y0UmO0dRJkc8_4tfEEO9ntBjeuN8O481MZRco7jQQcjtpc&_rdr",
      videoUrl: "https://youtu.be/f1U_1hGToG4",
      form: {
        fb_pages: [],
        fb_thumb: null,
      },
      thumbRemoved: false,
      thumbChanged: false,
      thumbnail: null,
    };
  },
  async created() {
    // const currentURL = window.location.href;

    // // Extract the hostname from the URL
    // const hostname = new URL(currentURL).hostname;
    // console.log("loc",window.location.href, hostname);
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.$watch(
        () => this.connectedApps,
        (newVal) => {
          if (newVal && newVal.length > 0) {
            this.updateConfiguration();
          }
        }
      );
    }
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "facebook");
      return platform || {};
    },
    menuProps() {
      return {
        minWidth: this.isMobileScreen ? "90%" : "27%",
      };
    },
    isMobileScreen() {
      return window.innerWidth <= 600;
    },
  },
  methods: {
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        this.thumbRemoved = false;
        let thumbnail = null;
        // this.$emit("thumbremoved", this.thumbRemoved);
        console.log("img", image);
        this.thumbChanged = true;
        this.form.fb_thumb = image;
        let data = {
              platform: "fb",
            }
        if (image instanceof File) {
          var reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            this.thumbnail = imageUrl;
            this.form.fb_thumb = imageUrl;
            data.thumbnail = imageUrl;
            this.$emit("thumbnailAdded", data);
          };
          reader.readAsDataURL(image);
        }
        this.$emit("input", this.form);
      }
    },
    removeThumb() {
      this.form.fb_thumb = null;
      this.thumbnail = "";
      this.$emit("thumbnailRemoved", { platform: "ig", removed: true });
    },
    showGroupInstructions() {
      this.groupInstructionsDialog = true;
    },
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });

      if (this.pages && this.pages.length === 1) {
      this.form.fb_pages = [this.pages[0].value];
      this.$emit("input", this.form);
    }
    },
    updateConfiguration() {
      let fetched_fb_pages = [];
      if (
        this.configuration.fb_pages?.length 
      ) {
        fetched_fb_pages = this.configuration.fb_pages;
      }
      const isFacebookConnected = this.connectedApps.find(
        (app) => app.id === "facebook"
      );
      if (isFacebookConnected) {
        const item =
          this.connectedApps.find((item) => item.id === "facebook") || {};
        const userFacebookPages = item.pages || [];
        const commonPages = fetched_fb_pages.filter((fbPage) =>
          userFacebookPages.some((userPage) => userPage.id === fbPage)
        );

        this.form.fb_pages = commonPages;
      }
      if (this.configuration.fb_post_type) {
        this.form.fb_post_type = this.configuration.fb_post_type;
      }
    },
  },
  beforeMount() {
    this.initForm();
  },
};
</script>

<style></style>
