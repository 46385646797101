<template>
  <div>
  <div
    class="flex flex-col items-center md:pb-[50px] h-screen justify-center bg-[#F2F6FA]"
    style="font-family: Poppins, sans-serif"
  >
    <div
      v-if="!isOtpSend && !isNotSignedUp"
      class="mt-5 md:mt-[40px] justify-center items-center"
    >
      <div
        class="flex xl:w-[1169px] w-full rounded-3xl shadow-[20px_20px_20px_0px_rgba(0,_0,_0,_0.1)]"
      >
        <div
          class="flex flex-col px-4 lg:px-0 py-36 rounded-l-2xl rounded-r-2xl md:rounded-r-none md:rounded-l-3xl w-full items-center gap-2 xl:w-[585px] lg:w-[450px] border bg-white"
        >
          <div class="flex flex-col items-center w-full gap-6">
            <div class="flex flex-col items-center gap-2 mb-px">
              <div class="flex flex-col items-center w-full gap-1">
                <div class="flex flex-col items-center w-full md:flex-row">
                  <a :href="getLandingPageURL()">
                    <img
                      src="../assets/img/fly-new-logo.png"
                      alt="Flynewlogo"
                      id="Flynewlogo"
                      class="w-20 cursor-pointer"
                    />
                  </a>
                  <div>
                    <div
                      class="text-xl font-semibold text-center lg:-ml-8 lg:text-start w-full text-[#121212]"
                    >
                      Create new Account
                    </div>
                    <div
                      class="relative flex flex-row items-start w-full gap-4"
                    >
                      <div
                        class="text-xs text-[#636b8c] h-8 md:w-[275px] max-w-full text-start"
                      >
                        If you already have an account
                        <span
                          class="text-xs cursor-pointer font-medium text-[#8056de]"
                          @click="openRoute('login')"
                        >
                          login
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center w-full gap-6">
              <div class="flex flex-col items-center w-full gap-4">
                <div class="relative w-full md:w-[309px]">
                  <vue-tel-input-vuetify
                    class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-12 rounded-[26px]"
                    mode="international"
                    v-model="phone"
                  >
                    <input
                      type="text"
                      class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
                      placeholder="Phone Number"
                      v-model="phone"
                      @keyup.enter="sendOTP"
                    />
                  </vue-tel-input-vuetify>
                  <!-- <img
                    src="https://file.rendit.io/n/ypCBTa2JM2EZ8ZN6gm3e.svg"
                    alt="Group"
                    class="absolute w-3 mt-1 top-3 left-5"
                  /> -->
                </div>
                <div
                  class="text-sm font-medium w-full md:w-[309px] cursor-pointer text-white bg-[#8056de] flex flex-row justify-center pt-3 h-12 rounded-[26px]"
                  @click="sendOTP()"
                >
                  <span v-if="!loading"> Sign up</span>
                  <span v-else> Please wait...</span>
                </div>
                <!-- <div class="relative w-full md:w-[309px]">
                  <input
                    type="password"
                    class="bg-[#f2f6fa] w-full flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-12 items-start pl-10 rounded-[26px]"
                    placeholder="OTP"
                    v-model="otp"
                    @keyup.enter="submitOTP"
                  />
                  <img
                    src="https://file.rendit.io/n/URyxFP4KhzyN1d8egKar.svg"
                    alt="Group"
                    class="absolute w-3 mt-1 top-4 left-5"
                  />
                </div> -->
              </div>
              <div
                v-if="isLiveEnv"
                class="text-xs text-center w-full text-[#636b8c]"
              >
                Or continue with social media
              </div>
            </div>
            <div class="flex flex-col items-center w-full gap-4">
              <div
                class="flex flex-row justify-center w-[309px] gap-3 lg:justify-start"
              >
                <div
                  v-if="isLiveEnv"
                  class="bg-[#f2f6fa] cursor-pointer flex flex-row justify-center items-center w-full gap-2 py-3.5 rounded-[26px]"
                  @click="signInWithGoogle()"
                >
                  <img
                    src="https://file.rendit.io/n/s8TLCKGDVanZHQaa9xwm.svg"
                    alt="Brandbrandsgooglelogologosicon"
                    class="w-4"
                  />
                  <div class="text-sm font-medium text-[#636b8c]">Google</div>
                </div>
                <!-- <div
                  class="bg-[#f2f6fa] flex hidden cursor-pointer flex-row justify-center items-center gap-2 w-[149px] py-2.5 rounded-[26px]"
                  @click="signInWithFacebook()"
                >
                  <img
                    src="https://file.rendit.io/n/Kskx4SAWbXX9LF0rj3aN.svg"
                    alt="FbfacebookfacebookLogoicon3"
                    class="w-4 mt-1"
                  />
                  <div class="text-sm font-medium text-[#636b8c] mt-1">
                    Facebook
                  </div>
                </div> -->
              </div>
              <!-- <div class="flex items-center justify-center gap-2">
                <p><input type="checkbox" class="mr-2" />Remember me</p>
              </div> -->
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>

        <div
          class="bg-[#8056de] px-4 lg:px-0 hidden md:flex flex-col rounded-r-3xl justify-center items-center w-full xl:w-[585px] lg:w-[450px]"
        >
          <div class="flex flex-col items-center justify-center w-full gap-3">
            <img src="../assets/img/Login_Page_V4.png" class="w-[368px]" alt="" />
            <div
              class="text-xl font-semibold text-center text-white lg:text-left"
            >
              Your Social, Simplified!
            </div>
            <!-- <div class="pb-10 text-xs text-center text-white lg:text-left">
              Everything you can easily manage with customizable dashboard.
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isOtpSend && !isNotSignedUp"
      class="mt-5 md:mt-[40px] justify-center items-center"
    >
      <div
        class="flex xl:w-[1169px] w-full rounded-3xl shadow-[20px_20px_20px_0px_rgba(0,_0,_0,_0.1)]"
      >
        <div
          class="md:w-[589px] w-full px-4 md:px-0 py-20 bg-white flex flex-col rounded-l-2xl rounded-r-2xl md:rounded-r-none md:rounded-l-3xl justify-center items-center"
          :class="isOtpSend ? 'h-screen md:h-auto  ' : ''"
        >
          <div class="flex flex-col">
            <img
              src="../assets/img/fly-new-logo.png"
              alt="Flynewlogo"
              id="Flynewlogo"
              class="w-20 -ml-4"
            />
            <div class="text-xl font-semibold mt-1 text-[#121212]">
              Enter OTP
            </div>
            <div
              class="text-xs text-[#636b8c] mt-[7px] h-8 md:w-[275px] max-w-full text-start"
            >
              An OTP was sent at your phone number.
            </div>
            <div class="text-xs w-full flex items-center justify-end gap-1 text-[#636b8c] h-8 md:w-[275px] text-end">
  <span v-if="countdown > 0">Resend in {{ countdown }}s</span>
  <span v-else @click="sendOTP()" class="text-[#8056DE] font-bold cursor-pointer">Resend OTP</span>
</div>

            <div class="flex flex-col items-center w-full gap-4">
              <div class="relative w-full md:w-[309px]">
                <v-otp-input
                  type="number"
                  length="6"
                  class="w-full outline-[#8056DE] rounded-[26px]"
                  v-model="otp"
                  @finish="submitOTP"
                ></v-otp-input>
              </div>
              <div class="md:w-[309px] w-full h-12">
                <button
                  class="text-sm font-medium w-full text-white bg-[#8056de] flex flex-row justify-center pt-3 h-12 rounded-[26px]"
                  @click="submitOTP()"
                >
                  <span v-if="!loading"> Continue</span>
                  <span v-else> please wait... </span>
                </button>
              </div>
              <div class="md:w-[309px] w-full h-12">
                <button
                  class="text-sm font-medium w-full text-[#636B8C] bg-[#F2F6FA] flex flex-row justify-center pt-3 h-12 rounded-[26px]"
                  @click="closeOtpSend"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="bg-[#8056de] px-4 lg:px-0 hidden md:flex flex-col rounded-r-3xl justify-center items-center w-full xl:w-[585px] lg:w-[450px]"
        >
          <div class="flex flex-col items-center justify-center w-full gap-3">
            <img src="../assets/img/Login_Page_V4.png" class="w-[368px]" alt="" />
            <div
              class="text-xl font-semibold text-center text-white lg:text-left"
            >
              Your Social, Simplified!
            </div>
            <!-- <div class="pb-10 text-xs text-center text-white lg:text-left">
              Everything you can easily manage with customizable dashboard.
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <complete-signup v-if="isNotSignedUp" />
    <div
      v-if="isNotValidPhone"
      class="absolute inset-0 flex flex-col bg-black bg-opacity-40 z-[99] items-center justify-center"
    >
      <div class="w-[321px] py-5 px-[27px] shadow bg-white rounded-[20px]">
        <div class="text-base font-medium text-neutral-900">Message</div>
        <div class="mt-4 text-xs font-medium text-slate-500">
          Please enter a valid phone number
        </div>
        <div class="flex items-end justify-end">
          <button
            @click="isNotValidPhone = false"
            class="w-[119px] text-base font-semibold text-white text-center py-3 mt-[23px] bg-red-600 rounded-3xl"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPopup"  class="popup-overlay">
    <div class="popup">
     
      <div class="popup-header">
  <img src="../assets/img/fly-new-logo.png" alt="Flynewlogo" class="popup-logo">
</div>
      <h2 class="text-xl font-semibold text-[#636B8C] my-3 [text-shadow:0px_4px_4px_rgba(0,_0,_0,_0.25)] font-poppins">Sell more with  <span
                class="text-xl  font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#FF006B] to-[#7A58D5]"
                >Fly!</span
              ></h2>
      <div class="popup-content">
        <p class="text-[#636B8C] font-semibold my-3">Sign up -> Connect -> Publish</p>
        <p class="text-[#636B8C] font-semibold -mt-3 ml-3">your car listing in one tap!</p>
        <h3 class="text-[#636B8C] mt-8 font-medium ml-3">Top Social Media Platforms</h3>
        <div class="flex justify-center items-center">
          <img
                      src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Ffacebook.png?alt=media&token=a191e54e-f4e9-469b-af44-dfc9360f5289"
                      alt="Facebook logo"
                      id="Facebooklogo"
                      class=" aspect-square w-[33px]"

                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Finstagram.png?alt=media&token=6973820c-b95f-45d6-b527-d16b5dcf0343"
                      alt="Instagramlogo"
                      id="Instagramlogo"
                      class=" aspect-square w-[33px]"

                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Fyoutube-play.png?alt=media&token=2e39cc0b-c0c0-4377-ad69-f9a417120a58"
                      alt="Youtubelogo"
                      id="Youtubelogo"
                      class=" aspect-square w-[33px]"

                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Fpinterest.png?alt=media&token=255301e8-3e8d-4df9-9120-ec3d986c3490"
                      alt="Pinterestlogo"
                      id="Pinterestlog"
                      class=" aspect-square w-[33px]"

                    />
                    <div class=" relative text-[10px] font-poppins text-slategray text-left inline-block">& 4 More</div>

        </div>
        <h3 class="text-[#636B8C] font-medium ml-12 mt-8">Top Marketplaces </h3>
        <div class=" text-[14px] font-poppins text-darkorange text-center text-[#FA8316] mb-1 font-semibold">Coming Soon</div>
        <div class="flex justify-center items-center gap-1 mb-2">
              <img
              src="../assets/img/Olx 1.svg"                      
              alt="Olxlogo"
              id="Olxlogo"
                      class=" aspect-square w-[33px]"

                    />
                    <img
                    src="../assets/img/Quikr 1.svg"                     
                    alt="Quikrlogo"
                    id="Quikrlogo"
                    class=" aspect-square w-[33px]"

                    />
                    <img
                    src="../assets/img/FB Market Place 1.svg"                      
                    alt="FBMarketPlacelogo"
                    id="FBMarketPlacelogo"
                    class=" aspect-square w-[33px]"
                    />
                    <img
                    src="../assets/img/googlebussiness.png"                    
                    alt="GoogleBusinesslogo"
                    id="GoogleBusinesslogo"
                    class=" aspect-square w-[29px]"
                    />
                    <div class="w-[px] relative text-[10px] font-poppins text-slategray text-left inline-block">& More</div>
        </div>
      </div>
      <button  @click="closePopup" class="btn-signup rounded-xl px-4 " :class="!isSmallAndUp ? 'text-xs' : ''" >Continue to Sign up</button>
    </div>
  </div>
</div>
</template>

<script>
import inviteMixins from "@/mixins/invite.mixins";
import {
  FacebookAuthProvider,
  onAuthStateChanged,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPopup,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, colUsers, waitlist, db } from "@/utils/firebase.utils";
import { doc, getDoc, setDoc, collection } from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import CompleteSignup from "@/components/CompleteSignup.vue";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";

export default {
  components: {
    PleaseWait,
    CompleteSignup,
    InputField,
    DialogCard,
  },
  data() {
    return {
      showPopup: false,
      otp: "",
      phone: "",
      waitlistUserEmail: "",
      countdown: null,
      loading: false,
      isLoggedIn: false,
      isOtpSend: false,
      isAuthLoading: true,
      isNotValidPhone: false,
      isNotSignedUp: false,
      isGoogleLoading: false,
      showEmailDialog: false,
      showConfirmDialog: false,
      isFacebookLoading: false,
      restrictUsersDialog: false,
    };
  },
  watch: {
    '$route'(to) {
      this.evaluatePopupVisibility(to);
    }
  },
  computed: {
    isSignup() {
      return this.$route.name == "signup";
    },
    title() {
      if (this.isNotSignedUp) return "Sign Up";
      return this.isSignup ? "Sign Up" : "Login";
    },
    isLiveEnv() {
      let domain = window.location.host;
      if (domain.includes("dev") || domain.includes("uat")) {
        return false;
      } else {
        return true;
      }
    },
  },
  mixins: [inviteMixins],
  methods: {
    closePopup() {
      this.$mixpanel.track("Car Dealers Signup Clicked");
      this.showPopup = false;
    },
    evaluatePopupVisibility(route) {
      if (route.query.campaign === 'car-dealers-in') {
        this.showPopup = true;
      } else {
        this.showPopup = false;
      }
    },
    getLandingPageURL() {
      
      return process.env.VUE_APP_FLY_HOST;
    
  },
    startCountdown() {
      if (this.countdown !== null) {
    clearInterval(this.countdownInterval);
  }
      this.countdown = 60;
      const interval = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(interval);
          this.countdown = 0; // Ensure countdown does not display a negative number
          // Optionally reset isOtpSend to false or handle as needed
        }
      }, 1000);
    },
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
      provider.addScope("https://www.googleapis.com/auth/user.gender.read");

      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        // console.log(user);

        // Check if user exists in your database
        const userDoc = (await getDoc(doc(colUsers, user.uid))).data();
        if (userDoc == null) {
          localStorage.setItem('signupWithGoogleDetails', JSON.stringify({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          }));
          this.$router.push({
            path: "/complete-signup",
            query: {
              displayName: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
            },
          });
        } else {
          this.$router.replace("/dashboard");
          console.log("User already exists...");
        }
      } catch (error) {
        console.error("Error during Google sign-in:", error);
        // Handle errors here
      }
    },
    // async signInWithFacebook() {
    //   console.log("sign in with facebook");

    //   const provider = new FacebookAuthProvider();
    //   console.log(provider, "provider");
    //   const vm = this;

    //   try {
    //     const result = await signInWithPopup(auth, provider);
    //     const user = result.user;
    //     console.log(user, "all");

    //     // Check if user exists in your database
    //     const userDoc = (await getDoc(doc(colUsers, user.uid))).data();
    //     if (userDoc == null) {
    //       this.$router.replace({
    //         path: "/complete-signup",
    //         query: {
    //           displayName: user.displayName,
    //           email: user.email,
    //           photoURL: user.photoURL,
    //         },
    //       });
    //     } else {
    //       this.$router.replace("/dashboard");
    //       console.log("User already exists...");
    //     }
    //   } catch (error) {
    //     console.error("Error during Facebook sign-in:", error);
    //     vm.handleError(error);
    //   } finally {
    //     vm.isFacebookLoading = false;
    //   }
    // },
    openRoute(name) {
      let query = this.$route.query;
      this.$router.push({
        name,
        query,
      });
    },

    async submitOTP() {
      const vm = this;
      if (vm.otp.length != 6) {
        vm.$alert.show("Please enter 6 digit otp");
        return;
      }
      let result = window.confirmationResult;
      try {
        vm.loading = true;
        let { user } = await result.confirm(vm.otp);
        console.log("Authenticated user : ", user);
        let userDoc = (await getDoc(doc(colUsers, user.uid))).data();
        if (userDoc == null) {
          localStorage.setItem('signupDetails', JSON.stringify({
            phoneNumber: user.phoneNumber,
          }));
          // Correctly access the phone number from the user object
          this.$router.push({
            path: "/complete-signup",
            query: {
              phone: user.phoneNumber, // This is the correct property
            },
          });
        } else {
          vm.redirect();
          console.log("User already exists...");
        }
        vm.loading = false;
        this.isOtpSend = false;
      } catch (e) {
        vm.loading = false;
        vm.$alert.show(e.message);
      }
    },
    //  Not needed right now
    // Function to add other country users to waitlist
    // async addWaitListUser() {
    //   const vm = this;
    //   const waitlistRef = doc(waitlist);
    //   await setDoc(waitlistRef, {
    //     email: vm.waitlistUserEmail,
    //     created_at: new Date(),
    //     phone_number: this.phone,
    //   });
    //   vm.showEmailDialog = false;
    //   vm.showConfirmDialog = true;
    // },
    async sendOTP() {
      this.startCountdown();

      const vm = this;
      // * COUNTRY CHECK FOR PHONE NUMBER: NOT NEEDED RIGHT NOW

      // const countryRegex = /^\+(\d+)/;
      // const match = vm.phone.toString().match(countryRegex);
      // if (match[0] !== "+91") {
      //   vm.showEmailDialog = true;
      //   return;
      // }
      const phoneNumber_without_code = vm.phone
        .toString()
        .replace(/^\+\d+\s*/, "")
        .split(" ")
        .join("")
        .replace(/-/g, "")
        .slice(-10);

      const phoneNumber_with_code = vm.phone
        .toString()
        .trim()
        .split(" ")
        .join("");

      // if (phoneNumber_without_code.length != 10) {
      //   // vm.$alert.show("Please enter a valid phone number");
      //   vm.isNotValidPhone = true;
      //   return;
      // }

      // console.log("vue",phoneNumber_without_code,phoneNumber_with_code);
      let domain = window.location.host;
      let usersArray = [];
      if (!vm.isLiveEnv) {
        vm.loading = true;
        const configDoc = (
          await getDoc(doc(db, "fly_config", "global"))
        ).data();
        // console.log("configDoc", configDoc);
        if (domain.includes("dev") && configDoc && configDoc.dev_users) {
          usersArray = configDoc.dev_users;
        } else if (domain.includes("uat") && configDoc && configDoc.uat_users) {
          usersArray = configDoc.uat_users;
        }
        vm.loading = false;
      }
      if (
        (usersArray && usersArray.includes(`${phoneNumber_with_code}`)) ||
        domain.includes("localhost") ||
        vm.isLiveEnv
      ) {
        var appVerifier = window.recaptchaVerifier;
        vm.loading = true;
        signInWithPhoneNumber(auth, phoneNumber_with_code, appVerifier)
          .then((result) => {
            vm.loading = false;
            window.confirmationResult = result;
            console.log(window.confirmationResult);
            vm.isOtpSend = true;
          })
          .catch(function (e) {
            vm.loading = false;
            vm.$alert.show(e.message);
            vm.isNotValidPhone = false;
          });
      } else {
        this.restrictUsersDialog = true;
        return;
      }
    },
    initCaptcha() {
      this.loading = true;
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (recaptchaContainer) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response) {
              console.log(response);
            },
          },
          auth
        );
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaWidgetId = widgetId;
          this.loading = false;
        });
      } else {
        console.error("Recaptcha container not found.");
        this.loading = false;
        // Handle the error (e.g., show an alert or a message to the user)
      }
    },
    redirect() {
      let { redirect } = this.$route.query;
      this.$router.replace(redirect || "/dashboard");
    },
    closeOtpSend() {
      location.reload();
      this.isOtpSend = false;
    },
  },
  destroyed() {},
  mounted() {
    this.evaluatePopupVisibility(this.$route);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.isLoggedIn = true;
        this.isAuthLoading = false;
        this.isNotSignedUp = true;
      } else {
        const referredBy = this.$route.query.r;
        this.$store.commit("SET_REFERRED_BY", referredBy);
        // const campaign = this.$route.query.campaign;
        // this.$store.commit("SET_CAMPAIGN", campaign);
        this.isAuthLoading = false;
        this.$nextTick(() => {
          this.initCaptcha();
        });
      }
    });
  },
};
</script>

<style scoped>
.fly-logo-container {
  transform: translate(-50%, -50%);
  border-radius: 50% !important;
}
.fly-logo-container img {
  max-width: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-header {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers the logo horizontally */
}
.popup-logo {
  width: 80px; /* Adjust the width as necessary */
  cursor: pointer;
}
.btn-signup {
  display: block;
  width: 60%;
  padding: 10px;
  margin-top: 20px;
  background-color: #8056de;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
