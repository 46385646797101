<template>
  <div>
    <div
      v-if="
        $route.path !== '/resources' &&
        $route.path !== '/login-phone' &&
        $route.name !== 'login-email'
      "
    >
      <div v-if="!isAuth" class="justify-center pt-20 pb-10 d-flex">
        <router-link to="/">
          <img width="100px" src="../assets/img/fly-new-logo.png" alt="logo"
        /></router-link>
      </div>
      <v-app-bar app height="64" class="white app-header" flat v-else>
         <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="d-flex d-lg-none"
        ></v-app-bar-nav-icon>
        <div class="justify-center d-flex flex-column">
          <div class="text-base sm:text-lg flex items-center" style="line-height: 1em !important">
            <!-- Display Workspace Logo -->
            <v-list-item-avatar v-if="currentWorkspaceLogo" class="mr-2">
              <img :src="currentWorkspaceLogo" alt="Workspace Logo" class="h-6 w-6 rounded-full">
            </v-list-item-avatar>
            <!-- Display Workspace Name -->
            <span v-if="currentWorkspaceName" class="text-gray-900 text-base font-bold">{{ currentWorkspaceName }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>

        <div class="items-center justify-between gap-2 d-flex">
          <router-link to="/create">

            <div 
  v-if="$route.path !== '/create-post' && $route.path !== '/gallery' && $route.path !== '/create' && checkWorkspaceRole"
  class="create-button md:w-[136px] md:h-[41px] w-[40px] h-[40px] border bg-white rounded-full cursor-pointer flex justify-center items-center shadow-md border-primary text-primary"
  @click="trackMixpanelEvent"
>
  <img
    src="@/assets/img/create_pg.svg"
    alt="Create Post"
    class="w-7 h-5"
  />
  <div class="hidden text-sm font-medium md:block">
    Create
  </div>
</div>
          </router-link>
          <!-- <workspace-menu></workspace-menu> -->
          <notification/>
          <user-profile></user-profile>
        </div>
      </v-app-bar>
    </div>

    <app-drawer v-model="drawer" v-if="isAuth"></app-drawer>
  </div>
</template>

<script>
import { onSnapshot,doc } from "firebase/firestore";
import headerConstants from "../assets/constants/header.constants.js";
import AppDrawer from "./AppDrawer.vue";
import WorkspaceMenu from "./WorkspaceMenu.vue";
import Notification from "./notification/Notification.vue"
import UserProfile from "./UserProfile.vue";
import { colUsers } from "@/utils/firebase.utils.js";
export default {
  components: {
    AppDrawer,
    UserProfile,
    WorkspaceMenu,
    Notification
  },
  data() {
    return {
      drawer: false,
      currentWorkspaceLogo: null,
      currentWorkspaceName: null,
    };
  },
  computed: {
    checkWorkspaceRole(){
      if(this.currentWorkspace && this.currentWorkspace?.role==="Client") return false;
      return true;
    },
    name() {
      if (this.user?.firstName) {
        return `${this.user.firstName} ${this.user.lastName}`;
      }
      return `${this.authUser.username}`;
    },
    title() {
      return headerConstants[this.$route.name] || "";
    },
  },
  methods: {
    loadCurrentWorkspace() {
      const workspaceDocRef = doc(colUsers,this.currentUID,'fly_workspaces',this.WID);
      onSnapshot(workspaceDocRef, (doc) => {
        if (doc.exists()) {
          const workspaceData = doc.data();
          this.currentWorkspaceLogo = workspaceData.logo_original || "";
          this.currentWorkspaceName = workspaceData.name || "";
        } else {
          console.error("No such document!");
        }
      }, (error) => {
        console.error("Error getting document:", error);
      });
      
    },
    trackMixpanelEvent() {
      this.$mixpanel.track("Create Post Clicked");
    },
  },
  mounted() {
    this.loadCurrentWorkspace();
  },
};
</script>
<style scoped>
.border-primary {
  border-color: #007BFF; /* Example blue color, adjust as per your theme */
}

.text-primary {
  color: #007BFF; /* Same as border to keep consistency */
}
.create-button {
  transition: box-shadow 0.3s ease;
}

.create-button:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}
</style>
