import { MAX_IMAGE_SIZE } from "@/assets/constants/config.constants";
import Compressor from "compressorjs";
// export const MAX_IMAGE_SIZE = 8 * 1024 * 1024;
export const OVERRIDE_MAX_IMAGE_SIZE = 5 * 1024 * 1024;
export function compressImage(file) {
  return new Promise((resolve, reject) => {
    var currentQuality = 0.98; // Starting quality value
    var targetSize = MAX_IMAGE_SIZE; // Target file size in bytes
    var minSize = 7 * 1024 * 1024; // Minimum file size after compression
    var maxIterations = 10; // Maximum number of iterations

    var compressIteration = 0;

    function compressWithQuality() {
      new Compressor(file, {
        quality: currentQuality,
        success(result) {
          var reader = new FileReader();
          reader.onload = function (e) {
            console.log(result.size, targetSize);
            if (result.size > targetSize && compressIteration < maxIterations) {
              if (result.size > minSize) {
                // Reduce quality only if above minimum size threshold
                currentQuality -= 0.025;
                compressIteration++;
                compressWithQuality();
              }
            } else {
              resolve({ base64: e.target.result, size: result.size });
            }
          };
          reader.readAsDataURL(result);
        },
        error(e) {
          console.error(e.message);
          reject(e);
        },
      });
    }

    compressWithQuality();
  });
}

export function compressOverrideImage(file) {
  return new Promise((resolve, reject) => {
    var currentQuality = 0.9; // Starting quality value

    var targetSize = OVERRIDE_MAX_IMAGE_SIZE; // Target file size in bytes

    var maxIterations = 10; // Maximum number of iterations

    var compressIteration = 0;

    function compressWithQuality() {
      new Compressor(file, {
        quality: currentQuality,
        success(result) {
          var reader = new FileReader();
          reader.onload = function (e) {
            console.log(result.size, targetSize);
            if (result.size > targetSize && compressIteration < maxIterations) {
              // If the compressed image is still larger than the target size, reduce the quality further
              currentQuality -= 0.1;
              compressIteration++;
              compressWithQuality();
            } else {
              resolve({ base64: e.target.result, size: result.size });
            }
          };
          reader.readAsDataURL(result);
        },
        error(e) {
          console.error(e.message);
          reject(e);
        },
      });
    }

    compressWithQuality();
  });
}

export function dataURLToBlob(dataURL) {
  // Split the Data URL into two parts: content type and Base64 data
  const [header, base64Data] = dataURL.split(",");
  const contentType = header.match(/:(.*?);/)[1];

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  const sliceSize = 512;
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
