<template>
  <v-card flat :width="isMobile ? '100%' : '700px'" class="mx-auto">
    <stripe-payment-method
      v-if="isUpdatePayment"
      @close="isUpdatePayment = false"
    />
    <v-card v-else flat>
      <v-card flat outlined>
        <v-card-text class="pa-4">
          <template v-if="payment">
            <div class="body-small">Card Details</div>
            <p class="body-medium black--text mb-2">
              {{ payment }}
            </p>
            <div class="body-small">Name</div>
            <div class="body-medium black--text mb-2">
              {{ name }}
            </div>
            <div class="body-small">Billing Address</div>
            <div class="body-medium black--text" v-html="address"></div>
          </template>
          <template v-else>
            <div class="body-medium black--text">
              Payment method not available
            </div>
          </template>
        </v-card-text>
      </v-card>
      <div class="d-flex mt-8">
        <img
          width="200px"
          height="44px"
          src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
          alt=""
        />
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          depressed
          color="primary"
          large
          @click="isUpdatePayment = true"
          >UPDATE PAYMENT METHOD</v-btn
        >
      </div>
    </v-card>
  </v-card>
</template>

<script>
import StripePaymentMethod from "./StripePaymentMethod.vue";
export default {
  components: {
    StripePaymentMethod,
  },
  data() {
    return {
      isUpdatePayment: false,
    };
  },
  computed: {
    name() {
      let billingDetails = this.authUser.billing_details || {};
      return billingDetails.name || "";
    },
    address() {
      let billingDetails = this.authUser.billing_details;
      if (!billingDetails || !billingDetails.address) return "-";
      let { line1, line2, city, state, country } = billingDetails.address;
      let countries =
        require("../../assets/constants/country.constants.js").default;
      country = countries[country] || "";
      let address = `${line1} ${line2 || ""}, <br>${city}, <br>${state}`;
      if (country) address = `${address}, ${country}.`;
      return address;
    },
    payment() {
      let { card_details } = this.authUser;
      if (!card_details) return "";
      let { last4, expMonth, expYear } = card_details;
      return `XXXX-XXXX-XXXX-${last4}  -  ${expMonth}/${expYear}`;
    },
  },
};
</script>

<style></style>
