<template>
  <div class="px-2 mb-5 border shadow-md rounded-xl">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <img :src="logo_url" class="w-6" alt="facebook" />
        <p class="text-sm font-normal mt-4 text-[#007E85]">
          {{ post.name }}
        </p>
      </div>
      <div class="mt-5">
        <p class="text-[13px]">
          {{ new Date(post.created_at._seconds * 1000).toLocaleDateString() }}
        </p>
      </div>
    </div>
    <p>{{ post.text }}</p>
    {{ post.url }}
    <img
      class="h-[200px] w-full rounded-md object-contain"
      v-if="post.images.length"
      :src="post.images[0]"
      alt="post image"
    />
    <div class="mt-1 overflow-hidden rounded-lg">
      <video-auto-play
        :autoplay="false"
        class="h-[200px] w-full rounded-md"
        v-if="post.videos.length > 0"
        :videoSrc="post.videos[0]"
      ></video-auto-play>
    </div>
    <div class="mt-3 d-flex">
      <v-avatar
        tile
        size="30"
        class="mr-1"
        v-for="(socialMedia, index) in post.social_media"
        :key="index"
      >
        <v-img :src="socialMedia.logo_url" :alt="socialMedia.name"></v-img>
      </v-avatar>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center gap-4 pb-2 mt-5">
        <div class="flex gap-1">
          <v-icon class="-mt-1">mdi-thumb-up</v-icon>
          {{ getCount(post, "likes") }}
        </div>
        <div class="flex gap-1">
          <v-icon>mdi-forum</v-icon>
          {{ getCount(post, "comments") }}
        </div>
      </div>
      <div class="flex flex-col items-end">
        <div class="flex gap-2 mt-2 ml-[100px] items-center">
          <h1 @click="showShares()" class="text-[13px] cursor-pointer">
            <v-icon class="-mt-1" size="20">mdi-reply</v-icon>
            {{ post.shares }} Shares
          </h1>

          <h1 class="text-[13px]">
            <v-icon class="" size="15">mdi-eye</v-icon>
            {{ getCount(post, "views") }} Views
          </h1>
        </div>
        <div v-if="showSocialIcons" class="mr-10 d-flex align-center">
          <div
            tile
            size="25"
            v-for="(item, i) in shareBtns"
            :key="i"
            class="ml-1 cursor-pointer"
            @click="openLink(post, item)"
          >
            <v-img width="20" :src="item.icon"></v-img>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoAutoPlay from "../public/VideoAutoPlay.vue";
import PostOutput from "./PostOutput.vue";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
export default {
  components: { VideoAutoPlay, PostOutput },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    postId: {
      type: String,
      default: "",
    },
    platform: {
      type: String,
      default: "",
    },

    logo_url: {
      type: String,
      default: "",
    },
    openOutput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSocialIcons: false,
      statistics: {},
      shareBtns: [
        {
          icon: "https://img.icons8.com/water-color/50/whatsapp.png",
          url: "https://api.whatsapp.com/send?text={link}",
        },
        {
          icon: "https://img.icons8.com/color/48/pinterest--v1.png",
          url: "https://pinterest.com/pin/create/button/?url={link}",
        },
        {
          icon: "https://img.icons8.com/color-glass/48/reddit.png",
          url: "https://www.reddit.com/submit?url={link}",
        },
        {
          icon: "https://img.icons8.com/fluency/48/telegram-app.png",
          url: "https://t.me/share/url?url={link}",
        },
      ],
    };
  },
  methods: {
    getCount({ id }, type) {
      // console.log("social", this.platform, "id", id, "type", type);
      if (!id) return 0;
      if (this.platform === "facebook") {
        return _.get(this.statistics, `${this.platform}.${id}.${type}`, 0);
      } else {
        let data = _.get(
          this.statistics,
          `${
            this.platform == "x / twitter" ? "twitter" : this.platform
          }.${type}`,
          0
        );
        return data;
      }
      let url = item.url.replace("{link}", link);
      window.open(url, "_blank");
    },
    async fetchPostStatistics() {
      const vm = this;
      try {
        let fetchPostStatistics = httpsCallable(
          functions,
          "fetchPostStatistics"
        );
        let uid = vm.postUID || vm.$route.params.uid;
        let id = vm.postId || vm.$route.params.id;
        let result = (await fetchPostStatistics({ uid: this.uid, postId: id }))
          .data;
        if (!result.status) {
          vm.$alert.show(result.message);
        } else {
          vm.statistics = result.result;
        }
      } catch (error) {
        vm.handleError(error);
      }
    },

    showShares() {
      this.showSocialIcons = !this.showSocialIcons;
    },
    openLink({ id }, item) {
      // console.log("social", this.platform, "id", id);
      let link = "";
      if (!id) return 0;
      if (this.platform === "facebook") {
        link = _.get(this.post.output, `${this.platform}.data[0].post_link`, 0);
      } else {
        link = _.get(
          this.post.output,
          `${
            this.platform == "x / twitter" ? "twitter" : this.platform
          }.data[0].post_link`,
          0
        );
      }
      let url = item.url.replace("{link}", link);
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.fetchPostStatistics();
  },
};
</script>
