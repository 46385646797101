<template>
  <div>
    <label class="input-label" v-if="label">{{ label }}</label>
    <vuetify-google-autocomplete
      v-model="input"
      :dense="dense"
      ref="input"
      id="map"
      :class="[
        label ? 'mt-1' : '',
        'py-1',
        ,
        'pr-16',
        'pl-5',
        'mt-2.5',
        'whitespace-nowrap',
        'bg-slate-100',
        'rounded-[32px]',
        'text-slate-500',
        'max-md:pr-5',
      ]"
      v-bind="$attrs"
      :value="input"
      prepend-icon-inner="mdi-map-marker"
      :rules="getRules"
      v-on:placechanged="$emit('onAddress', $event)"
      @input="$emit('input', input)"
    >
      <template v-slot:append>
        <v-tooltip bottom v-if="help">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ help }}</span>
        </v-tooltip>
      </template>
    </vuetify-google-autocomplete>
  </div>
</template>

<script>
import { getRules } from "@/utils/common.utils";
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>
