<template>
  <v-container style="max-width: 600px" class="py-10">
    <please-wait class="mt-3" v-if="isLoading"></please-wait>
    <!-- <post-card :post="post" :open-output="true" v-else></post-card> -->
    <large-post-card :post="post" v-else></large-post-card>
  </v-container>
</template>

<script>
import PleaseWait from "@/components/ui/PleaseWait.vue";
import PostCard from "@/components/post/PostCard.vue";
import LargePostCard from '@/components/post/LargePostCard'
import { doc, getDoc } from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
export default {
  components: { PleaseWait, PostCard,LargePostCard },
  data() {
    return {
      post: {},
      isLoading: true,
    };
  },
  methods: {
    async fetchPost() {
      const vm = this;
      try {
        vm.isLoading = true;
        let { uid, id, wid} = this.$route.params;
        if(!wid) wid = vm.WID
        let postRef = doc(colUsers, uid, "fly_workspaces", wid, "social-posts", id);
        let post = (await getDoc(postRef)).data();
        if (post == null) {
          vm.$router.replace("/dashboard");
          vm.$alert.show("Post not found");
          return;
        }
        post.id=id;
        post.wid=wid
        vm.post = post;
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchPost();
    }, 2000);
  },
};
</script>

<style></style>
