const firebaseConfigProd = {
  apiKey: "AIzaSyDOFH_u0UADv6IVR44nrym8byn78IMR0TU",
  authDomain: "fly-prod-in-zl.firebaseapp.com",
  projectId: "fly-prod-in-zl",
  storageBucket: "fly-prod-in-zl.appspot.com",
  messagingSenderId: "681315111540",
  appId: "1:681315111540:web:8d89d6e62ddc25df157721",
  measurementId: "G-TD8DQE0H16",
  vapidKey: "BPs4kcPbC05by5zilRt0Y3fNojgYohG5gnp1yyfnGVQC53eYsIx07d_ZwPsiTqybh5vdyRA81f_C2fVyrwidr5M"
};

export { firebaseConfigProd };