<template>
    <v-card>
      <v-card-title class="text-h5">Enter Your Mastodon Server</v-card-title>
      <v-card-text class="text-h10">Please provide the server name where your Mastodon account is registered (e.g., mastodon.social).</v-card-text>
      <v-card-text>
        <form-wrapper @submit="submitLogin()">
          <v-text-field
            label="Server Provider"
            prepend-icon="mdi-web"
            v-model="form.server"
            :rules="[rules.required, rules.validUrl]"
            required
          ></v-text-field>
          <v-btn
            :color="buttonColor"
            type="submit"
            :disabled="loading || success"
            block
          >
            <v-progress-circular
              indeterminate
              color="black"
              v-if="loading"
            ></v-progress-circular>
            <v-icon left v-if="success" color="black">mdi-check</v-icon>
            <span v-if="loading" class="text-black">Verifying...</span>
            <span v-if="success" class="text-black">Connected</span>
            <span v-if="!loading && !success" class="text-white">Login</span>
          </v-btn>
        </form-wrapper>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-center">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
  
      <!-- Dialog for Login Fail -->
      <v-dialog v-model="showDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h6">Login Failed</v-card-title>
          <v-card-text>{{ errorMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import {
    functions,
    googleProjectId,
    googleRegion,
    isLocalHost,
  } from "@/utils/firebase.utils";
  import axios from "axios";
  import FormWrapper from "@/components/ui/form/FormWrapper.vue";
  import rulesConstants from "@/assets/constants/rules.constants";
  import RadioGroup from "@/components/ui/form/RadioGroup.vue";
  import { updateDoc, doc } from "firebase/firestore";
  import { colUsers } from "@/utils/firebase.utils";

  
  export default {
    components: {
      FormWrapper,
      RadioGroup,
    },
    data() {
      return {
        wid: this.$store.getters.WID,
        form: {
          server: "",
          },
        loading: false,
        success: false,
        loginStatus: false,
        showDialog: false,
        errorMessage: "",
        ignoreResult: false,
        rules: rulesConstants,
      };
    },
    computed: {
      buttonColor() {
        if (this.loading) return "#a0aec0"; // Grey color while loading
        else if (this.success) return "#8056DE"; // Green color on success
        return "#8056DE"; // Default purple color
      },
    },
    methods: {
      closeDialog() {
        this.showDialog = false;
      },
      async submitLogin() {
        this.errorMessage = "";
  
        // Define the payload for the callable function
        const data = {
          ...this.form,
          uid: this.currentUID,
          wid: this.WID,
        };
        console.log(JSON.stringify(data));

          this.loading = true;
          let url = `https://${googleRegion}-${googleProjectId}.cloudfunctions.net/oauth/mastodon/request_token.html`;
          if (isLocalHost) {
            url = `http://localhost:5001/${googleProjectId}/${googleRegion}/oauth/mastodon/request_token.html`;
          }
          axios
        .post(url, data, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.dialog = false;
          console.log("Response:", response.data);
          let data = {
            last_posted_at: new Date()
          }
          //const responseData = JSON.parse(response.data);
          const url = response.data.authLink;
          this.openLoginDialog(url);
          updateDoc(doc(colUsers, this.currentUID), data)
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            console.error("Error Response:", error);
            this.$alert.show("Error Response:", error.response.data.message);
          } else {
            console.error("Error:", error);
            this.$alert.show("An error occurred. Please try again.");
          }
        });
      },
  
      openLoginDialog(url) {
      let w = 500;
      let h = 600;
      var left = window.screen.width / 2 - w / 2;
      var top = window.screen.height / 2 - h / 2;
      var win = window.open(
        url,
        "_blank",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h
      );
      try {
        win.focus();
        win.moveBy(left, top);
      } catch (e) {
        this.$alert.show(
          "Pop-up Blocker is enabled! Please add this site to your exception list."
        );
      }
    },

      resetForm() {
        this.form = {
          server: "",
        };
      },
      close() {
        this.ignoreResult = true; // Set the flag to ignore the result of the ongoing operation
        this.resetForm();
        if (this.loading) {
          this.loading = false; // Optionally reset loading state
          this.showDialog = false; // Close any open dialogs
        }
        this.$emit("close-dialog");
      },
    },
  };
  </script>
  