<template>
  <div class="relative">
    <label class="input-label" v-if="label">{{ label }}</label>
    <v-combobox
      v-model="input"
      :items="options"
      label=""
      ref="combobox"
      :dense="dense"
      :loading="isLoading"
      :return-object="false"
      item-value="value"
      outlined
      :class="[
        label ? 'mt-1' : '',
      ]"
      :search-input.sync="search"
      item-text="name"
      @input="$emit('input', input)"
      v-bind="$attrs"
      multiple
      small-chips
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      :rules="getRules"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          label
          small
          color="white lighten-3"
          class="rounded-3xl"
        >
          <span class="pr-2"> # {{ item }} </span>
          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Type and press <span class="font-semibold">enter</span> to add.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <div
      class="count-caption absolute d-flex right-3 mt-[-20px]"
      :class="{ 'text-red-500': tagCount > 500 }"
    >
      <span>{{ tagCount }}</span> / 500
    </div>
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  props: {
    fetch: {
      type: Function,
      default: null,
    },
    tagCount: Number,
  },
  mixins: [inputMixin],
  data() {
    return {
      search: "",
      isLoading: false,
      options: [],
    };
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      if (!val || val.length < 2) return;

      if (this.fetch) {
        this.isLoading = true;
        this.options = [];
        this.fetch(val)
          .then((res) => {
            this.options = res;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      let input = this.$refs.combobox.$el.querySelector("input");
      if (input) {
        input.setAttribute("enterkeyhint", "go");
      }
    });
  },
};
</script>

<style>
.count-caption {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
</style>
