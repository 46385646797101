<template>
  <editor class="editor mb-4" :value="value" :onInput="onInput" ref="editor" :editorId="editorId" :uid="uid" :wid="WID">
  </editor>
</template>

<script>
import { eventBus } from "@/utils/common.utils";
import DraftEditor from "./draftEditor";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    activeItem: String,
    editorRef: String,
    editorId: String,
  },
  data() {
    return {
      data: "",
    };
  },
  components: {
    editor: DraftEditor,
  },
  created() {
    eventBus.$on("mentionDataLoadingEditor", this.handleMentionDataLoading);
  },
  methods: {
    onInput(val) {
      // if(this.activeItem !== "PostText") {
      // this.$emit("newValue",val);
      // }
      // else {
      this.$emit("input", val);
      // }
    },
    updateValue(val) {
      // console.log("editorId", this.editorId);
      eventBus.$emit("updateEditorText" + this.editorId, val);
    },
    handleMentionDataLoading(isLoading) {
      // Re-emit the event for the parent component
      this.$emit("mentionDataLoadingStep", isLoading);
    }
  },
  beforeDestroy() {
    eventBus.$off("mentionDataLoadingEditor", this.handleMentionDataLoading);
  }
};
</script>

<style>
.editor {
  min-height: 90px;
  padding: 10px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 20px;
  display: flex;
}

.editor>div:nth-child(2),
.DraftEditor-root+div {
  display: flex !important;
  flex-direction: column;
}

.editor .DraftEditor-root {
  min-height: 90px;
}

.editor .mnw6qvm {
  display: flex;
  z-index: 999999 !important;
  /* box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6); */
  box-shadow: 0px 0px 14px 9px rgba(0, 0, 0, 0.1);
  padding: 10px !important;
  max-height: 90px !important;
  overflow-y: scroll !important;
}

.editor .mtiwdxc {
  display: flex !important;
  align-items: center;
}

.mnw6qvm>div>div {
  display: flex !important;
  gap: 1rem;
}

.mnw6qvm>div>div img {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  object-fit: fill;
}

.DraftEditor-editorContainer span.m6zwb4v {
  color: blue !important;
}

.hngfxw3 {
  color: blue !important;
}
</style>
