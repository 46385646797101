<template>
  <div>
    <div
      class="flex flex-col items-center md:pb-[50px] justify-center h-screen bg-[#F2F6FA]"
    >
      <div class="mt-5 md:mt-[40px] justify-center items-center">
        <div
          class="flex xl:w-[1169px] w-full rounded-3xl shadow-[20px_20px_20px_0px_rgba(0,_0,_0,_0.1)]"
        >
          <div
            class="flex flex-col px-4 lg:px-0 rounded-l-2xl rounded-r-2xl md:rounded-r-none md:rounded-l-3xl w-full py-4 md:py-[50px] items-center gap-2 xl:w-[585px] lg:w-[450px] border bg-white"
          >
            <v-form
              v-model="isValid"
              @submit.prevent="submitRegister()"
              ref="form"
              class="flex flex-col items-center md:w-[310px] w-full gap-6 lg:items-start"
            >
              <div
                class="flex flex-col items-center gap-2 mb-px lg:items-start"
              >
                <div
                  class="flex flex-col items-center w-full gap-1 lg:items-start"
                >
                  <img
                    src="../assets/img/fly-new-logo.png"
                    alt="Flynewlogo"
                    id="Flynewlogo"
                    class="w-20 md:-ml-4"
                  />
                  <div class="text-xl font-semibold text-[#121212]">
                    Create new Account
                  </div>
                </div>
                <div
                  class="relative flex flex-row items-start justify-center w-full gap-4"
                >
                  <div
                    class="text-xs text-[#636b8c] h-8 md:w-[275px] max-w-full text-start"
                  >
                    If you already have an account
                    <span
                      class="text-xs cursor-pointer font-medium text-[#8056de]"
                      @click="openRoute('login')"
                    >
                      login
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col items-center w-full gap-6 lg:items-start"
              >
                <div
                  class="flex flex-col items-center w-full gap-4 lg:items-start"
                >
                  <div class="relative w-full md:w-[309px]">
                    <input
                      type="text"
                      class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start pl-10 rounded-[26px]"
                      placeholder="Name"
                      v-model="form.display_name"
                      @keyup.enter="sendOTP"
                    />
                    <img
                      src="../assets/img/complete-signup/name.svg"
                      alt="Group"
                      class="absolute w-4 mt-1 top-3 left-4"
                    />
                  </div>
                  <div class="relative w-full md:w-[309px]">
                    <vue-tel-input-vuetify
                      class="bg-[#f2f6fa] flex flex-row outline-[#8056DE] md:w-[309px] h-12 rounded-[26px]"
                      mode="international"
                      v-model="form.phone_number"
                      placeholder="Enter Phone Number"
                      label=""
                    >
                      <input
                        type="text"
                        class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start pl-10 rounded-[26px]"
                        placeholder="Phone Number"
                        v-model="form.phone_number"
                        required="required"
                      />
                    </vue-tel-input-vuetify>
                    <!-- <img
                      src="https://file.rendit.io/n/ypCBTa2JM2EZ8ZN6gm3e.svg"
                      alt="Group"
                      class="absolute w-3 mt-1 top-3 left-5"
                    /> -->
                  </div>
                  <div class="relative w-full md:w-[309px]">
                    <input
                      type="email"
                      class="bg-[#f2f6fa] w-full flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-12 items-start pl-11 rounded-[26px]"
                      placeholder="Email"
                      v-model="form.email"
                    />
                    <img
                      src="../assets/img/complete-signup/email.svg"
                      alt="Group"
                      class="absolute w-4 mt-[3px] top-4 left-4"
                    />
                  </div>
                  <signup-select
                    v-model="form.user_type"
                    :items="items"
                    rules="required"
                    class="w-full"
                  ></signup-select>
                  <!-- <address-field
                    label="Your Location"
                    class="w-full"
                    rules="required"
                    @onAddress="onAddress"
                  ></address-field> -->
                  <div class="gap-1 text-[12px]">
                    <input type="checkbox" v-model="consent" class="mr-2" />
                    <span @click="toggleContentCheckbox"
                      >I have read, understood, and agree to the
                      <a
                        href="https://fly-social.com/terms-of-use"
                        target="_blank"
                        ><span class="text-[#8056DE] cursor-pointer"
                          >Terms</span
                        ></a
                      >
                      and
                      <a
                        href="https://fly-social.com/privacy-policy"
                        target="_blank"
                        ><span class="text-[#8056DE] cursor-pointer"
                          >Privacy</span
                        ></a
                      >.</span
                    >
                  </div>
                  <button
                    class="text-sm font-medium w-full md:w-[309px] cursor-pointer text-white bg-[#8056de] h-12 rounded-[26px]"
                    @click="handleNextStep()"
                  >
                    <span v-if="!isLoading"> Sign Up</span>

                    <span v-else> please wait... </span>
                  </button>
                </div>
              </div>
            </v-form>
          </div>
          <!-- <div id="recaptcha-container"></div> -->

          <div
            class="bg-[#8056de] px-4 lg:px-0 hidden md:flex flex-col py-10 rounded-r-3xl justify-center items-center w-full xl:w-[585px] lg:w-[450px]"
          >
            <img
              src="../assets/img/Login_Page_V4.png"
              class="w-[368px] mt-20"
              alt=""
            />
            <div class="flex flex-col items-center justify-center w-full gap-3">
              <div
                class="text-xl font-semibold text-center text-white lg:text-left"
              >
                Your Social, Simplified!
              </div>
              <!-- <div class="pb-10 text-xs text-center text-white lg:text-left">
                Everything you can easily manage with customizable dashboard.
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isUserType"
      class="absolute inset-0 flex flex-col bg-black bg-opacity-40 z-[99] items-center justify-center"
    >
      <div class="w-[321px] py-5 px-[27px] shadow bg-white rounded-[20px]">
        <div class="text-base font-medium text-neutral-900">Oops..</div>
        <div class="mt-4 text-sm font-medium text-slate-500">
          Please select a user type
        </div>
        <div class="flex items-end justify-end">
          <button
            @click="isUserType = false"
            class="w-[119px] text-base font-semibold text-white text-center py-2 mt-[23px] bg-red-600 rounded-3xl"
          >
            Done
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="isNotValidPhone"
      class="absolute inset-0 flex flex-col bg-black bg-opacity-40 z-[99] items-center justify-center"
    >
      <div class="w-[321px] py-5 px-[27px] shadow bg-white rounded-[20px]">
        <div class="text-base font-medium text-neutral-900">Message</div>
        <div class="mt-4 text-xs font-medium text-slate-500">
          Please enter a valid phone number
        </div>
        <div class="flex items-end justify-end">
          <button
            @click="isNotValidPhone = false"
            class="w-[119px] text-base font-semibold text-white text-center py-2 mt-[23px] bg-red-600 rounded-3xl"
          >
            Done
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="termsAndPrivacy"
      class="absolute inset-0 flex flex-col bg-black bg-opacity-40 z-[99] items-center justify-center"
    >
      <div class="w-[321px] py-5 px-[27px] shadow bg-white rounded-[20px]">
        <div class="text-base font-medium text-neutral-900">Oops..</div>
        <div class="mt-4 text-sm font-medium text-slate-500">
          Please accept terms and privacy policy.
        </div>
        <div class="flex items-end justify-end">
          <button
            @click="termsAndPrivacy = false"
            class="w-[119px] text-base font-semibold text-white text-center py-2 mt-[23px] bg-red-600 rounded-3xl"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, colUsers, db } from "@/utils/firebase.utils";
import CompleteSignup from "@/components/CompleteSignup.vue";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  writeBatch,
  updateDoc,
  GeoPoint,
  orderBy,
  setDoc,
  addDoc,
} from "firebase/firestore";
import rulesConstants from "@/assets/constants/rules.constants";
import AddressField from "@/components/ui/form/AddressField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import InputField from "@/components/ui/form/InputField.vue";
import { searchClient } from "@/utils/algolia.utils.js";
import SignupSelect from "@/components/ui/form/SignupSelect.vue";
import { trackGTM } from "../utils/common.utils";

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  unlink,
} from "firebase/auth";
import axios from "axios";
import { eventBus } from "@/utils/common.utils";
export default {
  components: {
    CompleteSignup,
    AddressField,
    ComboBox,
    InputField,
    SignupSelect,
  },
  data() {
    return {
      isLoading: false,
      isUserType: false,
      isNotValidPhone: false,
      plans: [],
      step: 1,
      consent: false,
      showSignup: true,
      isGoogleLoading: false,
      isFacebookLoading: false,
      termsAndPrivacy: false,
      loading: false,
      rules: [rulesConstants.required],
      emailRules: [rulesConstants.required, rulesConstants.email],
      isValid: false,
      items: [
        "Individual",
        "Journalist",
        "Social media influencer",
        "Advisor / Consultant / Coach",
        "Small / Medium Business",
        "Community Head",
        "Enterprise",
        "Car Dealer",
        "Student",
        "PR/Media Agency",
        "Other",
      ],
      form: {
        display_name: "",
        email: "",
        interests: [],
        professionalskills: [],
        residential: {},
        user_type: "",
        dob: "",
        gender: "",
        photo_url: "",
        phone_number: "",
      },
      showDialog: false,
      isSignedInWithGoogle: false,
    };
  },

  created() {
    let google_signup_details = localStorage.getItem("signupWithGoogleDetails");
    let signup_details = localStorage.getItem("signupDetails");

    if (google_signup_details) {
      google_signup_details = JSON.parse(google_signup_details);
      this.form.display_name = google_signup_details.displayName;
      this.form.email = google_signup_details.email;
      this.form.photo_url = google_signup_details.photoURL;
    }
    if (signup_details) {
      signup_details = JSON.parse(signup_details);
      this.form.phone_number = signup_details.phoneNumber;
    }
    if (this.$route.query.displayName) {
      this.form.display_name = this.$route.query.displayName;
    }
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
    if (this.$route.query.photoURL) {
      this.form.photo_url = this.$route.query.photoURL;
    }
    if (this.$route.query.phone) {
      this.form.phone_number = this.$route.query.phone;
    }
  },
  methods: {
    connection() {
      this.$router.push("/dashboard");
    },
    async signInWithGoogle() {
      this.isSignedInWithGoogle = true;
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
      provider.addScope("https://www.googleapis.com/auth/user.gender.read");
      // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      const vm = this;
      try {
        vm.isGoogleLoading = true;
        let googleAuth = auth.currentUser.providerData.find(
          (i) => i.providerId == "google.com"
        );
        if (googleAuth) {
          await unlink(auth.currentUser, "google.com");
        }
        let user = await linkWithPopup(auth.currentUser, provider);
        let { oauthAccessToken, displayName, email, photoURL } =
          user._tokenResponse;

        // Update the form data including photo_url
        vm.form = {
          ...vm.form,
          photo_url: photoURL,
          display_name: displayName,
          email: email,
        };

        try {
          let peopleRes = await axios.get(
            "https://people.googleapis.com/v1/people/me",
            {
              params: {
                personFields: "genders,birthdays",
              },
              headers: {
                Authorization: `Bearer ${oauthAccessToken}`,
              },
            }
          );
          let birthday = peopleRes.data.birthdays[0];
          let gender = peopleRes.data.genders[0];
          if (birthday && birthday.date) {
            vm.form.dob = `${birthday.date.day}/${birthday.date.month}/${birthday.date.year}`;
          }
          if (gender) {
            vm.form.gender = gender.value || "";
          }
        } catch (error) {
          console.log(error);
        }
        vm.form.username = await vm.generateUniqueUsername(
          vm.form.display_name
        );
        vm.isGoogleLoading = false;
        vm.showSignup = true;
      } catch (error) {
        vm.isGoogleLoading = false;
        vm.handleError(error);
      }
    },
    async signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      const vm = this;
      try {
        vm.isFacebookLoading = true;
        let facebookAuth = auth.currentUser.providerData.find(
          (i) => i.providerId == "facebook.com"
        );
        if (facebookAuth) {
          await unlink(auth.currentUser, "facebook.com");
        }
        let user = await linkWithPopup(auth.currentUser, provider);
        let { displayName, email, photoUrl } = user._tokenResponse;
        vm.form.photo_url = photoUrl;
        vm.form.display_name = displayName;
        vm.form.email = email;
        vm.form.username = await vm.generateUniqueUsername(
          vm.form.display_name
        );
        vm.isFacebookLoading = false;
        vm.showSignup = true;
      } catch (error) {
        vm.isFacebookLoading = false;
        vm.handleError(error);
      }
    },
    async generateUniqueUsername(name) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!name) {
            resolve("");
            return;
          }
          const cleanedName = name.replace(/[^a-zA-Z0-9]/g, ".");
          let suffix = "";
          let isUnique = false;
          let generatedUsername = "";
          let attempts = 0;
          while (!isUnique && attempts < 10) {
            generatedUsername = (cleanedName + suffix).toLowerCase();
            let q = query(colUsers, where("username", "==", generatedUsername));
            const snapshot = await getDocs(q);
            if (snapshot.empty) {
              isUnique = true;
            }
            suffix = suffix === "" ? "1" : (parseInt(suffix) + 1).toString();
            attempts++;
          }
          resolve(generatedUsername);
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchInterests(val) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        // console.log("in interests");
        const index = searchClient.initIndex("interests_master");
        // console.log("index", index);
        try {
          let res = await index.search(val, {
            cacheable: true,
          });
          // console.log("res", res);
          let list = res.hits.map((i) => i.interestname);
          resolve(list);
        } catch (error) {
          reject(error);
        }
      });
    },
    toggleContentCheckbox() {
      this.consent = !this.consent;
    },
    async handleNextStep() {
      const vm = this;
      if (vm.form.email === "" || !vm.form.email) {
        vm.$alert.show("Email is required");
        vm.isLoading = false;
        return;
      }
      const emailRe =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (vm.form.email && !vm.form.email.match(emailRe)) {
        vm.$alert.show("E-mail must be valid");
        return;
      }

      // Validate the form fields
      vm.$refs.form.validate();

      // Check if the user type is selected
      if (vm.form.user_type === "" || !vm.form.user_type) {
        vm.isUserType = true;
        return;
      }
      if (!vm.isValid) return;

      // Check for the user's consent to Terms of Service and Privacy Policy
      if (!vm.consent) {
        vm.termsAndPrivacy = true;
        return;
      }

      const phoneNumberWithoutCode = vm.form.phone_number
        .toString()
        .replace(/^\+\d+\s*/, "")
        .split(" ")
        .join("");
      const phoneNumberWithCode = vm.form.phone_number
        .toString()
        .trim()
        .split(" ")
        .join("");

      // if (phoneNumberWithoutCode.length != 10) {
      //   vm.isNotValidPhone = true;
      //   return;
      // }

      vm.submitRegister();
    },

    async getPlan(id) {
      const vm = this;
      try {
        let planRef = doc(collection(db, "fly_plans"), id);
        let plan = (await getDoc(planRef)).data() || {};
        // console.log("plans", plan);
        return plan;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async submitRegister() {
      // console.log(this.$store.getters.referredBy);
      let plan = await this.getPlan(this.planId || "free");
      const vm = this;
      vm.$refs.form.validate();

      if (!vm.isValid || !vm.consent) {
        if (!vm.consent) {
          vm.termsAndPrivacy = true;
          this.isLoading = false;
        }
        return; // Stop the execution if the form is invalid or consent not given
      }
      const emailRe =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (vm.form.email && !vm.form.email.match(emailRe)) {
        vm.$alert.show("E-mail must be valid");
        return;
      }
      if (vm.form.user_type === "" || !vm.form.user_type) {
        vm.isUserType = true;
        vm.isLoading = false;
        return;
      }
      if (vm.form.email === "" || !vm.form.email) {
        vm.$alert.show("Email is required");
        vm.isLoading = false;
        return;
      }
      if (
        !vm.isSignedInWithGoogle &&
        (vm.form.display_name === "" || !vm.form.display_name)
      ) {
        vm.isLoading = false;
        vm.$alert.show("Name is required");
        return;
      }
      const phoneNumberWithoutCode = vm.form.phone_number
        .toString()
        .replace(/^\+\d+\s*/, "")
        .split(" ")
        .join("");
      if (
        !vm.isSignedInWithGoogle &&
        (vm.form.phone_number === "" ||
          !vm.form.phone_number ||
          phoneNumberWithoutCode.length != 10)
      ) {
        vm.isLoading = false;
        vm.isNotValidPhone = true;
        return;
      }
      try {
        vm.loading = true;
        let data = {
          ...vm.form,
          created_time: new Date(),
          fly_plan: plan && plan.id ? plan.id : "free",
          photo_url: vm.form.photo_url,
          phone_number: vm.form.phone_number,
          fly_storage: 0,
          fly_ai_image_credits: plan.limits.ai_image_actions || 0,
          fly_ai_text_credits: plan.limits.ai_text_actions || 0,
          fly_rzpay_subscribed: false,
          fly_stripe_subscribed: false,
          fly_credits_upd_at: new Date(),
          fly_credits: plan.limits.posts || 0,
          web_signup: true,
          residential: {
            ...vm.form.residential,
            City: vm.form.residential?.City || "", // Ensure City field is not undefined
            State: vm.form.residential?.State || "",
          },
        };
        // console.log("data:", data);
        // Assuming auth.currentUser is the authenticated user's instance
        let uid = auth.currentUser.uid;
        let userRef = doc(db, "users", uid);
        // console.log("userRef", uid);
        // console.log("userRef", data);
        // Replace 'users' with your actual users collection name
        await setDoc(userRef, data);

        // Create a reference to the fly_workspaces collection for the user
        const workspaceRef = collection(db, "users", uid, "fly_workspaces");
        // Create the initial data in the fly_workspaces collection
        const initialWorkspaceData = {
          name: this.form.display_name,
        };
        // Create the document with the ID "default" in the fly_workspaces collection
        const initialWorkspaceDocRef = doc(workspaceRef, "default");
        // Set the initial workspace document data
        await setDoc(initialWorkspaceDocRef, initialWorkspaceData);
        // Handle referral logic if applicable
        if (this.$store.getters.referredBy != null) {
          let referrerQuery = query(
            collection(db, "users"),
            where("username", "==", this.$store.getters.referredBy)
          );
          let matchingUsers = (await getDocs(referrerQuery)).docs;
          if (matchingUsers.length > 0) {
            const currentCredits = matchingUsers[0].data().fly_credits || 0;
            const updatedCredits = currentCredits + 10;
            await updateDoc(matchingUsers[0].ref, {
              fly_credits: updatedCredits,
            });
          }
        }
        if (this.$store.getters.campaign != null) {
          let campaignId = this.$store.getters.campaign;
          let campaignRef = doc(collection(db, "fly_campaign"), campaignId);
          let campaign = (await getDoc(campaignRef)).data() || {};
          // console.log("campaignRef", campaign);
          if (campaign) {
            let plan = await this.getPlan(campaign.plan);
            // console.log("plan", plan);
            // Assuming campaign.end is an object with 'seconds' and 'nanoseconds' properties
            var endDate = new Date(campaign.end.seconds * 1000);

            // Get today's date
            var today = new Date();

            // Check if today is before the end date
            const campaignStartDate = new Date(campaign.start.seconds * 1000);
            if (today >= campaignStartDate && today <= endDate) {
              // Today is within the campaign period
              if (plan) {
                let userRef = doc(collection(db, "users"), this.uid);
                await updateDoc(userRef, {
                  fly_plan: campaign.plan,
                  fly_ai_image_credits: plan.limits.ai_image_actions,
                  fly_ai_text_credits: plan.limits.ai_text_actions,
                  fly_credits: plan.limits.posts,
                  fly_campaign: campaignId,
                  fly_plan_valid_until: new Date(
                    new Date().getTime() +
                      campaign.valid_for_months * 30 * 24 * 60 * 60 * 1000
                  ),
                });
              }
            } else {
              // Today is not within the campaign period
              console.log("The campaign has ended.");
            }
          }
        }
        const loginSessionRef = collection(db, "fly_login_sessions");
        const loginSessionData = {
          uid: uid,
          created_at: new Date(),
          origin_url: window.location.hostname,
          action: "signup",
        };
        addDoc(loginSessionRef, loginSessionData);
        eventBus.$emit("openPwa");
        vm.loading = false;
        this.$mixpanel.identify(uid);
        this.$mixpanel.track("Signup Completed");
        trackGTM(
          "Signup Completed",
          "app",
          "signup-completed",
          "Signup Completed",
          0
        );
        vm.redirect();
      } catch (e) {
        console.error("Error during registration:", e);
        vm.loading = false;

        vm.$alert.show(e.message);
      }
      this.isLoading = false;
    },

    onAddress(e) {
      if (e) {
        this.location = e.Address || "";
        this.form.residential = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country || "",
          City: e.locality || "",
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
      }
    },
    redirect() {
      let { redirect } = this.$route.query;
      this.$router.replace(redirect || "/dashboard");
    },
  },
  async mounted() {
    this.step = 1;
  },
  beforeDestroy() {
    this.step = "";
  },
};
</script>

<style scoped>
.dialog-card {
  height: 480px;
  transform: translateZ(25px);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}
@media (max-width: 600px) {
  .dialog-card {
    height: 500px;
  }
}
input[type="checkbox"] {
  accent-color: #8056de;
}
</style>
