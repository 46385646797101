<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <template>
        <div class="d-flex gap-2" v-for="(data, i) of user_data" :key="i">
          <v-list-item-avatar class="mr-2" size="40">
            <v-img v-if="data.picture" :src="data.picture" width="30px"></v-img>
            <user-logo v-else :size="40" />
          </v-list-item-avatar>

          <v-list-item-content class="mr-3">
            <v-list-item-title
              class="text-sm md:text-lg d-flex align-center"
              v-if="data.name"
            >
              {{ data.name }}
            </v-list-item-title>
            <v-list-item-title v-else> username </v-list-item-title>
          </v-list-item-content>
        </div>
      </template>
      <v-spacer></v-spacer>
      <v-list-item-action>
        <v-img
          src="https://img.icons8.com/fluency/48/instagram-new.png"
          width="30px"
        ></v-img>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pa-0">
      <div v-html="instagram_text ? instagram_text : content" class="mb-3" />
      <div v-html="hashtags" />
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="d-flex align-center h-full black">
            <v-img :src="item.src" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex my-3 align-center">
        <v-img
          max-width="25px"
          class="mt-1"
          src="https://img.icons8.com/fluency-systems-regular/48/like--v1.png"
        ></v-img>
        <v-img
          max-width="23px"
          style="transform: rotate(270deg)"
          class="mx-2 mt-1"
          src="https://img.icons8.com/fluency-systems-regular/48/000000/speech-bubble--v1.png"
        ></v-img>
        <v-img
          max-width="23px"
          style="transform: rotate(-25deg)"
          src="https://img.icons8.com/fluency-systems-regular/48/000000/sent--v1.png"
        ></v-img>
        <v-spacer />
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import UserLogo from "@/components/UserLogo.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import { auth, colUsers } from "@/utils/firebase.utils";
import { doc, getDoc } from "firebase/firestore";
export default {
  components: { UserLogo, VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Array,
      default: () => [],
    },
    configuration: Object,
    instagram_text: String,
    thumbRemoved: Boolean,
  },
  data() {
    return {
      carousel: 0,
      picture: "",
      name: "",
      user_data: {},
      thumbnail: "",
    };
  },
  watch: {
    "configuration.ig_users": {
      handler(val) {
        console.log("val:", val);
        this.fetchInstagramData();
      },
    },
    "configuration.ig_thumb": {
      handler(val) {
        const vm = this;
        // console.log("val",val);
        if (val) {
          if (typeof val === "object") {
            if (val instanceof File) {
              var reader = new FileReader();
              reader.onload = function (e) {
                vm.thumbnail = e.target.result;
              };
              reader.readAsDataURL(val);
            }
          } else {
            vm.thumbnail = val;
          }
        } else { vm.thumbnail = null; }
      },
      deep: true,
    },
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        // console.log("thum in iggg prv", this.thumbnail);
        // console.log(this.post.videos);
        if ((this.thumbnail || this.thumb) && !this.thumbRemoved) {
          list.push({
            src: this.thumbnail ? this.thumbnail : this.thumb,
            type: "image",
          });
        } else {
          this.post.videos.forEach((item) => {
            list.push({
              src: item.url || item,
              type: "video",
            });
          });
        }
      }
      if (this.post.images) {
        if (this.post.images.length > 10) {
          let images = this.post.images.slice(0, 10);
          images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        } else {
          this.post.images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        }
      }
      return list;
    },
  },
  methods: {
    async fetchInstagramData() {
      const connectedAppsRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps",
        "instagram"
      );
      const connectedAppsSnap = await getDoc(connectedAppsRef);
      const connectedAppsData = connectedAppsSnap.data();
      const ig_users = this.configuration.ig_users;
      connectedAppsData?.users.map((connectedAppsUser, index) => {
        ig_users.map((configurationUser) => {
          if (connectedAppsUser.user_id === configurationUser) {
            if (
              connectedAppsUser.picture ||
              connectedAppsUser.name ||
              connectedAppsUser.user_name
            ) {
              const user_data = {
                user_id: connectedAppsUser.user_id || "",
                name:
                  connectedAppsUser.name || connectedAppsUser.user_name || "",
                picture: connectedAppsUser.picture || "",
              };
              this.$set(this.user_data, index, user_data);
            }
          }
        });
      });
    },
  },
};
</script>

<style></style>
