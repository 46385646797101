<template>
  <div v-if="isActiveDomain && item.alias !== 'around'">
    <v-list-item>
      <v-list-item-avatar tile>
        <img width="48" height="48" :src="item.logo_url" :alt="item.name" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <template v-if="!isHelpCMS">
          <v-list-item-subtitle v-if="item.connection?.connection_status == 'in progress'">
            Connecting...
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="isManualPosting">
            Manual posting | <br class="d-sm-none" />Automation coming soon
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="connectionName" class="d-flex align-center">
            <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/92d500/checkmark--v1.png" alt="checkmark--v1"/>
            Connected As: <br class="d-sm-none" />
            <span class="ml-1">{{ connectionName }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{ platform }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <span v-if="expiryDate">
              Connection Expires on <br class="d-sm-none" /> {{ expiryDate }}
            </span>
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-center">
        <v-btn
          icon
          v-if="isHelpCMS"
          @click="$router.push(`/update-help-cms/${item.id}`)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <template v-else>
          <div class="grow" v-if="platform == 'Connection required'">
            <v-btn
              depressed
              outlined
              color="#8050DE"
              height="30px"
              class="py-[-1px] px-2"
              @click="openConnectionDialog"
            >
              <span class="text-xs">Connect Now</span>
            </v-btn>
          </div>

          <tool-tip message="Disconnect">
            <v-btn
              icon
              v-if="item.connection"
              :loading="isDisconnectLoading"
              @onDisconnect="$emit('onDisconnect')"
              @click="disconnectConnection(item.alias, profile)"
              class="mr-3"
              color="red"
            >
              <v-icon>mdi-connection</v-icon>
            </v-btn>
          </tool-tip>

          <connection-info-dialog ref="connectionInfoDialog" :item="item" :profile="profile" />
        </template>
      </v-list-item-action>
    </v-list-item>
    <v-divider v-if="!isLast"></v-divider>
  </div>
</template>



<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth } from "@/utils/firebase.utils";
import ConnectionInfoDialog from "./ConnectionInfoDialog.vue";
import ToolTip from "../ui/ToolTip.vue";
import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS
import hopscotch from "hopscotch";
export default {
  components: { ConnectionInfoDialog, ToolTip },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
      default: "personal",
    },
  },
  data() {
    return {
      showDisconnectModal: false,
      isLoading: false,
      isDisconnectLoading: false,
      expiryDate: "",
      show: false,
    };
  },
  async created() {
    const expiryDate = await this.connectedHandle(this.item.alias, this.WID);
    this.expiryDate = expiryDate;
  },
  computed: {
    isHelpCMS() {
      return this.$route.name == "help-cms";
    },
    platform() {
      // if (this.item.alias == "around") return "Connected";
      if (this.item.connection) return "Reconnect";
      if (this.isActiveDomain) return "Connection required";
      return "Coming Soon";
    },
    isActiveDomain() {
      const currentHostname = window.location.hostname;
      return this.item.active_domains?.some(
        (domain) => new URL(domain).hostname === currentHostname
      );
    },
    igUsers() {
      if (this.item.connection.id == "instagram") {
        if (
          this.item.connection.users &&
          (this.item.connection.users.length == 0 ||
            !this.item.connection.users[0])
        ) {
          // console.log('this.item.connect.name:', this.item.connection)
          return false;
        }
        return true;
      }
      return true;
    },
    connectionName() {
      if (this.item.type === "marketplace") {
        if (this.item.connection && this.item.connection.phone_number) {
          return this.item.connection.phone_number;
        }
      }

      let name = this.authUser.display_name || auth.currentUser.email;
      if (this.currentUser) {
        name = this.currentUser.display_name || this.currentUser.email;
      }
      //if (this.item.alias == "around") return name;
      if (
        this.item.connection &&
        this.item.connection.name &&
        this.item.connection.name.length > 0
      )
        return this.item.connection.name;
      if (
        this.item.connection &&
        this.item.connection.user_name &&
        this.item.connection.user_name.length > 0
      )
        return this.item.connection.user_name;
      return null;
    },
    isManualPosting() {
      let whitelist = ["Whatsapp"];
      return whitelist.indexOf(this.item.name) != -1;
    },
  },
  mixins: [connectedAppsMixins],
  methods: {
    openConnectionDialog() {
    this.$refs.connectionInfoDialog.dialog = true;
  },
  handleButtonClick(item, profile) {
    if (item.type === "marketplace") {
      if (item.name === "Olx" || item.name === "Quickr") {
        this.showTermsDialog = true; // This will show the terms dialog before proceeding
      }
    } else {
      this.initConnection(item.alias, profile); // Proceed with the normal flow for other types
    }
  },
    shouldShowItem(item) {
      // List of restricted aliases
      const restrictedAliases = ["olx", "quickr"];
      // Check if the item's alias is restricted and if the phone number starts with "+91"
      if (restrictedAliases.includes(item.alias)) {
        return this.authUser.country_code === "IN";
      }
      // If the item's alias is not restricted, always show it
      return true;
    },
    showDisconnectConfirmation() {
      this.$mixpanel.track("Disconnect App Initiated");
      this.showDisconnectModal = true;
    },

    // Method to hide the disconnect modal
    hideDisconnectModal() {
      this.showDisconnectModal = false;
    },

    // Method to handle disconnection confirmed
    disconnectConnectionConfirmed() {
      this.$mixpanel.track("Disconnected App Successfully");
      this.disconnectConnection(this.item.alias, this.profile);
      this.hideDisconnectModal();
    },
    openVideo({ intro_video }) {
      window.open(intro_video, "_blank");
    },
  },
};
</script>

<style></style>

<style scoped>
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
</style>
