<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Tumblr Settings
      </div>
      <div class="px-7 py-3">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="Blog"
              @input="$emit('input', form)"
              v-model="form.blog"
              :items="blogs"
            ></select-field>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectField from "@/components/ui/form/SelectField.vue";
export default {
  components: { SelectField },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    blogs: Array,
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
  },

  data() {
    return {
      form: {
        blog: [],
      },
    };
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "tumblr");
      return platform || {};
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
      if (this.blogs && this.blogs.length === 1) {
        this.form.blog = this.blogs[0].name;
        this.$emit("input", this.form);
      }
    },
    updateConfiguration() {
      this.form.blog = this.configuration.blog;
    },
  },
  beforeMount() {
    this.initForm();

    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.updateConfiguration();
    }
  },
};
</script>

<style></style>
