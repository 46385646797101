<template>
  <div class="flex flex-col items-center justify-center w-full  pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img src="@/assets/img/redirect/social-tracker-main.png" class="cursor-pointer w-6 md:w-[28px]" alt="post" />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">Social Tracker</p>
          </div>
        </div>
        <div class="flex gap-2 justify-end">
          <div class="flex justify-end items-center mr-10 mt-6 max-w-[12rem]">
            <div class="flex border border-gray-300 rounded-full overflow-hidden">
              <button
                @click="isFetched = false"
                :class="`px-4 py-2 text-base ${!isFetched ? 'bg-[#1773ea] text-white font-semibold' : 'bg-white'}`">
                Fetched
              </button>
              <button
                @click="isFetched = true"
                :class="`px-[1.5rem] py-2 text-base ${isFetched ? 'bg-[#1773ea] text-white font-semibold' : 'bg-white'}`">
                Saved
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between mb-5 ml-[55px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="flex items-center gap-1">
          <p class="text-xs md:text-[18px] text-gray-600">Stay on top of trending content relevant to your brand</p>
        </div>
      </div>
      <div class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl">
        <v-container class="pt-6 ">
          <!-- Search and Select Options -->
          <v-card class="pa-4 mb-2" color="#1C2434" dark>
            <v-row class="flex-wrap">
              <v-col cols="12" lg="4" class="flex flex-nowrap -mb-4">
                <v-autocomplete :items="competitorOptions" label="Company / Social Profile URLs"
                  v-model="selectedOption1" multiple hide-details :search-input.sync="searchInput1" dark chips
                  deletable-chips class="custom-autocomplete flex-nowrap" @input="onInput($event, 1)"
                  @update:error="addItemIfNotFound($event, 1)">
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < maxDisplay">{{ trimtext(item, 25) }} &nbsp;</span>
                    <span v-if="index === maxDisplay" class="grey--text caption">(+{{ selectedOption1.length -
                      (maxDisplay) }}
                      more)</span>
                  </template>

                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-action>
                        <v-checkbox v-model="selectedOption1" :value="item" @click.stop></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon @click="removeCompetitorOption(item)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>

                  <template v-slot:no-data>
                    <template v-if="!isValidUrl(searchInput1)">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="grey--text">Enter a domain or URL</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template
                      v-else-if="isValidUrl(searchInput1) && !selectedOption1.includes(convertUrl(searchInput1))">
                      <v-list-item @click="addCompetitorOption(convertUrl(searchInput1))">
                        Add "{{ convertUrl(searchInput1) }}"
                      </v-list-item>
                    </template>
                  </template>

                  <template v-slot:append>
                    <div class="ml-2 flex items-center hover:cursor-pointer"
                      @click="showDialog = true, dialogType = 'url'">
                      <!-- <span class="mr-1 text-gray-300">Add </span> -->
                      <v-icon>mdi-plus-circle</v-icon>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="3" class="-mb-4">
                <v-autocomplete :items="phraseOptions" label="Hashtags" v-model="selectedOption2" multiple hide-details
                  :search-input.sync="searchInput2" dark chips deletable-chips class="custom-autocomplete"
                  @input="onInput($event, 2)" @update:error="addItemIfNotFound($event, 2)">

                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < maxDisplay"> #{{ trimtext(item, 25) }} ,&nbsp;</span>
                    <span v-if="index === maxDisplay" class="grey--text caption">(+{{ selectedOption2.length -
                      maxDisplay }} more)</span>
                  </template>

                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-action>
                        <v-checkbox v-model="selectedOption2" :value="item" @click.stop></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>#{{ item }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon @click="removePhraseOption(item)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>

                  <template v-slot:no-data>
                    <template v-if="!isValidHashtag(searchInput2)">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="grey--text">Enter a valid #(hashtag)</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else-if="searchInput2 && !phraseOptions.includes(searchInput2)">
                      <v-list-item @click="addPhraseOption(searchInput2)">
                        Add "{{ searchInput2.toLowerCase() }}"
                      </v-list-item>
                    </template>
                  </template>
                  <template v-slot:append>
                    <div class="ml-2 flex items-center hover:cursor-pointer"
                      @click="showDialog = true, dialogType = 'phrase'">
                      <v-icon class="">mdi-plus-circle</v-icon>
                    </div>
                  </template>
                </v-autocomplete>


              </v-col>
              <v-col cols="12" sm="3" class="flex mt-2 custom-col">
                <v-select :items="dateFilterOptions" label="Last Modified" v-model="selectedDateFilter" solo
                  prepend-inner-icon="mdi-calendar" hide-details dark class="custom-autocomplete">
                </v-select>
              </v-col>
              <v-col cols="12" sm="1" class="flex gap-2">
                <!-- <v-btn color="secondary" @click="addItem(this.selectedOption1, this.selectedOption2)" class="mt-3">
              Apply
            </v-btn> -->
                <v-btn color="primary" @click="refresh(selectedOption1, selectedOption2)" class="mt-3">
                  <img src="@/assets/img/redirect/refresh.png" height="25" width="25" class="" />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <!-- Table with Search Bar and Pagination -->
          <v-card>
            <v-card-title class="text-black flex justify-between  items-center">
              <div class="flex items-center justify-between gap-4">
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" single-line hide-details
                  class="max-w-sm pl-4 pb-3 bg-slate-100 rounded-xl text-center" />
                <div class="flex justify-end gap-5 items-center">
                  <v-autocomplete :items="socialPlatforms" label="Social Platform" v-model="selectedOption3" multiple
                    prepend-inner-icon="mdi-magnify" hide-details chips deletable-chips
                    class="social-plat max-h-13 max-w-sm mb-2 pl-4" @input="onInput($event, 3)">
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < maxDisplay">{{ item }} ,&nbsp;</span>
                      <span v-if="index === maxDisplay" class="grey--text caption">(+{{ selectedOption3.length -
                        maxDisplay }} more)</span>
                    </template>
                  </v-autocomplete>

                  <v-autocomplete :items="socialHandles" label="Handles" v-model="selectedHandles" multiple
                    prepend-inner-icon="mdi-magnify" hide-details chips deletable-chips
                    class="social-plat max-h-13 max-w-sm mb-2 pl-4" @input="onInput($event, 3)">
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index < maxDisplay">{{ item }} ,&nbsp;</span>
                      <span v-if="index === maxDisplay" class="grey--text caption">(+{{ selectedHandles.length -
                        maxDisplay }} more)</span>
                    </template>
                  </v-autocomplete>

                  <v-select :items="latestFilter" v-model="selectedFilter" label="Filter" solo hide-details
                    class="max-w-xs" />
                </div>
              </div>
            </v-card-title>
            <v-data-table :headers="headers" :items="filteredItems" item-key="postId" class="elevation-1"
              @update:items-per-page="updateItemsPerPage" :items-per-page="itemsPerPage" :page.sync="page"
              :server-items-length="totalItems" :loading="loading">
              <template v-slot:header.post="{ header }">
                <th class="nowrap-header no-underline">{{ header.text }}</th>
              </template>
              <template v-slot:header.likes>
                <v-icon>mdi-heart</v-icon>
              </template>
              <template v-slot:header.comments>
                <v-icon>mdi-comment</v-icon>
              </template>
              <template v-slot:header.shares>
                <v-icon>mdi-share</v-icon>
              </template>
              <template v-if="loading">
                <v-tr>
                  <v-td :colspan="headers.length" class="text-center">
                    <v-progress-circular indeterminate color="primary" />
                  </v-td>
                </v-tr>
              </template>
              <template v-slot:item="{ item }">
                <tr class="parent">
                  <td>
                    <v-img v-if="item?.thumbnail?.length > 0" :src="getImage(item)" class="square-image  my-5"
                      @mouseover="openImagePreview($event, getImage(item))" @mouseleave="closeImagePreview" />
                    <v-img v-else src="@/assets/img/redirect/defaultImage.png" class="square-image my-5 " />
                  </td>
                  <td class="align-top ">
                    <div class="flex flex-col gap-1 mt-5">
                      <div class="flex items-center gap-2">
                        <div class="flex-shrink-0 rounded-lg">
                          <v-img :src="getPlatformIcon(item.platform)" height="27" width="27" class="rounded" />
                        </div>
                        <div class="flex gap-3">
                          <div>
                            <p target="_blank" class="font-semibold align-bottom text-gray-500">
                              {{ formatDate(item.created_at) }}
                            </p>
                            <a v-if="item.handle !== ''" :href="item.post_url" target="_blank"
                              class="username no-underline text-xs font-semi text-gray-800 align-top">
                              @{{ item.handle }}
                            </a>
                          </div>
                          <a @click="addBookMark(item)" :disabled="isBookMarking">
                            <img v-if="checkBookMark(item.platform_unique_id)" src="@/assets/img/redirect/star.png" height="19" width="19" alt="">
                            <img v-if="!checkBookMark(item.platform_unique_id)" src="@/assets/img/redirect/star (1).png" height="19" width="19" alt="">
                          </a>
                          <a :href="item.post_url" class="redirect-icon opacity-80" target="_blank">
                            <img src="@/assets/img/redirect/redirect.png" height="19" width="19" />
                          </a>
                          <a @click="repurposePost = true; postText = item.text" class="repurpose-icon  opacity-80"
                            target="_blank">
                            <!-- Repurpose -->
                            <img src="@/assets/img/redirect/share.png" class="ml-1" height="14" width="19" />
                          </a>
                        </div>
                      </div>
                      <p>
                        <span
                          v-html="showFullText[item.post_id] ? parseText(item.text) : parseText(trimtext(item.text, 1024))"></span>
                        <button v-if="item.text.length > 1024" @click="toggleText(item.post_id)"
                          class="show-more-btn font-medium text-blue-500">
                          {{ showFullText[item.post_id] ? "Show Less" : "Show More" }}
                        </button>
                      </p>

                    </div>
                  </td>
                  <td class="text-center">
                    <span>{{ getEngagement(item) || '0' }}</span>
                  </td>
                  <td class="text-center">
                    {{ convertDigits(item.likes) || '0' }}
                  </td>
                  <td class="text-center">
                    {{ convertDigits(item.comments) || '0' }}
                  </td>
                  <td class="text-center">
                    {{ convertDigits(item.shares) || '0' }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <div v-if="showImagePreview" class="preview-box" :style="boxStyle">
            <img :src="selectedImageUrl" class="preview-image" />
          </div>
        </v-container>
        <v-dialog v-model="showDialog" max-width="600px">
          <v-card>
            <div class="d-flex justify-space-between align-center">
              <v-card-title v-if="dialogType == 'url'">Company / Social Profile URLs to track</v-card-title>
              <v-card-title v-else>Hashtag to track</v-card-title>
              <v-icon class="close_dialog mr-2" @click="showDialog = false">mdi-close</v-icon>
            </div>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-if="dialogType == 'url'" label="Enter Company Url" v-model="dialogInput" outlined
                    full-width :error-message="urlError" />
                  <v-text-field v-else label="Enter Hashtag(#)" v-model="dialogInput" outlined full-width
                    :error-messages="isValidHashtag(dialogInput) ? [] : [urlError]"
                    :error="!isValidHashtag(dialogInput)" />

                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="confirmAdd">Add</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialog" max-width="500">
          <v-card>
            <v-card-title class="headline">{{ messageTitle }}</v-card-title>
            <v-card-text>{{ messageContent }}</v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="closeMessageDialog">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <generate-content-dialog :show-dialog="repurposePost" @cancel-dialog="repurposePost = false"
          :social-networks="socialPlatforms" :tone-of-voice="toneOfVoice" :language="language" :post-text="postText"
          :fetched-description="fetchedDescription" :brand-name="brand_name" dialog-title="Recreate For Your Brand" />
      </div>
    </section>
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">{{ completedSteps }} of {{ totalSteps }} steps completed</div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{
              item.name }}</router-link>
          </label>
          <v-icon color="white">mdi-chevron-right</v-icon>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with
            a
            member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">Dismiss</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db, functions } from "@/utils/firebase.utils";
import { doc, getDoc, setDoc, collection, where, addDoc, getDocs, query, deleteDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { provide } from "vue";
import GenerateContentDialog from "./GenerateContentDialog.vue"
export default {
  components: {
    GenerateContentDialog
  },
  data() {
    return {
      fetchedDescription: '',
      brand_name: '',
      messageTitle: '',
      messageContent: '',
      messageDialog: false,
      isFetched:false,
      dialogType: '',
      editable: false,
      showImagePreview: false,
      boxStyle: {},
      postText: '',
      toneOfVoice: ['Conversational', 'Inspirational', 'Informative', 'Interactive', 'Questioning', 'Explanatory'],
      showDialog: false,
      repurposetext: '',
      language: ['English(US)', 'English(UK)'],
      repurposePost: false,
      bookMarkedPost:[],
      competitorOptions: [],
      phraseOptions: [],
      latestFilter: ['Latest', 'Most Trending'],
      savedPost: ['Bookmarked', 'Fetched Post'],
      socialPlatforms: [],
      selectedOption1: [],
      selectedOption2: [],
      selectedOption3: [],
      socialHandles: [],
      selectedHandles: [],
      profileUrls: ["https://instagram.com", "https://youtube.com", "https://facebook.com", "https://x.com",
        "https://twitter.com", "https://pinterest.com/", "https://linkedin.com", "https://tiktok.com"],
      showFullText: {},
      dialogInput: '',
      urlError: '',
      searchInput1: '',
      searchInput2: '',
      searchInput3: '',
      search: '',
      selectedDateFilter: 'Last 30 days',
      selectedFilter: 'Most Trending',
      dateFilterOptions: ['Last 24 hours', 'Last 3 days', 'Last 7 days', 'Last 15 days', 'Last 30 days', 'Last 3 months', 'Last 6 months', 'All time'],
      headers: [
        { text: 'Matching Post', value: 'post', sortable: false },
        { text: '', value: 'image', sortable: false },
        { text: 'Engagement', value: 'engagement', sortable: false },
        { text: '', value: 'likes', sortable: false, align: 'center' },
        { text: '', value: 'comments', sortable: false, align: 'center' },
        { text: '', value: 'shares', sortable: false, align: 'center' },
      ],
      items: [],
      itemsPerPage: 15,
      page: 1,
      totalItems: 0,
      loading: false,
      maxDisplay: 1,
      isOnboardingVisible: true,
      dismissed: true,
      isBookMarking:false,
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
    };
  },
  watch: {
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
  },
  computed: {
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    filteredItems() {
      if (this.items.length === 0) return [];

      const now = new Date();
      const normalizedSelectedPlatforms = new Set(this.selectedOption3.map(platform => platform.split(' (')[0].toLowerCase()));
      const selectedHandleNames = new Set(this.selectedHandles.map(handle => handle.split(' (')[0].toLowerCase()));
      const lowerCaseSearch = this.search?.toLowerCase() || "";
      const dateRanges = {
        'Last 24 hours': 1,
        'Last 3 days': 3,
        'Last 5 days': 5,
        'Last 7 days': 7,
        'Last 15 days': 15,
        'Last 30 days': 30,
        'Last 3 months': 90,
        'Last 6 months': 180,
      };
      const handleCounts = new Map();
      const platformCounts = new Map();

      // Main filtering logic
      const filteredItems = this.items.filter(item => {
        const itemDate = new Date(item.created_at);
        const diffDays = Math.ceil(Math.abs(now - itemDate) / (1000 * 60 * 60 * 24));
        let savedPosts = true;
        if(this.isFetched){
          savedPosts = this.bookMarkedPost.some(post => post.id === item.platform_unique_id);
        }
        // Matching criteria
        const matchesSearch = !this.search || item.text?.toLowerCase().includes(lowerCaseSearch);
        const matchesPlatform = this.selectedOption3.length === 0 || normalizedSelectedPlatforms.has(item.platform?.toLowerCase());
        const matchesDate = !this.selectedDateFilter || (diffDays <= (dateRanges[this.selectedDateFilter] || Infinity));
        const matchesUrl = this.selectedOption1.length === 0 || this.selectedOption1.includes(item.company_url);
        const matchesPhrase = this.selectedOption2.length === 0 || this.selectedOption2.some(phrase =>
          item?.topics?.some(topic => topic.toLowerCase() === phrase.toLowerCase())
        );
        const matchesHandles = selectedHandleNames.size === 0 || selectedHandleNames.has(item?.handle?.toLowerCase());

        // Track handle counts if phrases match
        if (matchesPhrase && item.handle) {
          handleCounts.set(item.handle, (handleCounts.get(item.handle) || 0) + 1);
        }

        // Track platform occurrences if matchesUrl and matchesPhrase
        if (matchesUrl && matchesPhrase && item.platform) {
          const platformKey = item.platform.charAt(0).toUpperCase() + item.platform.slice(1).toLowerCase();
          platformCounts.set(platformKey, (platformCounts.get(platformKey) || 0) + 1);
        }

        return matchesSearch && matchesPlatform && matchesDate && matchesUrl && matchesPhrase && matchesHandles && savedPosts;
      });

      // Update social handles and platforms
      if (this.selectedOption1.length > 0) {
        this.socialHandles = [];
      } else {
        this.socialHandles = Array.from(handleCounts.entries())
          .sort((a, b) => b[1] - a[1])
          .slice(0, 20)
          .map(([handle, count]) => `${handle} (${count})`);
      }

      this.socialPlatforms = Array.from(platformCounts.entries())
        .map(([platform, count]) => `${platform} (${count})`);

      // Sorting based on 'Most Trending' or 'Latest'
      const sortedItems = this.selectedFilter === 'Most Trending'
        ? filteredItems.sort((a, b) => this.calEngagement(b) - this.calEngagement(a))
        : filteredItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      this.totalItems = sortedItems.length;

      // Pagination
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      console.log(sortedItems,"this is sortedItems");

      return sortedItems.slice(start, end);
    },

    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    toggleOption() {
      if (this.isFetched) {
        console.log('Fetched option selected');
      } else {
        console.log('Saved option selected');
      }
    },
    checkBookMark(uid) {
      return this.bookMarkedPost.some(post => post.id === uid);
    },
    async addBookMark(item){
      this.isBookMarking = true;
      if(this.bookMarkedPost.some(post => post.id === item.platform_unique_id)){
        this.bookMarkedPost = this.bookMarkedPost.filter(post => post.id !== item.platform_unique_id);
        const postRef = doc(db, 'users', this.currentUID, "fly_workspaces", this.WID, "bookMarkedPosts",item.platform_unique_id);
        await deleteDoc(postRef);
      }
      else{
        const date = new Date();
        this.bookMarkedPost.push({
          id:item.platform_unique_id,
          createdAt:date
        });
        const docRef = doc(db, 'users', this.currentUID, "fly_workspaces", this.WID, "bookMarkedPosts",item.platform_unique_id);
        await setDoc(docRef,{
          createdAt: date
        })
      }
      this.isBookMarking = false;
    },
    isValidHashtag(input) {
      let t = /^#[a-zA-Z0-9_]+$/.test(input);
      if (!t) this.urlError = "Invalid Hashtag(#)"
      return t;
    },
    parseText(text) {
      if (!text) return '';
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      const hashtagPattern = /#(\w+)/g;
      // Replace URLs and hashtags
      const formattedText = text
        .replace(urlPattern, (url) => {
          return `<a href="${url}" class="text-blue-500" target="_blank">${url}</a>`;
        })
        .replace(hashtagPattern, (hashtag) => {
          return `<a class="text-blue-500" target="_blank">${hashtag}</a>`;
        });
      // Replace new lines with paragraphs
      const paragraphText = formattedText
        .split('\n')
        .map(line => `<p>${line}</p>`)
        .join('');
      return paragraphText;
    },
    convertDigits(num) {
      if (num) {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
        }
        if (num >= 1e6) {
          return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1e3) {
          return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num.toString();
      }
      return null;
    },
    openImagePreview(event, imageUrl) {
      const { clientX } = event;
      const rowElement = event.target.closest('tr');
      const rowRect = rowElement.getBoundingClientRect();
      this.selectedImageUrl = imageUrl;
      this.boxStyle = {
        top: `${rowRect.top + window.scrollY - 30}px`,
        left: `${clientX - clientX / 2.7}px`
      };
      this.showImagePreview = true;
    },
    closeImagePreview() {
      this.showImagePreview = false;
    },
    toggleText(postId) {
      this.$set(this.showFullText, postId, !this.showFullText[postId]);
    },
    trimtext(text, maxLength) {
      // Change this to your desired length
      return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    },
    confirmAdd() {
      if (this.dialogType == 'url') {
        if (this.isValidUrl(this.dialogInput)) {
          this.urlError = '';
          this.addCompetitorOption(this.dialogInput);
        } else {
          this.urlError = 'Please enter a valid URL';
        }
      }
      else {
        this.addPhraseOption(this.dialogInput);
      }
    },
    calEngagement(item) {
      return ['likes', 'views', 'shares', 'comments'].reduce((sum, key) => sum + (item[key] || 0), 0);
    },
    getEngagement(item) {
      let sum = 0;
      if (item.likes) sum += item.likes;
      if (item.views) sum += item.views;
      if (item.shares) sum += item.shares;
      if (item.comments) sum += item.comments;
      return this.convertDigits(sum);
    },
    updateItemsPerPage(val) {
      this.itemsPerPage = val;
    },
    getPlatformIcon(platform) {
      return require(`@/assets/img/social-logo/${platform}.png`);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    getImage(item) {
      if (!item.thumbnail || item?.thumbnail?.length == 0) {
        return require(`@/assets/img/redirect/defaultImage.png`);
      }
      return item?.thumbnail;
    },
    onInput(value, index) {
      // Handle input
    },
    isValidUrl(url) {
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
      } catch (error) {
        // Additional check to validate domain-like strings (e.g., "abc.com", "sbi.co.in")
        const domainPattern = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
        return domainPattern.test(url);
      }
    },
    removeWWW(url) {
      console.log("this is url", url);
      return url.includes('://www.') ? url.replace('://www.', '://') : url;
    },
    addHttps(url) {
      if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`;
      }
      return url;
    },
    filterProfileUrl(url) {
      try {
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname.replace('www.', '');
        const pathname = parsedUrl.pathname.split('/').filter(segment => segment);
        const profileName = pathname[1] || pathname[0];

        const platforms = {
          'instagram.com': `https://instagram.com/${profileName}`,
          'twitter.com': `https://twitter.com/${profileName}`,
          'x.com': `https://twitter.com/${profileName}`,
          'facebook.com': `https://facebook.com/${profileName}`,
          'youtube.com': `https://youtube.com/${profileName}`,
          'pinterest.com': `https://pinterest.com/${profileName}`,
          'tiktok.com': `https://tiktok.com/@${profileName}`,
          'linkedin.com': pathname[0] === 'company'
            ? `https://linkedin.com/company/${profileName}`
            : `https://linkedin.com/in/${profileName}`
        };

        for (const key in platforms) {
          if (hostname.includes(key)) return platforms[key];
        }

        console.error('Unsupported platform or invalid URL');
      } catch (error) {
        console.error('Invalid URL:', error);
      }
      return '';
    },
    convertUrl(url) {
      try {
        const domainUrl = this.isValidUrl(url);
        if (domainUrl) {
          url = this.addHttps(url);
        }
        let { origin } = new URL(url);
        origin = this.removeWWW(origin);
        console.log(origin, "printed url")
        if (this.profileUrls.includes(origin)) {
          url = this.removeWWW(url);
          url = this.filterProfileUrl(url);
          return url
        }
        return origin;
      } catch (error) {
        console.log("error thrown")
        return '';
      }
    },
    extractHostName(url) {
      try {
        const { hostname } = new URL(url);
        return hostname.replace('www.', ''); // Optionally remove 'www.'
      } catch (error) {
        console.error('Invalid URL', error);
        return '';
      }
    },
    async removeCompetitorOption(item) {
      // Store the current selection temporarily
      const selectedOptionTemp = [...this.selectedOption1];

      // Remove the item from competitorOptions
      this.competitorOptions = this.competitorOptions.filter(option => option !== item);

      try {
        // Query to find the document(s) to delete
        const q = query(collection(db, 'users', this.currentUID, "fly_workspaces", this.WID, "competitors"), where('company_url', '==', item));
        const querySnapshot = await getDocs(q);

        // Delete all matching documents
        const deletePromises = querySnapshot.docs.map(doc =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deletePromises);

        // Update selectedOption1 after removing the competitor
        this.selectedOption1 = selectedOptionTemp.filter(selectedItem => this.competitorOptions.includes(selectedItem));

        // Update this.items by removing the relevant competitor and keeping valid items
        this.items = this.items.filter(existingItem => {
          const matchesUrl = existingItem.company_url !== item;
          const matchesTopic = existingItem.topics?.some(topic => this.phraseOptions.includes(topic));
          return matchesUrl || matchesTopic; // Keep if either condition is true
        });

        // Trigger reactivity updates (filteredItems is computed property, no need to manually trigger it)
      } catch (error) {
        console.error("Error removing document: ", error);
        // Revert changes to competitorOptions and selectedOption1 on error
        this.competitorOptions = [...this.competitorOptions, item];
        this.selectedOption1 = selectedOptionTemp;
      } finally {
        // Ensure UI updates properly regardless of success or failure
        this.filteredItems; // Computed property, reactivity triggered by changes to items/competitorOptions
      }
    },

    async removePhraseOption(item) {
      const selectedOptionTemp = [...this.selectedOption2];

      // Remove the item from phraseOptions
      this.phraseOptions = this.phraseOptions.filter(option => option.toLowerCase() !== item.toLowerCase());

      try {
        // Query to find the document(s) to delete
        const q = query(collection(db, 'users', this.currentUID, "fly_workspaces", this.WID, "competitorsTopics"), where('topic', '==', item));
        const querySnapshot = await getDocs(q);

        // Delete all matching documents
        const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);

        // Update selectedOption2 based on updated phraseOptions
        this.selectedOption2 = selectedOptionTemp.filter(selectedItem =>
          this.phraseOptions.some(option => option.toLowerCase() === selectedItem.toLowerCase())
        );
        // Update items in a single pass based on phrase and competitor options
        this.items = this.items.filter(existingItem => {
          const matchesTopic = !existingItem.topics?.some(topic => topic.toLowerCase() === item.toLowerCase());
          const matchesCompetitor = this.competitorOptions.some(option => option.toLowerCase() === existingItem.company_url?.toLowerCase());
          return matchesTopic || matchesCompetitor;
        });

        // Trigger any necessary reactivity updates (filteredItems is computed)
        this.filteredItems;

      } catch (error) {
        console.error("Error removing document: ", error);
        // Revert the changes on error
        this.phraseOptions = [...this.phraseOptions, item];
        this.selectedOption2 = selectedOptionTemp;
      }
    },
    async addCompetitorOption(input) {
      this.showDialog = false;
      this.dialogInput = '';
      this.$snackbar.show("The company details are being saved. It may take some time to load the data");
      const originURL = this.convertUrl(input);
      // Early exit if URL is invalid or already added
      if (!originURL || this.selectedOption1.includes(originURL)) {
        return;
      }

      // Update UI state before database operation
      this.competitorOptions.push(originURL);
      this.selectedOption1 = [...this.selectedOption1, originURL];
      this.searchInput1 = '';
      // Prepare document to add
      const competitorDoc = {
        company_url: originURL,
        host_name: this.extractHostName(originURL),
        created_at: new Date(),
      };
      try {
        const competitorOptionsRef = collection(db, 'users', this.currentUID, "fly_workspaces", this.WID, "competitors");

        // Perform database operation and fetch items concurrently
        await addDoc(competitorOptionsRef, competitorDoc);
        await this.addItem([originURL], []);
      } catch (error) {
        console.error("Error saving competitor:", error);
        // Handle error: show message, revert UI changes, etc.
        this.$snackbar.show("Failed to save company details. Please try again.");
        this.competitorOptions = this.competitorOptions.filter(url => url !== originURL);
        this.selectedOption1 = this.selectedOption1.filter(url => url !== originURL);
      }
    },

    async addPhraseOption(input) {
      this.dialogInput = '';
      this.showDialog = false;
      this.$snackbar.show("The topic has been saved. It may take some time to load the data");

      // Early exit if input is empty or already exists in phraseOptions
      if (!input || this.phraseOptions.includes(input)) {
        return;
      }

      // Normalize input and remove the '#' if it exists
      input = input.startsWith('#') ? input.slice(1) : input;
      input = input.toLowerCase();

      // Update UI state immediately
      this.phraseOptions.push(input);
      this.selectedOption2 = [...this.selectedOption2, input];
      this.searchInput2 = '';

      const competitorTopicRef = collection(db, 'users', this.currentUID, "fly_workspaces", this.WID, "competitorsTopics");

      // Create the document object
      const competitorDoc = {
        topic: input,
        created_at: new Date(),
      };
      try {
        // Perform the database operation and fetch new items
        await addDoc(competitorTopicRef, competitorDoc);
        await this.addItem([], [input]);
      } catch (error) {
        console.error("Error saving topic:", error);
        this.$snackbar.show("Failed to save the topic. Please try again.");
        // Revert UI changes on failure
        this.phraseOptions = this.phraseOptions.filter(option => option !== input);
        this.selectedOption2 = this.selectedOption2.filter(option => option !== input);
      }
    },
    addItemIfNotFound(event, selectNumber) {
      if (event) {
        const newItem = this[`searchInput${selectNumber}`];
        if (selectNumber == 1 && newItem && !this.competitorOptions.includes(newItem)) {
          this.competitorOptions.push(newItem);
          this[`selectedOption${selectNumber}`] = newItem;
        }
        if (selectNumber == 2 && newItem && !this.phraseOptions.includes(newItem)) {
          this.phraseOptions.push(newItem);
          this[`selectedOption${selectNumber}`] = newItem;
        }
      }
    },
    showMessageDialog(title, message) {
      this.messageTitle = title;
      this.messageContent = message;
      this.messageDialog = true;
    },
    closeMessageDialog() {
      this.messageDialog = false;
    },
    async addItem(options, topics) {
      this.dialogInput = '';
      let competitorQueryFunction = httpsCallable(functions, "competitorQueryFunction");
      let competitorTopicQueryFunction = httpsCallable(functions, "competitorTopicQueryFunction");

      try {
        this.loading = true;

        // Retrieve current data from localStorage once
        let currentCompetitors = JSON.parse(localStorage.getItem("CURRENT_COMPETITOR")) || [];
        let currentCompetitorsTopics = JSON.parse(localStorage.getItem("CURRENT_COMPETITOR_TOPICS")) || [];

        // Combine options and topics into a single array with their respective function
        const requests = [
          ...options.map(option => ({
            queryFunction: competitorQueryFunction,
            params: { operation: "fetch-post", uid: auth.currentUser.uid, currUid: this.currentUID, wid: this.WID, name: "NEW BUSINESS", url: option },
            isTopic: false,
            itemKey: option
          })),
          ...topics.map(topic => ({
            queryFunction: competitorTopicQueryFunction,
            params: { operation: "fetch-post", uid: auth.currentUser.uid, currUid: this.currentUID, wid: this.WID, provided_text: topic },
            isTopic: true,
            itemKey: topic
          }))
        ];

        // Process all requests in parallel using Promise.all
        const responses = await Promise.all(requests.map(async ({ queryFunction, params, isTopic, itemKey }) => {
          try {
            const response = await queryFunction(params);
            return { response, isTopic, itemKey };
          } catch (error) {
            console.log(`Error fetching data for ${itemKey}`, error);
            return { error: true, isTopic, itemKey };
          }
        }));

        // Handle responses
        for (const { response, isTopic, itemKey, error } of responses) {
          if (error) {
            this.showMessageDialog("Failed", `Could not fetch data for ${itemKey}, try another URL`);
            if (!isTopic) this.removeCompetitorOption(itemKey);
            continue;
          }

          // Handle "fetching data" case
          if (response.data?.data === "fetching data") {
            const storageArray = isTopic ? currentCompetitorsTopics : currentCompetitors;
            if (!storageArray.includes(itemKey)) {
              storageArray.push(itemKey);
              const storageKey = isTopic ? "CURRENT_COMPETITOR_TOPICS" : "CURRENT_COMPETITOR";
              localStorage.setItem(storageKey, JSON.stringify(storageArray));
              await this.sendToLoop(isTopic ? competitorTopicQueryFunction : competitorQueryFunction, isTopic ? null : itemKey, isTopic ? itemKey : null);
            }
            continue;
          }

          // Process fetched data
          const fetchedData = response.data?.data || [];
          const arr = Array.isArray(fetchedData) ? fetchedData : [];
          this.items = this.items.filter(item => !arr.some(newItem => newItem.platform_unique_id === item.platform_unique_id));
          this.items = [...this.items, ...arr];
          this.filteredItems;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async sendToLoop(competitorFunction, url, topic) {
      const callFunction = async () => {
        try {
          let payload;
          if (topic) {
            payload = {
              operation: 'get-scraped-data',
              uid: auth.currentUser.uid,
              currUid: this.currentUID,
              wid: this.WID,
              provided_text: topic,
            };
          } else {
            payload = {
              operation: 'get-scraped-data',
              uid: auth.currentUser.uid,
              currUid: this.currentUID,
              wid: this.WID,
              name: "NEW BUSINESS",
              url: url,
            };
          }
          const response = await competitorFunction(payload);
          const arr = [];

          if (response.data.success) {
            arr.push(...response.data.data);
            this.items = this.items.filter(item => !arr?.some(newItem => newItem.platform_unique_id === item.platform_unique_id));
            this.items = [...this.items, ...arr];
            this.filteredItems;
            console.log(this.items, "updated items");
            this.loading = false;
            return;
          }
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      };

      await callFunction();
    },
    async refresh(options, topics) {
      let competitorQueryFunction = httpsCallable(functions, "competitorQueryFunction");
      let competitorTopicQueryFunction = httpsCallable(functions, "competitorTopicQueryFunction");

      try {
        this.loading = true;

        // Combine both options and topics into a single array with their corresponding function
        const requests = [
          ...options.map(option => ({
            queryFunction: competitorQueryFunction,
            params: {
              operation: 'get-scraped-data',
              uid: auth.currentUser.uid,
              currUid: this.currentUID,
              wid: this.WID,
              name: "NEW BUSINESS",
              url: option
            },
            itemKey: option
          })),
          ...topics.map(topic => ({
            queryFunction: competitorTopicQueryFunction,
            params: {
              operation: 'get-scraped-data',
              uid: auth.currentUser.uid,
              currUid: this.currentUID,
              wid: this.WID,
              provided_text: topic
            },
            itemKey: topic
          }))
        ];

        // Process all requests concurrently
        const responses = await Promise.all(requests.map(async ({ queryFunction, params, itemKey }) => {
          try {
            const response = await queryFunction(params);
            return { response, itemKey, error: false };
          } catch (error) {
            console.error(`Error fetching data for ${itemKey}:`, error);
            return { error: true };
          }
        }));

        // Handle the responses
        for (const { response, error } of responses) {
          if (error || !response?.data?.success) continue;

          const arr = Array.isArray(response.data.data) ? response.data.data : [];
          this.items = this.items.filter(item => !arr.some(newItem => newItem.platform_unique_id === item.platform_unique_id));
          this.items = [...this.items, ...arr];
          this.filteredItems;
        }

      } catch (error) {
        console.error('An error occurred during refresh:', error);
      } finally {
        this.loading = false;
      }
    },
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed = data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },
  },
  async mounted() {
    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
  },
  async beforeMount() {
    const competitorOptionsRef = collection(db, 'users', this.currentUID, "fly_workspaces", this.WID, "competitors");
    const competitorTopicRef = collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "competitorsTopics");
    const bookMarkedPostRef = collection(db, "users", this.currentUID, "fly_workspaces", this.WID, "bookMarkedPosts");
    const businessRef = doc(db, 'users', this.currentUID, "fly_workspaces", this.WID);

    try {
      // Fetch competitor options and topics in parallel
      const [competitorSnap, topicSnap, businessDoc, bookMarkedPostSnap] = await Promise.all([
        getDocs(competitorOptionsRef),
        getDocs(competitorTopicRef),
        getDoc(businessRef),
        getDocs(bookMarkedPostRef),
      ]);

      // Process competitor options
      if(!bookMarkedPostSnap.empty){
        const bookMarkedPosts = bookMarkedPostSnap.docs?.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        this.bookMarkedPost.push(...bookMarkedPosts);
      }
      if (!competitorSnap.empty) {
        const competitorUrls = competitorSnap.docs.map(doc => doc.data().company_url);
        this.competitorOptions.push(...competitorUrls);
      }

      // Process competitor topics
      if (!topicSnap.empty) {
        const topics = topicSnap.docs.map(doc => doc.data().topic);
        this.phraseOptions.push(...topics);
      }

      // Fetch business data
      if (businessDoc.exists()) {
        const businessData = businessDoc.data();
        this.fetchedDescription = businessData?.businessDescription || '';
        this.brand_name = businessData?.brandName || '';
      }

      // Refresh the data
      this.refresh(this.competitorOptions, this.phraseOptions);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

};
</script>

<style scoped>
.custom-autocomplete .v-menu__content {
  border-radius: 20px;
  overflow: hidden;
}

.social-plat {
  border-radius: 10px !important;
  text-wrap: nowrap;
  box-shadow: 0 3px 3px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.2);
}

.nowrap-header {
  white-space: nowrap;
}

.preview-box {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
}

.preview-image {
  max-width: 300px;
  max-height: 300px;
}

.square-image {
  width: 70px;
  height: 70px;
  border-radius: 5px !important;
  cursor: pointer !important;
}


.username {
  color: rgb(17, 87, 192);
  text-decoration: none;
}

.redirect-icon,
.repurpose-icon {
  display: none;
}

.parent:hover .redirect-icon {
  display: block;
}

.parent:hover .repurpose-icon {
  color: rgb(30 64 175);
  font-weight: 500;
  align-self: flex-start;
  display: flex;
}
</style>
