<template>
  <div>
    <div
      class="flex flex-col items-center justify-center pb-20 mx-auto"
      style="font-family: 'Inter', sans-serif"
    >
      <nav
        class="fixed top-0 z-[99] text-blue-500 text-color text-color w-full shadow-md bg-white"
      >
        <div
          class="items-center justify-between hidden mx-auto lg:w-3/4 lg:flex"
        >
          <div class="">
            <router-link to="/">
              <img
                src="../../../assets/img/fly-new-logo.png"
                width="100"
                height="50"
                style="object-fit: contain"
              />
            </router-link>
          </div>
          <div>
            <a
              href="#approved-platforms"
              class="text-lg text-blue-500 cursor-pointer"
              >Platforms</a
            >
          </div>
          <div>
            <a href="#ai-features" class="text-lg text-blue-500 cursor-pointer"
              >Fly AI</a
            >
          </div>
          <div class="relative">
            <!-- <div @click="openInstructions()" class="">
            <a to="" class="text-lg text-blue-500 cursor-pointer"
              >Resources<v-icon color="#60A5FA" size="30"
                >mdi-menu-down</v-icon
              ></a
            >
          </div>
         -->
            <!-- <router-link to="/resources">
            <a to="" class="text-lg text-blue-500 cursor-pointer">Resources</a>
          </router-link>
          -->
            <router-link to="/resources">
              <a to="" class="text-lg text-blue-500 cursor-pointer"
                >Resources</a
              >
            </router-link>
            <div
              v-if="showList"
              class="absolute z-[999] bg-white shadow-md rounded-md"
            >
              <a
                v-for="item in items"
                :key="item.name"
                as="a"
                :href="item.href"
                class="block py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
              >
                {{ item.name }}
              </a>
            </div>
          </div>

          <div>
            <a href="#price" class="text-lg text-blue-500 cursor-pointer"
              >Pricing</a
            >
          </div>
          <div>
            <router-link
              to="/dashboard"
              class="text-lg text-blue-500 cursor-pointer"
              v-if="isLoggedIn"
              >Dashboard</router-link
            >
            <router-link
              to="/login"
              class="text-lg text-blue-500 cursor-pointer"
              v-if="!isLoggedIn"
              >Login</router-link
            >
          </div>
        </div>
        <div class="flex items-center justify-between w-full lg:hidden">
          <router-link to="/">
            <img
              src="../../../assets/img/fly-new-logo.png"
              width="100"
              height="50"
              style="object-fit: contain"
            />
          </router-link>

          <img
            src="../../../assets/img/menu.svg"
            @click="showHeader()"
            class="w-10 pr-4 cursor-pointer md:pr-6"
          />
        </div>
        <div
          v-if="showMobileView"
          class="flex flex-col items-center mx-auto lg:hidden"
        >
          <div class="mb-2">
            <a
              href="#approved-platforms"
              class="text-lg text-blue-500 cursor-pointer"
              >Platforms</a
            >
          </div>
          <div class="mb-2">
            <a href="#ai-features" class="text-lg text-blue-500 cursor-pointer"
              >Fly AI</a
            >
          </div>
          <div class="relative mb-2">
            <!-- <div @click="openInstructions()" class="">
            <a to="" class="text-lg text-blue-500 cursor-pointer"
              >Resources<v-icon color="#60A5FA" size="30"
                >mdi-menu-down</v-icon
              ></a
            >
          </div> -->
            <router-link to="/resources">
              <a to="" class="text-lg text-blue-500 cursor-pointer"
                >Resources</a
              >
            </router-link>
            <div
              v-if="showList"
              class="absolute z-[999] bg-white shadow-md rounded-md"
            >
              <a
                v-for="item in items"
                :key="item.name"
                as="a"
                :href="item.href"
                class="block py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
              >
                {{ item.name }}
              </a>
            </div>
          </div>
          <div class="mb-2">
            <a href="#price" class="text-lg text-blue-500 cursor-pointer"
              >Pricing</a
            >
          </div>
          <div class="pb-2">
            <router-link
              to="/dashboard"
              class="text-lg text-blue-500 cursor-pointer"
              v-if="isLoggedIn"
              >Dashboard</router-link
            >
            <router-link
              to="/login"
              class="text-lg text-blue-500 cursor-pointer"
              v-if="!isLoggedIn"
              >Login</router-link
            >
          </div>
        </div>
        <!-- <div class="w-full px-4 mx-auto text-right lg:w-7/12 md:w-8/12 sm:w-10/12">
        <router-link to="/dashboard" class="text-lg text-blue-500 cursor-pointer"
          v-if="isLoggedIn">Dashboard</router-link>
        <router-link to="/login" class="text-lg text-blue-500 cursor-pointer" v-if="!isLoggedIn">Login</router-link>
      </div> -->
      </nav>
      <div
        class="flex flex-col items-start justify-start shadow-md w-[907px] mt-10 border px-[45px]"
      >
        <p class="mt-10 text-3xl">How to connect with a Twitter Custom App</p>
        <p class="text-base">
          As promised we've <strong>restored</strong> the complete functionality
          of Twitter. Now you would be able to add your own Twitter app's
          <strong> consumer id and the secret</strong> to authenticate the
          application. And this will enable full access to all of the
          functionalities.
        </p>
        <div>
          <iframe
            class="md:w-[500px] md: h-[300px]"
            src="https://www.youtube.com/embed/VKw4D2uFmig?si=Ep6vZ93IR7EixrCZ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <p class="text-base">
          Following are the <strong>steps</strong> and information you need to
          get started. In order to connect a custom app to Twitter, you need to
          first create a <strong>Twitter</strong> app, then set up the app and
          connect the app to Twitter.
        </p>
        <div class="bg-[#FFFCD5] border-dashed border w-full p-4">
          <p class="text-sm">
            In this article, we'll cover the following topics:
          </p>
          <p class="font-semibold">1. How to create a new Twitter App</p>
          <p class="font-semibold">2. How to Set up your new Twitter App</p>

          <p class="font-semibold">
            3. How to connect with a custom Twitter App
          </p>
        </div>
        <p class="mt-10 text-2xl">1. How to create a new Twitter App</p>
        <p class="text-base">
          1- Go to
          <a href="https://developer.twitter.com"
            >https://developer.twitter.com</a
          >
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf12737fd073d73d6ca5c/file-pufMr2VvHn.png"
            alt="screenshot"
          />
        </p>
        <p class="text-base">
          2- Sign in with your <strong>Twitter account</strong>.
        </p>
        <p class="text-base">
          3- Click on the <strong>Developer Portal</strong> button in the top
          right corner
        </p>
        <p class="text-base">
          4- From the <strong> petitions page</strong>, either subscribe to the
          <strong> paid plan </strong>or sign up for a free account. For this
          example, we will sign up for free.
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf1f8ad20e8714a50d9d2/file-bvGqZ34hYj.png"
            alt="screenshot"
          />
        </p>
        <p class="text-base">
          5- Fill out the <strong>developer agreement form. </strong>
        </p>
        <dt>
          You would need to <strong>describe</strong> all of the use cases
          required by the Application. For The Fly we would be using the
          <strong>API</strong> for the following cases:
        </dt>
        <ul class="mt-6">
          <li class="list-disc">Scheduling and publishing Tweets</li>
          <li class="mt-6 list-disc">@mentioning Twitter accounts</li>
          <li class="mt-6 list-disc">Analytics</li>
          <li class="mt-6 list-disc">Social Inbox</li>
        </ul>
        <h1 class="mt-6 text-base">
          Here is a <strong>sample text</strong> to give an idea of what the
          description would look like.
        </h1>
        <div class="mx-auto mt-6">
          <div
            class="p-2 text-green-700 bg-green-100 border-l-4 border-green-500"
          >
            <i>
              With <strong>Twitter API access</strong>, I want to schedule and
              publish tweets, track mentions, reply to comments on my tweets and
              respond to customer inquiries and complaints in real time. I want
              to utilize the data returned from the API to help me display and
              gauge my tweets' performance, impressions, and engagements, along
              with other metrics.
            </i>
          </div>
        </div>
        <img
          src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf5b54b128526704532b0/file-oQq0usfUSc.png"
          alt="screenshot"
          class="mt-6"
        />
        <p class="mt-6 text-base">
          6- Click on Submit! In most cases, you will get access to the
          <strong>developer portal</strong> right away.
        </p>
        <p class="mt-6 text-[21px] font-bold">
          How to set up your new Twitter App?
        </p>
        <p class="text-base">
          1- When the <strong>creation steps</strong> are completed and your
          <strong>Twitter Developer Account</strong> is approved you would be
          navigated to the dashboard now let’s set it up.
        </p>
        <img src="../../../assets/img/screenshot-1.png" alt="screenshot" />

        <p class="mt-6 text-base">
          2- From the <strong>left side menu</strong>, click on “<strong
            >Projects & Appss</strong
          >” and click on the “Default project-123xyz..” label.
        </p>
        <img src="../../../assets/img/screenshot-2.png" alt="screenshot" />
        <p class="mt-6 text-base">
          3- From the <strong>left side menu</strong>, click on “<strong
            >Projects & Apps</strong
          >” and click on the “Default project-123xyz..” label.
        </p>
        <img src="../../../assets/img/screenshot-2.png" alt="screenshot" />

        <p class="mt-6 text-base">
          4- From the <strong>left side menu</strong>, click on “<strong
            >Projects & Apps</strong
          >” and click on the “Default project-123xyz..” label.
        </p>
        <img src="../../../assets/img/screenshot-2.png" alt="screenshot" />

        <p class="mt-6 text-base">
          5- From the <strong>left side menu</strong>, click on “<strong
            >Projects & Apps</strong
          >” and click on the “Default project-123xyz..” label.
        </p>
        <img src="../../../assets/img/screenshot-3.png" alt="screenshot" />

        <p class="mt-6 text-base">
          6- Edit the “<strong>User authentication settings</strong>”
        </p>
        <p class="text-base">
          a- App permissions: Select “Read and write” permission
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf7dead20e8714a50d9dd/file-lLxzmQTMFF.png"
            alt=""
          />
        </p>
        <p class="text-base">
          b- Type of App: Select “<strong>Web App, Automated App, or Bot</strong
          >”
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf822ad20e8714a50d9de/file-d9yGeap5xa.png"
            alt=""
          />
        </p>
        <p class="text-base">
          c- App info: Please make sure to add the
          <strong>callback URL</strong> of The Fly here. This is really
          important as this is required to authenticate the application when
          connecting with Twitter.
        </p>
        <div class="w-full mx-auto mt-6">
          <div
            class="p-2 text-green-700 bg-green-100 border-l-4 border-green-500"
          >
            <a href=" https://api-fly-social.com/twitter/connect">
              https://api-fly-social.com/twitter/connect
            </a>
          </div>
        </div>
        <p class="mt-6 text-base">
          Also, you would need to provide the
          <strong>URL to your website</strong> here, the other options are all
          optional.
          <img src="../../../assets/img/twitter-app-info.png" alt="" />
        </p>

        <p class="mt-6 text-base">7- Click on Save.</p>
        <p class="mt-6 text-base">
          8- On successful completion of this step, you would be shown the OAuth
          2.0 <strong>Client ID and Client Secret </strong>for your application.
          Copy and save them in a safe location, in the future, they would not
          be shown in full and would need to be regenerated if there is any
          mistake.
        </p>
        <div class="w-full mx-auto mt-6">
          <div class="p-2 bg-[#FFFCD5] border-l-4 border-[#E7DCA4]">
            <i
              >These client details are for
              <strong>v2-level authentication</strong>, ContentStudio uses the
              v1.1 method and you would need the “<strong>Consumer Key</strong>”
              and “<strong>Consumer Secret</strong>” to authenticate the
              application.</i
            >
          </div>

          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cf96037fd073d73d6ca61/file-G8h51ClOnP.png"
            alt=""
            class="mt-6"
          />
          <p class="mt-6 text-[21px] font-bold">
            How to connect with a custom Twitter App?
          </p>
          <p class="text-base">1- Log in to The Fly</p>

          <p class="text-base">
            2- Click on the <strong> 3 dots</strong> in the top right corner and
            select <strong>Social Handles</strong>
          </p>
          <img src="../../../assets/img/screenshot-4.png" alt="screenshot" />

          <p class="text-base">
            3- Navigate to the <strong>Twitter section</strong> and click on the
            “<strong>Connect Twitter Profile</strong>”
          </p>
          <img src="../../../assets/img/screenshot-5.png" alt="screenshot" />

          <p class="text-base">
            4- Click on “<strong>Connect X/Twitter</strong>”.
          </p>
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643e45ad37fd073d73d6cd5a/file-CTkQVBmyWe.png"
            alt=""
          />
          <p class="text-base">
            5- From the modal add the Twitter App’s name, API Key, and Secret in
            the text field.
          </p>
          <p class="text-base">
            a- In a different tab <strong>navigate</strong> to
            <a href="https://developers.twitter.com"
              >https://developers.twitter.com</a
            >
          </p>
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/643cfbab4b128526704532b5/file-evVrzSVVBr.png"
            alt=""
          />
          <p class="text-base">
            b- Click on “<strong>Developer Portal</strong>”
          </p>
          <img src="../../../assets/img/screenshot-1.png" alt="" />

          <p class="mt-6 text-base">
            c- From the left side menu, click on “<strong
              >Projects & Apps</strong
            >”, select your project, and then the App.
          </p>
          <img src="../../../assets/img/screenshot-3.png" alt="" />

          <p class="mt-6 text-base">
            d- Click on the “<strong>Keys and Tokens</strong>” tab, and from the
            “<strong>Consumer Keys</strong>” sections click on
            “<strong>Regenerate</strong>”. Copy and paste these generated keys
            to the form in ContentStudio. >”
          </p>
          <img src="../../../assets/img/twitter-app-info.png" alt="" />

          <p class="mt-6 text-base">
            6- Add our <strong>Callback URL</strong> to Twitter:
          </p>
          <p class="text-base">
            a- Copy the callback URL for The Fly
            <a href=" https://api-fly-social.com/twitter/connect">
              https://api-fly-social.com/twitter/connect</a
            >
          </p>
          <p class="text-base">
            b- On the “<strong>Settings</strong>” page find the “<strong
              >User authentication settings</strong
            >” section and click on Edit.
          </p>
          <img
            src="//d33v4339jhl8k0.cloudfront.net/docs/assets/576134c4c6979153877cd3cc/images/64379bd737fd073d73d6c6af/file-lqTghYWAzn.png"
            alt=""
          />
          <p class="text-base">
            c- On this page find the “<strong>App info</strong>” section and
            paste the URL in the “<strong>Callback URI / Redirect URL</strong>”
            text field and click on Save
          </p>
          <img src="../../../assets/img/twitter-app-info.png" alt="" />

          <p class="mt-6 text-base">
            Go back to The Fly, click on the checkbox confirming that you’ve
            added the callback URL, and click on
            <strong>Proceed</strong> to start the
            <strong>authentication process</strong>. That's all you are now
            ready to post on Twitter
          </p>
        </div>
        <div class="flex items-center justify-between w-full pb-10 mt-20">
          <i class="text-gray-400">
            Still need help? Contact Us
            <a href="mailto:fly@zoomlocal.in">fly@zoomlocal.in</a>
          </i>
          <i class="text-gray-400">Last updated on Feb 08, 2024</i>
        </div>
      </div>
    </div>
    <footer class="bg-[#8056DE]">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="px-4 pt-16 pb-8 mx-auto max-w-7xl sm:pt-24 lg:px-8 lg:pt-32">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="w-[300px]">
            <router-link to="/">
              <img
                src="../../../assets/img//fly-new-logo.png"
                class="h-[40px] xl:h-[56px] w-[56px] rounded-full"
              />
            </router-link>

            <div class="text-sm leading-6 text-gray-300">
              Your social, simplified!
              <div
                class="mt-4 text-base font-black leading-7 text-white mt- md:text-lg 2xl:text-xl"
              >
                Registered Address
              </div>
              Ground Floor, Prabhavee Tech Park, Baner, Pune, Maharashtra 411045
            </div>
            <div class="flex items-center py-2 2xl:w-[160px] 2xl:mt-[56px]">
              <a target="_blank" href=" https://www.facebook.com/flysocial.in/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fly-prod-in-zl.appspot.com/o/fly_platforms%2Ficons%2Ffacebook.png?alt=media&token=a191e54e-f4e9-469b-af44-dfc9360f5289"
                  class="2xl:w-10 2xl:h-10 w-[34px] h-[33.94px] px-1 object-contain"
                />
              </a>
              <a
                target="_blank"
                href=" https://www.instagram.com/fly_by_around_2024/"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fly-prod-in-zl.appspot.com/o/fly_platforms%2Ficons%2Finstagram.png?alt=media&token=6973820c-b95f-45d6-b527-d16b5dcf0343"
                  class="2xl:w-10 2xl:h-10 w-[34px] h-[33.94px] px-1 object-contain"
                />
              </a>
              <a target="_blank" href="https://www.youtube.com/@TheFlySocial">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fly-prod-in-zl.appspot.com/o/fly_platforms%2Ficons%2Fyoutube-play.png?alt=media&token=2e39cc0b-c0c0-4377-ad69-f9a417120a58"
                  class="2xl:w-10 2xl:h-10 w-[34px] h-[33.94px] px-1 object-contain"
                />
              </a>
              <a target="_blank" href="https://twitter.com/TheFlySocial">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fly-prod-in-zl.appspot.com/o/fly_platforms%2Ficons%2Ftwitterx.png?alt=media&token=67a40c5a-6095-41db-88e1-cf8daa8dca75"
                  class="2xl:w-8 2xl:h-8 w-[34px] h-[33.94px] object-contain"
                />
              </a>
            </div>
          </div>
          <div
            class="w-full md:flex md:justify-between lg:justify-stretch lg:gap-[60px] xl:gap-[112px] 2xl:gap-[154px]"
          >
            <div class="mt-[45px] md:mt-[43px] lg:mt-[0px] grid"></div>
            <div class="mt-[30px] md:mt-[43px] lg:mt-[0px]">
              <div
                class="text-base font-black leading-7 text-white md:text-lg 2xl:text-xl"
              >
                Company
              </div>
              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <router-link to="/about-us">About</router-link>
              </div>
              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <!-- <router-link to="/terms-of-use">
                  Terms & Conditions</router-link
                > -->
              </div>
              <div
                class="text-sm font-normal sm:w-[150px] leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <!-- <router-link to="/privacy-policy">Privacy Policy</router-link> -->
              </div>
              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <router-link to="/cookie-policy">Cookie policy</router-link>
              </div>
            </div>
            <div class="mt-[30px] md:mt-[43px] lg:mt-[0px]">
              <div
                class="text-base font-black leading-7 text-white footer-text md:text-lg 2xl:text-xl:"
              >
                Contact
              </div>
              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <router-link to="/resources">Help</router-link>
              </div>

              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <span>
                  <a href="mailto:fly@zoomlocal.in">fly@zoomlocal.in</a></span
                >
              </div>
              <div
                class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
              >
                <span> <a href="tel:+919881474184">+919881474184</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:px-[80px] xl:px-[160px] 2xl:px-[194px] md:px-[32px]">
          <div
            class="flex justify-center md:mt-[62px] lg:mt-[79px] xl:mt-[80px] 2xl:mt-[100px]"
          >
            <div
              class="w-full 2xl:w-full h-[0px] border border-white border-opacity-10 hidden md:block"
            ></div>
          </div>
          <div class="items-center lg:flex">
            <div class="md:mt-[55px] mt-[19px] md:hidden block">
              <div class="flex items-center justify-center gap-2 text-center">
                <span
                  class="text-white text-[10px] font-normal leading-[13px] md:text-xs xl:text-sm"
                >
                  Crafted in India with
                </span>

                <v-icon color="red">mdi-heart</v-icon>
              </div>
            </div>
            <div class="flex justify-center md:hidden">
              <div
                class="w-[300px] h-[0px] border border-white border-opacity-10"
              ></div>
            </div>

            <div
              class="items-center w-full md:justify-between md:flex lg:mt-[60px] 2xl:mt-[40px] md:mt-[30px] mt-[43px]"
            >
              <div class="hidden md:block">
                <div class="flex items-center justify-between gap-2">
                  <span
                    class="text-white text-[10px] font-normal leading-[13px] md:text-xs xl:text-sm"
                  >
                    Crafted in India with
                  </span>

                  <v-icon color="red">mdi-heart</v-icon>
                </div>
              </div>
              <div
                class="text-white pt-[6px] text-center text-opacity-60 text-[10px] md:text-xs xl:text-sm font-normal leading-tight"
              >
                © 2023 ZoomLocal Tech Private Limited, India
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMobileView: false,
      showList: false,
      items: [
        { name: "Facebook", href: "/help/facebook" },
        { name: "Instagram", href: "/help/instagram" },
        { name: "Twitter", href: "/help/twitter" },
        { name: "Youtube", href: "/help/youtube" },
      ],
    };
  },
  methods: {
    showHeader() {
      this.showMobileView = !this.showMobileView;
    },
    openInstructions() {
      this.showList = !this.showList;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
</style>
