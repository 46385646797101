<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
        <template v-if="currentUser.photo_url">
          <v-img :src="currentUser.photo_url" width="30px" ></v-img>
        </template>
        <template v-else>
          <user-logo :size="40" />
        </template>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-sm md:text-lg d-flex align-center">
          {{ currentUser.display_name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-xs md:text-sm">
          {{ currentUser.username }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-img src="https://goaround.me/img/logo-mini.png" width="30px"></v-img>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pa-0 " v-if="hasPreview">
      <div v-html="content" class="mb-3" />
    </v-card-text>
    <v-card :href="url" target="_blank" v-if="hasPreview">
      <v-img :src="detail?.image" />
      <v-card-title>{{ detail?.title }}</v-card-title>
      <v-card-text>{{ detail?.description }}</v-card-text>
    </v-card>
    <v-card-text v-else class="pa-0 ">
      <div v-html="content" class="mb-3" />
      <div v-html="hashtags" />
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="d-flex align-center h-full black">
            <v-img :src="item.src" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex my-1 align-center">
        <div style="min-width: 70px" class="d-flex align-center black--text">
          <v-btn tile icon small color="red">
            <v-icon size="19">mdi-heart-outline</v-icon>
          </v-btn>
          <b>0</b>
        </div>
        <div style="min-width: 55px" class="d-flex align-center black--text">
          <v-icon size="19" color="grey" class="mr-1">mdi-eye</v-icon>
          <b>0</b>
        </div>
        <v-btn depressed class="white">
          <v-icon size="18" color="grey" class="mr-1"
            >mdi-comment-outline</v-icon
          >
          <b>0</b>
        </v-btn>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import UserLogo from "@/components/UserLogo.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";

export default {
  components: { UserLogo, VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    detail: Object,
    hasPreview: Boolean,
    url: String,
  },
  data() {
    return {
      carousel: 0,
    };
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, " <br><br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        // console.log(this.post.videos);
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.thumbnail || item.url || item,
            type: "image",
          });
        });
      }
      return list;
    },
  },
};
</script>

<style></style>
