<template>
  <form-wrapper class="pa-2" @onSubmit="submit()">
    <select-field label="Choose from your saved publish config" v-model="presetId" outlined :loading="isPresetLoading"
      @input="onPresetSelected()" :items="presets" />
    <please-wait v-if="isLoading"></please-wait>
    <template v-else>
      <linkedin-configuration
        v-model="linkedin"
        v-if="isSocialHandle('linkedin')"
        :pages="linkedinPages"
        :profile="linkedinProfile"
        :configuration="configuration"
        :connectedApps="connectedApps"
        :isEditing="isEditing"
        :isRepost="isRepost"
        :isDraftPost="isDraftPost"
      />
      <vimeo-configuration
        v-model="vimeo"
        v-if="isSocialHandle('vimeo')"
        :folders="vimeoFolders"
        :configuration="configuration"
        :connectedApps="connectedApps"
        :isEditing="isEditing"
        :isRepost="isRepost"
        :isDraftPost="isDraftPost"
      />
      <facebook-configuration
        v-model="facebook"
        :pages="facebookPages"
        v-if="isSocialHandle('facebook')"
        :configuration="configuration"
        :isEditing="isEditing"
        :isRepost="isRepost"
        :isDraftPost="isDraftPost"
        :connectedApps="connectedApps"
        :postTypeConfiguration="postTypeConfiguration"
        :post="post"
        @thumbnailadded="handleThumbnails"
      />
      <instagram-configuration
        v-model="instagram"
        :post="post"
        :users="instagramUsers"
        :configuration="configuration"
        :isEditing="isEditing"
        :isRepost="isRepost"
        :isDraftPost="isDraftPost"
        :vidDuration="vidDuration"
        :openIgForm="openIgForm"
        :connectedIgUsers="connectedIgUsers"
        :postTypeConfiguration="postTypeConfiguration"
        @hasProfessionalAccounts="handleProfessionalAccounts"
        @professionalAccounts="handleIgProfessionalAccounts"
        @editAccountType="handleEditAccountType"
        :validVideoTypes="validVideoTypes"
        @thumbnailAdded="handleThumbnails"
        @thumbnailRemoved="handleThumbnails"
        v-if="
          isSocialHandle('instagram') &&
          (post.videos?.length || post.images?.length)
        " />
      <tumblr-configuration v-model="tumblr" :post="post" :blogs="tumblrUsers" :configuration="configuration"
        :isEditing="isEditing" :isRepost="isRepost" :isDraftPost="isDraftPost" v-if="isSocialHandle('tumblr')" />
      <reddit-configuration v-model="reddit" :post="post" :subreddits="redditSub" :configuration="configuration"
        :isEditing="isEditing" :isRepost="isRepost" :isDraftPost="isDraftPost" v-if="isSocialHandle('reddit')" />
      <pinterest-configuration v-model="pinterest" :post="post" :boards="pinterestBoards" :configuration="configuration"
        :isEditing="isEditing" :isVideoSelected="isVideoSelected" :isRepost="isRepost" :isDraftPost="isDraftPost"
        @coverimagechanges="handlecoverimagechanges" @coverimageremoved="$emit('coverimageremoved', $event)"
        v-if="isSocialHandle('pinterest')" />


      <youtube-configuration v-model="youtube" :configuration="configuration" :isEditing="isEditing"
        :isRepost="isRepost" :isDraftPost="isDraftPost" v-if="isSocialHandle('youtube') && post.videos?.length"
        @characterCount="ytCharacterCount" @titleChanged="handleYTtitle" @tagsAdded="handleTagsAdded"
        :yt_title="youtube.yt_title" :yt_tags="content" :vidDuration="vidDuration"
        @tagCharacterCount="handleTagsCharacterCount" @thumbchanged="handleThumbChange"
        @thumbremoved="handleThumbRemoved" :validVideoTypes="validVideoTypes" />
      <tiktok-configuration v-model="tiktok" :users="TiktokUsers" :privacy="TiktokPrivacy" :post="post"
        :configuration="configuration" :isEditing="isEditing" :isRepost="isRepost" :isDraftPost="isDraftPost"
        v-if="isSocialHandle('tiktok')" />
      <google-business-profile v-model="gmb" :post="post" :configuration="configuration" :isEditing="isEditing"
        :isRepost="isRepost" :isDraftPost="isDraftPost" :connectedApps="connectedApps"
        :locations="googleBusinessLocations" v-if="isSocialHandle('google_business_profile')" />

      
      <mastodon-configuration
        v-model="mastodon"
        :post="post"
        :configuration="configuration"
        :isEditing="isEditing"
        :isRepost="isRepost"
        :isDraftPost="isDraftPost"
        :connectedApps="connectedApps"
        v-if="isSocialHandle('mastodon')"
      />

      <div class="flex-wrap mt-5 d-flex align-center">
        <!-- Add the flex-wrap class for wrapping on smaller screens -->
        <div class="mb-3">
          <input-field hide-details placeholder="Name your config" v-model="presetName" />
        </div>
        <!-- Add a margin class for spacing between elements -->
        <v-btn height="40px" depressed @click="saveAsNewPreset()" :loading="isSaveAsNewLoading"
          class="mb-3 ml-1 mr-3 bt">
          Save Preset<v-icon color="primary">mdi-content-save-plus</v-icon></v-btn>
        <!-- Add margin classes for spacing -->
        <v-btn depressed height="40px" variant="outlined" @click="updatePreset()" v-if="presetId"
          :loading="isPresetUpdateLoading" class="mb-3 mr-3 rd">Edit <v-icon color="success">mdi-pencil</v-icon></v-btn>
        <!-- <v-btn @click="click">click</v-btn> -->
        <v-btn depressed height="40px" variant="outlined" @click="removePreset()" v-if="isPresetSelected"
          class="mb-3 mr-3 og">Remove<v-icon color="warning">mdi-broom</v-icon></v-btn>
        <v-btn depressed height="40px" variant="outlined" @click="deletePreset()" v-if="isPresetSelected"
          class="mb-3 mr-3 rt">Delete
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
        <!-- Add margin classes for spacing -->
        <!-- <v-spacer></v-spacer> -->
      </div>
      <div
        class="button-container flex-wrap justify-end mt-3 d-flex align-center"
      >
        <v-btn
          class="my-2 grey-lighten-5"
          @click="$emit('back')"
          :small="!isSmallAndUp"
          :large="isSmallAndUp"
          depressed
          :disabled="draftLoading"
          >Back</v-btn
        >

        <v-btn
          class="my-2 md:ml-3 px-6 grey-lighten-5"
          @click="saveDraftPost()"
          v-if="(!isRepost || !isEditing) && !isDraftPost"
          color="#F2F6FA"
          :small="!isSmallAndUp"
          :large="isSmallAndUp"
          depressed
          :loading="draftLoading"
          ><span>Save as draft</span></v-btn
        >

        <v-btn
          depressed
          :small="!isSmallAndUp"
          :large="isSmallAndUp"
          v-if="isDraftPost"
          :loading="draftLoading"
          class="ml-3 primary"
          @click="updateDraft()"
          >Update Draft</v-btn
        >

        <v-btn
          class="my-2 md:ml-3"
          :small="!isSmallAndUp"
          :large="isSmallAndUp"
          type="submit"
          color="#8056DE"
          depressed
          :loading="loadConfigBtn"
          :disabled="draftLoading"
          ><span class="text-white">Preview</span></v-btn
        >
      </div>
    </template>
  </form-wrapper>
</template>

<script>
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import FacebookConfiguration from "./PostConfigure/FacebookConfiguration.vue";
import LinkedinConfiguration from "./PostConfigure/LinkedinConfiguration.vue";
import VimeoConfiguration from "./PostConfigure/VimeoConfiguration.vue";
import InstagramConfiguration from "./PostConfigure/InstagramConfiguration.vue";
import YoutubeConfiguration from "./PostConfigure/YoutubeConfiguration.vue";
import TumblrConfiguration from "@/components/post/PostConfigure/TumblrConfiguration.vue";
import RedditConfiguration from "@/components/post/PostConfigure/RedditConfiguration.vue";
import PinterestConfiguration from "./PostConfigure/PinterestConfiguration.vue";
import TiktokConfiguration from "./PostConfigure/TiktokConfiguration.vue";
import GoogleBusinessProfile from "./PostConfigure/GoogleBusinessProfile.vue";
import MastodonConfiguration from "./PostConfigure/MastodonConfigurations.vue";
import { colUsers } from "@/utils/firebase.utils";
import { getDate } from "@/utils/common.utils";
import PleaseWait from "../ui/PleaseWait.vue";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    connectedApps: {
      type: Array,
      default: () => [],
    },
    validVideoTypes: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    isEditing: Boolean,
    isRepost: Boolean,
    draftLoading: Boolean,
    isDraftPost: Boolean,
    isVideoSelected: Boolean,
    postId: String,
    vidDuration: Number,
    configuration: {
      type: Object,
    },
    postUID: {
      type: String,
      default: () => "",
    },
    tags: String,
    loadConfigBtn: Boolean,
    configureClicked: Boolean,
    postTypeConfiguration: Object,
  },
  components: {
    FormWrapper,
    YoutubeConfiguration,
    InstagramConfiguration,
    TumblrConfiguration,
    RedditConfiguration,
    PinterestConfiguration,
    FacebookConfiguration,
    LinkedinConfiguration,
    InputField,
    SelectField,
    PleaseWait,
    TiktokConfiguration,
    VimeoConfiguration,
    GoogleBusinessProfile,
    MastodonConfiguration,
  },
  data() {
    return {
      isLoading: false,
      thumbChanged: false,
      coverImageChanged: false,
      coverimage: false,
      tagCount: 0,
      presetName: "",
      characterCount: 0,
      presetId: "",
      presets: [],
      newTitle: "",
      isYTtitleChanged: false,
      isPresetUpdateLoading: false,
      isSaveAsNewLoading: false,
      isPresetLoading: false,
      isPresetSelected: false,
      hasProfessionalIgAccounts: false,
      tiktok: {
        tt_users: [],
        tt_privacy: [],
      },
      gmb: {
        gmb_locations: [],
        gmb_callToAction: "",
        gmb_callToActionUrl: "",
      },
      youtube: {
        yt_title: "",
        yt_tags: "",
        yt_category: [],
        yt_thumb: null,
      },
      instagram: {
        ig_users: [],
        ig_thumb: null,
      },
      vimeo: {
        vimeo_folder: [],
      },
      tumblr: {
        blog: [],
      },
      reddit: {
        subreddit: [],
      },
      pinterest: {
        board: [],
        cover_image_url: null,
      },
      facebook: {
        fb_pages: [],
        fb_thumb: null,
      },
      linkedin: {
        profile: [],
        linkedin_pages: [],
      },
      content: [],
      newTags: null,
      newYTtags: null,
      tagsArray: null,
      openIgForm: false,
      connectedIgUsers: null,
      igProfessionalAccounts: null,
    };
  },
  watch: {
    postUID() {
      this.fetchPresets();
      this.presetId = "";
      this.presetName = "";
    },
    WID() {
      this.fetchPresets();
    },
    post: {
      handler() {
        if (this.isSocialHandle("youtube")) {
          const hashtagRegex = /#\w+/g;
          if (!this.isEditing && !this.isRepost) {
            this.youtube.yt_title = this.post.name;
            const ytTags = (this.post.text.match(hashtagRegex) || []).map(
              (tag) => tag.slice(1)
            );
            if (ytTags?.length) {
              this.youtube.yt_tags = ytTags.join(",");
            }
            this.content = ytTags;
          }
          if (this.isRepost) {
            const existingTags = this.configuration.yt_tags?.length
              ? this.configuration.yt_tags.split(",")
              : null;
            const ytTags = (this.post.text.match(hashtagRegex) || []).map(
              (tag) => tag.slice(1)
            );
            // console.log("existingTags, ytTags : ", existingTags, ytTags)
            if (ytTags?.length) {
              this.newYTtags = ytTags.join(",");
            }
            if (existingTags && this.newYTtags?.length) {
              const allTags = [
                ...new Set([
                  ...existingTags,
                  ...(this.newYTtags || "").split(","),
                ]),
              ];
              // console.log("allTags", allTags)
              this.youtube.yt_tags = allTags.join(",");
            } else if (existingTags && !this.newYTtags) {
              this.youtube.yt_tags = existingTags.join(",");
            } else if (!existingTags && ytTags?.length) {
              // console.log("!existing")
              this.youtube.yt_tags = ytTags.join(",");
            }
            const tagsArray = this.youtube.yt_tags?.length
              ? this.youtube.yt_tags.split(",")
              : [];
            this.tagsArray = tagsArray;
          } else if (this.isEditing) {
            const existingTags = this.configuration.yt_tags?.length
              ? this.configuration.yt_tags.split(",")
              : null;
            if (existingTags) {
              this.youtube.yt_tags = existingTags.join(",");
            }
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.isEditing || this.isRepost) {
      this.$watch(
        () => this.connectedApps,
        (newVal) => {
          if (newVal && newVal.length > 0) {
            this.fetchConfiguration();
          }
        }
      );
      if (this.isSocialHandle("youtube")) {
        this.youtube.yt_title =
          this.isEditing || this.isRepost
            ? this.configuration.yt_title
            : this.isYTtitleChanged
              ? this.newTitle
              : this.post.name;
      }
    }
  },
  computed: {
    facebookPages() {
      let item = this.connectedApps.find((item) => item.id == "facebook") || {};
      return (item.pages || []).map((i) => ({
        ...i,
        value: i.id,
      }));
    },
    googleBusinessLocations() {
      let item =
        this.connectedApps.find(
          (item) => item.id == "google_business_profile"
        ) || {};
      // console.log('items:', item)
      // item.accounts.map((account) => {
      //   console.log('acc:', account.locations)
      // })
      // return ['1', '2'];
      // return (item.accounts || []).map((account) => ({
      //   ...i,
      //   value: i.id,
      // }));

      return (item.accounts || []).flatMap((account) =>
        (account.locations || []).map((location) => ({
          ...location,
          value: location.name,
          account: account.name,
          name: location.title,
        }))
      );
    },
    vimeoFolders() {
      let item = this.connectedApps.find((item) => item.id == "vimeo") || {};
      return (item.folders || []).map((i) => ({
        ...i,
        value: i.folder_id,
      }));
    },
    linkedinPages() {
      let item = this.connectedApps.find((item) => item.id == "linkedin") || {};
      return (item.pages || []).map((i) => ({
        ...i,
        value: i.id,
      }));
    },
    linkedinProfile() {
      let item = this.connectedApps.find((item) => item.id == "linkedin") || {};
      return [{ value: item.user_id, name: item.user_name }];
    },
    // facebookGroups() {
    //   let item = this.connectedApps.find((item) => item.id == "facebook") || {};
    //   return (item.groups || []).map((i) => ({
    //     ...i,
    //     value: i.id,
    //   }));
    // },
    instagramUsers() {
      let item =
        this.connectedApps.find((item) => item.id == "instagram") || {};
      this.connectedIgUsers = item.users?.length;
      if (
        item.users?.length === 1 &&
        !item.users[0].account_type &&
        this.configureClicked
      ) {
        this.openIgForm = true;
      }
      return (item.users || []).map((i) => ({
        ...i,
        value: i.user_id,
      }));
    },
    tumblrUsers() {
      let item = this.connectedApps.find((item) => item.id === "tumblr") || {};

      return (item.blogs || []).map((i) => ({
        name: i.name,
      }));
    },
    redditSub() {
      let item = this.connectedApps.find((item) => item.id == "reddit") || {};
      return (item.subreddits || []).map((i) => ({
        name: i.name,
      }));
    },
    pinterestBoards() {
      let item =
        this.connectedApps.find((item) => item.id == "pinterest") || {};

      return (item.boards || []).map((i) => ({
        ...i,
        value: i.id,
      }));
    },
    TiktokUsers() {
      let item = this.connectedApps.find((item) => item.id === "tiktok") || {};
      console.log("item", item);
      // return (item.users || []).map((i) => ({
      //   name: i.name,
      // }));
      return [item.name];
    },
    TiktokPrivacy() {
      let item = this.connectedApps.find((item) => item.id === "tiktok") || {};
      console.log("item", item);
      // return (item.users || []).map((i) => ({
      //   name: i.name,
      // }));
      return item.privacy_levels;
    },
  },
  methods: {
    handleEditAccountType({ userId, accountType }) {
      const userIndex = this.instagramUsers.findIndex(
        (user) => user.value === userId
      );
      if (userIndex !== -1) {
        this.$set(this.instagramUsers, userIndex, {
          ...this.instagramUsers[userIndex],
          account_type: accountType,
        });
      }
    },
    onPresetSelected() {
      let preset = this.presets.find((i) => i.value == this.presetId);
      if (preset) {
        this.isPresetSelected = true;
        let {
          facebook,
          instagram,
          youtube,
          tumblr,
          reddit,
          pinterest,
          linkedin,
          google_business_profile,
          mastodon,
        } = preset;
        if (this.isSocialHandle("linkedin")) {
          this.linkedin.profile = linkedin?.profile || [];
          this.linkedin.linkedin_pages = linkedin?.linkedin_pages || [];
        }
        let { videos, images } = this.post;
        if (this.isSocialHandle("tumblr")) {
          this.tumblr.blog = tumblr?.blog || [];
        }
        if (this.isSocialHandle("reddit")) {
          this.reddit.subreddit = reddit?.subreddit || [];
        }
        if (this.isSocialHandle("pinterest")) {
          this.pinterest.board = pinterest?.board || [];
        }
        if (this.isSocialHandle("google_business_profile")) {
          this.gmb.gmb_callToAction = google_business_profile.gmb_callToAction;
          this.gmb.gmb_locations = google_business_profile.gmb_locations;
          this.gmb.gmb_callToActionUrl =
            google_business_profile.gmb_callToActionUrl;
        }
        if (this.isSocialHandle("facebook")) {
          let fb_pages = [];
          this.facebookPages.forEach((i) => {
            if (facebook.fb_pages.indexOf(i.value) != -1) {
              fb_pages.push(i.value);
            }
          });
          // this.facebookGroups.forEach((i) => {
          //   if (facebook.fb_groups.indexOf(i.value) != -1) {
          //     fb_groups.push(i.value);
          //   }
          // });
          this.facebook.fb_pages = fb_pages;
        }
        if (
          this.isSocialHandle("instagram") &&
          (videos.length || images.length)
        ) {
          let ig_users = [];
          this.instagramUsers.forEach((i) => {
            if (instagram.ig_users.indexOf(i.value) != -1) {
              ig_users.push(i.value);
            }
          });
          this.instagram.ig_users = ig_users;
        }
        if (this.isSocialHandle("youtube") && videos.length) {
          this.youtube = youtube;
        }
        if (this.isSocialHandle("mastodon") && videos.length) {
          this.mastodon = mastodon;
        }
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        this.presetName = preset.name;
        if (this.isSocialHandle("youtube")) {
          this.youtube.yt_title =
            this.isEditing || this.isRepost
              ? this.configuration.yt_title
              : this.isYTtitleChanged
                ? this.newTitle
                : this.post.name;
          this.youtube.yt_tags = this.newTags
            ? this.newTags
            : this.tagsArray
              ? this.tagsArray.join(",")
              : this.content.join(",");
        }

        if (this.isSocialHandle("tiktok")) {
          this.tiktok.tt_users = preset.tiktok.tt_users;
          this.tiktok.tt_privacy = preset.tiktok.tt_privacy;
        }
      }
    },
    handleIgProfessionalAccounts(accounts) {
      this.igProfessionalAccounts = accounts;
    },
    handleProfessionalAccounts(hasAccounts) {
      this.hasProfessionalIgAccounts = hasAccounts;
    },
    handleThumbnails(data) {
      if (data && data.platform == "ig") {
        if (data.removed) {
          this.instagram.ig_thumb = null;
        } else {
          this.instagram.ig_thumb = data.thumbnail;
        }
      } else if (data && data.platform == "fb") {
        if (data.removed) {
          this.facebook.fb_thumb = null;
        } else {
          this.facebook.fb_thumb = data.thumbnail;
        }
      }
    },
    handleThumbChange(thumb) {
      if (thumb) {
        this.thumbChanged = true;
        this.thumbnail = thumb;
      }
    },
    handlecoverimagechanges(val) {
      if (val) {
        this.coverImageChanged = true;
        this.coverimage = val;
      }
    },
    handleThumbRemoved(thumbremoved) {
      this.$emit("thumbremoved", thumbremoved);
    },
    handleYTtitle(newTitle) {
      // this.youtube.yt_title = newTitle;
      this.isYTtitleChanged = true;
      this.newTitle = newTitle;
    },
    handleTagsAdded(tags) {
      this.newTags = tags;
    },
    handleTags(tagsArray) {
      const tagsString = tagsArray.join(",");
      this.youtube.yt_tags = tagsString;
    },
    handleTagsCharacterCount(count) {
      // console.log("count", count);
      this.tagCount = count;
    },
    ytCharacterCount(characterCount) {
      this.characterCount = characterCount;
    },
    isSocialHandle(name) {
      return (this.post.social_media || []).indexOf(name) != -1;
    },
    async fetchFacebookConfiguration() {
      let fetched_fb_pages = [];
      if (this.configuration.fb_pages?.length) {
        fetched_fb_pages = this.configuration.fb_pages;
      }
      const isFacebookConnected = this.connectedApps.find(
        (app) => app.id === "facebook"
      );
      if (isFacebookConnected) {
        const item =
          this.connectedApps.find((item) => item.id === "facebook") || {};
        const userFacebookPages = item.pages || [];
        // console.log("userFacebookPages, userFacebookGroups", userFacebookPages, userFacebookGroups)
        const commonPages = fetched_fb_pages.filter((fbPage) =>
          userFacebookPages.some((userPage) => userPage.id === fbPage)
        );
        // const commonGroups = fetched_fb_groups.filter((fbGroup) =>
        //   userFacebookGroups.some((userGroup) => userGroup.id === fbGroup)
        // );
        // console.log("common page, grp", commonPages, commonGroups)
        this.facebook.fb_pages = commonPages;
      }
    },
    async fetchConfiguration() {
      await this.fetchFacebookConfiguration();
      if (this.configuration.ig_users?.length) {
        this.instagram.ig_users = this.configuration.ig_users;
        this.instagram.ig_post_type = this.configuration.ig_post_type;
      }
      if (this.configuration.blog?.length) {
        this.tumblr.blog = this.configuration.blog;
      }
      if (this.configuration.subreddit?.length) {
        this.reddit.subreddit = this.configuration.subreddit;
      }
      if (this.configuration.board?.length) {
        this.pinterest.board = this.configuration.board;
        this.pinterest.cover_image_url = this.configuration.cover_image_url;
      }
      if (this.isSocialHandle("instagram")) {
        if (this.configuration && this.configuration.ig_thumb) {
          this.instagram.ig_thumb = this.configuration.ig_thumb;
        }
      }
      if (this.isSocialHandle("youtube")) {
        if (this.configuration.yt_title) {
          this.youtube.yt_title = this.configuration.yt_title;
        }
        this.youtube.yt_category = this.configuration.yt_category;
        this.youtube.yt_license = this.configuration.yt_license;
        if (!this.youtube.yt_thumb) {
          this.youtube.yt_thumb = this.configuration.yt_thumb;
        }
        this.youtube.yt_tags = this.configuration.yt_tags?.length
          ? this.configuration.yt_tags
          : "";
      }
      if (this.isSocialHandle("linkedin")) {
        if (this.configuration.profile?.length) {
          this.linkedin.profile = this.configuration.profile;
        }
        if (this.configuration.linkedin_pages?.length) {
          this.linkedin.linkedin_pages = this.configuration.linkedin_pages;
        }
      }
      if (this.isSocialHandle("google_business_profile")) {
        if (this.configuration.gmb_callToAction) {
          this.gmb.gmb_callToAction = this.configuration.gmb_callToAction;
        }
        if (this.configuration.gmb_locations) {
          this.gmb.gmb_locations = this.configuration.gmb_locations;
        }
        if (this.configuration.gmb_callToActionUrl) {
          this.gmb.gmb_callToActionUrl = this.configuration.gmb_callToActionUrl;
        }
      }
    },
    async updatePreset() {
      const vm = this;
      if (!vm.presetName.trim()) {
        vm.$alert.show("Please enter the preset name.");
        return;
      }
      try {
        vm.isPresetUpdateLoading = true;
        
        let presetCol = collection(
          colUsers,
          vm.postUID,
          "fly_workspaces",
          vm.WID,
          "presets"
        );;
        

        const linkedin = {
          profile: this.linkedin.profile,
          linkedin_pages: this.linkedin.linkedin_pages,
        };

        const tumblr = { blog: this.tumblr.blog };
        const reddit = { subreddit: this.reddit.subreddit || [] };
        const pinterest = { pinterest: this.pinterest.board };
        const google_business_profile = { ...this.gmb };
        const tiktok = {
          tt_users: this.tiktok.tt_users,
          tiktok_privacy: this.tiktok.tt_privacy,
        };
        const youtube = { ...this.youtube };
        delete youtube.yt_title;
        delete youtube.yt_thumb;
        if (!this.isSocialHandle("youtube")) {
          delete youtube.yt_category;
        }
        await updateDoc(doc(presetCol, vm.presetId), {
          linkedin,
          tiktok,
          reddit,
          pinterest,
          tumblr,
          youtube,
          mastodon,
          google_business_profile,
          instagram: this.instagram,
          facebook: this.facebook,
          name: this.presetName,
        });
        vm.$snackbar.show("Preset updated successfully!");
        await vm.fetchPresets();
        vm.isPresetUpdateLoading = false;
      } catch (error) {
        vm.isPresetUpdateLoading = false;
      }
    },
    async saveAsNewPreset() {
      this.$mixpanel.track("Save Preset Clicked");
      const vm = this;
      if (!vm.presetName.trim()) {
        vm.$alert.show("Please enter the preset name.");
        return;
      }

      // Check if a preset with the same name already exists
      const existingPreset = vm.presets.find(
        (preset) => preset.name === vm.presetName
      );

      try {
        vm.isSaveAsNewLoading = true;
        const tiktok = {
          tt_users: this.tiktok.tt_users,
          tt_privacy: this.tiktok.tt_privacy,
        };
        const linkedin = {
          profile: this.linkedin.profile,
          linkedin_pages: this.linkedin.linkedin_pages,
        };
        const google_business_profile = { ...this.gmb };
        // console.log("this.gmb:::", this.gmb);
        const tumblr = { blog: this.tumblr.blog };
        const pinterest = { board: this.pinterest.board };
        const reddit = { subreddit: this.reddit.subreddit || [] };
        const youtube = { ...this.youtube };
        delete youtube.yt_title;
        delete youtube.yt_thumb;
        if (!this.isSocialHandle("youtube")) {
          delete youtube.yt_category;
          delete youtube.yt_license;
          delete youtube.yt_post_type;
          delete youtube.yt_publish_type;
          delete youtube.yt_notify_subscribers;
          delete youtube.yt_embeddable;
          delete youtube.yt_made_for_kids;
        }
        
        let presetCol = collection(
          colUsers,
          vm.postUID,
          "fly_workspaces",
          vm.WID,
          "presets"
        );
        
        if (existingPreset) {
          // Update the existing preset
          await updateDoc(doc(presetCol, existingPreset.value), {
            linkedin,
            tiktok,
            pinterest,
            reddit,
            youtube,
            tumblr,
            google_business_profile,
            instagram: this.instagram,
            facebook: this.facebook,
            name: this.presetName,
          });
          vm.$snackbar.show("Preset updated successfully!");
        } else {
          // Create a new preset
          await addDoc(presetCol, {
            linkedin,
            tiktok,
            pinterest,
            reddit,
            tumblr,
            youtube,
            google_business_profile,
            instagram: this.instagram,
            facebook: this.facebook,
            created_by: vm.currentUID,
            created_at: getDate(),
            name: this.presetName,
          });
          vm.$snackbar.show("Preset saved successfully!");
        }

        await vm.fetchPresets();
        vm.isSaveAsNewLoading = false;
      } catch (error) {
        vm.isSaveAsNewLoading = false;
        vm.handleError(error);
      }
    },
    removePreset() {
      this.presetId = "";
      this.presetName = "";

      const igUsers = this.instagramUsers;
      const fbPages = this.facebookPages;
      const ptBoards = this.pinterestBoards;
      const tbUsers = this.tumblrUsers;
      const rd = this.redditSub;
      const ttUsers = this.TiktokUsers;
      const ttPrivacy = this.TiktokPrivacy;
      if (this.isSocialHandle("linkedin")) {
        this.linkedin = { profile: [], linkedin_pages: [] };
      }
      if (this.isSocialHandle("tiktok") && ttUsers.length > 1) {
        this.tiktok.tt_users = [];
      }
      if (this.isSocialHandle("tiktok") && ttPrivacy.length > 1) {
        this.tiktok.tt_privacy = [];
      }
      if (this.isSocialHandle("tumblr") && tbUsers.length > 1) {
        this.tumblr.blog = [];
      }
      if (this.isSocialHandle("reddit") && rd.length > 1) {
        this.reddit.subreddit = [];
      }
      if (this.isSocialHandle("pinterest") && ptBoards.length > 1) {
        this.pinterest.board = [];
      }

      if (this.isSocialHandle("facebook")) {
        if (fbPages.length > 1) {
          this.facebook.fb_pages.splice(0);
        }
      }
      if (this.isSocialHandle("instagram") && igUsers.length > 1) {
        this.instagram = { ig_users: [] };
      }
      if (this.isSocialHandle("youtube")) {
        this.youtube.yt_post_type = "Video";
        this.youtube.yt_publish_type = "public";
        this.youtube.yt_category = "";
        // this.youtube.yt_playlist = "";
        this.youtube.yt_license = "youtube";
        this.youtube.yt_embeddable = true;
        // this.youtube.yt_notify_subscribers = false;
        this.youtube.yt_made_for_kids = false;
      }
      if (this.isSocialHandle("google_business_profile")) {
        this.gmb.gmb_locations = [];
        this.gmb.gmb_callToAction = "";
        this.gmb.gmb_callToActionUrl = "";
      }
      this.$snackbar.show("Preset removed successfully!");
      this.isPresetSelected = false;
      // console.log("handles", this.facebook, this.instagram, this.youtube);
    },
    deletePreset() {
      try {
        const vm = this;
        this.$confirm.show({
          message: `Are you sure you want to delete this preset?`,
          onConfirm: async () => {
            this.isPresetSelected = false;
            
            let presetCol = collection(
              colUsers,
              vm.postUID,
              "fly_workspaces",
              vm.WID,
              "presets"
            );;

            await deleteDoc(doc(presetCol, vm.presetId));
            this.presetId = "";
            this.presetName = "";
            if (this.isSocialHandle("facebook")) {
              this.facebook.fb_pages.splice(0);
            }
            if (this.isSocialHandle("instagram")) {
              this.instagram = { ig_users: [], ig_post_type: "Feed" };
            }
            if (this.isSocialHandle("youtube")) {
              this.youtube.yt_post_type = "Video";
              this.youtube.yt_publish_type = "public";
              this.youtube.yt_category = "";
              // this.youtube.yt_playlist = "";
              this.youtube.yt_license = "youtube";
              this.youtube.yt_embeddable = true;
              // this.youtube.yt_notify_subscribers = false;
              this.youtube.yt_made_for_kids = false;
            }
            if (this.isSocialHandle("google_business_profile")) {
              this.gmb.gmb_locations = [];
              this.gmb.gmb_callToAction = "";
              this.gmb.gmb_callToActionUrl = "";
            }
            vm.$snackbar.show("Preset deleted successfully!");
            await vm.fetchPresets();
            vm.linkedin = { profile: [], linkedin_pages: [] };
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
    submit() {
      // console.log("gmb:", this.gmb);
      // console.log("this.instagram", this.instagram);
      this.$mixpanel.track("Preview Post Clicked");
      let isTiktok = this.isSocialHandle("tiktok");
      let { tt_users, tiktok_privacy } = this.tiktok;
      if (isTiktok && (!tt_users || tt_users.length === 0)) {
        this.$alert.show("At least one user must be selected for Tiktok");
        return;
      }
      if (isTiktok && (!tiktok_privacy || tiktok_privacy.length === 0)) {
        this.$alert.show(
          "At least one privacy level must be selected for Tiktok"
        );
        return;
      }

      let istumblr = this.isSocialHandle("tumblr");
      let { blog } = this.tumblr;

      if (istumblr && (!blog || blog.length === 0)) {
        this.$alert.show("At least one blog must be selected for Tumblr");
        return;
      }
      let isReddit = this.isSocialHandle("reddit");
      let { subreddit } = this.reddit;

      if (isReddit && (!subreddit || subreddit.length === 0)) {
        this.$alert.show("At least one subreddit must be selected for Reddit");
        return;
      }
      let isLinkedin = this.isSocialHandle("linkedin");
      if (
        isLinkedin &&
        this.linkedin?.profile?.length == 0 &&
        this.linkedin?.linkedin_pages?.length == 0
      ) {
        this.$alert.show(
          "Either profile or page must be selected for Linkedin"
        );
        return;
      }

      let isPinterest = this.isSocialHandle("pinterest");
      let { board } = this.pinterest;

      if (isPinterest && (!board || board.length === 0)) {
        this.$alert.show("At least one board must be selected for Pinterest");
        return;
      }
      if (
        isPinterest &&
        this.isVideoSelected &&
        !this.pinterest.cover_image_url
      ) {
        this.$alert.show("Select the cover image for pinterest");
        return;
      }

      let isFacebook = this.isSocialHandle("facebook");
      let { fb_pages } = this.facebook;
      if ((!fb_pages && isFacebook) || (isFacebook && fb_pages.length == 0)) {
        this.$alert.show("Facebook: At least one page must be selected");
        return;
      }
      if (this.characterCount) {
        // console.log("yt char count", this.characterCount);
        if (this.characterCount > 100) {
          this.$alert.show("YouTube: Title character limit exceeded!");
          return;
        }
      }
      if (this.tagCount > 500) {
        this.$alert.show("YouTube: Tags character limit exceeded!");
        return;
      }

      // if (
      //   this.postTypeConfiguration.fb_post_type === "Reel" &&
      //   this.isVideoSelected &&
      //   this.facebook.fb_groups?.length
      // ) {
      //   this.$alert.show("Reels can't be posted to groups");
      //   return;
      // }

      let isVimeo = this.isSocialHandle("vimeo");
      let { vimeo_folder } = this.vimeo;
      if (isVimeo) {
        if (!vimeo_folder || vimeo_folder.length === 0) {
          this.$alert.show("Vimeo: At least one folder must be selected");
          return;
        }
      }

      let isInstagram = this.isSocialHandle("instagram");
      let { ig_users, ig_post_type } = this.instagram;
      if (
        (this.post.videos?.length || this.post.images?.length) &&
        isInstagram &&
        ig_users.length == 0
      ) {
        this.$alert.show("Instagram: At least one user must be selected");
        return;
      }
      if (isInstagram) {
        // if (ig_post_type === "Feed" && !(this.vidDuration >= 3 && this.vidDuration <= 3600)) {
        //   this.$alert.show(
        //     "Instagram: Min 3s and max 60m video allowed for Feed"
        //   );
        //   return;
        // } else
        if (
          ig_post_type === "Feed" &&
          this.isVideoSelected &&
          !(this.vidDuration >= 3 && this.vidDuration <= 3600)
        ) {
          this.$alert.show("Instagram: Min 3s and max 60m video");
          return;
        }
        if (
          this.postTypeConfiguration.ig_post_type === "Story" &&
          this.igProfessionalAccounts &&
          Object.keys(this.igProfessionalAccounts).length >= 1
        ) {
          this.$alert.show(
            "Instagram: Cannot post Story with a creator account. Please switch to Business in Instagram and update your Instagram account type in Fly to Business."
          );
          return;
        }
        // if (ig_post_type === "Story" && this.isVideoSelected) {
        //   // console.log("sel", this.vidCount)
        //   this.$alert.show("Currently we don't allow video to Story");
        //   return;
        // }
        // else if (ig_post_type === "Story" && this.isVideoSelected && !(this.vidDuration >= 1 && this.vidDuration <= 60)) {
        //   this.$alert.show(
        //     "Instagram: Min 1s and max 60s video allowed for Story"
        //   );
        //   return;
        // }
      }
      let isYoutube = this.isSocialHandle("youtube");
      let { yt_category, yt_license } = this.youtube;
      if (
        (!yt_category && isYoutube) ||
        (this.post.videos?.length && isYoutube && yt_category?.length == 0)
      ) {
        this.$alert.show("Youtube: Please select a Category");
        return;
      }
      if (
        (!yt_license && isYoutube) ||
        (this.post.videos.length && isYoutube && yt_license.length == 0)
      ) {
        this.$alert.show("Youtube: Please select a License");
        return;
      }
      let isGmb = this.isSocialHandle("google_business_profile");
      let { gmb_locations, gmb_callToAction, gmb_callToActionUrl } = this.gmb;
      if (isGmb) {
        if (!gmb_locations || gmb_locations.length === 0) {
          this.$alert.show(
            "Google Business Profile: At least one location must be selected"
          );
          return;
        }
        if (!gmb_callToAction) {
          this.$alert.show(
            "Google Business Profile: Call to action is required"
          );
          return;
        }
        if (
          gmb_callToAction &&
          gmb_callToAction != "CALL" &&
          !gmb_callToActionUrl
        ) {
          this.$alert.show(
            "Google Business Profile: Call to action URL is required"
          );
          return;
        }
      }

      this.$emit("input", {
        ...this.youtube,
        ...this.instagram,
        ...this.facebook,
        ...this.tumblr,
        ...this.reddit,
        ...this.pinterest,
        ...this.tiktok,
        ...this.linkedin,
        ...this.vimeo,
        ...this.gmb,
        ...this.mastodon,
      });
      this.$emit("submit");
      if (this.thumbChanged) {
        this.$emit("thumb", this.thumbnail);
      } else {
        this.$emit("thumb", this.youtube.yt_thumb);
      }
      if (this.coverImageChanged) {
        this.$emit("coverimage", this.coverImage);
      } else {
        this.$emit("coverimage", this.pinterest.cover_image_url);
      }
    },
    updateDraft() {
      let configuration = {
        ...this.youtube,
        ...this.instagram,
        ...this.facebook,
        ...this.tumblr,
        ...this.reddit,
        ...this.pinterest,
        ...this.tiktok,
        ...this.linkedin,
        ...this.mastodon,
      };
      if (this.coverImageChanged) {
        this.$emit("coverimage", this.coverImage);
      } else {
        this.$emit("coverimage", this.pinterest.cover_image_url);
      }
      this.$emit("input", configuration);
      this.$emit("updateDraft", configuration);
    },
    saveDraftPost() {
      let data = {
        ...this.youtube,
        ...this.instagram,
        ...this.facebook,
        ...this.tumblr,
        ...this.reddit,
        ...this.pinterest,
        ...this.tiktok,
        ...this.linkedin,
        ...this.mastodon,
      };
      // console.log("dataaa:::", data);
      this.$mixpanel.track("Save as Draft Clicked", { step: "Configure" });
      this.$emit("input", data);
      this.$emit("savePostToDraft", data);
    },
    async fetchPresets() {
      const vm = this;
      if (!vm.postUID && !vm.WID) return;
      try {
        vm.isPresetLoading = true;
        if (vm.postUID && vm.WID) {

          let presetCol = collection(
            colUsers,
            vm.postUID,
            "fly_workspaces",
            vm.WID,
            "presets"
          );

          vm.presets = (await getDocs(presetCol)).docs.map((i) => {
            return { ...i.data(), value: i.id };
          });
          // console.log("post in fetp", this.post)
          // this.youtube.yt_title = this.post.name;

          vm.isPresetLoading = false;
        }
      } catch (error) {
        vm.isPresetLoading = false;
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchPresets();
    this.fetchConfiguration();
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.bt.v-btn {
  outline-offset: -2px;
}

.rd.v-btn {
  outline-offset: -2px;
}

.og.v-btn {
  outline-offset: -2px;
}

.rt.v-btn {
  outline-offset: -2px;
}

.v-btn:focus {
  outline-width: 4px;
}

.bt.v-btn,
.rd.v-btn,
.og.v-btn,
.rt.v-btn {
  background-color: transparent;
}

.bt.v-btn {
  outline: 2px solid #1773ea;
  outline-offset: -2px;
}

.rd.v-btn {
  outline: 2px solid #4caf50;
  outline-offset: -2px;
}

.og.v-btn {
  outline: 2px solid #fb8c00;
  outline-offset: -2px;
}

.rt.v-btn {
  outline: 2px solid #ff5252;
  outline-offset: -2px;
}

.v-btn:focus {
  outline-width: 4px;
}
.button-container {
  flex-wrap: nowrap;
  gap: 5px;
}

@media (max-width: 780px) {
  .button-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .button-container .v-btn {
    flex: 1;
    margin: 2px;
  }
}
</style>
