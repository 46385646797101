import React, { Component } from 'react';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import { eventBus } from '@/utils/common.utils';
import debounce from 'lodash/debounce';
import createMentionPlugin from '@draft-js-plugins/mention';
import { filterMentions } from './mentions.vue';

const hashtagPlugin = createHashtagPlugin({});
const mentionPlugin = createMentionPlugin({
    mentionPrefix: '@',
});
const { MentionSuggestions } = mentionPlugin;

const plugins = [hashtagPlugin, mentionPlugin];

export default class CustomHashtagEditor extends Component {

    state = {
        editorState: createEditorStateWithText(this.props.value),
        suggestions: [],
        users: [],
        open: false,
        searchTerm: '',
        firstTimeCall: true,
    };
    constructor(props) {
        super(props);
        this.debouncedFilterMentions = debounce(this.filterMentions, 3000); // Debounce filterMentions function
        eventBus.$on("updateEditorText" + this.props.editorId, (text) => {
            this.setState({
                editorState: createEditorStateWithText(text),
            });

        })
    }
    componentDidMount() {
        eventBus.$on("mentionDataLoading", this.handleMentionDataLoading);
    }
    componentWillUnmount() {
        eventBus.$off("mentionDataLoading", this.handleMentionDataLoading);
        eventBus.$off("updateEditorText" + this.props.editorId);
        
    }
    handleMentionDataLoading = (isLoading) => {
        // Re-emit the event for the parent component
        eventBus.$emit("mentionDataLoadingEditor", isLoading);
    }
    onChange = (editorState) => {
        let val = editorState.getCurrentContent().getPlainText();
        this.props.onInput(val)
        this.setState({
            editorState,
        });
    };

    onOpenChange = (isOpen) => {
        this.setState({
            open: true,
        });
    };

    onSearchChange = async ({ value }) => {
        eventBus.$emit("mentionsSearchText", value);
        // console.log('Search value:', value, value.length);
        if(value && value.length > 0) {
            eventBus.$emit("mentionDataLoading", true);
        } else if(value.length == 0) {
            eventBus.$emit("mentionDataLoading", false);
        }
        // Filter suggestions based on the search term
        this.setState({ searchTerm: value }); // Update searchTerm state
        this.debouncedFilterMentions();
    };

    filterMentions = async () => {
        try {
            if(this.props.editorId == 'instagram' || this.props.editorId == 'twitter') {
                if(this.state.searchTerm) {
                    // console.log('Search term:', this.state.searchTerm, this.state.searchTerm.length);
                    // Call filterMentions here with this.state.searchTerm
                    const MentionedUsers = await filterMentions(this.state.searchTerm, this.props.uid, this.props.wid, this.props.editorId);
                    // console.log('MentionedUsers jsx:', MentionedUsers);
                    if(MentionedUsers) {
                        // suggestions: defaultSuggestionsFilter(this.state.searchTerm, MentionedUsers),
                        this.setState({
                            suggestions: MentionedUsers,
                        });
                        // console.log('Suggestions:', this.state.suggestions);
                    }
                }
                else if(!this.state.searchTerm || this.state.searchTerm == 0) {
                    this.setState({
                        suggestions: [],
                    });
                }
            }

        } catch (error) {
            console.error('Error filtering mentions:', error);
        }
    };

    focus = () => {
        this.editor.focus();
    };

    render() {
        return (
            <div onClick={this.focus}>
                <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    plugins={plugins}
                    ref={(element) => {
                        this.editor = element;
                    }}
                />
                <MentionSuggestions
                    open={this.state.open}
                    onOpenChange={this.onOpenChange}
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                    onAddMention={(mention) => {
            this.setState({
                suggestions: []
            })
                        // Handle the addition of the mention here
                        console.log("Mention added:", mention);
                    }}
                />
            </div>
        );
    }
}