<template>
    <v-container class="text-center">
      <v-card class="mx-auto" max-width="400">
        <v-card-title class="headline">Invitation Accepted</v-card-title>
        <v-card-text>
          <p>Your invitation has been successfully accepted!</p>
          <v-btn color="primary mt-2" @click="goToDashboard">Go to Dashboard</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
  methods: {
    goToDashboard() {
      window.location.href = "/dashboard";
    }
  }
};
  </script>
  
  <style scoped>
  .v-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  </style>
  