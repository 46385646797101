<template>
  <div style="font-family: 'Inter', sans-serif">
    <please-wait v-if="isLoading" class="mt-5" />
    <section class="bg-white" v-else>
      <div class="">
        <div class="mt-10 sm:flex sm:items-center sm:justify-between">
          <div class="w-full">
            <h2
              class="text-2xl font-bold text-center text-transparent text-gray-800 lg:text-3xl bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
            >
              Incredible features, at the most affordable price!
            </h2>
            <p
              v-if="currentSubscription.status === 'created'"
              class="mt-4 text-center text-black"
            >
              Subscription is in progress...
              <v-progress-circular
                indeterminate
                :size="20"
                :width="3"
                class="pl-8"
                color="primary"
              ></v-progress-circular>
            </p>
            <p v-if="isSignupPlan" class="mt-4 text-center text-black">
              Signup package is active<span
                v-if="
                  !authUser.fly_subscription_cancelled ||
                  authUser.fly_subscription_cancelled == undefined
                "
                >, will be switched to
                <span class="font-semibold">{{ planName }}</span>
                <span v-if="authUser.plan_renew_date"
                  >from {{ renewDate }}</span
                ></span
              >
            </p>
          </div>
        </div>
        <div
          class="flex-wrap justify-center gap-4 pb-10 mt-10 xl:gap-1 md:mt-12 font-semibold max-w-[900px] mx-auto"
          :class="isINR && userCountry === 'IN' ? 'flex' : 'hidden'"
        >
          <div
            class="bg-[#F2F6FA] py-2 px-4 md:px-0 flex flex-wrap flex-col md:flex-row gap-1 md:gap-4 md:p-1 md:rounded-full"
          >
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center text-xs md:text-base"
              :class="
                tab == 0
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 0;
                  fetchPlans();
                }
              "
            >
              <div class="block">Monthly</div>
            </button>
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center text-xs md:text-base"
              :class="
                tab == 1
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 1;
                  fetchPlans();
                }
              "
            >
              <div
                class="flex gap-0 md:gap-2 flex-col md:flex-row justify-center text-center items-center"
              >
                <div>Quarterly</div>
                <div class="text-orange-400 mt-1 text-xs items-center">
                  - 10% Off
                </div>
              </div>
            </button>
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center text-xs md:text-base"
              :class="
                tab == 2
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 2;
                  fetchPlans();
                }
              "
            >
              <div
                class="flex gap-0 md:gap-2 flex-col md:flex-row justify-center text-center items-center"
              >
                <div>Half Yearly</div>
                <div class="text-orange-400 mt-1 text-xs items-center">
                  - 15% Off
                </div>
              </div>
            </button>
            <button
              @click="
                () => {
                  tab = 3;
                  fetchPlans();
                }
              "
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center text-xs md:text-base"
              :class="
                tab == 3
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
            >
              <div
                class="flex gap-0 md:gap-2 flex-col md:flex-row justify-center text-center items-center"
              >
                <div>Yearly</div>
                <div class="text-orange-400 mt-1 text-xs items-center">
                  - 25% Off
                </div>
              </div>
            </button>
          </div>
        </div>

        <please-wait v-if="loading" class="mt-5" />
        <div class="text-center" v-if="!loading && plans.length == 0">
          <h4 class="text-xl font-bold">No plans to display</h4>
        </div>
        <section
          v-if="!loading && plans.length > 0"
          class="flex flex-wrap justify-center gap-4 pb-10 mt-10 xl:gap-1 md:mt-12"
        >
          <div
            v-for="(plan, index) in plans"
            :key="index"
            class="p-4 md:p-2 border border-gray-200 rounded-2xl md:w-[280px]"
            :class="
              isActivePlan(plan, userData)
                ? 'bg-gray-700'
                : 'hover:bg-gray-200 border'
            "
          >
            <p
              class="text-lg font-bold"
              :class="
                isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'
              "
            >
              {{ plan.name }}
            </p>

            <h1
              class="mt-4 text-sm text-[#59626F]"
              :class="
                isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'
              "
            >
              {{ plan.short_desc }}
            </h1>
            <h4
              class="mt-2 text-3xl font-semibold"
              :class="
                isActivePlan(plan, userData)
                  ? 'text-gray-100 '
                  : 'text-gray-800'
              "
            >
              <template v-if="isINR && userCountry === 'IN'">
                ₹ {{ plan.monthly_price_inr }}
              </template>
              <template v-else> $ {{ plan.monthly_price_usd }} </template>
              <span
                class="text-base text-[#59626F] font-semibold"
                :class="
                  isActivePlan(plan, userData)
                    ? 'text-gray-100'
                    : 'text-gray-800'
                "
                >/
              </span>
              <span
                class="text-base text-[#59626F] font-semibold"
                :class="
                  isActivePlan(plan, userData)
                    ? 'text-gray-100'
                    : 'text-gray-800'
                "
              >
                {{ plan.months != 1 ? plan.months : "" }}
                {{ plan.months > 1 ? "months" : "month" }}</span
              >
            </h4>
            <div
              v-if="isActivePlan(plan, userData)"
              class=""
              :class="plan.name === 'Free' ? '' : 'hidden'"
            >
              <button
                class="w-full px-3 py-2 mt-6 mb-8 text-sm font-bold text-black bg-white rounded-md"
              >
                Free
              </button>
            </div>

            <div
              v-if="isActivePlan(plan, userData)"
              class=""
              :class="plan.name === 'Free' ? 'hidden' : ''"
            >
              <span v-if="isSubscriptionCancelled">
                <div class="text-red-500 mt-2 font-semibold text-center">
                  Cancelled
                </div>
              </span>
              <button
                class="w-full px-3 py-2 mt-4 mb-2 text-sm font-bold text-white bg-green-500 rounded-md"
              >
                <template v-if="userData.fly_plan_valid_until">
                  Plan valid until -
                  {{
                    userData.fly_plan_valid_until === null ||
                    userData.fly_plan_valid_until === undefined
                      ? ""
                      : new Date(
                          userData.fly_plan_valid_until.seconds * 1000
                        ).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                  }}
                </template>
                <span v-else> Subscribed </span>
              </button>
            </div>
            <div v-else>
              <button
                @click="plan.name === 'Free' ? null : subscribe(plan)"
                class="bg-[#8056DE] mb-8 rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                :class="plan.name === 'Free' ? 'hidden' : ''"
              >
                Choose plan
              </button>
            </div>
            <div
              v-if="isActivePlan(plan, userData)"
              class="flex items-center justify-center"
              :class="plan.name === 'Free' ? 'hidden' : ''"
              @click="cancelSubscription"
            >
              <button
                v-if="
                  !cancelLoading &&
                  (authUser.fly_subscription_cancelled == undefined ||
                    !isSubscriptionCancelled)
                "
                :class="userData.fly_plan_valid_until == null ? '' : 'hidden'"
                class="w-full px-3 py-2 mb-8 text-sm font-medium text-white transition-colors duration-300 transform rounded-md"
              >
                Cancel
              </button>
              <img
                v-if="cancelLoading"
                src="../../assets/img/cancel-loading.gif"
                class="w-8"
                alt=""
              />
            </div>
            <div
              v-for="features in plan.features"
              :key="features"
              class="flex items-center mb-3"
              :class="index === 0 ? 'mt-5' : ''"
            >
              <v-icon color="#8056DE">mdi-check</v-icon>

              <span
                class="mx-4"
                :class="
                  isActivePlan(plan, userData)
                    ? 'text-gray-300'
                    : 'text-gray-700'
                "
              >
                {{ features }}</span
              >
            </div>
          </div>
        </section>
      </div>
      <v-card
        max-width="1000px"
        class="mx-auto mt-5 mb-10"
        v-if="activeAddons.length"
      >
        <v-card-text class="pa-10">
          <div class="text-xl black--text font-weight-bold mb-5">
            Subscribed Add Ons
          </div>
          <template v-for="(item, i) in addons">
            <div
              class="d-flex align-center mt-5 flex-column flex-md-row"
              :key="i"
              v-if="isActiveAddon(item)"
            >
              <div
                class="flex-1 justify-between d-flex align-center flex-column flex-md-row"
              >
                <div class="flex-1">
                  <div
                    class="text-subtitle-1 black--text font-weight-medium text-center text-md-left"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="text-caption text-[#636b8c] text-center text-md-left"
                  >
                    {{ isINR ? item.price_inr : item.price_usd }}
                  </div>
                </div>
                <div class="mt-3 mt-md-0 mx-md-3">
                  <v-autocomplete
                    flat
                    solo
                    v-model="activeAddonForm[item.id]"
                    :items="getAddItems(item)"
                    hide-details
                    style="width: 150px"
                    class="addon-input"
                  ></v-autocomplete>
                </div>
              </div>
              <div
                class="flex-1 justify-end d-flex align-center flex-row ml-md-3 mt-3 mt-md-0"
              >
                <v-btn
                  color="red"
                  dark
                  text
                  depressed
                  x-large
                  @click="cancelAddon(item)"
                  class="!rounded-full mr-3"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="#8056DE"
                  dark
                  depressed
                  x-large
                  width="200px"
                  @click="upgradeAddon(item)"
                  class="!rounded-full"
                >
                  Update
                </v-btn>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
      <v-card max-width="1000px" class="mx-auto mt-5 mb-10">
        <v-card-text class="pa-10">
          <div class="text-xl black--text font-weight-bold mb-5">Add Ons</div>
          <div
            class="d-flex align-center mt-5 flex-column flex-md-row"
            v-for="(item, i) in addons"
            :key="i"
          >
            <div
              class="flex-1 justify-between d-flex align-center flex-column flex-md-row"
            >
              <div class="flex-1">
                <div
                  class="text-subtitle-1 black--text font-weight-medium text-center text-md-left"
                >
                  {{ item.name }}
                </div>
                <div
                  class="text-caption text-[#636b8c] text-center text-md-left"
                >
                  {{ isINR ? item.price_inr : item.price_usd }}
                </div>
              </div>
              <div class="mt-3 mt-md-0 mx-md-3">
                <v-autocomplete
                  flat
                  solo
                  v-model="addonForm[item.id].qty"
                  :items="getAddItems(item)"
                  hide-details
                  style="width: 150px"
                  class="addon-input"
                ></v-autocomplete>
              </div>
            </div>
            <div
              class="flex-1 justify-between d-flex align-center flex-column flex-md-row ml-md-3"
            >
              <v-radio-group
                v-model="addonForm[item.id].type"
                hide-details=""
                class="my-5 pt-0 my-md-0"
              >
                <v-radio
                  label="One Time"
                  value="One Time"
                  color="#636B8C"
                  v-if="item.is_onetime == true"
                ></v-radio>
                <v-radio
                  label="Monthly"
                  v-if="item.is_monthly == true"
                  value="Monthly"
                  color="#636B8C"
                ></v-radio>
              </v-radio-group>
              <v-btn
                color="#8056DE"
                depressed
                x-large
                width="200px"
                :disabled="isAddonSubscribed(item)"
                @click="subscribeAddon(item)"
                class="!rounded-full white--text"
              >
                {{ isAddonSubscribed(item) ? "Subscribed" : "Purchase" }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </section>
    <section class="" v-if="historyExists">
      <v-card max-width="1000px" class="mx-auto mt-5 mb-10">
        <v-card-text class="pa-8">
          <div class="text-xl black--text font-weight-bold mb-3">
            Add Ons Purchase History
          </div>
        </v-card-text>
        <div class="flex flex-col list-view-container p-3 pt-0">
          <!-- Header row for titles -->
          <div class="flex text-left bg-[#F2F6FA] p-3 rounded-t-md">
            <div class="w-1/5 pl-10 font-bold text-[#636B8C]">Name</div>
            <div class="w-1/5 font-bold text-[#636B8C]">Type</div>
            <div class="w-1/6 font-bold text-[#636B8C]">Quantity</div>
            <div class="w-1/5 font-bold text-[#636B8C]">Amount</div>
            <div class="w-1/5 font-bold text-[#636B8C]">Status</div>
            <div class="w-1/4 font-bold text-[#636B8C]">Purchased on</div>
          </div>
          <!-- Data rows -->
          <div
            v-for="(addon, index) in addonsHistory"
            :key="index"
            class="flex items-center p-3 border-b"
          >
            <div class="w-1/5 pl-4">{{ addon.name }}</div>
            <div class="w-1/5">
              {{ addon.type == "monthly" ? "Monthly" : "One Time" }}
            </div>
            <div class="w-1/6">
              {{ addon.quantity }}
            </div>
            <div class="w-1/5">
              {{ addon.amount }}
            </div>
            <div
              class="w-1/5"
              :class="
                addon.status === 'active' ? 'text-green-500' : 'text-red-400'
              "
            >
              {{ addon.status == 'cancelled' ? 'Cancelled' : 'Active' }}
            </div>
            <div class="w-1/5">
              {{ addon.created_at }}
              <!-- {{
                    addon.created_at.seconds
                      ? formatDate(addon.created_at.seconds * 1000)
                      : formatDate(addon.created_at)
                  }} -->
            </div>
          </div>
        </div>
      </v-card>
    </section>
    <v-dialog v-model="dialog" max-width="700px" persistent>
      <v-card class="pa-2">
        <v-card-title class="pt-3 pb-3 primary--text">
          Update Payment Details</v-card-title
        >
        <v-card-text>
          <stripe-payment-method
            @update="subscribeStripe()"
            :isDialog="true"
            @close="dialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEmailDialog" persistent max-width="400px">
      <dialog-card
        title="We need your email address to proceed"
        submitText="Continue"
        @close="showEmailDialog = false"
        @submit="saveUserEmailAddress()"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <input-field
              placeholder="Your email address"
              v-model="emailAddress"
              rules="email"
            />
          </v-col>
        </v-row>
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import PleaseWait from "./PleaseWait.vue";
import { colUsers, db, fly_addons, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import StripePaymentMethod from "../account/StripePaymentMethod.vue";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";
import InfiniteLoading from "vue-infinite-loading";

import moment from "moment";
export default {
  components: { PleaseWait, StripePaymentMethod, DialogCard, InputField },
  data() {
    return {
      addons: [],
      userData: {},
      cancelLoading: false,
      dialog: false,
      plans: [],
      currentSubscription: {},
      isLoading: true,
      fly_plan_valid_until: null,
      selectedPlan: null,
      addonForm: {},
      activeAddons: [],
      activeAddonForm: {},
      showEmailDialog: false,
      emailAddress: "",
      isSubscriptionCancelled: false,
      plan: null,
      subscription_id: "",
      addonId: "",
      historyExists: false,
      addonsHistory: [],
      tab: 0,
      loading: false,
      quarterlyPlans: ["lite_3m", "boost_3m", "plus_3m"],
      halfYearlyPlans: ["lite_6m", "boost_6m", "plus_6m"],
      yearlyPlans: ["lite_12m", "boost_12m", "plus_12m"],
      isSubscriptionActive: true,
      pollCount: 0,
      maxPollAttempts: 35,
    };
  },
  computed: {
    selectedTabName() {
      switch (this.tab) {
        case 0:
          return "Monthly";
        case 1:
          return "Quaterly";
        case 2:
          return "Half Yearly";
        case 3:
          return "Yearly";
      }
      return "";
    },
    isSignupPlan() {
      if (this.authUser.signup_offer_subscribed) {
        return true;
      }
    },
    planName() {
      let plan = this.authUser.fly_plan;
      if (plan == "lite") {
        return "Personal";
      } else if (plan == "boost") {
        return "Enterprise";
      } else if (plan == "plus") {
        return "Professional";
      }
      return "Free";
    },
    renewDate() {
      let date = null;
      date = this.authUser.plan_renew_date.seconds
        ? this.authUser.plan_renew_date.seconds
        : this.authUser.plan_renew_date;
      date = new Date(
        this.authUser.plan_renew_date.seconds * 1000
      ).toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return date;
    },
  },
  methods: {
    isAddonSubscribed({ id }) {
      let item = this.activeAddons.find((i) => i.id == id);
      // console.log('item:', item)
      // if(this.addonForm[id].type == "Monthly") {
      //   console.log('id, this.addonForm[id].type:', id, this.addonForm[id].type)
      // }
      return item && this.addonForm[id].type == "Monthly";
    },
    isActiveAddon({ id }) {
      let item = this.activeAddons.find((i) => i.id == id);
      return item != null;
    },
    getAddItems(item) {
      let { min, max, step } = item.constraints || {};
      try {
        let list = [];
        for (let i = min; i < max; i = i + step) {
          list.push(i);
        }
        return list;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    isActivePlan(plan, userData) {
      // let { status, plan_id } = this.currentSubscription;

      if (this.authUser.fly_plan == "free" && plan.name == "Free") {
        return true;
      }
      if (this.authUser.signup_offer_subscribed) {
        return false;
      }
      return plan.id == userData.fly_plan;
    },
    async fetchUserCountry() {
      try {
        const response = await fetch(
          "https://ipinfo.io/json?token=f8e9b91843be5c"
        );
        const data = await response.json();
        this.userCountry = data.country; // Corrected from country_code to country
        console.log(this.userCountry); // Ensure we're using this.userCountry for consistency
      } catch (error) {
        console.error("Error fetching user country:", error);
        this.userCountry = "US"; // Default to US if there's an error
      }
    },
    async initPlan() {
      const vm = this;
      try {
        vm.isLoading = true;
        await this.fetchAddons();
        await this.fetchUserCountry();
        if (this.userCountry === "IN") {
          if (this.quarterlyPlans.includes(this.authUser.fly_plan)) {
            // this.tab = 1;
            this.$set(this, "tab", 1);
          } else if (this.halfYearlyPlans.includes(this.authUser.fly_plan)) {
            // this.tab = 2;
            this.$set(this, "tab", 2);
          } else if (this.yearlyPlans.includes(this.authUser.fly_plan)) {
            // this.tab = 3;
            this.$set(this, "tab", 3);
          }
        }
        let userRef = doc(db, "users", vm.uid);
        let user = (await getDoc(userRef)).data();
        if (user.fly_subscription_cancelled) {
          this.isSubscriptionCancelled = user.fly_subscription_cancelled;
        }
        vm.userData = user;
        // let pricingField = "monthly_price_usd"; // Default to USD pricing
        // if (vm.userCountry === "IN") {
        //   pricingField = "monthly_price_inr"; // Use INR pricing for users in India
        // }
        // let planQuery = query(
        //   collection(db, "fly_plans"),
        //   orderBy(pricingField, "asc")
        // );
        // vm.plans = (await getDocs(planQuery)).docs.map((i) => ({
        //   ...i.data(),
        //   id: i.id,
        // }));
        let subscriptionRef = doc(collection(db, "fly_subscriptions"), vm.uid);
        vm.currentSubscription = (await getDoc(subscriptionRef)).data() || {};
        let addSubCol = collection(db, "users", this.uid, "addons");
        let activeAddonQuery = query(
          addSubCol,
          where("status", "==", "active"),
          where("type", "==", "monthly")
        );
        vm.activeAddons = (await getDocs(activeAddonQuery)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        vm.activeAddons.forEach((i) => {
          let addon = this.addons.find((a) => a.id == i.id);
          this.activeAddonForm[i.id] =
            i.qty * _.get(addon, "constraints.step", 1);
        });
        console.log(vm.activeAddons);
        // console.log("sub", vm.currentSubscription.plan_id);
        vm.isLoading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
    async fetchPlans() {
      const vm = this;
      let currentUID = vm.currentUID || vm.authUser.uid || vm.uid;
      if (!currentUID && !vm.WID) return;
      try {
        vm.loading = true;
        await this.fetchAddons();
        await this.fetchUserCountry();
        let userRef = doc(db, "users", vm.uid);
        let user = (await getDoc(userRef)).data();
        let col = collection(db, "fly_plans");
        if (user.fly_subscription_cancelled) {
          this.isSubscriptionCancelled = user.fly_subscription_cancelled;
        }
        vm.userData = user;
        let pricingField = "monthly_price_usd"; // Default to USD pricing
        if (vm.userCountry === "IN") {
          pricingField = "monthly_price_inr"; // Use INR pricing for users in India
        }
        let queries = [];
        if (vm.tab == 0) {
          queries.push(
            orderBy(pricingField, "asc"),
            where("type", "==", "monthly")
          );
        }
        if (vm.tab == 1) {
          queries.push(
            orderBy(pricingField, "asc"),
            where("type", "==", "quarterly")
          );
        }
        if (vm.tab == 2) {
          queries.push(
            orderBy(pricingField, "asc"),
            where("type", "==", "halfyearly")
          );
        }
        if (vm.tab == 3) {
          queries.push(
            orderBy(pricingField, "asc"),
            where("type", "==", "yearly")
          );
        }

        let q = query(col, ...queries);
        let docs = (await getDocs(q)).docs;
        console.log("Fetching post...", docs);
        let allPlans = docs.map((i) => {
          return {
            ...i.data(),
            id: i.id,
          };
        });
        vm.plans = allPlans;
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchAddons() {
      try {
        let q = query(fly_addons, orderBy("name", "asc"));
        let addons = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));

        addons.forEach((i) => {
          let { min } = i.constraints || {};
          this.$set(this.addonForm, i.id, {
            type: "",
            qty: min || 0,
          });
          if (i.is_monthly == true && i.is_onetime == true) {
            this.addonForm[i.id].type = "One Time";
          } else if (i.is_onetime == true) {
            this.addonForm[i.id].type = "One Time";
          } else if (i.is_monthly == true) {
            this.addonForm[i.id].type = "Monthly";
          }
        });
        this.addons = addons;
        return "";
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    calculateDiscountPrice({ monthly_price_inr }) {
      let discount = monthly_price_inr * 0.3;
      return Math.round(monthly_price_inr - discount);
    },
    openStripe(plan) {
      this.selectedPlan = { ...plan, type: "account" };
      if (!this.authUser.card_details) {
        this.dialog = true;
        return;
      }
      this.subscribeStripe();
    },
    subscribeAddon(plan) {
      this.$confirm.show({
        message: `Are you sure you wish to subscribe to ${plan.name} package?`,
        onConfirm: async () => {
          this.selectedPlan = { ...plan, type: "addon" };
          if (!this.isINR) {
            if (!this.authUser.card_details) {
              this.dialog = true;
              return;
            }
            this.subscribeStripe();
            return;
          } else {
            this.subscribeRazorpayAddon();
          }
        },
      });
    },
    async saveUserEmailAddress() {
      const vm = this;
      try {
        let userRef = doc(db, "users", vm.uid);
        await updateDoc(userRef, {
          email: vm.emailAddress,
        });
        vm.showEmailDialog = false;
        await this.subscribePlan(this.plan);
      } catch (error) {
        vm.handleError(error);
      }
    },
    async subscribePlan(plan) {
      const vm = this;
      if (!vm.isINR) {
        vm.openStripe(plan);
        return;
      }
      try {
        vm.$loader.show();
        let data = { plan: plan.id };

        let subscribe = httpsCallable(functions, "razorpaySubscribe");
        let result = (await subscribe(data)).data;
        if (result.status == "authenticate_payment") {
          vm.openRazorPay(result.subscription_id, "", "subscription");
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.handleError(error);
      }
    },
    async subscribe(plan) {
      const vm = this;
      vm.plan = plan;
      let userRef = doc(db, "users", vm.uid);
      let user = (await getDoc(userRef)).data();
      console.log("user:", user);
      if (!user.email || user.email == undefined) {
        vm.showEmailDialog = true;
        // vm.$alert.show("Please update your email address in your profile to proceed with subscription.");
        return;
      }

      vm.$confirm.show({
        message: `Are you sure you wish to subscribe to ${plan.name} package?`,
        onConfirm: async () => {
          await this.subscribePlan(plan);
        },
      });
    },

    isIndianPhoneNumber() {
      return this.userData.country_code && this.userData.country_code === "IN";
    },

    async upgradeAddon(item) {
      this.dialog = false;
      const vm = this;
      vm.addonId = item.id;
      vm.$confirm.show({
        message: `Are you sure you wish to subscribe to ${item.name} package?`,
        onConfirm: async () => {
          try {
            vm.$loader.show();
            if (!this.isINR) {
              let subscribe = httpsCallable(functions, "stripeSubscribe");
              let request = {
                id: item.id,
                type: "addon",
                isINR: vm.isINR,
                qty: this.activeAddonForm[item.id],
                addon_type: "Monthly",
              };
              let result = (await subscribe(request)).data;
              if (result.type == "confirm_payment") {
                const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
                let { error } = await stripe.confirmCardPayment(
                  result.client_secret
                );
                if (error) {
                  vm.$alert.show("Error in payment, please try again later");
                }
              }
            } else {
              let subscribe = httpsCallable(functions, "razorpaySubscribe");
              let data = {
                id: item.id,
                type: "addon",
                handle: "razorpay",
                quantity: this.activeAddonForm[item.id],
                addon_type: "Monthly",
              };
              let result = (await subscribe(data)).data;
              // console.log('result: ', result);
              if (result.status == "authenticate_payment") {
                await vm.openRazorPay(
                  result.subscription_id,
                  "Monthly",
                  "razorpay_addon"
                );
              }
            }
            // await this.sleep(10000);
            // this.initPlan();
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
      });

      // console.log(this.selectedPlan);
    },
    async subscribeRazorpayAddon() {
      this.dialog = false;
      const vm = this;
      try {
        vm.$loader.show();
        let subscribe = httpsCallable(functions, "razorpaySubscribe");
        let data = {
          id: this.selectedPlan.id,
          type: this.selectedPlan.type,
          handle: "razorpay",
          quantity: this.addonForm[this.selectedPlan.id].qty,
          addon_type: this.addonForm[this.selectedPlan.id].type,
        };
        this.addonId = this.selectedPlan.id;
        let recurring = this.addonForm[this.selectedPlan.id].type;
        // let amount = this.addonForm[this.selectedPlan.id].qty *
        let result = (await subscribe(data)).data;
        this.subscription_id = result.subscription_id;
        if (result.status == "authenticate_payment") {
          await vm.openRazorPay(
            result.subscription_id,
            recurring,
            "razorpay_addon"
          );
        }
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    async subscribeStripe() {
      this.dialog = false;
      const vm = this;
      try {
        vm.$loader.show();
        let subscribe = httpsCallable(functions, "stripeSubscribe");
        let request = {
          id: this.selectedPlan.id,
          type: this.selectedPlan.type,
        };
        if (this.selectedPlan.type == "addon") {
          request.qty = this.addonForm[this.selectedPlan.id].qty || 1;
          request.addon_type = this.addonForm[this.selectedPlan.id].type;
        }
        let result = (await subscribe(request)).data;
        if (result.type == "confirm_payment") {
          const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
          let { error } = await stripe.confirmCardPayment(result.client_secret);
          if (error) {
            vm.$alert.show("Error in payment, please try again later");
          }
          //  else {
          //   let verify = httpsCallable(functions, "verifyStripePayment");
          //   await verify({ id: result.id });
          // }
        }
        await this.sleep(10000);
        this.initPlan();
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }

      // console.log(this.selectedPlan);
    },
    sleep(s) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, s || 1000);
      });
    },
    openRazorPay(id, recurring, type) {
      const vm = this;
      var options = {
        key: process.env.VUE_APP_RP_KEY,
        subscription_id: id,
        name: "ZoomLocal",
        description: recurring ? recurring : "Monthly Plan",
        handler: function (response) {
          vm.handlePayment(response, type);
          vm.planSubscribed();
          // console.log('response', JSON.stringify(response));
          // Redirect to /account page after successful payment
          // vm.$router.push("/account"); // If using Vue Router
          // Or use window.location for a hard redirect:
          // window.location.href = '/account';
        },
        prefill: {
          name: this.authUser.display_name,
          email: this.authUser.email,
          //contact: this.authUser.phone_number,
        },
        theme: {
          color: "#1773EA",
        },
      };

      // Code to open Razorpay payment modal
      var rzp = new Razorpay(options);
      rzp.open();
    },
    async handlePayment(json, type) {
      const vm = this;
      try {
        vm.$loader.show();
        let userRef = doc(db, "users", vm.uid);
        let addonId = this.addonId;
        let subscribe = httpsCallable(functions, "validateRazorpayPayment");
        (await subscribe({ json, type, addonId })).data;
        await updateDoc(userRef, {
          fly_subscription_cancelled: false,
        });
        this.initPlan();
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    async cancelAddon(item) {
      this.$confirm.show({
        message: "Are you sure you want to cancel?",
        onConfirm: async () => {
          try {
            this.$loader.show();
            let addonSub = this.activeAddons.find((i) => i.id == item.id);
            let functionName = "razorpaySubscribe";
            if (addonSub.payment_gateway == "stripe") {
              functionName = "stripeSubscribe";
            }
            let data = {
              subscription: addonSub.subscription_id,
              type: "addon",
              addonId: item.id,
            };
            let subscribe = httpsCallable(functions, functionName);
            (await subscribe(data)).data;
            location.reload();
            this.$loader.hide();
          } catch (error) {
            this.cancelLoading = false;
            this.$loader.hide();
            this.$alert.show(error.message);
          }
        },
      });
    },
    async cancelSubscription() {
      this.$confirm.show({
        message: "Are you sure you want to cancel?",
        onConfirm: async () => {
          try {
            let userRef = doc(db, "users", this.uid);
            this.cancelLoading = true;
            await updateDoc(userRef, {
              fly_subscription_cancelled: true,
            });
            let subscriptionRef = doc(
              collection(db, "fly_subscriptions"),
              this.uid
            );
            let currentSubscription =
              (await getDoc(subscriptionRef)).data() || {};
            console.log(currentSubscription);
            let functionName = "razorpaySubscribe";
            if (currentSubscription.payment_gateway == "stripe") {
              functionName = "stripeSubscribe";
            }
            let data = { subscription: currentSubscription.subscription_id };
            let subscribe = httpsCallable(functions, functionName);
            (await subscribe(data)).data;

            location.reload();
            this.isSubscriptionCancelled = true;
            this.cancelLoading = false;
          } catch (error) {
            this.cancelLoading = false;
            this.$alert.show(error.message);
          }
        },
      });
    },
    async planSubscribed() {
      try {
        if (this.pollCount < this.maxPollAttempts) {
          const subscriptionRef = doc(db, "fly_subscriptions", this.uid);
          const subscriptionSnap = await getDoc(subscriptionRef);
          if (subscriptionSnap.exists()) {
            const subscriptionData = subscriptionSnap.data();

            if (subscriptionData.status == "active") {
              if (!this.isSubscriptionActive) {
                location.reload();  // Reload the page once status is 'active
              }
            } else {
              this.isSubscriptionActive = false;
              // Increment the poll count
              this.pollCount++;
              // If status is not 'active' and max attempts not reached, keep polling
              setTimeout(this.planSubscribed, 5000); // Poll every 5 seconds
            }
          } else {
            console.error("Subscription data not found");
          }
        } else {
          console.log("Max polling attempts reached, stopping poll");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAddonsHistory() {
      try {
        const addSubCol = query(
          collection(db, "users", this.uid, "addons_history"),
          orderBy("created_at", "desc") // Fetch documents already sorted by updated_at
        );
        const querySnapshot = await getDocs(addSubCol);

        if (!querySnapshot.empty) {
          this.$set(this, "historyExists", true);

          this.addonsHistory = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const date = moment(
              new Date(data.created_at.seconds * 1000)
            ).format("MM/DD/YYYY");
            const updatedDate = moment(
              new Date(data.updated_at.seconds * 1000)
            ).format("MM/DD/YYYY");

            return {
              type: data.type,
              name: data.add_name,
              quantity: data.credit,
              amount: data.amount,
              id: doc.id,
              addon_id: data.addon_id,
              created_at: date,
              updated_at: updatedDate,
              status: data.status,
            };
          });
          // console.log("this.addonsHistory::", this.addonsHistory);
        } else {
          // Set historyExists to false if the collection is empty
          this.$set(this, "historyExists", false);
        }
      } catch (error) {
        console.error(error);
        this.$set(this, "historyExists", false);
      }
    },
  },
  mounted() {
    this.initPlan();
    this.fetchPlans();
    this.planSubscribed();
    this.fetchAddonsHistory();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.addon-input {
  .v-input__slot {
    background: #f2f6fa !important;
    border-radius: 50px !important;
  }
}
.list-view-container {
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container > .flex {
    min-width: 700px;
    flex-wrap: nowrap;
  }
}
.list-view-container .flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list-view-container .flex > div {
  flex: 1;
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container .flex {
    min-width: 800px; /* Increase this if necessary to prevent overlapping */
  }

  .list-view-container .flex > div {
    padding: 4px; /* Adjust padding to manage space */
    font-size: 14px; /* You may adjust font size for better visibility */
  }
}

/* Ensure there's no overlap by adding specific flex-basis values */
.list-view-container .flex > div:nth-child(1) {
  flex-basis: 18%; /* Adjust percentage based on your content needs */
}

.list-view-container .flex > div:nth-child(2),
.list-view-container .flex > div:nth-child(3),
.list-view-container .flex > div:nth-child(4),
.list-view-container .flex > div:nth-child(5) {
  flex-basis: 14%; /* Smaller flex basis for other columns */
}
</style>
