<template>
    <v-card outlined class="mb-3">
      <v-card-text>
        <div class="subtitle-1 black--text font-weight-medium d-flex align-center">
          <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
          Pinterest Settings
        </div>
        <div class="px-7 py-3">
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <select-field
                label="Board"
                @input="$emit('input', form)"
                v-model="form.board"
                :items="boards"
              ></select-field>
            </v-col>
            <v-col v-if="isVideoSelected" cols="12" md="8" class="py-0">
              <!-- <v-btn
                depressed
                class="primary"
                height="40px"
                @click="$refs.image.click()"
                variant="outlined"
              >
                <v-icon>mdi-image</v-icon>
                <span class=""> Add Cover Image </span>
              </v-btn> -->
              <div class="flex gap-3 items-center">
              <div class="flex flex-col gap-2">
                <span class="text-[14px] font-medium">Add Cover Image (Mandatory)</span>
                <div class="flex flex-row gap-2 items-center">
                  <span class="h-[30px] w-[30px] border-2 my-1 mr-2">
                    <v-icon @click="$refs.image.click()">mdi-plus</v-icon>
                  </span>
                </div>
              </div>
            </div>
              <input
                accept="image/*"
                type="file"
                class="d-none"
                ref="image"
                @input="$emit('input', form)"
                @change="onImageChange"
              />
            </v-col>
            <v-col
              v-if="form.cover_image_url || coverimage"
              cols="12"
              class="py-2 px-3 d-flex flex-col gap-2"
            >
              <div class="d-flex w-1/2 flex-row justify-between">
                <span>{{ form.cover_image_url?.name }}</span>
                <v-icon @click="removeThumb" class="cursor-pointer" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <v-img class="w-1/2" :src="coverimage"></v-img>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import SelectField from "@/components/ui/form/SelectField.vue";
  
  export default {
    components: { SelectField },
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      boards: Array,
      configuration: {
        type: Object,
      },
      isEditing: Boolean,
      isRepost: Boolean,
      isDraftPost: Boolean,
      isVideoSelected: Boolean,
    },
    data() {
      return {
        form: {
          board: [],
          cover_image_url: null,
        },
        coverimage: "",
        coverimagechanged:false,
        coverimagerenmoved:false,
      };
    },
    computed: {
      platform() {
       
        let platforms = this.$store.getters.flyPlatforms;
        let platform = platforms.find((i) => i.alias == "pinterest");
        return platform || {};
      },
    },
    methods: {
      initForm() {
       
        Object.keys(this.form).forEach((key) => {
          if (this.value[key] !== undefined) {
            this.form[key] = this.value[key];
          }
        });
        if (this.boards && this.boards.length === 1) {
      this.form.board = this.boards[0].value;
      this.$emit("input", this.form);
    }
      },
      updateConfiguration() {
        
        this.form.board = this.configuration.board;
        this.form.cover_image_url = this.configuration.cover_image_url;
        this.coverimage = this.configuration.cover_image_url;
      },
      onImageChange(e) {
        const vm = this;
        let image = e.target.files[0];
        this.$emit("coverimageremoved", false);
        vm.$refs.image.value = null;
        if (image) {
          this.$emit("coverimageremoved", false);
          this.coverimagechanged=true
          this.form.cover_image_url = image;
          this.coverimage = URL.createObjectURL(image);
          if (image instanceof File) {
          var reader = new FileReader();
          reader.onload = function (e) {
            vm.coverimage = e.target.result;
          };
          if (this.isRepost || this.isEditing || vm.isDraftPost) {
           
            this.$emit("coverimagechanges", vm.coverimage);
          }
          reader.readAsDataURL(image);
        }
        this.$emit("input", this.form)
        }
      },
      removeThumb() {
        this.form.cover_image_url = null;
        this.coverimage = "";
        this.$emit("coverimageremoved", true);
      },
    },
    beforeMount() {
        this.initForm();
      if (this.isEditing || this.isRepost || this.isDraftPost) {
        this.updateConfiguration();
      }
      
    },
  };
  </script>
  
  <style></style>