// clarityPlugin.js
const clarityPlugin = {
    install(Vue, options) {
      // Inject the script into the HTML document's head
      const clarityScript = document.createElement('script');
      clarityScript.type = 'text/javascript';
      clarityScript.async = true;
      clarityScript.src = 'https://www.clarity.ms/tag/' + options.siteId;
      document.head.appendChild(clarityScript);
      // console.log("Running at each page");
  
      // Vue Router navigation guard to execute the script on each page navigation
      Vue.mixin({
        beforeRouteEnter(to, from, next) {
          next(vm => {
            // Script is already injected, no need to do anything here
          });
        },
        beforeRouteUpdate(to, from, next) {
          // Script is already injected, no need to do anything here
          next();
        }
      });
    }
  };
  
  export default clarityPlugin;
  