<template>
  <div class="" style="font-family: 'Inter', sans-serif">
    <section
      id="mySwiper"
      class="mt-[170px] px-4 lg:hidden overflow-y-scroll flex flex-col gap-4"
    >
      <div
        class="w-full swiper-wrapper responsive-container scrollable-container"
        id="custom-scroll"
      >
        <div
          v-for="(feeds, index) in pagePosts"
          :key="index"
          class="overflow-x-hidden swiper-slide md:overflow-y-scroll responsive-container scrollable-container"
          id="custom-scroll"
        >
          <div class="posts">
            <feed-card
              v-for="(post, i) in feeds.feeds"
              :post="post"
              :postId="post.id"
              :platform="feeds.title.toLowerCase()"
              :logo_url="feeds.logo_url"
              :key="i"
            ></feed-card>
          </div>
        </div>
      </div>
      <div
        class="w-[30px] hidden md:block h-[30px] rounded-full swiper-button-next"
      >
        <div class="wrapper">
          <div class="video-main">
            <div class="promo-video">
              <div class="waves-block">
                <div class="waves wave-1"></div>
                <div class="waves wave-2"></div>
                <div class="waves wave-3"></div>
              </div>
            </div>
            <a class="video" data-lity><i class="fa fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <div
        class="w-[30px] hidden md:block h-[30px] bg-blue-500 rounded-full swiper-button-prev"
      >
        <div class="wrapper2">
          <div class="video-main2">
            <div class="promo-video2">
              <div class="waves-block2">
                <div class="waves wave-12"></div>
                <div class="waves wave-22"></div>
                <div class="waves wave-32"></div>
              </div>
            </div>
            <a class="video2" data-lity><i class="fa fa-arrow-left"></i></a>
          </div>
        </div>
      </div>
    </section>
    <section class="fixed gap-2 flex flex-col w-full z-[999] top-16 bg-white">
      <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 rtl:text-right">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="px-3 py-1.5 md:py-3">Channel</th>

              <th scope="col" class="px-3 py-1.5 md:py-3">Total FOLLOWERS</th>
              <th scope="col" class="px-3 py-1.5 md:py-3">New FOLLOWERS</th>
              <th scope="col" class="px-3 py-1.5 md:py-3">NO.OF POSTS</th>
              <th scope="col" class="px-3 py-1.5 md:py-3">REACH</th>
              <th scope="col" class="px-3 py-1.5 md:py-3">ENGAGEMENT</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(metric, index) in metrics"
              :key="index"
              v-show="showAll || index < 2"
              class="bg-white border-b"
            >
              <th
                scope="row"
                class="flex items-center gap-2 px-3 py-2 font-medium text-gray-900 md:px-6 md:py-4 whitespace-nowrap"
              >
                <img :src="metric.logo_url" class="w-4" alt="" />

                {{ metric.name }}
              </th>

              <td class="px-3 py-2 md:px-6 md:py-4">
                {{ metric.followers }}
              </td>

              <td class="px-3 py-2 md:px-6 md:py-4">
                {{
                  metric.new_followers === undefined ||
                  metric.new_followers.last_30d === undefined
                    ? metric.new_followers
                    : metric.new_followers.last_30d
                }}
              </td>
              <td class="px-3 py-2 md:px-6 md:py-4">
                {{
                  metric.total_post !== undefined
                    ? metric.total_post
                    : metric.total_posts
                }}
              </td>
              <td class="px-3 py-2 md:px-6 md:py-4">
                {{ metric.reach }}
              </td>
              <td class="px-3 py-2 md:px-6 md:py-4">
                {{ metric.engagement?.last_30d }}
                <span class="text-gray-500">
                  <span :class="metric.engagement?.change < 0 ? 'hidden' : ''">
                    <v-icon size="18" color="green">mdi-arrow-up</v-icon>
                  </span>

                  <span :class="metric.engagement?.change > 0 ? 'hidden' : ''">
                    <v-icon size="18" color="red">mdi-arrow-down</v-icon>
                  </span>
                  {{ metric.engagement?.change }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="py-4 absolute flex justify-center items-center w-full -bottom-8 z-[99999] text-center"
      >
        <button
          class="p-1 text-white bg-[#8056de] rounded-full hover:bg-[#a38bd6]"
          @click="showAll = !showAll"
        >
          <transition name="fade" mode="out-in">
            <v-icon key="down" v-if="!showAll" color="white"
              >mdi-arrow-down</v-icon
            >

            <v-icon key="up" v-if="showAll" color="white">mdi-arrow-up</v-icon>
          </transition>
        </button>
      </div>
    </section>

    <section
      class="mt-[120px] md:flex-row flex-col gap-2 hidden md:px-2 lg:flex"
    >
      <div id="mySwiper" class="flex items-center justify-center">
        <div class="swiper-wrapper">
          <div
            v-for="(feeds, index) in pagePosts"
            :key="index"
            class="w-[367px] swiper-slide md:overflow-y-scroll overflow-x-hidden responsive-container scrollable-container"
            id="custom-scroll"
          >
            <div class="flex items-center gap-1 ml-2">
              <img :src="feeds.logo_url" class="w-6" alt="" />

              <p class="text-sm font-normal mt-4 text-[#007E85]">
                {{ feeds.title }}
              </p>
            </div>
            <div class="posts">
              <feed-card
                v-for="(post, i) in feeds.feeds"
                :post="post"
                :postId="post.id"
                :platform="feeds.title.toLowerCase()"
                :logo_url="feeds.logo_url"
                :key="i"
              ></feed-card>
            </div>
          </div>
        </div>
        <div class="w-[30px] h-[30px] rounded-full swiper-button-next">
          <div class="wrapper">
            <div class="video-main">
              <div class="promo-video">
                <div class="waves-block">
                  <div class="waves wave-1"></div>
                  <div class="waves wave-2"></div>
                  <div class="waves wave-3"></div>
                </div>
              </div>
              <a class="video" data-lity><i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div
          class="w-[30px] h-[30px] bg-blue-500 rounded-full swiper-button-prev"
        >
          <div class="wrapper2">
            <div class="video-main2">
              <div class="promo-video2">
                <div class="waves-block2">
                  <div class="waves wave-12"></div>
                  <div class="waves wave-22"></div>
                  <div class="waves wave-32"></div>
                </div>
              </div>
              <a class="video2" data-lity><i class="fa fa-arrow-left"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions, platforms } from "@/utils/firebase.utils";
import FeedCard from "@/components/post/FeedCard.vue";
import { auth, db } from "@/utils/firebase.utils";
import {
  collection,
  doc,
  docs,
  getDoc,
  getDocs,
  getDocsFromCache,
  orderBy,
  query,
} from "firebase/firestore";
export default {
  components: {
    FeedCard,
  },
  data() {
    return {
      showAll: false,
      metrics: [],
      openedPostIndex: 0,
      openedPostIndex2: 0,
      pagePosts: [],
      showAll: true,
      processedData: [],
    };
  },

  mounted() {
    this.feeds();
    this.userCollections();
  },
  methods: {
    async userCollections() {
      const channelCollection = collection(
        db,
        "users",
        auth.currentUser.uid,
        "fly_workspaces",
        this.WID,
        "connected-apps"
      );
      const channelSnapshot = await getDocs(channelCollection);
      const channelData = [];
      channelSnapshot.docs.forEach((doc) => {
        channelData.push({ id: doc.id, ...doc.data() });
      });

      // console.log("Channel Data:", channelData);

      channelData.forEach(async (channel) => {
        switch (channel.id) {
          case "facebook":
            channel.pages.map((page) => {
              this.processedData.push({
                id: page.id,
                name: page.name,
                platform: channel.id,
              });
            });
            break;
          case "instagram":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
            });
            break;

          case "pinterest":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
            });
            break;

          case "reddit":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
            });
            break;

          case "tumblr":
            channel.blogs.map((blog) => {
              this.processedData.push({
                name: blog.name,
                platform: channel.id,
              });
            });
            break;

          case "twitter":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
            });
            break;

          case "youtube":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
            });
            break;

          default:
        }
      });
      this.processedData.map(async (data) => {
        let result = await this.getMetrics(data);

        if (result) {
          this.metrics.push({ name: data.name, ...result.data });
        }
      });
    },
    async getMetrics(data) {
      try {
        let payload = { uid: this.uid, platform: data.platform };
        if (data.platform === "tumblr") {
          payload.blog = data.name;
        }
        if (data.platform === "facebook") {
          payload.pageId = data.id;
        }

        let subscribe = httpsCallable(functions, "fetchPlatformMetrics");
        let result = await subscribe(payload);

        return result;
      } catch (error) {
        console.error("Error fetching platform metrics:", error);
      }
    },

    async feeds() {
      let data = { uid: this.uid };
      let subscribe = httpsCallable(functions, "fetchUniversalfeeds");
      let result = (await subscribe(data)).data;
      this.pagePosts = result.result;
      this.initializeSwiper();
    },
    initializeSwiper() {
      this.$nextTick(() => {
        new window.Swiper("#mySwiper", {
          spaceBetween: 16,
          slidesPerView: "auto",

          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: "1",
              spaceBetween: 8,
              centerslide: true,
            },
            768: {
              slidesPerView: "2",
              spaceBetween: 8,
              centerslide: true,
            },
            1024: {
              slidesPerView: "3",
              spaceBetween: 10,
              centerslide: true,
            },
            1440: {
              slidesPerView: "3.7",
              centerslide: true,
            },
          },
        });
      });
    },
    openPost(index) {
      this.openedPostIndex = index;
    },
    openPost2(index) {
      this.openedPostIndex2 = index;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.responsive-container {
  max-height: 700px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .responsive-container {
    max-height: 100%;
  }
}
#custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  display: none;
}

#custom-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  display: none;
}

#custom-scroll::-webkit-scrollbar-thumb {
  background-color: #8056d6;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #8056de;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-main {
  position: relative;
  display: inline-block;
}

.video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #a38bd6;

  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.waves2 {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #8056de;

  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-12 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-22 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-32 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.wrapper2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-main2 {
  position: relative;
  display: inline-block;
}

.video2 {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #a38bd6;

  z-index: 999;
}
</style>
