<template>
  <div class="max-w-[1400px] w-[98%] mt-4">
  <v-card >
    <v-card-title class="text-black flex justify-between items-center">
      Posts
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="paginatedItems"
      :search="search"
      item-key="postId"
      class="elevation-1"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50],
        'items-per-page-text': 'Rows per page:',
      }"
      :loading="loading"
    >
      <template v-slot:header.likes>
        <v-icon>mdi-heart</v-icon>
      </template>
      <template v-slot:header.comments>
        <v-icon>mdi-comment</v-icon>
      </template>
      <template v-slot:header.shares>
        <v-icon>mdi-share</v-icon>
      </template>
      <template v-if="loading">
        <tr>
          <td :colspan="headers.length" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </td>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-img
              :src="getImage(item)"
              height="60"
              width="60"
              class="square-image my-5 cursor-pointer"
              @mouseover="openImagePreview($event, getImage(item))" @mouseleave="closeImagePreview"
            />
          </td>
          <td class="align-top">
            <div class="flex flex-col gap-1 mt-5">
              <div class="flex items-center gap-2">
                <div class="flex-shrink-0 rounded-lg">
                  <v-img :src="item.logo_url" width="22" height="22"></v-img>
                </div>
                <div>
                  <p class="font-bold align-bottom text-gray-800">
                    {{ formatDate(item.date) }}
                  </p>
                  <div class="flex items-center gap-1">
                    <a
                      :href="item.post_url"
                      target="_blank"
                      class="username no-underline text-xs font-semi text-gray-800 align-top"
                    >
                      @{{ item.profile_name }}
                    </a>
                    <v-icon
                      small
                      class="cursor-pointer"
                      @click="goToLink(item.post_url)"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </div>
                </div>
              </div>
              <p class="">{{ truncateText(item.content, 100) }}</p>
            </div>
          </td>
          <td class="text-center">
            <span>{{ item.engagement }}</span>
          </td>
          <td class="text-center">
            {{ item.likes }}
          </td>
          <td class="text-center">
            {{ item.comments }}
          </td>
          <td class="text-center">
            {{ item.shares }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Image Preview Container -->
    
  </v-card>
  <div v-if="showImagePreview" class="preview-box" :style="boxStyle">
            <img :src="selectedImageUrl" class="preview-image" />
          </div>
        </div>
</template>

  
<script>
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "@/utils/firebase.utils.js";
import moment from "moment";

export default {
  props: {
    postData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: "Post", value: "post", sortable: false },
        { text: "Content", value: "content", sortable: false },
        { text: "Engagement", value: "engagement", sortable: false },
        { text: "Likes", value: "likes", sortable: false },
        { text: "Comments", value: "comments", sortable: false },
        { text: "Shares", value: "shares", sortable: false },
      ],
      search: "",
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      platforms: [], // To store platform details
      loading: true, // Loading state
      showImagePreview: false, // State for image preview
      selectedImageUrl: "", // URL of the selected image for preview
      boxStyle: {}, // Style for positioning the image preview
    };
  },
  computed: {
    filteredItems() {
      return this.enrichPosts(this.postData).map((post) => {
        const postId = post.post_id || post.unique_id || post.id || "undefined";
        return {
          postId,
          date: post.created_at,
          username: post.username,
          profile_name: post.profile_name,
          content: post.text,
          post_url: post.post_url,
          engagement: this.calculatePostEngagement(post),
          likes: parseInt(post.likes, 10) || 0,
          comments: parseInt(post.comments || post.comment, 10) || 0,
          shares: parseInt(post.shares, 10) || 0,
          image: post.images[0] || (post.videos && post.videos[0] ? post.videos[0].thumbnail : ""),
          logo_url: post.platformDetails ? post.platformDetails.logo_url : "",
        };
      });
    },
    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
  },
  methods: {
    async fetchAllPlatforms() {
      const platformsRef = collection(db, "fly_platforms"); // Adjust the collection path if necessary
      const snapshot = await getDocs(platformsRef);
      let platforms = [];
      snapshot.forEach((doc) => {
        platforms.push({ id: doc.id, ...doc.data() });
      });
      return platforms;
    },
    async fetchPosts() {
      const userId = auth.currentUser.uid;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ensure starting from the beginning of today

      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      this.platforms = await this.fetchAllPlatforms();

      this.posts = {};

      for (let d = new Date(today); d <= endOfMonth; d.setDate(d.getDate() + 1)) {
        const dateStr = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`; // Ensuring proper ISO string format
        const postsRef = collection(db, "users", userId, "fly_workspaces", this.WID, "calendar", dateStr, "posts");

        try {
          const querySnapshot = await getDocs(postsRef);
          if (!querySnapshot.empty) {
            const postsWithPlatformData = querySnapshot.docs.map((doc) => {
              let postData = { id: doc.id, ...doc.data() };

              // Enrich posts with platform details
              if (postData.social_media) {
                postData.platformDetails = postData.social_media.map((media) => {
                  const platform = this.platforms.find((p) => p.alias === media);
                  return platform ? {
                    name: media,
                    label: platform.name,
                    logo_url: platform.logo_url,
                  } : { name: media, label: "Unknown", logo_url: "" };
                });
              }

              return postData;
            });

            this.posts[dateStr] = postsWithPlatformData;
          }
        } catch (error) {
          console.error("Error fetching posts for", dateStr, ":", error);
        }
      }
      setTimeout(() => {
        this.loading = false; // Set loading to false after 4 seconds
      }, 6000);
    },
    enrichPosts(posts) {
      return posts.flat().map((post) => {
        const platform = this.platforms.find((p) => p.alias === post.platform);
        if (platform) {
          post.platformDetails = {
            name: platform.alias,
            label: platform.name,
            logo_url: platform.logo_url,
          };
        } else {
          post.platformDetails = {
            name: post.platform,
            label: "Unknown",
            logo_url: "",
          };
        }
        return post;
      });
    },
    getImage(item) {
  if (item.image) {
    return item.image;
  }
  if (item.videos && item.videos[0] && item.videos[0].thumbnail) {
    return item.videos[0].thumbnail;
  }
  // Return the external URL as the default image
  return "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg";
},
    formatDate(date) {
      return moment(date).format("MMM DD, HH:mm");
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    goToLink(url) {
      window.open(url, "_blank");
    },
    calculatePostEngagement(post) {
      const likes = parseInt(post.likes, 10) || 0;
      const comments = parseInt(post.comments || post.comment, 10) || 0;
      const shares = parseInt(post.shares, 10) || 0;
      const views = parseInt(post.views, 10) || 0;
      const replies = parseInt(post.replies, 10) || 0;
      const retweet = parseInt(post.retweet, 10) || 0;
      const quote = parseInt(post.quote, 10) || 0;
      const dislikes = parseInt(post.dislikes, 10) || 0;

      const totalEngagement = likes + comments + shares + views + replies + retweet + quote + dislikes;

      return totalEngagement;
    },
    openImagePreview(event, imageUrl) {
      const { clientX } = event;
      const rowElement = event.target.closest('tr');
      const rowRect = rowElement.getBoundingClientRect();
      this.selectedImageUrl = imageUrl;
      this.boxStyle = {
        top: `${rowRect.top + window.scrollY - 30}px`,
        left: `${clientX - clientX / 2.7}px`
      };
      this.showImagePreview = true;
    },
    closeImagePreview() {
      this.showImagePreview = false;
    },
  },
  created() {
    this.fetchPosts();
  },
  watch: {
    filteredItems(val) {
      this.totalItems = val.length;
    },
    itemsPerPage() {
      this.page = 1; // Reset page number to 1 when items per page changes
    },
  },
};
</script>

  
<style scoped>
.square-image {
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer !important;
}

.username {
  color: #3b5998;
  text-decoration: none;
}

.font-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.preview-box {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
}

.preview-image {
  max-width: 300px;
  max-height: 300px;
}
</style>

  