<template>
    <div>
    <v-card class="pa-2" height="100%" >
      <v-card-title class="primary--text pb-3 pt-3 subtitle-1" >
        <v-avatar size="30" class="mr-3">
            <v-img :src="media.logo_url" />
          </v-avatar>
        Connect your {{ media.name }} Account
        <v-spacer />
      </v-card-title>
      <v-divider></v-divider>
      <div>
      <v-card-text class="pa-md-10 pa-3 mx-auto">
        <v-row>
          <v-col cols="12" md="6">
            <iframe
              class="mt-5"
              width="100%"
              v-if="media.intro_video"
              :height="isMobile ? '200px' : '500px'"
              :src=media.intro_video
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </v-col>
          <v-col cols="12" md="6">
            <div
              class="ql-editor mt-1"
              v-if="media.intro_content"
              v-html="media.intro_content"
            ></div>
          </v-col>
        </v-row>
      </v-card-text>
      </div>
    </v-card>
</div>
  </template>
  
  <script>
  import { platforms } from "@/utils/firebase.utils.js";
  import { getDocs } from "firebase/firestore";
  import { onAuthStateChanged } from "firebase/auth";
  import { auth } from "@/utils/firebase.utils";
  
  export default {
    name: "Help",
    data() {
      return {
        isLoggedIn: false,
        socialAppLists: [],
        isMobile: false,
       media: {},
      };
    },
    methods: {
      async getAllPlatforms() {
        const platformsRef = await getDocs(platforms);
        let allPlatforms = platformsRef.docs.map((doc) => doc.data());
        allPlatforms.sort((a, b) => a.display_priority - b.display_priority);
        this.socialAppLists = allPlatforms;
        if (allPlatforms.length > 0) {
          this.media = allPlatforms[2];
        }
      },
      setMedia(media) {
      this.media = media;
    },
      
    },
    mounted() {
      this.getAllPlatforms();
  
      onAuthStateChanged(auth, (user) => {
        this.isLoggedIn = !!user;
      });
  
      // Check for mobile device
      this.isMobile = window.innerWidth <= 768;
      window.addEventListener("resize", () => {
        this.isMobile = window.innerWidth <= 768;
      });
    },
  };
  </script>