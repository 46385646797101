<template>
  <v-dialog v-model="dialog" max-width="450px">
    <dialog-card
      title="Add Profile"
      @submit="submit()"
      @close="dialog = false"
      :loading="isLoading"
    >
      <input-field label="Profile Name" v-model="name" rules="required" />
    </dialog-card>
  </v-dialog>
</template>

<script>
import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
import { colUsers } from "@/utils/firebase.utils";
import { getDate } from "@/utils/common.utils";
export default {
  components: { DialogCard, InputField },
  data() {
    return {
      dialog: false,
      name: "",
      isLoading: false,
    };
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true;
        let name_lowercase = this.name.trim().toLowerCase();

        let profileCollection = collection(
          colUsers,
          this.uid,
          "social-profiles"
        );

        let profileQuery = query(
          profileCollection,
          where("name_lowercase", "==", name_lowercase),
          limit(1)
        );

        let size = (await getDocs(profileQuery)).size;
        if (size > 0) {
          this.$alert.show("Profile name must be unique");
          this.isLoading = false;
          return;
        }
        await addDoc(profileCollection, {
          name: this.name,
          name_lowercase,
          created_at: getDate(),
          updated_at: getDate(),
        });
        this.isLoading = false;
        this.dialog = false;
        this.name = "";
        this.$emit("onNewProfile");
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
