<template>
  <v-container style="max-width: 1000px">
    <please-wait v-if="loading"></please-wait>
    <v-card v-else outlined>
      <v-list class="py-0" v-for="(item, i) in list" :key="i">
        <v-list-item>
          <v-list-item-avatar tile>
            <img width="48" height="48" :src="item.logo_url" :alt="item.name" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="d-flex flex-row align-center">
            <v-btn icon @click="$router.push(`/update-help-cms/${item.id}`)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="!(list.length - 1 == i)"></v-divider>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { getDocs } from "firebase/firestore";
import { platforms } from "@/utils/firebase.utils";
export default {
  components: { PleaseWait },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  methods: {
    async fetchSocialHandles() {
      const vm = this;
      try {
        vm.loading = true;
        const snap = await getDocs(platforms);
        vm.list = snap.docs.map((i) => ({ ...i.data(), id: i.id }));
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchSocialHandles();
  },
};
</script>

<style></style>
