<template>
  <div class="flex gap-0 bg-slate-100 max-md:flex-wrap">
    <div class="flex flex-col flex-1 max-md:max-w-full">
      <div
        class="flex flex-col items-start self-center pt-7 pr-20 pb-12 pl-6 mt-8 w-full bg-white rounded-3xl max-w-[1285px] max-md:px-5 max-md:max-w-full"
      >
        <div
          class="flex gap-4 text-2xl font-medium whitespace-nowrap text-neutral-800"
        >
          <img
            loading="lazy"
            src="@/assets/img/brand.svg"
            class="aspect-[0.95] w-[21px]"
          />
          <div class="grow text-xs sm:text-sm md:text-[22px]">
            Brand & Business Information
          </div>
        </div>
        <v-stepper v-model="step" :vertical="false" class="w-full" flat>
          <v-stepper-header flat class="elevation-0">
            <v-stepper-step
              editable
              flat
              :complete="step > 1"
              step="1"
              color="#8056DE"
            >
              Brand Identity</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              editable
              color="#8056DE"
              :complete="step > 2"
              step="2"
              >Brand Essentials</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              editable
              :complete="step > 3"
              step="3"
              color="#8056DE"
              >Visual Elements</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              editable
              :complete="step > 4"
              step="4"
              color="#8056DE"
              >Marketing and Communication</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              editable
              :complete="step > 5"
              step="5"
              color="#8056DE"
              >Target Audience</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div
                class="flex flex-col px-5 py-5 mt-6 max-w-full rounded-xl bg-slate-100 w-[624px]"
              >
                <div class="text-base text-gray-950 max-md:max-w-full">
                  Brand Identity
                </div>
                <div class="mt-5 text-xs text-slate-500 max-md:max-w-full">
                  Business Name
                </div>
                <div class="relative">
                  <input
                    type="text"
                    v-model="businessDetails.businessName"
                    placeholder="Fly Social"
                    label="Business Name"
                    required
                    class="justify-center items-start py-3.5 pr-10 pl-3.5 mt-3.5 text-sm whitespace-nowrap bg-white rounded-xl text-neutral-700 w-full"
                  />
                  <button
                    v-if="businessDetails.businessName"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.businessName = ''"
                  >
                    &times;
                  </button>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Tagline
                </div>
                <div class="relative">
                  <input
                    type="text"
                    placeholder="Your social simplified!"
                    v-model="businessDetails.Tagline"
                    label="Tagline"
                    required
                    class="justify-center items-start py-3.5 pr-10 pl-3.5 mt-3.5 text-sm whitespace-nowrap bg-white rounded-xl text-neutral-700 w-full"
                  />
                  <button
                    v-if="businessDetails.Tagline"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.Tagline = ''"
                  >
                    &times;
                  </button>
                </div>
                <div class="mt-7 text-xs text-slate-500 max-md:max-w-full">
                  Logo
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                    <div
                      class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
                    >
                      <div
                        class="flex flex-col grow text-xs whitespace-nowrap text-neutral-700 max-md:mt-10"
                      >
                        <div class="text-center">Original</div>
                        <input
                          ref="fileInputOriginal"
                          type="file"
                          style="display: none"
                          @change="handleLogoOriginalChange"
                        />
                        <img
                          v-if="
                            businessDetails.logoOriginal ||
                            brandImages.logoOriginal
                          "
                          :src="
                            businessDetails.logoOriginal ||
                            brandImages.logoOriginal
                          "
                          loading="lazy"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputOriginal')"
                        />
                        <img
                          v-else
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputOriginal')"
                        />
                      </div>
                    </div>
                    <div
                      class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full items-center"
                    >
                      <div
                        class="flex flex-col grow text-xs whitespace-nowrap text-neutral-700 max-md:mt-10"
                      >
                        <div class="text-center">Dark</div>
                        <input
                          ref="fileInputDark"
                          type="file"
                          style="display: none"
                          @change="handleLogoDarkChange"
                        />
                        <img
                          v-if="businessDetails.logoDark"
                          :src="businessDetails.logoDark"
                          loading="lazy"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputDark')"
                        />

                        <img
                          v-else
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputDark')"
                        />
                      </div>
                    </div>
                    <div
                      class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
                    >
                      <div
                        class="flex flex-col grow text-xs whitespace-nowrap text-neutral-700 max-md:mt-10"
                      >
                        <div class="text-center">Transparent</div>
                        <input
                          ref="fileInputTransparent"
                          type="file"
                          style="display: none"
                          @change="handleLogoTransparentChange"
                        />
                        <img
                          v-if="businessDetails.logoTransparent"
                          :src="businessDetails.logoTransparent"
                          loading="lazy"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputTransparent')"
                        />
                        <img
                          v-else
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                          @click="openFileDialog('fileInputTransparent')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="brandCollectionExists"
                  @click="goToProductServicePage"
                  class="justify-center items-center px-16 py-3 mt-6 text-sm text-black whitespace-nowrap bg-white rounded-2xl max-md:px-5 max-md:max-w-full cursor-pointer text-center"
                >
                  <span class="text-sm -ml-8">
                    Add your Products / Services</span
                  >
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div
                class="flex flex-col px-5 py-5 mt-6 max-w-full text-xs rounded-xl bg-slate-100 text-slate-500 w-[624px]"
              >
                <div class="text-base text-gray-950 max-md:max-w-full">
                  Brand Essentials
                </div>
                <div class="mt-6 max-md:max-w-full">Website</div>
                <div class="relative">
                  <input
                    type="text"
                    placeholder="https://fly-social.com"
                    v-model="businessDetails.website"
                    label="Website"
                    @input="validateWebsite"
                    required
                    class="justify-center items-start py-3.5 pr-10 pl-3.5 mt-3.5 text-sm whitespace-nowrap bg-white rounded-xl text-neutral-700 w-full"
                  />
                  <button
                    v-if="businessDetails.website"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.website = ''"
                  >
                    &times;
                  </button>
                </div>
                <div v-if="websiteError" class="text-red-500 text-xs">
                  {{ websiteError }}
                </div>

                <div class="mt-6 max-md:max-w-full">Describe your brand</div>
                <div class="relative">
                  <textarea
                    v-model.trim="businessDetails.businessDescription"
                    placeholder="Fly helps businesses and marketers manage their social media effortlessly!"
                    label="Business Description"
                    class="items-start pt-4 pr-16 pb-16 pl-3.5 mt-3 text-sm whitespace-pre-wrap bg-white rounded-xl text-neutral-900 max-md:pr-5 w-full"
                  >
                  </textarea>
                  <button
                    v-if="businessDetails.businessDescription"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.businessDescription = ''"
                  >
                    &times;
                  </button>
                </div>
                <div class="mt-7 max-md:max-w-full">
                  Purpose / What are you solving?
                </div>
                <div class="relative">
                  <textarea
                    placeholder="Help brands manage their social media effortlessly"
                    v-model.trim="businessDetails.purpose"
                    label="Purpose"
                    class="items-start pt-4 pr-16 pb-20 pl-3.5 text-sm mt-3 whitespace-pre-wrap bg-white rounded-xl text-neutral-900 max-md:pr-5 w-full"
                  ></textarea>
                  <button
                    v-if="businessDetails.purpose"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.purpose = ''"
                  >
                    &times;
                  </button>
                </div>
                <div class="mt-6 max-md:max-w-full">
                  Highlights of your brand
                </div>
                <div
                  v-for="(highlight, index) in businessDetails.highlights"
                  :key="index"
                  class=""
                >
                  <input
                    placeholder="Multi-platform posting"
                    v-model="businessDetails.highlights[index]"
                    type="text"
                    class="justify-center items-start py-4 pr-16 pl-3.5 text-sm mt-4 whitespace-nowrap bg-white rounded-xl text-neutral-700 max-md:pr-5 w-3/4"
                  />
                  <button
                    v-if="businessDetails.highlights.length > 1"
                    @click="removeHighlight(index)"
                    class="text-xs"
                  >
                    <v-icon class="">mdi-close</v-icon>
                  </button>
                  <button
                    v-if="index === businessDetails.highlights.length - 1"
                    @click="addHighlight"
                    class="ml-2"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div
                class="flex flex-col px-5 py-5 mt-6 max-w-full rounded-xl bg-slate-100 w-[624px]"
              >
                <div class="text-base text-gray-950 max-md:max-w-full">
                  Visual Elements
                </div>
                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Pictures / Sample Flyers
                </div>

                <div
                  class="flex flex-col justify-center items-start py-3.5 pr-16 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex">
                    <input
                      type="file"
                      ref="picturesSampleFlyersInput"
                      multiple
                      accept="image/*"
                      @change="handlePicturesSampleFlyersChange"
                      hidden
                    />
                    <div class="flex">
                      <img
                        v-for="(
                          imageUrl, index
                        ) in businessDetails.pictures_sample_flyers"
                        :key="index"
                        :src="imageUrl"
                        class="aspect-square w-[82px] mr-2 cursor-pointer"
                        @click="openImageModal(imageUrl)"
                      />

                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                        class="aspect-square w-[82px] cursor-pointer"
                        @click="openFileInput"
                      />
                    </div>
                  </div>
                  <v-dialog v-model="imageModal" max-width="800">
                    <v-img :src="selectedImage" contain></v-img>
                  </v-dialog>
                </div>
                <div class="mt-7 text-xs text-slate-500 max-md:max-w-full">
                  Brand Colours
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3.5 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                    <div
                      class="flex flex-col w-[45%] max-md:ml-0 max-md:w-full"
                    >
                      <div
                        class="flex flex-col grow text-xs whitespace-nowrap text-neutral-700 max-md:mt-10 justify-center items-center"
                      >
                        <div>Primary Color</div>
                        <img
                          loading="lazy"
                          @click="addRandomColor"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                          class="self-center mt-2 aspect-square w-[82px] cursor-pointer"
                        />
                        <div @click="toggleColorPicker" style="cursor: pointer">
                          <div
                            v-if="!showColorPicker"
                            :style="{
                              width: '25px',
                              height: '25px',
                              backgroundColor: businessDetails.primaryColor,
                              borderRadius: '50%',
                              marginTop: '8px',
                            }"
                          ></div>
                          <v-color-picker
                            mode="hexa"
                            v-model="businessDetails.primaryColor"
                            class="w-40 mt-2"
                          ></v-color-picker>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-col w-[55%] max-md:ml-0 max-md:w-full"
                    >
                      <div
                        class="flex flex-col grow text-xs whitespace-nowrap text-neutral-700 max-md:mt-10 justify-center items-center"
                      >
                        <div>Secondary Color</div>
                        <img
                          loading="lazy"
                          @click="addRandomColor2"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4d9832d2ef8da9b44bda40ab67806a4e87bac4e2deda8515759b39273ceba4c?"
                          class="mt-2 aspect-square w-[82px] cursor-pointer"
                        />
                        <div
                          @click="toggleSecondaryColorPicker"
                          style="cursor: pointer"
                        >
                          <div
                            v-if="!showSecondaryColorPicker"
                            :style="{
                              width: '25px',
                              height: '25px',
                              backgroundColor: businessDetails.secondaryColor,
                              borderRadius: '50%',
                              marginTop: '8px',
                            }"
                          ></div>
                          <v-color-picker
                            mode="hexa"
                            v-model="businessDetails.secondaryColor"
                            class="w-40 mt-2"
                          ></v-color-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div
                class="flex flex-col px-5 py-5 mt-6 max-w-full text-xs rounded-xl bg-slate-100 text-slate-500 w-[624px]"
              >
                <div class="text-base text-gray-950 max-md:max-w-full">
                  Marketing and Communication
                </div>
                <div class="mt-5 max-md:max-w-full">Industry Category</div>
                <v-combobox
                  v-model="selectedIndustryCategory"
                  :items="industry_categories"
                  class="flex gap-5 justify-between px-3.5 mt-3 bg-white rounded-xl text-neutral-800 max-md:flex-wrap max-md:max-w-full"
                  label="Select or type Industry Category"
                  :search-input.sync="search"
                  @update:search-input="(val) => (search = val)"
                  return-object
                  autocomplete
                  clearable
                ></v-combobox>
                <div class="mt-6 max-md:max-w-full">Brand Values</div>
                <div
                  v-for="(brandvalue, index) in businessDetails.brandvalues"
                  :key="index"
                  class=""
                >
                  <input
                    placeholder="Innovation"
                    v-model="businessDetails.brandvalues[index]"
                    type="text"
                    class="justify-center items-start text-sm py-4 pr-16 pl-3.5 mt-4 whitespace-nowrap bg-white rounded-xl text-neutral-700 max-md:pr-5 w-3/4"
                  />
                  <button
                    v-if="businessDetails.brandvalues.length > 1"
                    @click="removebrandvalues(index)"
                    class="text-xs"
                  >
                    <v-icon class="">mdi-close</v-icon>
                  </button>
                  <button
                    v-if="index === businessDetails.brandvalues.length - 1"
                    @click="addBrandValue"
                    class="ml-2"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </button>
                </div>

                <div class="mt-6 max-md:max-w-full">
                  Unique Selling Proposition (USP)
                </div>
                <div class="relative">
                  <textarea
                    placeholder="Social media management essentials at the most affordable cost!"
                    v-model.trim="businessDetails.unique_selling_proposition"
                    class="items-start pt-3.5 pr-16 text-sm pb-20 pl-3.5 mt-3 whitespace-pre-wrap bg-white rounded-xl text-neutral-900 max-md:pr-5 w-full"
                  >
                  </textarea>
                  <button
                    v-if="businessDetails.unique_selling_proposition"
                    class="absolute right-3 top-6 h-6 w-6 text-xl flex items-center justify-center rounded-full text-gray-600 hover:bg-gray-300"
                    @click="businessDetails.unique_selling_proposition = ''"
                  >
                    &times;
                  </button>
                </div>
                <div class="mt-6 max-md:max-w-full">Content Themes</div>
                <div
                  v-for="(content, index) in businessDetails.contenttheme"
                  :key="index"
                  class=""
                >
                  <input
                    placeholder="Social Media Management"
                    v-model="businessDetails.contenttheme[index]"
                    type="text"
                    class="justify-center text-sm items-start py-4 pr-16 pl-3.5 mt-4 whitespace-nowrap bg-white rounded-xl text-neutral-700 max-md:pr-5 w-3/4"
                  />
                  <button
                    v-if="businessDetails.contenttheme.length > 1"
                    @click="removecontenttheme(index)"
                    class="text-xs"
                  >
                    <v-icon class="">mdi-close</v-icon>
                  </button>
                  <button
                    v-if="index === businessDetails.contenttheme.length - 1"
                    @click="addContentTheme"
                    class="ml-2"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </button>
                </div>

                <div class="mt-6 max-md:max-w-full">Tone of Voice</div>
                <v-select
  :items="tones"
  class="my-2 w-[503px] bg-white rounded-3xl px-3 pt-3"
  color="#8056DE"
  dense
  chips
  deletable-chips
  small-chips
  multiple
  item-text="name"
  item-value="name"
  v-model="businessDetails.tone_of_voice"
>
  <template v-slot:item="{ item, on, attrs }">
    <v-list-item v-on="on" v-bind="attrs">
      <v-list-item-action>
        <v-checkbox v-model="businessDetails.tone_of_voice" :value="item.name"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </template>
</v-select>
              </div>
            </v-stepper-content>
            <v-stepper-content step="5">
              <div
                class="flex flex-col p-5 mt-5 max-w-full rounded-xl bg-slate-100 w-[624px]"
              >
                <div class="text-base text-gray-950 max-md:max-w-full">
                  Target Audience
                </div>
                <div class="mt-5 text-xs text-slate-500 max-md:max-w-full">
                  About your target audience
                </div>
                <div
                  v-for="(audience, index) in businessDetails.targetAudience"
                  :key="index"
                  class=""
                >
                  <input
                    v-model="businessDetails.targetAudience[index]"
                    placeholder="Social Media Influencers"
                    type="text"
                    class="justify-center items-start py-4 pr-16 pl-3.5 mt-4 whitespace-nowrap bg-white rounded-xl text-neutral-700 max-md:pr-5 w-3/4"
                  />
                  <button
                    v-if="businessDetails.targetAudience.length > 1"
                    @click="removetargetaduience(index)"
                    class="text-xs"
                  >
                    <v-icon class="">mdi-close</v-icon>
                  </button>
                  <button
                    v-if="index === businessDetails.targetAudience.length - 1"
                    @click="addTargetAudience"
                    class="ml-2"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </button>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Geographic Location
                </div>
                <div
                  class="flex gap-5 justify-between px-3.5 py-5 mt-3 text-xs whitespace-nowrap bg-white rounded-xl text-neutral-900 max-md:flex-wrap max-md:max-w-full"
                >
                  <v-select
                    v-model="selectedCountry"
                    :items="countries"
                    label="Country"
                    class="flex justify-between px-4 rounded-xl bg-slate-100"
                  ></v-select>
                </div>
                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Age
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="ageGroup in ageGroups"
                      :key="ageGroup.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="ageGroup.value"
                        v-model="selectedAgeGroups"
                        :value="ageGroup.value"
                        class="mr-2"
                      />
                      <label
                        :for="ageGroup.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ ageGroup.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Gender
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="gender in genders"
                      :key="gender.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="gender.value"
                        v-model="selectedGenders"
                        :value="gender.value"
                        class="mr-2"
                      />
                      <label
                        :for="gender.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ gender.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Income Level
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="incomeLevel in incomeLevels"
                      :key="incomeLevel.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="incomeLevel.value"
                        v-model="selectedIncomeLevels"
                        :value="incomeLevel.value"
                        class="mr-2"
                      />
                      <label
                        :for="incomeLevel.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ incomeLevel.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Education Level
                </div>

                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="educationLevel in educationLevels"
                      :key="educationLevel.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="educationLevel.value"
                        v-model="selectedEducationLevels"
                        :value="educationLevel.value"
                        class="mr-2"
                      />
                      <label
                        :for="educationLevel.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ educationLevel.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Employment Status
                </div>

                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="employmentStatus in employmentStatuses"
                      :key="employmentStatus.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="employmentStatus.value"
                        v-model="selectedEmploymentStatuses"
                        :value="employmentStatus.value"
                        class="mr-2"
                      />
                      <label
                        :for="employmentStatus.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ employmentStatus.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Marital Status
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="maritalStatus in maritalStatuses"
                      :key="maritalStatus.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="maritalStatus.value"
                        v-model="selectedMaritalStatuses"
                        :value="maritalStatus.value"
                        class="mr-2"
                      />
                      <label
                        :for="maritalStatus.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ maritalStatus.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Ethnicity/Race
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="ethnicityRace in ethnicitiesRaces"
                      :key="ethnicityRace.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="ethnicityRace.value"
                        v-model="selectedEthnicitiesRaces"
                        :value="ethnicityRace.value"
                        class="mr-2"
                      />
                      <label
                        :for="ethnicityRace.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ ethnicityRace.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Interests/Hobbies
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="interestHobby in interestsHobbies"
                      :key="interestHobby.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="interestHobby.value"
                        v-model="selectedInterestsHobbies"
                        :value="interestHobby.value"
                        class="mr-2"
                      />
                      <label
                        :for="interestHobby.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ interestHobby.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Household Size
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="householdSize in householdSizes"
                      :key="householdSize.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="householdSize.value"
                        v-model="selectedHouseholdSizes"
                        :value="householdSize.value"
                        class="mr-2"
                      />
                      <label
                        :for="householdSize.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ householdSize.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Number of Children
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="option in numberOfChildrenOptions"
                      :key="option.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="option.value"
                        v-model="selectedNumberOfChildren"
                        :value="option.value"
                        class="mr-2"
                      />
                      <label
                        :for="option.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ option.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Home Ownership
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="option in homeOwnershipOptions"
                      :key="option.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="option.value"
                        v-model="selectedHomeOwnership"
                        :value="option.value"
                        class="mr-2"
                      />
                      <label
                        :for="option.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ option.label }}</label
                      >
                    </div>
                  </div>
                </div>

                <div class="mt-6 text-xs text-slate-500 max-md:max-w-full">
                  Disability Status
                </div>
                <div
                  class="py-4 pr-20 pl-3.5 mt-3 bg-white rounded-xl max-md:pr-5 max-md:max-w-full"
                >
                  <div class="flex flex-wrap gap-4">
                    <div
                      v-for="option in disabilityStatusOptions"
                      :key="option.value"
                      class="flex items-center"
                    >
                      <input
                        type="checkbox"
                        :id="option.value"
                        v-model="selectedDisabilityStatus"
                        :value="option.value"
                        class="mr-2"
                      />
                      <label
                        :for="option.value"
                        class="text-neutral-900 text-xs font-normal"
                        >{{ option.label }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
          <div
            class="flex gap-5 justify-between mt-8 mb-12 ml-9 max-w-full text-base font-semibold whitespace-nowrap w-[588px] max-md:flex-wrap max-md:mb-10"
          >
            <div
              class="w-[114px] h-[38px] justify-center items-center inline-flex cursor-pointer"
              @click="submitBusinessInfo"
            >
              <div class="w-[114px] h-[38px] relative">
                <v-snackbar
                  v-model="snackbar.show"
                  :timeout="snackbar.timeout"
                  :color="snackbar.color"
                >
                  {{ snackbar.message }}
                </v-snackbar>
                <div
                  class="w-[40.44px] h-[19.04px] left-[36.38px] top-[8.17px] absolute text-slate-500 text-base font-semibold font-['Poppins']"
                >
                  Save
                </div>
                <div
                  class="w-[114px] h-[38px] left-0 top-0 absolute bg-white bg-opacity-0 rounded-3xl border-2 border-slate-200"
                ></div>
              </div>
            </div>

            <div class="flex gap-5 md:justify-between">
              <div
                class="w-[114px] h-[38px] justify-center items-center inline-flex cursor-pointer"
                @click="prevStep"
              >
                <div class="w-[114px] h-[38px] relative">
                  <div
                    class="w-[40.44px] h-[19.04px] left-[36.38px] top-[8.17px] absolute text-slate-500 text-base font-semibold font-['Poppins']"
                  >
                    Back
                  </div>
                  <div
                    class="w-[114px] h-[38px] left-0 top-0 absolute bg-white bg-opacity-0 rounded-3xl border-2 border-slate-200"
                  ></div>
                </div>
              </div>
              <div
                v-if="step < 5"
                class="justify-center px-8 py-2 text-white bg-violet-500 rounded-3xl max-md:px-5 cursor-pointer"
                @click="nextStep"
              >
                Next
              </div>
              <div
                v-else
                class="justify-center px-8 py-2 text-white bg-violet-500 rounded-3xl max-md:px-5 cursor-pointer"
                @click="submitBusinessInfo"
              >
                Done
              </div>
            </div>
          </div>
        </v-stepper>
      </div>
    </div>

    <!-- Business Information Form -->
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import "firebase/firestore";
import { storage, flyplans } from "@/utils/firebase.utils";
import {
  getDownloadURL,
  ref,
  uploadString,
  uploadBytes,
} from "firebase/storage";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { auth, colUsers, db, functions } from "@/utils/firebase.utils.js";
import openaiMixin from "@/mixins/openai.mixin";
export default {
  components: {},
  data() {
    return {
      snackbar: {
        show: false,
        message: "",
        timeout: 3000, // Duration in milliseconds
        color: "success", // Color of the snackbar, can be 'success', 'error', etc.
      },
      websiteError: "",
      search: "",
      imageModal: false,
      step: 1,
      brandCollectionExists: false,
      imageModal: false,
      selectedImage: "",
      showColorPicker: false,
      showSecondaryColorPicker: false,
      catalogueDialog: false,
      industry_categories: [
        "Advertising",
        "Aerospace",
        "Agricultural Chemicals",
        "Agriculture",
        "Air Purification",
        "Airline",
        "Alternative Energy",
        "Animal Husbandry",
        "Aquaculture",
        "Architecture",
        "Artificial Intelligence",
        "Augmented Reality",
        "Automotive",
        "Automotive Aftermarket",
        "Aviation",
        "Banking",
        "Beverages",
        "Biometrics",
        "Biotechnology",
        "Blockchain",
        "Cannabis",
        "Carbon Capture",
        "Cement",
        "Ceramics",
        "Chemical",
        "Circular Economy",
        "Clean Energy",
        "Clean Transportation",
        "Cloud Computing",
        "Cloud Services",
        "Commodities",
        "Computer Hardware",
        "Computer Software",
        "Construction",
        "Consumer Electronics",
        "Cryptocurrency",
        "Cybersecurity",
        "Data Analytics",
        "Digital Marketing",
        "Digital Payments",
        "E-commerce",
        "Education",
        "Education Technology",
        "Elderly Care",
        "Electric Vehicles",
        "Electronic Components",
        "Energy",
        "Entertainment",
        "Environmental",
        "Environmental Services",
        "Fashion",
        "Fashion Design",
        "Financial Technology",
        "Finance",
        "Fitness",
        "Food & Beverage",
        "Food Processing",
        "Forestry",
        "Freight",
        "Furniture",
        "Gaming",
        "Genetic Engineering",
        "Green Technology",
        "Healthcare",
        "Healthcare IT",
        "Healthtech",
        "Hospitality",
        "Human Resources",
        "Industrial Automation",
        "Information Technology",
        "Insurance",
        "Internet of Things",
        "Internet Services",
        "Legal Tech",
        "Logistics",
        "Machine Learning",
        "Manufacturing",
        "Maritime",
        "Media",
        "Medical Devices",
        "Mining",
        "Mobile Applications",
        "Music",
        "Nanotechnology",
        "Natural Gas",
        "Neurotechnology",
        "Non-profit",
        "Nuclear Energy",
        "Oil & Gas",
        "Online Gambling",
        "Online Retail",
        "Packaging",
        "Personal Care Products",
        "Petroleum",
        "Pharmaceutical",
        "Precision Agriculture",
        "Real Estate",
        "Recycling",
        "Renewable Energy",
        "Renewable Fuels",
        "Robotics",
        "Satellite",
        "Semiconductors",
        "Smart Cities",
        "Social Media",
        "Space Exploration",
        "Space Tourism",
        "Sports",
        "Sustainable Development",
        "Sustainable Fashion",
        "Supply Chain Management",
        "Telecommunications",
        "Textiles",
        "Tourism",
        "Transportation",
        "Travel",
        "Urban Farming",
        "Utilities",
        "Virtual Reality",
        "Waste Management",
        "Water Management",
        "Wearable Technology",
        "Wind Energy",
      ],
      selectedIndustryCategory: "",
      ageGroups: [
        { label: "All", value: "all" },
        { label: "Under 18", value: "under_18" },
        { label: "18-24", value: "18_24" },
        { label: "25-34", value: "25_34" },
        { label: "35-44", value: "35_44" },
        { label: "45-54", value: "45_54" },
        { label: "55-64", value: "55_64" },
        { label: "65+", value: "65_plus" },
      ],
      genders: [
        { label: "All", value: "all" },
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Non-binary", value: "non_binary" },
      ],
      incomeLevels: [
        { label: "All", value: "all" },
        { label: "Under $20k", value: "under_20k" },
        { label: "$20k to $39k", value: "20k_39k" },
        { label: "$40k to $59k", value: "40k_59k" },
        { label: "$60k to $79k", value: "60k_79k" },
        { label: "$80k to $99k", value: "80k_99k" },
        { label: "$100k to $149k", value: "100k_149k" },
        { label: "$150k to $199k", value: "150k_199k" },
        { label: "$200k+", value: "200k_plus" },
      ],
      educationLevels: [
        { label: "All", value: "all" },
        { label: "Some high school", value: "some high school" },
        { label: "High school graduate", value: "high school graduate" },
        { label: "Some college", value: "some college" },
        { label: "College graduate", value: "college graduate" },
        { label: "Postgraduate", value: "postgraduate" },
      ],
      employmentStatuses: [
        { label: "All", value: "all" },
        { label: "Full-time", value: "full-time" },
        { label: "Part-time", value: "part-time" },
        { label: "Self-employed", value: "self-employed" },
        { label: "Job Seeker", value: "job seeker" },
        { label: "Student", value: "student" },
        { label: "Unable to work", value: "unable to work" },
      ],
      maritalStatuses: [
        { label: "All", value: "all" },
        { label: "Single", value: "single" },
        { label: "In a relationship", value: "in a elationship" },
        { label: "Married", value: "married" },
        { label: "Separated", value: "separated" },
        { label: "Divorced", value: "divorced" },
        { label: "Widowed", value: "widowed" },
      ],
      ethnicitiesRaces: [
        { label: "All", value: "all" },
        { label: "Asian", value: "asian" },
        { label: "Black/African descent", value: "black/african descent" },
        { label: "Hispanic/Latin", value: "hispanic latin" },
        { label: "Middle Eastern", value: "middle eastern" },
        { label: "Native American", value: "native american" },
        { label: "Pacific Islander", value: "pacific islander" },
        { label: "Two or more races", value: "two or mre races" },
        { label: "White/Caucasian", value: "white/caucasian" },
      ],
      interestsHobbies: [
        { label: "All", value: "all" },
        { label: "Sports", value: "sports" },
        { label: "Music", value: "music" },
        { label: "Arts", value: "arts" },
        { label: "Travel", value: "travel" },
        { label: "Technology", value: "technology" },
        { label: "Cooking", value: "cooking" },
      ],
      householdSizes: [
        { label: "All", value: "all" },
        { label: "1 person", value: "1 person" },
        { label: "2 people", value: "2 people" },
        { label: "3-4 people", value: "3-4 people" },
        { label: "5 or more people", value: "5 or more people" },
      ],
      numberOfChildrenOptions: [
        { label: "All", value: "all" },
        { label: "None", value: "none" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4 or more", value: "4 or more" },
      ],
      homeOwnershipOptions: [
        { label: "All", value: "all" },
        { label: "Own", value: "own" },
        { label: "Rent", value: "rent" },
        {
          label: "Live with parents/family",
          value: "live with parents family",
        },
      ],
      disabilityStatusOptions: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        { label: "Prefer not to say", value: "prefer_not_to_say" },
      ],
      selectedDisabilityStatus: [],
      selectedAgeGroups: [],
      selectedHouseholdSizes: [],
      selectedInterestsHobbies: [],
      selectedMaritalStatuses: [],
      selectedEmploymentStatuses: [],
      selectedGenders: [],
      selectedIncomeLevels: [],
      selectedEducationLevels: [],
      selectedEthnicitiesRaces: [],
      selectedNumberOfChildren: [],
      selectedHomeOwnership: [],
      countries: [],
      selectedCountry: "",

      catalogue: {
        name: "",
        description: "",
        images: [],
        video: [],
        price: 0,
        postFrequency: "",
        // Add other fields as needed
      },
      brandImages: {
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
      },
      businessDetails: {
        businessName: "",
        Tagline: "",
        website: "",
        pictures_sample_flyers: [],
        placeOfBusiness: "",
        businessDescription: "",
        highlights: [""],
        brandvalues: [""],
        tone_of_voice: [""],
        contenttheme: [""],
        unique_selling_proposition: "",
        purpose: "",
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
        primaryColor: "",
        secondaryColor: "",
        primaryFont: "",
        secondaryFont: "",
        productsServicesCatalogue: [],
        targetAudience: [""],
      },
      fontOptions: [
        { text: "Font 1", value: "font1" },
        { text: "Font 2", value: "font2" },
      ],
    };
  },
  async created() {
    // try {
    //   const userId = auth.currentUser.uid;
    //   const userDocRef = doc(colUsers, userId,"fly_workspaces", this.WID);
    //   const brandCollectionRef = collection(userDocRef, 'brand');
    //   const brandDocSnapshot = await getDocs(brandCollectionRef);
    //   if (brandDocSnapshot.empty) {
    //     console.error("Brand document not found");
    //     return;
    //   }
    //   const brandDoc = brandDocSnapshot.docs[0].data();
    //   console.log("brnad",brandDoc)
    //   this.businessDetails.productsServicesCatalogue = brandDoc.products_services_catalogue || [];
    // } catch (error) {
    //   console.error("Error fetching business details from Firestore:", error);
    // }
  },
  mixins: [openaiMixin],

  watch: {
    WID() {
      this.getbrand();
    },
  },
  methods: {
    validateWebsite() {
      if (!this.businessDetails.website.startsWith("https://")) {
        this.websiteError = 'Website must start with "https://"';
        if (
          this.businessDetails.website &&
          !this.businessDetails.website.startsWith("http")
        ) {
          this.businessDetails.website =
            "https://" + this.businessDetails.website;
        }
      } else {
        this.websiteError = "";
      }
    },
    openImageModal(imageUrl) {
      this.selectedImage = imageUrl;
      this.imageModal = true;
    },
    goToProductServicePage() {
      this.$router.push({ name: "product-service-catalogue" });
    },
    addHighlight() {
      this.businessDetails.highlights.push("");
    },
    removeHighlight(index) {
      if (this.businessDetails.highlights.length > 1) {
        this.businessDetails.highlights.splice(index, 1);
      }
    },
    addBrandValue() {
      this.businessDetails.brandvalues.push("");
    },
    removebrandvalues(index) {
      if (this.businessDetails.brandvalues.length > 1) {
        this.businessDetails.brandvalues.splice(index, 1);
      }
    },
    addContentTheme() {
      this.businessDetails.contenttheme.push("");
    },
    removecontenttheme(index) {
      if (this.businessDetails.contenttheme.length > 1) {
        this.businessDetails.contenttheme.splice(index, 1);
      }
    },
    addToneOfVoice() {
      this.businessDetails.tone_of_voice.push("");
    },
    removetoneofvoice(index) {
      if (this.businessDetails.tone_of_voice.length > 1) {
        this.businessDetails.tone_of_voice.splice(index, 1);
      }
    },
    // Y
    addTargetAudience() {
      this.businessDetails.targetAudience.push("");
    },
    removetargetaduience(index) {
      if (this.businessDetails.targetAudience.length > 1) {
        this.businessDetails.targetAudience.splice(index, 1);
      }
    },
    openFileInput() {
      this.$refs.picturesSampleFlyersInput.click();
    },
    async handleLogoTransparentChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoTransparent/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoTransparent = imageUrl;

          // Update Firestore with the new logoTransparent URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_transparent: this.businessDetails.logoTransparent,
            });
          }
        } catch (error) {
          console.error("Error uploading logoTransparent: ", error);
        }
      }
    },
    async handleLogoOriginalChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logooriginal/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoOriginal = imageUrl;

          // Update Firestore with the new logooriginal URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_original: this.businessDetails.logoOriginal,
            });
          }
        } catch (error) {
          console.error("Error uploading logooriginal: ", error);
        }
      }
    },
    async handleLogoDarkChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoDark/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoDark = imageUrl;

          // Update Firestore with the new logoDark URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_dark: this.businessDetails.logoDark,
            });
          }
        } catch (error) {
          console.error("Error uploading logoDark: ", error);
        }
      }
    },
    async handlePicturesSampleFlyersChange(event) {
      const files = event.target.files;
      if (files) {
        const userId = auth.currentUser.uid;
        const uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const storageRef = ref(
            storage,
            `users/${userId}/pictures_sample_flyers/${file.name}`
          );
          const uploadTask = uploadBytes(storageRef, file).then(() =>
            getDownloadURL(storageRef)
          );
          uploadPromises.push(uploadTask);
        }

        try {
          const imageUrls = await Promise.all(uploadPromises);
          this.businessDetails.pictures_sample_flyers = [
            ...this.businessDetails.pictures_sample_flyers,
            ...imageUrls,
          ];

          // Update Firestore with the new pictures_sample_flyers array
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              pictures_sample_flyers:
                this.businessDetails.pictures_sample_flyers,
            });
          }
        } catch (error) {
          console.error("Error uploading images: ", error);
        }
      }
    },
    previewImages(event) {
      const input = event.target;
      const preview = document.getElementById("sample_flyers_preview");
      const files = input.files;

      // Clear the preview container before adding new images
      preview.innerHTML = "";

      // Iterate through the selected files and create image previews
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function (e) {
          const img = document.createElement("img");
          img.src = e.target.result;
          img.classList.add("preview-image");
          preview.appendChild(img);
        };

        reader.readAsDataURL(file);
      }
    },

    openFileDialog(refName) {
      // Trigger click event on the file input to open the file dialog
      this.$refs[refName].click();
    },
    handleImageChange(imageKey, event) {
      const file = event.target.files[0];
      if (file instanceof File) {
        // Read the selected file as a data URL
        const reader = new FileReader();
        reader.onload = () => {
          // Update the corresponding image source with the selected file
          // this.brandImages[imageKey] = reader.result;
          this.businessDetails[imageKey] = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async nextStep() {
      if (this.step < 5) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          let brandDocRef;
          if (brandDocSnapshot.empty) {
            // If the brand collection is empty, create a new document
            brandDocRef = await addDoc(brandCollectionRef, {});
          } else {
            // If the brand collection exists, use the existing document
            brandDocRef = brandDocSnapshot.docs[0].ref;
          }

          // Update the brand document with the current businessDetails
          await updateDoc(brandDocRef, {
            // Add or update fields as needed

            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
            country: this.selectedCountry,
          });
          this.brandCollectionExists = true;
          // Proceed to the next step
          this.scrollToTop();
          this.step++;
        } catch (error) {
          console.error("Error updating brand collection:", error);
          alert("An error occurred while updating the brand information.");
        }
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    editCatalogueItem(item) {
      // Populate the catalogue object with the data of the selected item
      this.catalogue.name = item.name;
      this.catalogue.description = item.description;
      this.catalogue.price = item.price;
      this.catalogue.postFrequency = item.post_frequency;
      // Set the images and video to empty arrays to clear previous values
      this.catalogue.images = [];
      this.catalogue.video = [];
      // Populate the images and video arrays with the URLs from the selected item
      this.catalogue.images.push(...item.images);
      this.catalogue.video.push(...item.video);
      // Open the dialog
      this.$nextTick(() => {
        this.$refs.catalogueNameInput.readonly = true;
      });
      this.catalogueDialog = true;
    },
    async deleteImageFromCatalogue(itemIndex, imageIndex) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];

        // Remove the image from the images array of the corresponding item
        updatedCatalogue[itemIndex].images.splice(imageIndex, 1);

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Image deleted successfully!");
      } catch (error) {
        console.error("Error deleting image:", error);
        alert("An error occurred while deleting the image.");
      }
    },
    async fetchCountries() {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    const countriesData = await response.json();
    this.countries = countriesData.map((country) => country.name.common)
                                  .sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
},
    async deleteCatalogueItem(index) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];
        updatedCatalogue.splice(index, 1); // Remove item at the specified index

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Catalogue item deleted successfully!");
      } catch (error) {
        console.error("Error deleting catalogue item:", error);
        alert("An error occurred while deleting the catalogue item.");
      }
    },
    openImageModal(image) {
      this.selectedImage = image;
      this.imageModal = true;
    },
    uploadImage(itemIndex) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          // Handle the uploaded image file
          // For example, you can upload the file to your storage and update the item.images array
          // Here's a simplified example assuming you have a function to upload images:
          this.uploadImageToStorage(file, itemIndex);
        }
      };

      input.click();
    },

    async uploadImageToStorage(file, itemIndex) {
      try {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/catalogue/${new Date().getTime()}_${Math.random()}.png`
        );

        // Upload the image to storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const imageUrl = await getDownloadURL(storageRef);

        // Update the item.images array with the new image URL
        this.businessDetails.productsServicesCatalogue[itemIndex].images.push(
          imageUrl
        );

        // Update the Firestore document with the modified array
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDoc = brandDocSnapshot.docs[0];
          const updatedCatalogue = [
            ...brandDoc.data().products_services_catalogue,
          ];
          updatedCatalogue[itemIndex].images.push(imageUrl);

          await updateDoc(brandDoc.ref, {
            products_services_catalogue: updatedCatalogue,
          });

          alert("Image uploaded successfully!");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image.");
      }
    },
    toggleSecondaryColorPicker() {
      this.showSecondaryColorPicker = !this.showSecondaryColorPicker;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },

    openCataloguePopup() {
      this.catalogueDialog = true;
    },
    closeCataloguePopup() {
      this.catalogueDialog = false;
    },
    async saveCatalogueItem() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);

        // Fetch the current brand document
        const brandCollectionRef = collection(userDocRef, "brand");
        const querySnapshot = await getDocs(brandCollectionRef);
        const brandDoc = querySnapshot.docs[0]; // Assuming there's only one brand document per user

        if (!brandDoc) {
          // Handle the case where the brand document doesn't exist
          alert("Brand document not found");
          return;
        }

        // Process and upload each image
        const uploadedImages = await Promise.all(
          this.catalogue.images.map(async (imageFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.png`
            );
            await uploadBytes(storageRef, imageFile);
            return getDownloadURL(storageRef);
          })
        );

        const uploadVideo = await Promise.all(
          this.catalogue.video.map(async (videoFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.mp4`
            );
            await uploadBytes(storageRef, videoFile);
            return getDownloadURL(storageRef);
          })
        );

        // Continue with the rest of your code
        const catalogueItem = {
          name: this.catalogue.name || "",
          description: this.catalogue.description || "",
          images: uploadedImages || [],
          video: uploadVideo || [],
          price: this.catalogue.price || 0,
          post_frequency: this.catalogue.postFrequency || "",
          // Add other fields as needed
        };

        // Concatenate the existing productsServicesCatalogue array with the new catalogueItem
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
          catalogueItem,
        ];

        // Update the existing brand document to include the updated productsServicesCatalogue array
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        alert("Catalogue item added successfully!");
        const itemIndex =
          this.businessDetails.productsServicesCatalogue.findIndex(
            (item) => item.name === this.catalogue.name
          );
        if (itemIndex !== -1) {
          // Update the existing item in the array
          this.businessDetails.productsServicesCatalogue[itemIndex] = {
            name: this.catalogue.name,
            description: this.catalogue.description,
            images: this.catalogue.images,
            video: this.catalogue.video,
            price: this.catalogue.price,
            post_frequency: this.catalogue.postFrequency,
            // Add other fields as needed
          };

          // Update the Firestore document with the modified array
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              products_services_catalogue:
                this.businessDetails.productsServicesCatalogue,
            });

            alert("Catalogue item updated successfully!");
            // Refresh the data after updating
          }
        }
        this.getbrand();
      } catch (error) {
        console.error("Error saving catalogue item in Firestore:", error);
        alert("An error occurred while saving the catalogue item.");
      }

      // Reset the catalogue object after saving
      this.catalogue = {
        name: "",
        description: "",
        images: [],
        video: [],
        // Add other fields as needed
      };

      this.catalogueDialog = false;
    },
    addRandomColor() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.primaryColor = randomColor;
    },
    addRandomColor2() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.secondaryColor = randomColor;
    },
    async fetchBrandDetailsAndConvertToJson() {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
      const brandCollectionRef = collection(userDocRef, "brand");
      const brandDocSnapshot = await getDocs(brandCollectionRef);

      if (brandDocSnapshot.empty) {
        console.error("Brand document not found");
        return;
      }
      const defaultLogoUrl = 'https://example.com/default-logo.jpg'; 

      const brandDoc = brandDocSnapshot.docs[0].data();
      const picturesSampleFlyers =
        brandDoc.pictures_sample_flyers &&
        brandDoc.pictures_sample_flyers.length > 0
          ? brandDoc.pictures_sample_flyers
          : ["https://example.com/default-image.jpg"];
      const brandDetails = {
        brand_details: {
          name: brandDoc.business_name || "fly scoial",
          description:
            brandDoc.business_description ||
            "Create & publish social media content ",
          purpose: brandDoc.purpose || "Manage social media effortlessly",
          tagline: brandDoc.tagline || "fly all over the world",
          highlights: brandDoc.highlights || [
            "Multi-channel publish",
            "TracK performance across channels",
          ],
          website: brandDoc.website || "https://www.flyscoial.in",
          logo: brandDoc.logo_original || defaultLogoUrl,
          social_handles: {
            facebook: "https://www.facebook.com/example",
            twitter: "https://www.twitter.com/example",
            instagram: "https://www.instagram.com/example",
          },
          primary_colours: [brandDoc.primary_color || ""] || ["#7483AE"],
          secondary_colours: [brandDoc.secondary_color || ""] || ["#7483AE"],
          pictures_sample_flyers: picturesSampleFlyers,
          industry_category: brandDoc.industry_category || "Enviornment", // This field is not present in the provided data
          // This field is not present in the provided data
          target_audience: {
            primary: [
              "Automotive solutions businesses seeking reliable data feeds",
            ],
            secondary: [
              "Top car manufacturers, large retailers, auction houses, lenders, and insurers in the automotive industry",
            ],
          },
          brand_values: brandDoc.brand_values || ["Simple", "Affordable"],
          unique_selling_proposition:
            brandDoc.unique_selling_proposition ||
            "Social media essentials at an affordable price",
          content_themes: brandDoc.content_themes || [
            "Expert Tips",
            "Client Testimonials",
          ],
          tone_of_voice: brandDoc.tone_of_voice || ["Authentic", "Friendly"],
        },
        product_service_catalogue:
          brandDoc.products_services_catalogue.length > 0
            ? brandDoc.products_services_catalogue.map((item) => ({
                name: item.name || "Default Product Name",
                description: item.description || "Default product description",
                offers_discounts: null,
                images:
                  item.images && item.images.length > 0
                    ? item.images
                    : ["https://example.com/default-product-image.jpg"], // Replace with your default product image URL
                price_fee: item.price
                  ? `Custom quote based on requirements: ${item.price}`
                  : "Custom quote based on requirements" ||
                    "Custom quote based on requirements",
                key_features_highlights: item.key_features_highlights || [
                  "Customized data feeds",
                  "Real-time data updates",
                  "Data security and privacy",
                ],
                product_service_description_web_page:
                  item.product_service_description_web_page ||
                  "https://www.example.com",
                // Add other fields as needed with default values
              }))
            : [
                {
                  name: "Default Product Name",
                  description: "Default product description",
                  offers_discounts: null,
                  images: ["https://example.com/default-product-image.jpg"], // Replace with your default product image URL
                  price_fee: "Custom quote based on requirements",
                  key_features_highlights: [
                    "Customized data feeds",
                    "Real-time data updates",
                    "Data security and privacy",
                  ],
                  product_service_description_web_page:
                    "https://www.example.com",
                },
              ],
        // product_service_catalogue: brandDoc.products_services_catalogue.map(item => ({
        //   name: item.name || "fly" ,
        //   description: item.description || "Publish to multiple social media platforms at once",
        //   offers_discounts: ["10% off for first-time customers"],

        //   images: item.images && item.images.length > 0 ? item.images : ['https://example.com/default-product-image.jpg'],
        //   "offers_discounts": null,
        //   price_fee: item.price ? `Custom quote based on requirements: ${item.price}` : "Custom quote based on requirements" || "Custom quote based on requirements",
        //   key_features_highlights: item.key_features_highlights || ["Customized data feeds", "Real-time data updates", "Data security and privacy"],
        //   product_service_description_web_page: item.product_service_description_web_page || "https://www.example.com",
        // }))
      };
      console.log("brandDetails", brandDetails);
      return brandDetails;
    },
    async postBrandInfoToEndpoint(operationType) {
      try {
        const brandDetails = await this.fetchBrandDetailsAndConvertToJson();
        let apiEndpoint;

        if (operationType === "create") {
          apiEndpoint =
            "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/create";
        } else if (operationType === "update") {
          apiEndpoint =
            "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/update";
        } else {
          throw new Error(
            'Invalid operation type. Must be "create" or "update".'
          );
        }

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(brandDetails),
        };

        console.log("Sending payload to API:", brandDetails); // Log the payload for debugging

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log("Successfully posted brand info:", responseData);
        return responseData;
      } catch (error) {
        console.error("Error posting brand info to endpoint:", error);
        return null;
      }
    },

    async submitBusinessInfo() {
      try {
        this.$loader.show({
          message:
            "Saving brand info.. Please stand by!",
          type: "ai",
        });
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");

        // Check if the user already has a brand collection
        const brandDocsSnapshot = await getDocs(brandCollectionRef);
        let brandDocRef;
        let operationType;
        if (!brandDocsSnapshot.empty) {
          // If a brand collection exists, get its reference
          brandDocRef = brandDocsSnapshot.docs[0].ref;
          this.brandCollectionExists = true;
          operationType = "update";
        } else {
          // If no brand collection exists, create a new document
          brandDocRef = await addDoc(brandCollectionRef, {
            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
          });
          this.brandCollectionExists = false;
          operationType = "create";
        }
        await this.postBrandInfoToEndpoint(operationType);
        const responseData = await this.postBrandInfoToEndpoint(operationType);
        await updateDoc(brandDocRef, {
          business_name: this.businessDetails.businessName,
          website: this.businessDetails.website,
          tagline: this.businessDetails.Tagline,
          purpose: this.businessDetails.purpose,
          highlights: this.businessDetails.highlights,
          brand_values: this.businessDetails.brandvalues,
          content_themes: this.businessDetails.contenttheme,
          tone_of_voice: this.businessDetails.tone_of_voice,
          unique_selling_proposition:
            this.businessDetails.unique_selling_proposition,
          pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
          place_business: this.businessDetails.placeOfBusiness,
          business_description: this.businessDetails.businessDescription,
          logo_original: this.businessDetails.logoOriginal,
          logo_dark: this.businessDetails.logoDark,
          logo_transparent: this.businessDetails.logoTransparent,
          primary_color: this.businessDetails.primaryColor,
          secondary_color: this.businessDetails.secondaryColor,
          primary_font: this.businessDetails.primaryFont,
          secondary_font: this.businessDetails.secondaryFont,
          products_services_catalogue:
            this.businessDetails.productsServicesCatalogue,
          age_groups: this.selectedAgeGroups,
          genders: this.selectedGenders,
          income_levels: this.selectedIncomeLevels,
          education_levels: this.selectedEducationLevels,
          employment_statuses: this.selectedEmploymentStatuses,
          marital_statuses: this.selectedMaritalStatuses,
          ethnicities_races: this.selectedEthnicitiesRaces,
          interests_hobbies: this.selectedInterestsHobbies,
          household_sizes: this.selectedHouseholdSizes,
          number_of_children: this.selectedNumberOfChildren,
          home_ownership: this.selectedHomeOwnership,
          disability_status: this.selectedDisabilityStatus,
          industry_category: this.selectedIndustryCategory,
          target_audience: this.businessDetails.targetAudience,
          country: this.selectedCountry,
          profile: responseData.profile,
        });

        // alert(`Brand information ${operationType}d successfully and posted to the endpoint!`);
        if (!this.brandCollectionExists) {
          this.snackbar.message = "Brand collection created successfully!";
        } else {
          this.snackbar.message = "Brand collection updated successfully!";
        }
        this.snackbar.color = "success"; // Set the color to indicate success
        this.snackbar.show = true;
        this.$loader.hide();
      } catch (error) {
        console.error("Error saving brand collection in Firestore:", error);
        alert("An error occurred while saving the brand collection.");
      }
    },

    //     async submitBusinessInfo() {
    //       try {
    //         const userId = auth.currentUser.uid;
    //         console.log("WID:", this.WID);
    //         const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
    //         const brandCollectionRef = collection(userDocRef, "brand");

    //         // Check if the user already has a businessDetails document
    //         const existingBrandDocs = await getDocs(brandCollectionRef);

    //         if (!existingBrandDocs.empty) {
    //           // If documents already exist, inform the user or handle it as needed
    //           alert(
    //             "You have already added business details.you can add multiple Products/Services Catalogue."
    //           );
    //           return;
    //         }

    //         // Continue with adding business details if no existing documents are found

    //         const logoOriginalFile = this.businessDetails.logoOriginal;
    //         // console.log("logoOriginalFile: ", logoOriginalFile);
    //         if (logoOriginalFile) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_original/${new Date().getTime()}.png`
    //           );
    //           if (logoOriginalFile instanceof File) {
    //             await uploadBytes(storageRef, logoOriginalFile);
    //           } else {
    //             await uploadString(storageRef, logoOriginalFile, "data_url");
    //           }
    //           this.businessDetails.logoOriginal = await getDownloadURL(storageRef);
    //           // console.log('getDownloadURL:', this.businessDetails.logoOriginal)
    //         }

    //         if (this.businessDetails.logoDark) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_dark/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoDark instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoDark);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoDark, "data_url");
    //           }
    //           this.businessDetails.logoDark = await getDownloadURL(storageRef);
    //         }
    //         if (this.businessDetails.logoTransparent) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_transparent/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoTransparent instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoTransparent);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoTransparent, "data_url");
    //           }

    //           this.businessDetails.logoTransparent = await getDownloadURL(
    //             storageRef
    //           );
    //         }
    //         const uploadedImages = await Promise.all(
    //   this.businessDetails.pictures_sample_flyers.map(async (imageFile) => {
    //     const storageRef = ref(
    //       storage,
    //       `users/${auth.currentUser.uid}/catalogue/${new Date().getTime()}_${Math.random()}.png`
    //     );
    //     if (imageFile instanceof File) {
    //       await uploadBytes(storageRef, imageFile);
    //     } else {
    //       await uploadString(storageRef, imageFile, "data_url");
    //     }
    //     return await getDownloadURL(storageRef);
    //   })
    // );
    // this.businessDetails.pictures_sample_flyers = uploadedImages;
    //         await addDoc(brandCollectionRef, {
    //           business_name: this.businessDetails.businessName,
    //           website: this.businessDetails.website,
    //           tagline: this.businessDetails.Tagline,
    //           purpose: this.businessDetails.purpose,
    //           highlights: this.businessDetails.highlights,
    //           brand_values: this.businessDetails.brandvalues,
    //           content_themes: this.businessDetails.contenttheme,
    //           tone_of_voice: this.businessDetails.tone_of_voice,
    //           unique_selling_proposition:
    //             this.businessDetails.unique_selling_proposition,
    //             pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
    //           place_business: this.businessDetails.placeOfBusiness,
    //           business_description: this.businessDetails.businessDescription,
    //           logo_original: this.businessDetails.logoOriginal,
    //           logo_dark: this.businessDetails.logoDark,
    //           logo_transparent: this.businessDetails.logoTransparent,
    //           primary_color: this.businessDetails.primaryColor,
    //           secondary_color: this.businessDetails.secondaryColor,
    //           primary_font: this.businessDetails.primaryFont,
    //           secondary_font: this.businessDetails.secondaryFont,
    //           products_services_catalogue:
    //             this.businessDetails.productsServicesCatalogue,
    //             age_groups: this.selectedAgeGroups,
    //             genders: this.selectedGenders,
    //             income_levels: this.selectedIncomeLevels,
    //             education_levels: this.selectedEducationLevels,
    //             employment_statuses: this.selectedEmploymentStatuses,
    //             marital_statuses: this.selectedMaritalStatuses,
    //             ethnicities_races: this.selectedEthnicitiesRaces,
    //             interests_hobbies: this.selectedInterestsHobbies,
    //             household_sizes: this.selectedHouseholdSizes,
    //             number_of_children: this.selectedNumberOfChildren,
    //             home_ownership: this.selectedHomeOwnership,
    //             disability_status: this.selectedDisabilityStatus,
    //             industry_category: this.selectedIndustryCategory,
    //         });
    //         this.brandCollectionExists = true;

    //         alert("Business information saved successfully!");
    //       } catch (error) {
    //         console.error("Error saving business information in Firestore:", error);
    //         alert("An error occurred while saving the business information.");
    //       }
    //     },
    async getbrand() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          this.brandCollectionExists = false;
          console.error("Brand document not found");
          // Clear businessDetails object properties
          this.businessDetails.businessName = "";
          this.businessDetails.Tagline = "";
          this.businessDetails.website = "";
          this.bussinessDetails.highlights = [];
          this.businessDetails.brandvalues = [];
          this.businessDetails.contenttheme = [];
          this.businessDetails.tone_of_voice = [];
          this.businessDetails.unique_selling_proposition = "";
          this.businessDetails.purpose = "";
          this.businessDetails.placeOfBusiness = "";
          this.businessDetails.businessDescription = "";
          this.businessDetails.logoOriginal = null;
          this.businessDetails.logoDark = null;
          this.businessDetails.logoTransparent = null;
          this.businessDetails.primaryColor = "";
          this.businessDetails.secondaryColor = "";
          this.businessDetails.primaryFont = "";
          this.businessDetails.secondaryFont = "";
          this.businessDetails.productsServicesCatalogue = [];
          this.businessDetails.targetAudience = "";
          return;
        }
        this.brandCollectionExists = true;
        const brandDoc = brandDocSnapshot.docs[0].data();
        // console.log("brnad", brandDoc);
        this.businessDetails.businessName = brandDoc.business_name || "";
        this.businessDetails.Tagline = brandDoc.tagline || "";
        this.businessDetails.purpose = brandDoc.purpose || "";
        this.businessDetails.highlights = brandDoc.highlights || [];
        this.businessDetails.brandvalues = brandDoc.brand_values || [];
        this.businessDetails.contenttheme = brandDoc.content_themes || [];
        this.businessDetails.tone_of_voice = brandDoc.tone_of_voice || [];
        this.businessDetails.pictures_sample_flyers =
          brandDoc.pictures_sample_flyers || [];
        this.businessDetails.unique_selling_proposition =
          brandDoc.unique_selling_proposition || "";
        this.businessDetails.website = brandDoc.website || "";
        this.businessDetails.placeOfBusiness = brandDoc.place_business || "";
        this.businessDetails.businessDescription =
          brandDoc.business_description || "";
        this.businessDetails.logoOriginal = brandDoc.logo_original || null;
        this.businessDetails.logoDark = brandDoc.logo_dark || null;
        this.businessDetails.logoTransparent =
          brandDoc.logo_transparent || null;
        this.businessDetails.primaryColor = brandDoc.primary_color || "";
        this.businessDetails.secondaryColor = brandDoc.secondary_color || "";
        this.businessDetails.primaryFont = brandDoc.primary_font || "";
        this.businessDetails.secondaryFont = brandDoc.secondary_font || "";
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.selectedAgeGroups = brandDoc.age_groups || [];
        this.selectedGenders = brandDoc.genders || [];
        this.selectedIncomeLevels = brandDoc.income_levels || [];
        this.selectedEducationLevels = brandDoc.education_levels || [];
        this.selectedEmploymentStatuses = brandDoc.employment_statuses || [];
        this.selectedMaritalStatuses = brandDoc.marital_statuses || [];
        this.selectedEthnicitiesRaces = brandDoc.ethnicities_races || [];
        this.selectedInterestsHobbies = brandDoc.interests_hobbies || [];
        this.selectedHouseholdSizes = brandDoc.household_sizes || [];
        this.selectedNumberOfChildren = brandDoc.number_of_children || [];
        this.selectedHomeOwnership = brandDoc.home_ownership || [];
        this.selectedDisabilityStatus = brandDoc.disability_status || [];
        this.selectedIndustryCategory = brandDoc.industry_category || [];
        this.businessDetails.targetAudience = brandDoc.target_audience || "";
        this.selectedCountry = brandDoc.country || "";
      } catch (error) {
        console.error("Error fetching business details from Firestore:", error);
      }
      console.log(
        "businessDetails.logoOriginal: " + this.businessDetails.logoOriginal
      );
    },
  },
  mounted() {
    if(auth.currentUser.uid!==this.currentUID){
      this.$router.push("/dashboard")
    }
    this.getbrand();
    this.fetchBrandDetailsAndConvertToJson();
    this.fetchCountries();
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.chip-progress-bar {
  height: 10px;
  width: 200px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}

.progress {
  height: 100%;
  background-color: #3b82f6; /* Green color for progress */
}
.small-plus-button {
  width: 24px; /* Adjust the width to your preference */
  height: 24px; /* Adjust the height to your preference */
  font-size: 12px; /* Adjust the font size to your preference */
}
.v-btn--icon {
  border: 2px solid #ccc; /* Border color */
  border-radius: 50%; /* Round shape for the button */
  padding: 40px; /* Adjust padding as needed */
}
.v-dialog {
  overflow-y: hidden; /* Prevent vertical scroll in modal */
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.beta-indicator {
  position: absolute;
  top: 10px; /* Adjust to position the 'Beta' text */
  right: 10px; /* Adjust to position the 'Beta' text */
  background-color: #ffcc00; /* Yellow background */
  color: #000; /* Black text */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
}
.relative {
  position: relative;
}
input[type="text"] {
  padding-right: 2rem; /* Make sure there is space for the close button */
}
button {
  outline: none;
  border: none;
}

@media (max-width: 600px) { 
    .v-stepper__content {
      padding: 0 !important;
    }
  }
</style>
