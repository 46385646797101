<template>
    <v-container style="max-width: 1000px">
  
      <please-wait v-if="isLoading" />
  
      
      <v-stepper v-model="step" vertical flat>
        
        <v-stepper-step :editable="step > 1" step="1">
        Which car was sold 
        </v-stepper-step>
        <v-stepper-content step="1" style="max-width: 500px">
          
          <form-wrapper @submit="step = 2" class="ml-3">
            <!-- <div class="mb-2 -ml-2" >For Olx:</div> -->
            <div >
                <input-field label="Sold By *" rules="required" v-model="form.sellBy" />
                <v-btn small text @click="saveBusinessName" color="primary" class="my-link-button -mt-10">Save</v-btn>
            <input-field label="Sold To *" rules="required" v-model="form.sellTo" />
              <select-field label="Year *" rules="required" :items="years" v-model="form.year" />
              
              <autocomplete-field label="Brand *" rules="required" v-model="form.brand" :items="brands" @input="
                  form.model = '';
                  form.variant = '';
                " />
                <autocomplete-field label="Model *" rules="required" :items="models" v-model="form.model" @input="form.variant = '';"  />
                
                <autocomplete-field label="Variant *" :items="variants" rules="required" v-model="form.variant" />
            </div>
         
  
              <div class="mb-3">
              <v-btn color="primary" depressed @click="goToNextStep(2)"> Continue </v-btn>
              <v-btn text @click="step = 1"> Back </v-btn>
            </div>
            <v-alert dense outlined type="error" v-if="errors.length">
                <p v-for="(error, i) of errors" :key="i" class="mb-0">
                  {{ error }}
                </p>
            </v-alert>
  
          </form-wrapper>
        </v-stepper-content>
        <!-- 2 -->
  
        
        <v-stepper-step step="2" :editable="step > 2"> Images</v-stepper-step>
        <v-stepper-content step="2" style="max-width: 400px">
          <v-row>
            <v-col cols="6" v-for="(image, i) in thumbnails" :key="i">
              <v-card outlined height="150px" max-width="200px" style="position: relative; overflow: hidden">
                <v-img width="100%" :src="getThumbnail(i)" height="100%" >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-btn icon color="red" @click="removeAssets(i)"
                  style="position: absolute; top: 0px; right: 0px"><v-icon>mdi-close</v-icon></v-btn>
              </v-card>
            </v-col>
            <v-col cols="6" v-if="form.images.length < 20">
              <v-card @click="initCloudinaryWidget()" outlined height="150px" max-width="200px"
                class="d-flex align-center justify-center">
                <v-icon size="75px">mdi-image-plus-outline</v-icon>
              </v-card>
            </v-col>
          </v-row>
          <!-- <input type="file" ref="file" accept="image/*" class="d-none" multiple @change="onImageChange" /> -->
          <div class="my-3">
            <v-btn color="primary" depressed @click="handleImagesSubmit()">
              Continue 
            </v-btn>
            <v-btn text @click="step = 1"> Back </v-btn>
          </div>
        </v-stepper-content>
  
        <v-stepper-step step="3" :editable="step > 3"> Suggest Social Media Post</v-stepper-step>
        <v-stepper-content step="3">
          <template v-if="step == 3">
            <v-card width="600px"  flat>
              <!-- <v-select label="Select Tone" :items="tones" class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                :rules="rules" color="#8056DE" dense item-text="name" v-model="generateCarListingData.tone" item-value="name">
                <template v-slot:item="{ item, on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.description
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select> -->
              <!-- <input-field label="Max Suggestions" v-model="generateCarListingData.maxSuggestions" type="number" rules="maxsuggestions" /> -->
              <!-- <input-field label="Max Chars" v-model="generateCarListingData.maxChars" type="number" class=" w-[255px] mt-3 md:w-[293px]" /> -->
              <div class="flex gap-2 mb-5 flex-wrap">
                <v-checkbox v-model="generateCarListingData.withEmoji" class="mt-6 w-[170px] h-12 bg-slate-100 rounded-3xl px-2 pt-3"
                label="With Emojis" />
                <v-checkbox v-model="includeBasicDetails" class="mt-6 w-[270px] h-12 bg-slate-100 rounded-3xl px-2 pt-3"
                label="Include Car Basic Details" />
              </div>
              <div class="flex gap-2 mb-5 flex-wrap">
           
            </div>
            <v-btn color="primary" depressed class="ml-3" @click="suggestPosts">{{ buttonText }}</v-btn>
  
            </v-card>
            
            <v-radio-group v-model="selectedResponse" @change="updateDescription(selectedIndex, selectedResponse)">
    <v-row>
      <v-col
        v-for="(item, index) in carlistingResponse"
        :key="index"
        cols="12"
        sm="6"
        md="12"
        lg="12"
      >
  
      <v-radio :value="item" @click="updateDescription(index, item)">
          <template v-slot:label>
            <v-card class="bg-slate-100" flat>
              <p>{{ item }}</p>
            </v-card>
          </template>
        </v-radio>
      </v-col>
    </v-row>
  </v-radio-group>
  
  
          </template>
          <div class="flex  gap-2 flex-wrap">
            
         
            <!-- <v-btn color="primary" depressed @click="createPosts"> Publish Post </v-btn> -->
            <v-btn color="primary" depressed :disabled="!selectedResponse" @click="step = 4"> Continue </v-btn>
  
            <v-btn text @click="step = 2"> Back </v-btn>
          </div>
          
        </v-stepper-content>
  
        <v-stepper-step :editable="step > 4" step="4"> Listing Footer </v-stepper-step>
        <v-stepper-content step="4">
          <div class="mb-2 text-sm -mt-1">Define hashtags, address and contact info to be included in your listings footer.<br/>These details will be saved under your account
  
  .</div>
  <form-wrapper @submit="step = 5">
      <!-- Responsive row for Hashtags -->
      <v-row align="center" class="mb-n4">
        <v-col cols="12" md="5">
          <div class="flex">
            <v-checkbox hide-details class="ma-0 pa-0" v-model="form.hashtagEnabled" @change="updateSelectedResponse"/>
            <div class="font-semibold text-sm">Include Saved Hashtags</div>
          </div>
          <v-text-field
      :disabled="!form.hashtagEnabled"
      v-model="hashtagInput"
      placeholder="Type a hashtag and press enter"
      @keydown.enter="addHashtag($event)"
      outlined
      @input="updateSelectedResponse"
      class="mt-2"
      append-outer-icon="mdi-plus"
      @click:append-outer="addHashtagClick"
  >
  </v-text-field>
          <div class="text-sm -mt-4 ml-3">Saved Hashtags:</div>
          <v-container class="px-0 py-0 mt-n2">
            <v-chip
              v-for="(hashtag, index) in form.hashtags"
              :key="index"
              :close="form.hashtagEnabled"
              :disabled="!form.hashtagEnabled"
              class="ma-2"
              @click:close="form.hashtagEnabled && removeHashtag(index)"
            >
              {{ hashtag }}
            </v-chip>
          </v-container>
        </v-col>
      </v-row>
      <v-row align="center" class="mb-n4">
      <v-col cols="12" md="5">
        <div class="flex">
          <v-checkbox hide-details class="ma-0 pa-0" v-model="form.addressEnabled" @change="updateSelectedResponse" />
          <div class="font-semibold text-sm">Include Address</div>
        </div>
        <v-textarea
          v-model="form.address"
          auto-grow
          outlined
          rows="3"
          placeholder="Add Address"
          @input="updateSelectedResponse"
          :disabled="!form.addressEnabled"
          required
          class="mt-2"
        ></v-textarea>
      </v-col>
    </v-row>

    <!-- Responsive row for Contact Info -->
    <v-row align="center" class="mb-2">
      <v-col cols="12" md="5">
        <div class="flex">
          <v-checkbox hide-details class="ma-0 pa-0" v-model="form.contactNumberEnabled" @change="updateSelectedResponse" />
          <div class="font-semibold text-sm">Include Contact Info</div>
        </div>
        <!-- <input-field
          v-model="form.contactNumber"
          @input="updateSelectedResponse"
          :disabled="!form.contactNumberEnabled"
          class="mt-2"
        /> -->
        <v-textarea
        v-model="form.contactNumber"
          auto-grow
          outlined
          rows="3"
          placeholder="Add contact details"
          @input="updateSelectedResponse"
          :disabled="!form.contactNumberEnabled"
          required
          class="mt-2"
        ></v-textarea>
      </v-col>
    </v-row>

  
      <!-- Responsive buttons -->
      <div class=" mt-3">
        <v-btn color="primary" depressed type="submit" @click="saveBrandDetails">Continue</v-btn>
        <v-btn text @click="postPreviewContinue">Back</v-btn>
      </div>
    </form-wrapper>
  </v-stepper-content>

  
        <v-stepper-step step="5" :editable="step > 65"> Your Post Preview</v-stepper-step>
        <v-stepper-content step="5">
          <v-row>
      <!-- Image Carousel Column -->
      <!-- <v-carousel
              hide-delimiters
              v-model="carousel"
              class="mt-1 rounded-lg"
              v-if="carousels.length"
              :show-arrows="carousels.length > 1"
              :height="isMobile ? '310px' : 'auto'"
            >
              <v-carousel-item v-for="(image, index) in form.images" :key="index">
                <div class="h-full d-flex align-center">
                  <v-img
                    :src="image.url"
                    style="height: 345px; object-fit: cover"
                  />
                </div>
              </v-carousel-item>
            </v-carousel> -->
      <v-col cols="12" md="6">
        <v-carousel hide-delimiters height="300px" v-if="form.images.length > 1" class="custom-carousel mt-5 justify-center">
        <v-carousel-item v-for="(image, index) in thumbnails" :key="index">
                <div class="h-full w-full d-flex align-center">
                  <v-img
                  style="background-size: 'contain' !important;"
                    :src="getThumbnail(index)"
                    aspect-ratio="1.5"
                   class="image-carousell"
                  />
                </div>
              </v-carousel-item>
      </v-carousel>
      <div v-else-if="form.images.length === 1" class="mt-5">
        <v-img style="background-size: 'contain' " :src="form.images[0].url" aspect-ratio="1.5"></v-img>
      </div>
    </v-col>
  
      <!-- Selected Response Column -->
      <v-col cols="12" md="6">
      <v-card flat>
        <v-card-text class="text--primary mobile-card-text">
          <div v-if="form.socialmediaDescription">
            <pre>{{ form.socialmediaDescription }}</pre>
          </div>
          <div v-else-if="selectedResponse">
            <pre>{{ selectedResponse }}</pre>
          </div>
          <div v-else>
            No response selected.
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
  
    <v-btn color="primary" depressed @click="createPosts" class="mt-3">Prepare to Publish </v-btn>
    <v-btn text @click="step = 4" class="mt-3">Back</v-btn>
        </v-stepper-content>
        
      </v-stepper>
      
  
      <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" color="success">
    {{ snackbar.text }}
  </v-snackbar>
    </v-container>
  </template>
  
  <script>
  // import { mapState } from 'vuex';
  import InputField from "@/components/ui/form/InputField.vue";
  import FormWrapper from "@/components/ui/form/FormWrapper.vue";
  import moment from "moment";
  import SelectField from "@/components/ui/form/SelectField.vue";
  import { getBase64, getDate } from "@/utils/common.utils";
  import Textarea from "@/components/ui/form/Textarea.vue";
  import RadioGroup from "@/components/ui/form/RadioGroup.vue";
  import ImageOverlay from "@/components/fly/ImageOverlay.vue";
  import axios from "axios";
  import AutocompleteField from "@/components/ui/form/AutocompleteField.vue";
  import PleaseWait from "@/components/ui/PleaseWait.vue";
  import { httpsCallable } from "firebase/functions";
  import { functions, colUsers } from "@/utils/firebase.utils";
  import openaiMixin from "@/mixins/openai.mixin";
  import rulesConstants from "@/assets/constants/rules.constants";
  import {
    collection,
    doc,
    onSnapshot,
    query,
    getDocs,
    setDoc,
    addDoc,
    getDoc
  } from "firebase/firestore";
  
  import _ from "lodash";
  import imageOverlayMixin from "@/mixins/image.overlay.mixin";
  import { colGallery, storage } from "@/utils/firebase.utils";
  import { getDownloadURL, ref, uploadString } from "firebase/storage";
  export default {
    components: {
      InputField,
      FormWrapper,
      SelectField,
      Textarea,
      RadioGroup,
      ImageOverlay,
      PleaseWait,
      AutocompleteField,
    },
    data() {
      return {
        workspaceName: '', 
        colors: [
          "Red",
          "Orange",
          "Yellow",
          "Green",
          "Blue",
          "Indigo",
          "Purple",
          "Black",
          "White",
          "Grey",
          "Silver",
          "Beige",
          "Golden",
          "Brown",
  
        ],
        thumbnails: [],
        citiesLoaded: false,
        statesLoaded: false,
        olx_citiesLoaded: false,
        olx_statesLoaded: false,
        olx_connected:true,
        snackbar: {
          show: false,
          text: '',
          timeout: 3000
        },
        formErrorMessages: {
          step1: '',
          step2: '',
          step3: '',
          // Add more steps as needed
        },
        errors:[],
        suggestedOption: 'SuggestOlx',
        updatedResponse: null,
        step: 1,
        isLoading: false,
        loading: false,
        logoUrl: "",
        // selectedCar: null,  // Initial state is null, meaning no selection has been made
        carlistingResponse: [],
        selectedIndex: null,
        twitterResponses:[],
        gmbResponses:[],
        olx_neighbourhoodsLoaded: false,
        quikrModelsLoaded:false,
        quikrVariantsLoaded:false,
        selectedResponse: null,
        twitterResponse: null,
        gmbResponse:null,
        checkboxOlx: false,
        checkboxQuikr: false,
        // neighbourhoodsArr: [],
        hashtagInput: '',
        selectedCityId: "",
        localityNames: [],
        selectedLocalityId: '',
        olx_cityNames: [],
        cityNames: [],
        selectedCity: '',
        locality: '',
        stateNames: [],
        states: [],
        cities: [],
        selectedState: '',
        olx_localityNames: [],
        olx_selectedCity: '',
        olx_locality: '',
        olx_stateNames: [],
        olx_states: [],
        olx_cities: [],
        olx_selectedState: '',
  
        currentToneIndex: 0,
  
        form: {
            sellBy: '',
      sellTo: '',
          socialmediaDescription: null,
          imageDataArray: [],
          hashtags: [],
          vehicleNo: '',
          color: '',
          taxType: '',
          insuranceType: '',
          validity: '',
          address: "",
          contactNumber: "",
          hashtagEnabled: true,
          addressEnabled: true,
          contactNumberEnabled: true,
          olx_description: "", 
          fuel: "",
          title: "",
          transmission: "",
          number_of_owners: "",
          mileage: "",
          brand: "",
          model: "",
          variant: "",
          model_key: "",
          model_value:"",
          variant_key: "",
          variant_value:"",
          olx_brand: "",
          olx_model: "",
          olx_variant: "",
          olx_model_key: "",
          olx_model_value:"",
          olx_variant_key: "",
          olx_variant_value:"",
          quikr_year: "",
          olx_year:"",
          quikr_brand: "",
          quikr_model: "",
          quikr_variant: "",
          quikr_description: "", 
          quikr_fuel: "",
          quikr_title: "",
          quikr_number_of_owners: "",
          highlights: [],
          price: "",
          images: [],
          specialInstructionToAI: "",
          logoPosition: "Top Left",
          highlightPosition: "Bottom Right",
          font: "Roboto",
          fontSize: 30,
          fontColor: "red",
          olx_state: "",
          olx_city: "",
          olx_locality: "",
          quikr_city: "",
          quikr_locality: "",
        },
        taxTypes: [
        { name: 'Individual', value: 'INDIVIDUAL' },
        { name: 'Corporate', value: 'Corporate' },
        { name: 'Society', value: 'Society' }
      ],
      insuranceTypes: [
        { name: 'Comprehensive', value: 'Comprehensive' },
        { name: 'Third-Party', value: 'Third-Party' }
      ],
        includeBasicDetails: true,
        generateCarListingData: {
          tone: "",
          maxSuggestions: 5,
          maxChars: 224,
          withEmoji: true,
        },
        rules: [rulesConstants.required], 
        selectedCar: "",
        images: [],
      };
    },
    mixins: [imageOverlayMixin, openaiMixin],
    watch: {
      'form.year'(newVal, oldVal) {
        this.updateTitleOlx();
      },
      'form.brand'(newVal, oldVal) {
        this.form.model_value="";
        this.form.variant_value="";
        this.form.olx_brand=this.form.brand;
        this.updateTitleOlx();
      },
      'form.olx_brand'(newVal, oldVal) {
        this.form.olx_model_value="";
        this.form.olx_variant_value="";
        // this.updateTitleOlx();
      },
      'form.model'(newVal, oldVal) {
        this.form.variant_value="";
        let olxModelWhole=this.models.find(model => model.value === newVal);
        this.form.model_key = olxModelWhole.key;
        this.form.model_value = olxModelWhole.text;
        this.form.olx_model=this.form.model;
        // console.log('olx_model_key',this.form.olx_model_key);
        // console.log('olx_model_value',this.form.olx_model_value);
        this.updateTitleOlx();
      },
      'form.olx_model'(newVal, oldVal) {
        this.form.olx_variant_value="";
        let olxModelWhole=this.olxModels.find(model => model.value === newVal);
        this.form.olx_model_key = olxModelWhole.key;
        this.form.olx_model_value = olxModelWhole.text;
        // console.log('olx_model_key',this.form.olx_model_key);
        // console.log('olx_model_value',this.form.olx_model_value);
        // this.updateTitleOlx();
      },
      'form.variant'(newVal, oldVal) {
        let olxVariantWhole=this.variants.find(variant => variant.value === newVal);
        this.form.variant_key = olxVariantWhole.key;
        this.form.variant_value = olxVariantWhole.text;
        this.form.olx_variant=this.form.variant;
        // console.log('olx_variant_key', this.form.olx_variant_key);
        // console.log('olx_variant_value', this.form.olx_variant_value);
        this.updateTitleOlx();
      },
      'form.olx_variant'(newVal, oldVal) {
        let olxVariantWhole=this.olxVariants.find(variant => variant.value === newVal);
        this.form.olx_variant_key = olxVariantWhole.key;
        this.form.olx_variant_value = olxVariantWhole.text;
        // console.log('olx_variant_key', this.form.olx_variant_key);
        // console.log('olx_variant_value', this.form.olx_variant_value);
        // this.updateTitleOlx();
      },
  
      'form.quikr_year'(newVal, oldVal) {
        this.updateTitleQuikr();
      },
      'form.quikr_brand'(newVal, oldVal) {
        this.updateTitleQuikr();
      },
      'form.quikr_model'(newVal, oldVal) {
        this.updateTitleQuikr();
      },
      'form.quikr_variant'(newVal, oldVal) {
        this.updateTitleQuikr();
      },
      'selectedState'(newVal, oldVal){
        this.handleStateChange();
      },
      'selectedCity'(newval, oldVal){
        this.handleCityChange();
      },
      // 'statesLoaded'(newVal) {
      //   if (newVal) {
      //     this.fetchBrandDetails();
      //   }
      // }
  
    },
    computed: {
    buttonText() {
      return this.carlistingResponse.length > 0 ? 'Suggest Variations' : 'Suggest Post';
    },
    priceInWords() {
      return this.convertNumberToWords(this.form.price);
    },
    isStep1Valid() {
    return this.form.year && this.form.brand && this.form.model && this.form.variant && this.form.fuel && this.form.transmission && this.form.number_of_owners && this.form.mileage;
  },

    olxFuelTypes() {
      return [
        { name: 'CNG & Hybrids', value: 'CNG & Hybrids' },
        { name: 'Diesel', value: 'Diesel' },
        { name: 'Electric', value: 'Electric' },
        { name: 'LPG', value: 'LPG' },
        { name: 'Petrol', value: 'Petrol' }
      ];
    },
    quikrFuelTypes() {
      return [
        { name: 'Petrol', value: 'Petrol' },
        { name: 'Diesel', value: 'Diesel' },
        { name: 'CNG', value: 'CNG' },
        { name: 'Hybrid', value: 'Hybrid' },
        { name: 'Electric', value: 'Electric' },
        { name: 'LPG', value: 'LPG' },
      ];
    },
    Transmissions() {
      return [
        { name: 'Automatic', value: 'Automatic' },
        { name: 'Manual', value: 'Manual' }
      ];
    },
    Number_of_owners() {
      return [
        { name: '1st', value: '1st' },
        { name: '2nd', value: '2nd' },
        { name: '3rd', value: '3rd' },
        {name: '4th', value: '4th'},
        { name: '4+', value: '4+' }
      ];
    },
    olxBrands() {
  let para= this.$store.getters.carCacheOlx;
  // Check if para and para.data are defined before accessing params
  if (para && para.data && para.data.params) {
    let params = para.data.params;
    let item = params.find((i) => i.key === "make");
    if (item && item.values) {
      return _.get(item, "values", []).map((i) => ({
        ...i,
        value: i.name,
      }));
    }
  }
  // Return an empty array if para or para.data.params is not defined
  return [];
},
    brands() {
      let para= this.$store.getters.carCacheOlx;
  // Check if para and para.data are defined before accessing params
        if (para && para.data && para.data.params) {
          let params = para.data.params;
          let item = params.find((i) => i.key === "make");
          if (item && item.values) {
            return _.get(item, "values", []).map((i) => ({
              ...i,
              value: i.name,
            }));
          }
        }
        // Return an empty array if para or para.data.params is not defined
        return [];
    },
    quikrBrands() {
      let result =this.$store.getters.carCacheBrandQuikr;
      // console.log('inside quikrbrands', result);
      let brands = result.map(item => item.brand);
      return brands;
    },
    quikrModels() {
      let result =this.$store.getters.carCacheModelQuikr;
      let models = result.map(item => item.value);
      return models;
    },
    quikrVariants() {
      let result =this.$store.getters.carCacheVariantQuikr;
      // console.log('inside quikrVariants', result);
      return result;
    },
    olxModels() {
      let brand = this.olxBrands.find((i) => i.value == this.form.olx_brand);
      let params = _.get(brand, "params", []);
      let item = params.find((i) => i.key == "model");
      let models = [];  
      _.get(item, "values", []).forEach(i => {
        models.push({
            ...i,
            value: i.key,  
            text:i.name,
        });
    });
    

    let modellist= models.map((item, index) => ({
    ...item,
    uniqueId: item.key + '-' + index,  // Construct a unique ID
  }));
    return modellist.sort((a, b) => a.display_order - b.display_order);

},
models() {
      let brand = this.olxBrands.find((i) => i.value == this.form.brand);
      let params = _.get(brand, "params", []);
      let item = params.find((i) => i.key == "model");
      let models = [];  
      _.get(item, "values", []).forEach(i => {
        models.push({
            ...i,
            value: i.key,  
            text:i.name,
        });
    });
    

    let modellist= models.map((item, index) => ({
    ...item,
    uniqueId: item.key + '-' + index,  // Construct a unique ID
  }));
    return modellist.sort((a, b) => a.display_order - b.display_order);

},
    
    olxVariants() {
      let model = this.olxModels.find((i) => i.value == this.form.olx_model);
      let params = _.get(model, "params", []);
      let item = params.find((i) => i.key == "variant");
      let variants = _.get(item, "values", []).map((i) => ({
        ...i,
        value: i.key,
        text: i.name,
      }));
      return variants.sort((a, b) => a.display_order - b.display_order);
    },
    variants() {
      let model = this.models.find((i) => i.value == this.form.model);
      let params = _.get(model, "params", []);
      let item = params.find((i) => i.key == "variant");
      let variants = _.get(item, "values", []).map((i) => ({
        ...i,
        value: i.key,
        text: i.name,
      }));
      return variants.sort((a, b) => a.display_order - b.display_order);
    },
    // colors() {
    //   return [
    //     "red",
    //     "orange",
    //     "yellow",
    //     "green",
    //     "lightgreen",
    //     "cyan",
    //     "azure",
    //     "blue",
    //     "lightblue",
    //     "violet",
    //     "pink",
    //   ].map((value) => ({
    //     value,
    //     name: value.charAt(0).toUpperCase() + value.slice(1),
    //   }));
    // },
    logoPositions() {
      return ["Top Left", "Top Right"].map((value) => ({ value, name: value }));
    },
    fonts() {
      return ["Roboto"].map((value) => ({ value, name: value }));
    },
    highlightPositions() {
      return ["Bottom Left", "Bottom Right"].map((value) => ({
        value,
        name: value,
      }));
    },
    years() {
      const currentYear = moment().year();
      const startYear = 1600;
      const endYear = currentYear + 1;
      const years = Array.from(
        { length: endYear - startYear },
        (_, index) => startYear + index
      );
      return years.reverse().map((value) => ({ name: value, value }));
    },
    olx_neighbourhoods() {
      const data = this.$store.getters.carNeighbourhoods;
      // console.log('neighbourhoods', data);
      return data;
    },
    canSubmit() {
      return (this.form.olx_city && this.olx_neighbourhoodsLoaded && (this.form.olx_locality || this.neighbourhoods));
    },
    tones() {
      return require("@/assets/constants/carlisting.tones.json");
    },
    quickmod(){
      return this.quikrModels();
    }
  },
  created() {
  this.loadWorkspaceName();
},
    methods: {
      initCloudinaryWidget() {
      let vm = this;
      let uid = this.currentUID || this.uid;
      const myWidget = window.cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: "demo_preset",
          sources: [
            "local",
            "camera",
            "url",
            "dropbox",
            "image_search",
            "shutterstock",
            "gettyimages",
            "istock",
            "unsplash",
            "google_drive",
          ],
          accept: 'image/*',
          clientAllowedFormats: ['png', 'jpg', 'jpeg', 'heic', 'heif', 'webp'],
          multiple: true,
          maxImageFileSize: 20000000,
          tags: [`${uid}_${vm.WID}`],
          folder: `users/${vm.currentUID}/${vm.WID}`,
        },
        (error, result) => {
          if (!error && result && result.event === "queues-end") {
            // console.log('result:', result)
            if (
              result.info.files.length === 1 &&
              result.info.files[0].uploadInfo === null
            ) {
              console.log(
                "Upload did not complete successfully, skipping success handler."
              );
            } else {
              this.handleUploadSuccess(result.info.files);
            }
            myWidget.close();
          }
          if (!error && result && result.event === "success") {
            // console.log("Done! Here is the image info: ", result.info);
          }
        }
      );

      myWidget.open();
    },
        // Handle successful uploads (save to your component's data, etc.)
        handleUploadSuccess(uploadResult) {
      uploadResult.forEach((file) => {
        const uploadInfo = file.uploadInfo;
        if (uploadInfo && uploadInfo.thumbnail_url) {
          let thumbnailUrl = uploadInfo.thumbnail_url;
          thumbnailUrl = thumbnailUrl
            .replace("h_60", "h_320")
            .replace("w_90", "w_320");
          // console.log("thumbnailUrl", thumbnailUrl);
          this.form.images.push({url:uploadInfo.url});
          this.images.push(uploadInfo.url);
          this.thumbnails.push(thumbnailUrl);
        }
      });
    },
    getThumbnail(index) {
  // Ensure form, thumbnails, and images are valid arrays, and index is within bounds
    if (!this.form || index < 0) return "";

      const thumbnails = this.form.thumbnails || [];
      const images = this.form.images || [];

  // Return the thumbnail if it exists, otherwise return the image
      return thumbnails[index] || images[index] || "";
    },
    removeAssets(i) {
      this.form.images.splice(i,1);
      this.images.splice(i,1);
      this.thumbnails.splice(i,1);
    },
        async saveBusinessName() {
    try {
      const userId = this.$store.getters.currentUID;
      const workspaceId = this.WID;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", workspaceId);
      const brandCollectionRef = collection(userDocRef, "brand");
      
      // Check if we already have a brandDocId or fetch the first available document
      if (!this.brandDocId) {
        const brandDocSnapshot = await getDocs(brandCollectionRef);
        if (!brandDocSnapshot.empty) {
          this.brandDocId = brandDocSnapshot.docs[0].id;  // Use the first document if it exists
        } else {
          this.brandDocId = doc(brandCollectionRef).id;  // Create a new document ID if none exists
        }
      }

      const brandDocRef = doc(brandCollectionRef, this.brandDocId);
      await setDoc(brandDocRef, { business_name: this.form.sellBy }, { merge: true });
      this.showSnackbar("Business name saved!");
    } catch (error) {
      console.error("Failed to save business name:", error);
      this.showSnackbar("Failed to save business name.");
    }
  },
        loadWorkspaceName() {
    const workspaceName = localStorage.getItem('CURRENT_WORKSPACE_NAME');
    if (workspaceName && workspaceName.toLowerCase() !== 'default') {
      this.workspaceName = workspaceName;
      this.form.sellBy = workspaceName;
    } else if (workspaceName.toLowerCase() === 'default') {
      this.workspaceName = workspaceName;
      this.form.sellBy = ''; // Keep 'sellBy' empty if the workspace name is 'default'
    } else {
      console.error('No valid workspace name found in local storage.');
      // Optionally fetch from the server or handle the lack of a workspace name appropriately
    }
  },
      async getolxconnected(){
        let vm = this;
        let q = query(
          collection(
            doc(colUsers, this.currentUID, "fly_workspaces", vm.WID),
            "connected-apps"
          )
        );
        let connectedApps = (await getDocs(q)).docs.map((i) => i.id);
        let isOLXConnected = connectedApps.includes('olx');
        if(!isOLXConnected){
          this.olx_connected=false;
        }
        // console.log("tell me",this.olx_connected,isOLXConnected);
      },
      async fetchLocations() {
        try {
          const response = await fetch('https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Folx%2Flocations.json?alt=media&token=45caff5d-129a-45b6-aed0-5f08d8698a1c');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (data && data.data && data.data.length > 0 && data.data[0].children) {
            const children = data.data[0].children;
            this.states = children.filter(child => child.type === 'STATE').map(state => ({
              id: state.id,
              name: state.name,
              children: state.children,
            }));
            this.stateNames = this.states.map(state => state.name); // Populate stateNames for dropdown
          }
        } catch (error) {
          // console.error('Error fetching locations data:', error);
        } finally {
          this.statesLoaded = true;
        }
  
      },
      async handleStateChange() {
        const selectedStateObject = this.states.find(state => state.name === this.selectedState);
        // console.log('Selected State Object:', selectedStateObject);
        this.form.olx_state=this.selectedState;
        await this.olxHandleStateChange();
        this.cities = selectedStateObject ? selectedStateObject.children : [];
        this.cityNames = this.cities.map(city => city.name);
        this.selectedCity = null; // Reset city selection
        this.locality = ''; // Reset locality
        this.localityNames = []; // Reset locality names
      },
      async handleCityChange() {
        this.locality = ''; // Reset locality when city changes
        this.localityNames = []; // Reset locality names
        let neighborhoodUrl = '';
        this.form.olx_city=this.selectedCity;
        
        if (this.selectedCity === 'Pune') {
          neighborhoodUrl = 'https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Folx%2FNeighbourhood%2FPune.json?alt=media&token=45caff5d-129a-45b6-aed0-5f08d8698a1c';
        } else if (this.selectedCity === 'Mumbai') {
          neighborhoodUrl = 'https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Folx%2FNeighbourhood%2FMumbai.json?alt=media&token=45caff5d-129a-45b6-aed0-5f08d8698a1c';
        }
        if (neighborhoodUrl) {
          try {
            const response = await fetch(neighborhoodUrl);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // console.log('Fetched Neighbourhood Data:', data);
            if (data && data.data) {
              this.localityNames = data.data.filter(item => item.type === 'NEIGHBOURHOOD').map(neighbourhood => neighbourhood.name);
              // console.log('Parsed Neighbourhoods:', this.localityNames);
            } else {
              // console.error('Unexpected data format', data);
            }
          } catch (error) {
            // console.error('Error fetching neighbourhood data:', error);
          }
        }
         this.olxHandleCityChange();
      },
  
      async olxFetchLocations() {
        try {
          const response = await fetch('https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Folx%2FlocationsOLX.json?alt=media&token=45caff5d-129a-45b6-aed0-5f08d8698a1c');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          // console.log('Fetched Locations Data:', data);
          if (data && data.data && data.data.length > 0 && data.data[0].children) {
            const children = data.data[0].children;
            // console.log("Children Array:", children);
            
            // Filter and map states
            this.olx_states = children.filter(child => child.type === 'STATE').map(state => ({
              id: state.id,
              name: state.name,
              children: state.children,
            }));
            this.olx_stateNames = this.olx_states.map(state => state.name); // Populate stateNames for dropdown
            // console.log('Parsed States:', this.olx_states);
          } else {
            // console.error('Unexpected data format', data);
          }
        } catch (error) {
          // console.error('Error fetching locations data:', error);
        } finally {
          this.olx_statesLoaded = true;
        }
      },
      olxHandleStateChange() {
        const selectedStateObject = this.olx_states.find(state => state.name === this.form.olx_state);
        // console.log('Selected State Object:',this.form.olx_state );
        this.olx_cities = selectedStateObject ? selectedStateObject.children : [];
        this.olx_cityNames = this.olx_cities.map(city => city.name);
        this.form.olx_city = null; // Reset city selection
        this.form.olx_locality = ''; // Reset locality
        this.olx_localityNames = []; // Reset locality names
      },
      async olxHandleCityChange() {
        this.form.olx_locality = ''; // Reset locality when city changes
        this.olx_localityNames = []; // Reset locality names
        // console.log('Selected State Object:',this.form.olx_city );
        // console.log('this.olx_cities:: ',this.olx_cities);
  
        this.olx_neighbourhoodsLoaded = false;
        if (!this.form.olx_city) {
          return; // Exit if no city is selected
        }
  
        const data = this.olx_cities;
        const city = data.find(city => city.name === this.form.olx_city);
        let cityId = city.id;
        // console.log('cityId', cityId);
  
        const url = `https://www.olx.in/api/locations?parent=${cityId}&hideAddressComponents=true&lang=en-IN`
        // Return undefined or any other value to indicate the city was not found
        const dataNeighbour = await this.fetchState(url, null, false);
  
        this.olx_neighbourhoodsLoaded = true;
  
      },
  
      convertNumberToWords(num) {
        this.getolxconnected();
    if (num === null || num === undefined) return '';
  
    const ones = [
      'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    if (num < 20) return ones[num];
    if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 ? ' ' + ones[num % 10] : '');
    if (num < 1000) return ones[Math.floor(num / 100)] + ' Hundred' + (num % 100 ? ' ' + this.convertNumberToWords(num % 100) : '');
  
    if (num < 100000) return this.convertNumberToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 ? ' ' + this.convertNumberToWords(num % 1000) : '');
    if (num < 10000000) return this.convertNumberToWords(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 ? ' ' + this.convertNumberToWords(num % 100000) : '');
  
    return this.convertNumberToWords(Math.floor(num / 10000000)) + ' Crore' + (num % 10000000 ? ' ' + this.convertNumberToWords(num % 10000000) : '');
  }
  ,
      getRandomTone() {
        const toneKeys = this.tones;
        this.currentToneIndex= Math.floor(Math.random() * this.tones.length);
        const tone = toneKeys[this.currentToneIndex].name;
        this.currentToneIndex = (this.currentToneIndex + 1) % toneKeys.length;
        return tone;
      },
      generateSuggestions() {
        // Dummy function to generate suggestions
        let suggestions = [];
        for (let i = 0; i < this.generateCarListingData.maxSuggestions; i++) {
          suggestions.push(`Suggestion ${i + 1}`);
        }
        return suggestions;
      },
  
      updateDescription(index, value) {
      this.form.socialmediaDescription = value;
      this.selectedResponse = value;
      this.selectedIndex = index; // Store the index
    },
  
      showSnackbar(message) {
      this.snackbar.text = message;
      this.snackbar.show = true;
    },
      validateStep1() {
      
      let errors=[];
      if(!this.form.year){
        errors.push('Year is required')
      }
      if(!this.form.brand){
        errors.push('Brand is required')
      }
      if(!this.form.model){
        errors.push('Model is required')
      }
      if(!this.form.variant){
        errors.push('Variant is required')
      }
    
      this.errors=errors;
      if (errors.length>0) {
        return false;
      }
      this.formErrorMessages.step1 = ''; // Clear any previous error message
      return true;
    },
    validateStep2() {
      
      let errors=[];
      if(!this.form.price){
        errors.push('Price is required');
      }
      this.errors=errors;
      if (errors.length>0) {
        return false;
      }
      return true;
    },
    
    goToNextStep(nextStep) {
      const isValid = this[`validateStep${this.step}`](); // Call validation function dynamically based on current step
      if (isValid) {
        this.step = nextStep; // Only change step if validation passes
      }
    },
  
  
      handlePlatform(){
        if(this.checkboxOlx){
          this.suggestedOption='SuggestOlx';
        }
        else{
          this.suggestedOption='SuggestQuikr'
        }
      },
      fetchCities() {
        if (this.form.quikr_city.length > 1) {
          // console.log("Fetching cities for:", this.form.quikr_city);
          const getCitySuggestions = httpsCallable(functions, "quickrCity");
          getCitySuggestions({ query: this.form.quikr_city })
            .then((result) => {
              // console.log("Result from Firebase:", result);
              if (result.data && result.data.data) {
                this.cityNames = result.data.data.map((item) => ({
                  id: item[0],
                  name: item[1],
                }));
                // console.log("Updated city names:", this.cityNames);
              } else {
                this.cityNames = [];
                // console.error("Unexpected result structure:", result);
              }
            })
            .catch((error) => {
              // console.error("Error fetching cities:", error);
              this.cityNames = [];
            });
        } else {
          this.cityNames = [];
          // console.log("Cleared city names due to insufficient input length.");
        }
      },
      fetchLocalities() {
        if (this.form.quikr_locality.length > 1) {
          // console.log("Fetching cities for:", this.form.quikr_city);
          const getCitySuggestions = httpsCallable(functions, "quickrLocality");
          getCitySuggestions({ query: this.form.quikr_locality, cityId: this.selectedCityId, cityName: this.form.quikr_city})
            .then((result) => {
              // console.log("Result from Firebase:", result);
              // console.log("Result from Firebase data:", result.data);
              // console.log("Result from Firebase data.data:", result.data.data);
              if (result.data && result.data.data) {
                this.localityNames = result.data.data.map((item) => ({
                  id: item[0],
                  name: item[1],
                }));
                // console.log("Updated locality names:", this.localityNames);
              } else {
                this.localityNames = [];
                // console.error("Unexpected result structure:", result);
              }
            })
            .catch((error) => {
              // console.error("Error fetching cities:", error);
              this.localityNames  = [];
            });
        } else {
          this.localityNames  = [];
          // console.log("Cleared city names due to insufficient input length.");
        }
      },
      selectCity(item) {
        this.form.quikr_city = item.name; // Update the city input with the selected city name
        this.selectedCityId = item.id; // Store the selected city ID
        this.cityNames = []; // Clear the list after selection
        // console.log("Selected city:", item.name, item.id);
      },
      selectLocality(item) {
        this.form.quikr_locality = item.name; // Update the city input with the selected city name
        this.selectedCityId = item.id; // Store the selected city ID
        this.localityNames = []; // Clear the list after selection
        // console.log("Selected locality:", this.form.quikr_locality, this.selectedCityId);
      },
      postPreviewContinue(){
        // this.updateSelectedResponse(); // Update the response based on current selections
        this.step = 3;
      },
      updateTitleOlx() {
        // console.log('form:: ', this.form);
        if (this.form.year && this.form.brand && this.form.model_value ) {
          this.form.title = `${this.form.year} ${this.form.brand} ${this.form.model_value} ${this.form.variant_value}`;
        }},
        updateTitleQuikr() {
          if (this.form.quikr_year && this.form.quikr_brand && this.form.quikr_model && this.form.quikr_variant) {
            this.form.quikr_title = `${this.form.quikr_year} ${this.form.quikr_brand} ${this.form.quikr_model} ${this.form.quikr_variant}`;
          }
        },
      updateSelectedResponse() {
        let response = this.selectedResponse; // This should be your initial response text.
        this.form.socialmediaDescription= response;
        if(this.generateCarListingData.withEmoji){
        
        }
        else{
          this.form.socialmediaDescription+="\nKms Driven: " + this.form.mileage;
          this.form.socialmediaDescription+="\nPrice: " + this.form.price;      
        }
        // Append address if enabled
        if (this.form.addressEnabled && this.form.address.trim() !== "") {
          response += "\n" + this.form.address;
          this.form.socialmediaDescription+="\n" + this.form.address;
        }
  
        // Append contact number if enabled
        if (this.form.contactNumberEnabled && this.form.contactNumber.trim() !== "") {
          // response += "\n" + this.form.contactNumber;
          this.form.socialmediaDescription+="\n" + this.form.contactNumber;
        }
  
        // Append hashtags if enabled
        if (this.form.hashtagEnabled && this.form.hashtags.length > 0) {
          response += "\n" + this.form.hashtags.join(" ");
          this.form.socialmediaDescription+="\n" + this.form.hashtags.join(" ");
        }
  
        this.updatedResponse = response;
      },
  
      async onImageChange(e) {
        let length = this.form.images.length + e.target.files.length;
        if (length > 20) {
          this.$alert.show("Only 20 images are allowed");
          this.$refs.file.value = null;
          return;
        }
        for (let file of e.target.files) {
          this.form.images.push({
            url: await getBase64(file),
          });
        }
        this.$refs.file.value = null;
      },
      handleImagesSubmit() {
        if (this.form.images.length == 0) {
          this.$alert.show("Please select at least one image.");
          return;
        }
        this.step = 3;
      },
      removeHighlight(i) {
        let text = this.form.highlights[i];
        this.form.images.forEach((item, imgIndex) => {
          if (item.highlights) {
            let index = item.highlights.findIndex((h) => {
              return text == h;
            });
            if (index != -1) {
              let highlights = item.highlights;
              highlights.splice(index, 1);
              this.$set(this.form.images, imgIndex, {
                ...item,
                highlights,
              });
            }
          }
        });
        this.form.highlights.splice(i, 1);
      },
      async fetchCarCacheOlx() {
        try {
          this.isLoading = true;
          let url =
            "https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Folx%2Fcategories.json?alt=media&token=45caff5d-129a-45b6-aed0-5f08d8698a1c";
          let data = (await axios.get(url)).data;
  
          // console.log(data)
          this.isLoading = false;
          this.$store.commit("SET_CAR_CACHE_OLX", data);
        } catch (error) {
          this.handleError(error);
        }
      },
      async fetchCarCacheQuikr() {
        try {
          this.isLoading = true;
          let url =
            "https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly-cache%2Fcars%2Findia%2Fquickr%2Fmakeid%2Fmodel.id.json?alt=media&token=9d65bf11-233d-42b9-ae53-71c5c7ad3673";
          let data = (await axios.get(url)).data.Model;
          // console.log('urldata', data)
          this.isLoading = false;
          this.$store.commit("SET_CAR_BRAND_CACHE_QUIKR", data); 
        } catch (error) {
          this.handleError(error);
        }
      },
      async fetchQuikrModels() {
        this.quikrModelsLoaded=false;
        this.quikrModels=[];
        const data = this.$store.getters.carCacheBrandQuikr;
        const brandId = data.find(model => model.brand === this.form.quikr_brand).id;
  
        try {
          this.isLoading = true;
          let url =
            `https://www.quikr.com/attr/postad/getAttributes?type=ATTRIBUTE&subcatId=71&attrId=51&parentValueId=${brandId}`;
          (await this.fetchState(url, brandId, false));
          this.quikrModelsLoaded=true;
          return [];
        } catch (error) {
          this.handleError(error);
        }
        
  
        return [];
      },
      async fetchQuikrVariants() {
        this.quikrVariantsLoaded=false;
        const data = this.$store.getters.carCacheModelQuikr;
        const modelId = data.find(model => model.value === this.form.quikr_model).id;
        try {
          this.isLoading = true;
          let url =
            `https://www.quikr.com/attr/postad/getAttributes?type=ATTRIBUTE&subcatId=71&attrId=807&parentValueId=${modelId}`;
          (await this.fetchState(url, modelId, false));
          this.quikrVariantsLoaded=true;
          return [];
        } catch (error) {
          this.handleError(error);
        }
        
        return [];
      },
      calculateImageSizeInMB(base64String) {
        const yourBase64String = base64String.substring(
          base64String.indexOf(",") + 1
        );
        const bits = yourBase64String.length * 6; // 567146
        const bytes = bits / 8;
        return parseInt(bytes);
      },
      async fetchState(url, id, isCity) {
        try {
          this.isLoading = true;
          let olxLocation = httpsCallable(functions, "olxLocation");
          let urlLocal;
          if (url) {
            urlLocal = url;
          }
          else {
            urlLocal = 'https://www.olx.in/api/locations';
          }
          const payload = {
            uid: this.$store.getters.currentUID,
            url: urlLocal
          };
          // console.log('urlLocal', urlLocal);
          this.isLoading = false;
          if (urlLocal === 'https://www.olx.in/api/locations') {
            this.statesLoaded=false;
            let dd=(await olxLocation(payload)).data;
            // console.log('dddsfd::: ', dd.result.data[0].children);
            // this.$store.commit("SET_CAR_LOCATION_STATES", dd.result.data[0].children[0].children[0].children[0].children);
            this.$store.commit("SET_CAR_LOCATION_STATES", dd.result.data[0].children);
            
            this.statesLoaded=true;
          }
          else if(urlLocal.startsWith("https://www.quikr.com/attr/postad/getAttributes?type=ATTRIBUTE&subcatId=71&attrId=51&parentValueId")){
            let result= (await olxLocation(payload));
            let res=result.data.result[id];
  
            this.$store.commit("SET_CAR_MODEL_CACHE_QUIKR",res);
          }
          else if(urlLocal.startsWith("https://www.quikr.com/attr/postad/getAttributes?type=ATTRIBUTE&subcatId=71&attrId=807&parentValueId")){
            let result= (await olxLocation(payload));
            let res=result.data.result[id];
            const arr = res.map(item => item.value);
            this.$store.commit("SET_CAR_VARIANT_CACHE_QUIKR",arr);
          }
          else if(isCity==false) {
            
            // console.log('cities::', urlLocal);
            let dd=(await olxLocation(payload)).data;
            let result=dd.result.data;
            const neighbourhoodNames = result.map(item => item.name);
            this.$store.commit("SET_CAR_LOCATION_NEIGHBOURHOODS", neighbourhoodNames);
          }
          else{
            // this.olx_cities=[];
            // console.log('cities::', urlLocal);
            let dd=(await olxLocation(payload)).data;
            // console.log('ddddddddddddd', dd);
            let result=dd.result.data;
            this.$store.commit("SET_CAR_LOCATION_CITIES", result);
          }
  
        } catch (error) {
          this.handleError(error);
        }
      },
      async olxfetchCities() {
        this.form.olx_city = ''; this.form.olx_locality = ''; this.olx_neighbourhoodsLoaded = false;
        this.citiesLoaded = false;
        // console.log("Hiiii2222");
        const data = this.$store.getters.carStates;
        const state = data.find(state => state.name === this.form.olx_state);
        // console.log('state:::', state);
        let stateId =state.id;
        // If the state is found, search for the city
        // if (state && state.children) {
        //   let city = state.children.find(city => city.name === this.form.city);
        //   // If the city is found, return the ID
        //   if (city) {
        //     cityId = city.id;
        //   }
        // }
        // console.log('cityId', cityId)
        const url = `https://www.olx.in/api/locations?parent=${stateId}&hideAddressComponents=true&lang=en-IN`
        // console.log('uriiiilddid', url);
        // Return undefined or any other value to indicate the city was not found
        const dataNeighbour = await this.fetchState(url,null, true);
        // console.log('dataNeighbour', dataNeighbour);
        this.citiesLoaded = true;
      },
      async olxFetchNeighbourhoods() {
        this.olx_neighbourhoodsLoaded = false;
        if (!this.form.olx_city) {
          return; // Exit if no city is selected
        }
  
        const data = this.$store.getters.carCities;
        const city = data.find(city => city.name === this.form.olx_city);
        let cityId = city.id;
        // console.log('cityId', cityId)
        const url = `https://www.olx.in/api/locations?parent=${cityId}&hideAddressComponents=true&lang=en-IN`
        // Return undefined or any other value to indicate the city was not found
        const dataNeighbour = await this.fetchState(url, null, false);
  
        this.olx_neighbourhoodsLoaded = true;
  
      }, 
      async fetchAndSetWorkspaceName() {
  const userId = this.$store.getters.currentUID;
  const workspaceId = this.WID;

  if (!userId || !workspaceId) {
    console.error('User ID or Workspace ID is undefined or empty.');
    return; // Exit the function if necessary data isn't available
  }

  try {
    const userDocRef = doc(colUsers, userId, "fly_workspaces", workspaceId);
    const workspaceSnap = await getDoc(userDocRef);
    if (workspaceSnap.exists()) {
      this.workspaceName = workspaceSnap.data().name; // Assuming the workspace document has a 'name' field
      this.form.sellBy = this.workspaceName; // Set here after ensuring it exists
    } else {
      console.error('Workspace data does not exist.');
    }
  } catch (error) {
    console.error('Failed to fetch workspace details:', error);
  }
},



      generateCarDetails() {
      const details = [];
      const { form } = this;
  
  
      
      return details.join('\n');
    },
      async suggestPosts() {
        let carBasicsDetails = '';
        this.selectedResponse=null;
  
        if (this.includeBasicDetails) {  // Check if checkbox is checked
          
          if(this.suggestedOption=='SuggestOlx'){
            carBasicsDetails = this.generateCarDetails(); 
      }
      else{
      carBasicsDetails = `
      Sell By:  ${this.form.sellBy}
   
      `;
      }
    }
    let data;
    if(this.suggestedOption=='SuggestOlx' || this.checkboxQuikr==false){
      this.generateCarListingData.tone = this.getRandomTone();
      this.suggestions = this.generateSuggestions().slice(-4);
      data = {
        operation: "generateCarSoldListingDescription",
        wid: this.WID,
        tone: this.generateCarListingData.tone,
        maxChars: this.generateCarListingData.maxChars,
        maxSuggestions: this.generateCarListingData.maxSuggestions,
        withEmoji: this.generateCarListingData.withEmoji,
        textContent: {
            soldBy: this.form.sellBy,
            soldto: this.form.sellTo,
          year: this.form.year,
          brand: this.form.brand,
          model: this.form.model_value,
          variant: this.form.variant_value,
          state: this.selectedState,
          city: this.selectedCity,
          locality: this.locality,
        }
      }
      console.log(data)
    }
    else{
      data = {
        operation: "generateCarSoldListingDescription",
        wid: this.WID,
        tone: this.generateCarListingData.tone,
        maxChars: this.generateCarListingData.maxChars,
        maxSuggestions: this.generateCarListingData.maxSuggestions,
        withEmoji: this.generateCarListingData.withEmoji,
        textContent: {
            soldBy: this.form.quikr_sellBy,
            soldto: this.form.quikr_seTo,
          year: this.form.quikr_year,
          brand: this.form.quikr_brand,
          model: this.form.quikr_model,
          variant: this.form.quikr_variant,
          city: this.form.quikr_city,
          locality: this.form.quikr_locality,
        }
      }
      console.log(data)
    }
    await this.handleGenerateCarListingPost(data);
      if (this.carlistingResponse) {
        
        this.carlistingResponse = this.carlistingResponse.slice(-3);
        this.twitterResponses=this.carlistingResponse;
        this.gmbResponses=this.carlistingResponse;
        this.carlistingResponse = this.carlistingResponse.map(response => `${response}\n${carBasicsDetails}`);  // Append the car basics details to each response
        this.gmbResponses= this.carlistingResponse; 
      }
    },
      async createPosts() {
        this.showSnackbar("Post saved to Draft");
        let vm = this;
        let platformType = ["social"];
        let platformCategoryType = ["cars"];
        this.$store.commit("SET_FLY_PLATFORMS_TYPE", platformType);
        this.$store.commit(
          "SET_FLY_PLATFORMS_CATEGORY_TYPE",
          platformCategoryType
        );
        // console.log(
        //   "flyPlatformsType:",
        //   this.flyPlatformsType,
        //   this.flyPlatformsCategoryType
        // );
        vm.$loader.show();
        let q = query(
          collection(
            doc(colUsers, this.currentUID, "fly_workspaces", vm.WID),
            "connected-apps"
          )
        );
        // let connectedApps = (await getDocs(q)).docs.map((i) => ({
  
        //   id: i.id,
        // }));
        let connectedApps = (await getDocs(q)).docs.map((i) => i.id);
        if(!this.checkboxOlx) {
          connectedApps = connectedApps.filter(app => app !== 'olx');
        }
  
        let textData = this.form.socialmediaDescription ? this.form.socialmediaDescription : this.selectedResponse;
        // console.log("textdata", textData);
        this.twitterResponse=this.twitterResponses[this.selectedIndex];
        this.gmbResponse=this.gmbResponses[this.selectedIndex];
      
        if (this.form.hashtagEnabled && this.form.hashtags.length > 0) {
          for(let hastag of this.form.hashtags){
            if(this.twitterResponse.length + hastag.length + 1 > 272){
              break;
            }
            this.twitterResponse += " " + hastag;
          }
        }
        let platforms = [];
        let title = "";
        if (this.suggestedOption == "SuggestOlx") {
          title = this.form.title;
        } else {
          title = this.form.quikr_title;
        }
        if (this.checkboxOlx) {
          platforms.push("olx");
        }
        if (this.checkboxQuikr) {
          platforms.push("quikr");
        }
        let olx_listing_json={};
        let quikr_listing_json={};
        if(this.checkboxOlx){
          olx_listing_json={
            brand: this.form.olx_brand,
            model: this.form.olx_model_value,
            variant: this.form.olx_variant_value,
            year: this.form.year,
            fuel: this.form.fuel,
            transmission: this.form.transmission,
            mileage: this.form.mileage,
            number_of_owners: this.form.number_of_owners,
            title: this.form.title,
            description: this.updatedResponse ? this.updatedResponse : this.selectedResponse,
            price: this.form.price,
            images: this.images,
            state: this.form.olx_state,
            city: this.form.olx_city,
            locality: this.form.olx_locality,
            model_key: this.form.olx_model_key,
            variant_key: this.form.olx_variant_key,
          }
        }
        if(this.checkboxQuikr){
          quikr_listing_json={
            brand: this.form.quikr_brand,
            model: this.form.quikr_model,
            variant: this.form.quikr_variant,
            year: this.form.quikr_year,
            fuel: this.form.quikr_fuel,
            mileage: this.form.mileage,
            number_of_owners: this.form.quikr_number_of_owners,
            description: this.updatedResponse,
            price: this.form.price,
            images: this.images,
            city: this.form.quikr_city,
            locality: this.form.quikr_locality,
            color:this.form.color,
          }
        }
  
  
        let data = {
          created_at: new Date(),
          updated_at: new Date(),
          quikr_listing_json: quikr_listing_json,
          olx_listing_json:olx_listing_json,
          platforms: platforms,
          uid: vm.currentUID,
          wid: vm.WID,
          domain: window.location.host,
          post_status: "draft",
          text: textData,
          name: title,
          social_media: connectedApps,
          images: this.images,
          imageDataArray: this.form.imageDataArray,
          videos: [],
          when_to_post: "immediate",
          configuration: {
            ig_post_type: "Feed",
          },
          isCarListingPost: true,
          twitter_text: this.twitterResponse,
          google_business_profile_text:this.gmbResponse,
        };
        // console.log('data:::', data)
        vm.$loader.show();
        let socialPostRef = collection(
          colUsers,
          vm.currentUID,
          "fly_workspaces",
          vm.WID,
          "social-posts"
        );
        const docRef = await addDoc(socialPostRef, data);
        const postId = docRef.id;
  
        // let addSocialPost = httpsCallable(functions, "addSocialPost");
        // let { postId } = (await addSocialPost(data)).data;
        vm.$loader.hide();
        this.$router.push({
          name: "draft-post",
          params: {
            postId: postId,
          },
        });
      },
    async fetchBrandDetails() { 
      
        let userId = this.$store.getters.currentUID || this.uid;   // or any method to get the userID
        // console.log('userId::: ', userId,this.currentUID, this.uid);
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);
        // console.log('brandDocSnapshot::: ', brandDocSnapshot);
        if (!brandDocSnapshot.empty) {
          const firstDoc = brandDocSnapshot.docs[0];
          this.brandDocId = firstDoc.id; // Save the document ID
          const brandData = firstDoc.data();
          this.form.hashtags = brandData.hashtags || [];
          this.form.address = brandData.address || '';
          this.form.contactNumber = brandData.contactinfo || '';
          this.selectedState = brandData.state || '';
          this.form.sellBy = brandData.business_name || '';
          await this.$nextTick();
          this.selectedCity = brandData.city || '';
          await this.$nextTick();
          this.locality = brandData.locality || '';
        }
  
    },
    async saveBrandDetails() {
      try {
      const userId = this.$store.getters.currentUID;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
      let brandDocRef;
  
      if (this.brandDocId) {
        // Update existing document
        brandDocRef = doc(userDocRef, "brand", this.brandDocId);
      } else {
        // Create new document
        const brandCollectionRef = collection(userDocRef, "brand");
        brandDocRef = doc(brandCollectionRef); // Automatically generate a new document ID
        this.brandDocId = brandDocRef.id; // Save the newly created document ID
      }
  
      await setDoc(brandDocRef, {
        hashtags: this.form.hashtags,
        address: this.form.address,
        contactinfo: this.form.contactNumber,
        state: this.selectedState,
        city: this.selectedCity,
        locality: this.locality
      }, { merge: true });
  
      this.updateSelectedResponse(); // Update the response based on current selections
    } catch (error) {
      console.error('Error saving brand details:', error);
    }
    },
    addHashtag(event) {
      event.preventDefault();  // Prevent the default form submission behavior
      event.stopPropagation(); // Stop the event from bubbling up
  
      let input = this.hashtagInput.trim();
  
      if (input) {
        // Ensure the input starts with '#'
        if (!input.startsWith('#')) {
          input = '#' + input;
        }
        // Check for duplicates before adding
        if (!this.form.hashtags.includes(input)) {
          this.form.hashtags.push(input);
        } 
        this.hashtagInput = ''; // Clear the input field after adding
      }
      this.updateSelectedResponse();
    },
    addHashtagClick() {
        // Simulate an event for addHashtag method
        const simulatedEvent = {
          preventDefault: () => {},
          stopPropagation: () => {}
        };
        this.addHashtag(simulatedEvent);
      },
      removeHashtag(index) {
        this.form.hashtags.splice(index, 1);
        this.updateSelectedResponse();
      }, 
    },
    async mounted() {
      
        await this.olxFetchLocations();
        await this.fetchCarCacheOlx();
        // await this.fetchCarCacheQuikr();
        // await this.fetchState();
        await this.fetchLocations(); // Fetch locations first
        await this.fetchBrandDetails(); // Fetch brand details after locations are fetched
        await this.olxFetchNeighbourhoods();
        if (!this.workspaceName) { // If there's no workspace name set
    this.loadWorkspaceName();
  }


    
    }
  
  };
  </script>
  
  <style>
  .chip-disabled {
    opacity: 0.5;
    pointer-events: none; /* Disable interactions */
  }
  .text--primary pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  @media (max-width: 600px) {
    .mobile-card-text > div {
      padding: 4px; /* Reduced padding for mobile screens */
      margin: -19px;
      margin-bottom: 4px; /* Slightly reduced margins for a closer fit */
    }
    .mobile-card-text pre {
      margin: 0; /* Remove margin from <pre> to maximize space usage */
      white-space: pre-wrap; /* Ensure long text wraps properly */
    }
  }
  .image-carousell {
    background-size: contain !important;
  }
  .my-link-button {
  color: #1976D2; /* Primary color for the link, change as needed */
  text-decoration: underline;
  padding: 0;
  min-width: 0;
}
  </style>