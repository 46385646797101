<template>
  <div class="bg-white p-4 rounded-lg shadow-md pr-60 mt-4 max-w-[1400px] w-[98%] mx-2">
    <div class="flex justify-between">
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
          Published posts
        </p>
        <p class="text-xs text-gray-500 whitespace-nowrap">Last 28 days</p>
        <p class="text-lg font-bold whitespace-nowrap">
          <span v-if="!loading">{{ stats.total_posts }}</span>
          <span v-else class="skeleton-loader"></span>
        </p>
      </div>
      <div class="border-l border-gray-300 mx-4"></div>
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
          Total Engagement
        </p>
        <p class="text-xs text-gray-500 whitespace-nowrap">Last 28 days</p>
        <p class="text-lg font-bold whitespace-nowrap">
          <span v-if="!loading">{{ totalEngagement }}</span>
          <span v-else class="skeleton-loader"></span>
        </p>
      </div>
      <div class="border-l border-gray-300 mx-4"></div>
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
          Total Reactions
        </p>
        <p class="text-xs text-gray-500 whitespace-nowrap">Last 28 days</p>
        <p class="text-lg font-bold whitespace-nowrap">
          <span v-if="!loading">{{ stats.total_likes }}</span>
          <span v-else class="skeleton-loader"></span>
        </p>
      </div>
      <div class="border-l border-gray-300 mx-4"></div>
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
          Total Comments
        </p>
        <p class="text-xs text-gray-500 whitespace-nowrap">Last 28 days</p>
        <p class="text-lg font-bold whitespace-nowrap">
          <span v-if="!loading">{{ stats.total_comments }}</span>
          <span v-else class="skeleton-loader"></span>
        </p>
      </div>
      <div class="border-l border-gray-300 mx-4"></div>
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
          Total Shares
        </p>
        <p class="text-xs text-gray-500 whitespace-nowrap">Last 28 days</p>
        <p class="text-lg font-bold whitespace-nowrap">
          <span v-if="!loading">{{ stats.total_shares }}</span>
          <span v-else class="skeleton-loader"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => ({
        total_posts: undefined,
        total_views: undefined,
        total_likes: undefined,
        total_comments: undefined,
        total_shares: undefined,
      }),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    totalEngagement() {
      if (
        this.stats.total_comments !== undefined &&
        this.stats.total_likes !== undefined &&
        this.stats.total_shares !== undefined &&
        this.stats.total_views !== undefined
      ) {
        return (
          this.stats.total_comments +
          this.stats.total_likes +
          this.stats.total_shares +
          this.stats.total_views
        );
      }
      return undefined;
    },
  },
};
</script>

<style scoped>
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
</style>
