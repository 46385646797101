/* eslint-disable no-console */

import { register } from "register-service-worker";
const VERSION = process.env.VUE_APP_VERSION;
console.log(VERSION);

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.Current Version:" +
          VERSION +
          "\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {},
    cached() {
      console.log(
        "Content has been cached for offline use.Current Version:" + VERSION
      );
    },
    updatefound(registration) {
      console.log("New content is downloading.Current Version:" + VERSION);
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    updated() {
      console.log(
        "New content is available; please refresh.Current Version:" + VERSION
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode.Current Version:" +
          VERSION
      );
    },
    error(error) {
      console.error(
        "Error during service worker registration:Current Version:" + VERSION,
        error
      );
    },
  });
}
