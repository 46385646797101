<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
        <!-- <user-logo :size="40" /> -->
        <v-img :src="userData.picture" width="30px"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-if="userData.name"
          class="text-sm md:text-lg d-flex align-center"
        >
          {{ userData.name }}
        </v-list-item-title>
        <v-list-item-title v-else> username </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-img
          src="https://img.icons8.com/color/48/youtube-play.png"
          width="30px"
        ></v-img>
      </v-list-item-action>
    </v-list-item>

    <v-card-text class="pa-0">
      <div class="flex gap-3">
        <span class="font-bold">Title:</span>
        <div v-html="configuration.yt_title" />
      </div>
      <div v-html="youtube_text ? youtube_text : content" />
      <!-- <div v-html="content" />
      <div class="flex gap-3">
        <span  class="font-bold">Title:</span>
        <div v-html="yt_title" />
      </div> -->
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="d-flex align-center h-full black">
            <v-img :src="item.src" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";

export default {
  components: { VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    configuration: {
      type: Object,
      default: () => ({}),
    },
    yt_title: {
      type: String,
    },
    userData: {
      type: Array,
      default: () => [],
    },
    thumb: String,
    thumbRemoved: Boolean,
    youtube_text: String,
  },
  data() {
    return {
      carousel: 0,
      thumbnail: "",
    };
  },
  watch: {
    "configuration.yt_thumb": {
      handler(val) {
        const vm = this;
        // console.log(val);
        if (val) {
          if (typeof(val) === 'object') {
            if (val instanceof File) {
              var reader = new FileReader();
              reader.onload = function (e) {
                vm.thumbnail = e.target.result;
              };
              reader.readAsDataURL(val);
            }
          }
          else {
            vm.thumbnail = val;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      // console.log("configgg in yt", this.configuration)
      // console.log("thum in yttttt", this.thumbnail, ",", this.thumb);
      if (this.post.videos) {
        // console.log("thumb rem", this.thumbRemoved)
        if ((this.thumbnail || this.thumb) && !this.thumbRemoved) {
          list.push({
            src: this.thumbnail ? this.thumbnail : this.thumb,
            type: "image",
          });
        } else {
          this.post.videos.forEach((item) => {
            list.push({
              src: item.url || item,
              type: "video",
            });
          });
        }
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "image",
          });
        });
      }
      return list;
    },
  },
};
</script>

<style></style>
