<template>
  <div
    class="flex items-center md:pb-[50px] justify-center h-screen bg-[#F2F6FA]"
    style="font-family: Poppins, sans-serif"
  >
    <div class="mt-5 md:mt-[40px] justify-center items-center">
      <div
        class="flex xl:w-[1169px] w-full rounded-3xl shadow-[20px_20px_20px_0px_rgba(0,_0,_0,_0.1)]"
      >
        <div
          class="flex flex-col px-4 lg:px-0 py-28 rounded-l-2xl rounded-r-2xl md:rounded-r-none md:rounded-l-3xl w-full items-center gap-2 xl:w-[585px] lg:w-[450px] border bg-white"
        >
          <div class="flex flex-col items-center w-full gap-6">
            <div class="flex flex-col items-center gap-2 mb-px">
              <div class="flex flex-col items-center w-full gap-1">
                <template v-if="view == 'login'">
                  <div
                    class="flex flex-col items-center w-full md:flex-col mb-5"
                  >
                    <a :href="getLandingPageURL()">
                      <img
                        src="../assets/img/fly-new-logo.png"
                        alt="Flynewlogo"
                        id="Flynewlogo"
                        class="w-20 cursor-pointer"
                      />
                    </a>
                    <div>
                      <div
                        class="text-xl font-semibold text-center lg:text-start w-full text-[#121212]"
                      >
                        Welcome!
                      </div>
                      <div class="text-md text-[#636b8c]">
                        Sign in to your account
                      </div>

                      <div
                        class="relative flex flex-row items-start justify-center w-full gap-4"
                      ></div>
                    </div>
                  </div>
                  <div class="flex flex-col" style="width: 320px">
                    <form-wrapper @submit="submitLogin()">
                      <v-text-field
                        outlined
                        :rules="[rulesConstants.required, rulesConstants.email]"
                        class="login-input"
                        v-model="loginForm.email"
                        prepend-inner-icon="mdi-email"
                        placeholder="Email"
                      />
                      <v-text-field
                        v-model="loginForm.password"
                        :rules="[rulesConstants.required]"
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        class="login-input"
                        @click:append="showPassword = !showPassword"
                        :append-icon="
                          showPassword
                            ? 'mdi-eye-off-outline'
                            : 'mdi-eye-outline'
                        "
                        placeholder="Password"
                      />
                      <div
                        class="text-[#8056DE] font-bold cursor-pointer text-xs mb-5 text-center"
                        @click="view = 'forgot-password'"
                      >
                        Forgot password?
                      </div>
                      <v-btn
                        depressed
                        dark
                        large
                        block
                        type="submit"
                        :loading="loading"
                        class="mb-3 rounded-xl !bg-[#8056de]"
                        >Continue</v-btn
                      >
                      <v-btn
                        depressed
                        large
                        block
                        @click="$router.push('/login')"
                        :disabled="loading"
                        class="rounded-xl !text-[#636B8C] !bg-[#F2F6FA]"
                        >Go Back</v-btn
                      >
                      <div class="text-xs text-center mt-5">
                        Don't have an account?
                        <span
                          class="!text-[#8056DE] font-bold cursor-pointer mb-5"
                          @click="view = 'signup'"
                        >
                          Signup
                        </span>
                      </div>
                    </form-wrapper>
                  </div>
                </template>
                <template v-if="view == 'signup'">
                  <div
                    class="flex flex-col items-center w-full md:flex-col mb-5"
                  >
                    <a :href="getLandingPageURL()">
                      <img
                        src="../assets/img/fly-new-logo.png"
                        alt="Flynewlogo"
                        id="Flynewlogo"
                        class="w-20 cursor-pointer"
                      />
                    </a>
                    <div>
                      <div
                        class="text-xl font-semibold text-center lg:text-start w-full text-[#121212]"
                      >
                        Create account!
                      </div>

                      <div
                        class="relative flex flex-row items-start justify-center w-full gap-4"
                      ></div>
                    </div>
                  </div>
                  <div class="flex flex-col" style="width: 320px">
                    <form-wrapper @submit="submitSignup()">
                      <v-text-field
                        outlined
                        :rules="[rulesConstants.required, rulesConstants.email]"
                        class="login-input"
                        v-model="signupForm.email"
                        prepend-inner-icon="mdi-email"
                        placeholder="Email"
                      />
                      <v-text-field
                        v-model="signupForm.password"
                        :rules="[rulesConstants.required]"
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock"
                        class="login-input"
                        placeholder="Password"
                        @click:append="showPassword = !showPassword"
                        :append-icon="
                          showPassword
                            ? 'mdi-eye-off-outline'
                            : 'mdi-eye-outline'
                        "
                      />
                      <v-text-field
                        v-model="signupForm.confirmPassword"
                        :rules="[rulesConstants.required, confirmPasswordRules]"
                        outlined
                        :type="showConfirmPassword ? 'text' : 'password'"
                        @click:append="
                          showConfirmPassword = !showConfirmPassword
                        "
                        :append-icon="
                          showConfirmPassword
                            ? 'mdi-eye-off-outline'
                            : 'mdi-eye-outline'
                        "
                        prepend-inner-icon="mdi-lock"
                        class="login-input"
                        placeholder="Password"
                      />
                      <v-btn
                        depressed
                        dark
                        large
                        block
                        type="submit"
                        :loading="loading"
                        class="mb-3 rounded-xl !bg-[#8056de]"
                        >Continue</v-btn
                      >
                      <v-btn
                        depressed
                        large
                        block
                        @click="$router.push('/login')"
                        :disabled="loading"
                        class="rounded-xl !text-[#636B8C] !bg-[#F2F6FA]"
                        >Go Back</v-btn
                      >
                      <div class="text-xs text-center mt-5">
                        Already have an account?
                        <span
                          class="!text-[#8056DE] font-bold cursor-pointer mb-5"
                          @click="view = 'login'"
                        >
                          
                        Login
                        </span>
                      </div>
                    </form-wrapper>
                  </div>
                </template>
                <template v-if="view == 'forgot-password'">
                  <div
                    class="flex flex-col items-center w-full md:flex-col mb-5"
                  >
                    <a :href="getLandingPageURL()">
                      <img
                        src="../assets/img/fly-new-logo.png"
                        alt="Flynewlogo"
                        id="Flynewlogo"
                        class="w-20 cursor-pointer"
                      />
                    </a>
                    <div>
                      <div
                        class="text-xl font-semibold text-center lg:text-start w-full text-[#121212]"
                      >
                        Reset your password
                      </div>
                      <div class="text-xs text-[#636b8c]">
                        We will send you an email to reset your password
                      </div>

                      <div
                        class="relative flex flex-row items-start justify-center w-full gap-4"
                      ></div>
                    </div>
                  </div>
                  <div class="flex flex-col" style="width: 320px">
                    <form-wrapper @submit="submitReset()">
                      <v-text-field
                        outlined
                        :rules="[rulesConstants.required, rulesConstants.email]"
                        class="login-input"
                        v-model="email"
                        prepend-inner-icon="mdi-email"
                        placeholder="Email"
                      />
                      <v-btn
                        depressed
                        dark
                        large
                        block
                        type="submit"
                        :loading="loading"
                        class="mb-3 rounded-xl !bg-[#8056de]"
                        >Continue</v-btn
                      >
                      <v-btn
                        depressed
                        large
                        block
                        @click="view = 'login'"
                        :disabled="loading"
                        class="rounded-xl !text-[#636B8C] !bg-[#F2F6FA]"
                        >Go Back</v-btn
                      >
                    </form-wrapper>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div
          class="bg-[#8056de] px-4 lg:px-0 hidden md:flex flex-col rounded-r-3xl justify-center items-center w-full xl:w-[585px] lg:w-[450px]"
        >
          <div class="flex flex-col items-center justify-center w-full gap-3">
            <img
              src="../assets/img/Login_Page_V4.png"
              class="w-[368px]"
              alt=""
            />
            <div
              class="text-xl font-semibold text-center text-white lg:text-left"
            >
              Your Social, Simplified!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/utils/firebase.utils";
import authMixin from "@/mixins/auth.mixin.js";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import rulesConstants from "@/assets/constants/rules.constants";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default {
  components: { FormWrapper },
  data() {
    return {
      email: "",
      rulesConstants,
      showPassword: false,
      showConfirmPassword: false,
      loginForm: {
        email: "",
        password: "",
      },
      signupForm: {
        email: "",
        confirmPassword: "",
        password: "",
      },
      loading: false,
      view: "signup",
    };
  },
  mixins: [authMixin],
  watch: {
    $route() {
      this.isPhoneAuth = this.$route.name == "login-phone";
    },
  },
  computed: {
    confirmPasswordRules() {
      return (v) => v === this.signupForm.password || "Passwords do not match";
    },
  },
  methods: {
    async submitReset() {
      try {
        this.loading = true;
        await sendPasswordResetEmail(auth, this.email);
        this.$alert.show("A password reset email has been sent.");
        this.loading = false;
        this.view = "login";
      } catch (error) {
        console.error(error);
        this.loading = false;
        let message = this.getErrorMessage(error);
        this.$alert.show(message);
      }
    },
    async submitLogin() {
      try {
        this.loading = true;
        let { email, password } = this.loginForm;
        let credential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        console.log(credential.user);
        await this.completeSignup(credential.user);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        let message = this.getErrorMessage(error);
        this.$alert.show(message);
      }
    },
    async submitSignup() {
      try {
        this.loading = true;
        let { email, password } = this.signupForm;
        let credential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        console.log(credential.user);
        await this.completeSignup(credential.user);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        let message = this.getErrorMessage(error);
        this.$alert.show(message);
      }
    },
  },
};
</script>

<style lang="scss">
.login-input {
  .v-input__slot {
    background: #f2f6fa !important;
    color: #636b8c !important;
    border-radius: 24px !important;
    min-height: 48px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .v-input__prepend-inner {
    margin-top: 12px !important;
    padding-right: 10px !important;
    i {
      color: grey !important;
    }
  }
  .v-input__append-inner {
    margin-top: 12px !important;
    cursor: pointer;
  }
  fieldset {
    border: 0px !important;
  }
}
</style>
