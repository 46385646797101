<template>
  <v-card>
    <router-link to="/">
      <div class="">
        <!-- <img
          src="../assets/img/fly-new-logo.png"
          alt="logo"
          class="max-h-full"
        /> -->
      </div>
    </router-link>
    <div>
      <!-- <h1 class="flex justify-center px-6 pt-10 text-2xl font-medium">
        Complete Signup
      </h1> -->

      <complete-signup />
    </div>
  </v-card>
</template>
<script>

import inviteMixins from "@/mixins/invite.mixins";
import CompleteSignup from "@/components/CompleteSignup.vue";
import { useGtm } from '@gtm-support/vue2-gtm';

export default {
  components: {
    CompleteSignup,
  },
  setup() {
    const gtm = useGtm();

    function triggerEvent() {
      gtm.trackEvent({
        event: 'Signup Complete',
        category: 'conversion',
        action: 'sign-up',
        label: 'Sign up completed',
        value: 0,
        noninteraction: false,
      });
    }

    return {
      triggerEvent,
    };
  },
  data() {
    return {
      isOtpSend: false,
      isAuthLoading: true,
      phone: "",
      loading: false,
      isNotSignedUp: false,
      isLoggedIn: false,
      otp: "",
    };
  },
  computed: {
    isSignup() {
      return this.$route.name == "signup";
    },
    title() {
      if (this.isNotSignedUp) return "Sign Up";
      return this.isSignup ? "Sign Up" : "Login";
    },
  },
  mounted() {},
  mixins: [inviteMixins],
  methods: {
    openRoute(name) {
      let query = this.$route.query;
      this.$router.push({
        name,
        query,
      });
    },

    redirect() {
      let { redirect } = this.$route.query;
      this.$router.replace(redirect || "/dashboard");
    },
  },
};
</script>

<style scoped>
.fly-logo-container {
  transform: translate(-50%, -50%);
  border-radius: 50% !important;
}
.fly-logo-container img {
  max-width: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
