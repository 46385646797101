<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Instagram Settings
      </div>
      <div class="py-3 px-7">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="Users"
              @input="$emit('input', form)"
              v-model="form.ig_users"
              :items="users"
              multiple
              @editAccountType="openEditAccountTypeDialog"
            ></select-field>
            <v-alert
              dense
              outlined
              type="error"
              v-if="
                Object.keys(professionalAccounts).length > 0 &&
                postTypeConfiguration.ig_post_type === 'Story'
              "
            >
              <div class="flex-col gap-2 d-flex"></div>

              <span class="mb-6">
                Cannot post Story with a creator account. Please switch to
                Business in Instagram and update your Instagram account type in
                Fly to Business.
              </span>
              <br />
              <span class="font-medium"> Creator Accounts: </span>
              <span class="font-semibold">
                {{ Object.values(professionalAccounts).join(", ") }}
              </span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="post?.videos?.length > 0 && postTypeConfiguration.ig_post_type !== 'Story'" cols="12" md="6" class="py-0">
            <div class="flex gap-3 items-center">
              <div class="flex flex-col gap-2">
                <span class="text-[14px] font-medium">Add Thumbnail (Optional)</span>
                <div class="flex flex-row gap-2 items-center">
                  <span class="h-[30px] w-[30px] border-2 my-1 mr-2">
                    <v-icon @click="$refs.image.click()">mdi-plus</v-icon>
                  </span>
                  <span class="text-xs mb-0.5">Upload: JPEG only, max 8 MB.</span>
                </div>
              </div>
            </div>
            <!-- <v-btn
              depressed
              class="primary"
              height="40px"
              @click="$refs.image.click()"
              variant="outlined"
            >
              <v-icon>mdi-image</v-icon>
              <span class=""> Add Thumbnail </span>
            </v-btn> -->
            <input
              accept="image/jpeg"
              type="file"
              class="d-none"
              ref="image"
              @input="$emit('input', form)"
              @change="onImageChange"
            />
          </v-col>
          <v-col
            v-if="form.ig_thumb || thumbnail"
            cols="12"
            class="py-2 px-3 d-flex flex-col gap-2"
          >
            <div class="d-flex w-1/2 flex-row justify-between">
              <span>{{ form.ig_thumb?.name }}</span>
              <v-icon @click="removeThumb" class="cursor-pointer" color="red"
                >mdi-close</v-icon
              >
            </div>
            <v-img class="w-1/2" :src="thumbnail"></v-img>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <template>
      <v-dialog v-model="showIgForm" persistent max-width="400px">
        <dialog-card
          title="Select your Account Type"
          submitText="Submit"
          @close="closeDialog"
          @submit="saveAccountType"
          :message="'User: ' + selectedUsernameForDialog"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <radio-group
                label="Account Type"
                v-model="account_type"
                row
                :items="accountType"
              />
            </v-col>
          </v-row>
        </dialog-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="showEditForm" persistent max-width="400px">
        <dialog-card
          title="Edit your Account Type"
          submitText="Submit"
          @close="showEditForm = false"
          @submit="editAccountType"
          :message="'User: ' + selectedUsernameForDialog"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <radio-group
                label="Account Type"
                v-model="account_type"
                row
                :items="accountType"
              />
            </v-col>
          </v-row>
        </dialog-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import { doc, getDoc, updateDoc } from "firebase/firestore";
import RadioGroup from "../../ui/form/RadioGroup.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import { auth, colUsers } from "@/utils/firebase.utils";
import { compressImage, MAX_IMAGE_SIZE } from "@/utils/image.utils";
import DialogCard from "@/components/ui/DialogCard.vue";
export default {
  components: { RadioGroup, SelectField, DialogCard },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    users: Array,
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    configureClicked: Boolean,
    openIgForm: Boolean,
    connectedIgUsers: Number,
    validVideoTypes: {
      type: Object,
      default: () => ({}),
    },
    postTypeConfiguration: Object,
  },

  data() {
    return {
      form: {
        ig_users: [],
        ig_thumb: null,
      },
      showIgForm: false,
      showEditForm: false,
      editUserAccountType: {},
      account_type: "",
      selectedUserIdForDialog: "",
      selectedUsernameForDialog: "",
      dialogClosedManually: false,
      userAccountType: {},
      professionalAccounts: {},
      hasProfessionalAccounts: false,
      localUsers: [],
      thumbChanged: false,
      thumbnail: null,
    };
  },
  watch: {
    users: {
      immediate: true,
      handler(newUsers) {
        this.localUsers = newUsers;
        if (this.openIgForm && this.connectedIgUsers === 1) {
          this.showIgForm = true;
        }
      },
    },
    "form.ig_users": {
      async handler(val, oldVal) {
        const newlySelectedUsers =
          val?.filter((userId) => !oldVal?.includes(userId)) ?? [];
        const newlyDeselectedUsers =
          oldVal?.filter((userId) => !val?.includes(userId)) ?? [];

        for (const selectedUserId of newlySelectedUsers) {
          if (this.isUserIdInUsers(selectedUserId)) {
            const connectedAppsRef = doc(
              colUsers,
              this.currentUID,
              "fly_workspaces",
              this.WID,
              "connected-apps",
              "instagram"
            );
            const connectedAppsSnap = await getDoc(connectedAppsRef);

            if (connectedAppsSnap.exists()) {
              const connectedAppsData = connectedAppsSnap.data();
              const selectedUser = connectedAppsData.users.find(
                (user) => user.user_id === selectedUserId
              );
              if (selectedUser && selectedUser.account_type) {
                if (selectedUser.account_type === "creator") {
                  this.$set(
                    this.professionalAccounts,
                    selectedUserId,
                    selectedUser.name
                  );
                  this.$emit("professionalAccounts", this.professionalAccounts);
                  this.hasProfessionalAccounts = true;
                  this.$emit(
                    "hasProfessionalAccounts",
                    this.hasProfessionalAccounts
                  );
                }
                const accountType = {
                  [selectedUser?.name]: {
                    account_type: selectedUser?.account_type,
                  },
                };
                this.$set(this.userAccountType, selectedUserId, accountType);
                this.$emit("accountType", accountType);
              } else {
                this.selectedUserIdForDialog = selectedUserId;
                this.selectedUsernameForDialog = selectedUser?.name;
                if (this.connectedIgUsers > 1) {
                  this.showIgForm = true;
                }
              }
            }
          }
        }
        for (const deselectedUserId of newlyDeselectedUsers) {
          this.$delete(this.userAccountType, deselectedUserId);
          if (this.professionalAccounts[deselectedUserId]) {
            this.$delete(this.professionalAccounts, deselectedUserId);
          }
          const remainingProfessionalAccounts = Object.values(
            this.userAccountType
          ).some(
            (accountType) =>
              accountType[Object.keys(accountType)[0]].account_type ===
              "creator"
          );
          this.hasProfessionalAccounts = remainingProfessionalAccounts;
          this.$emit("hasProfessionalAccounts", this.hasProfessionalAccounts);
        }
      },
    },
  },
  computed: {
    accountType() {
      let list = [
        {
          name: "Creator",
          value: "creator",
        },
        {
          name: "Business",
          value: "business",
        },
      ];
      return list;
    },
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "instagram");
      return platform || {};
    },
  },
  methods: {
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        this.thumbRemoved = false;
        this.thumbChanged = true;
        // this.form.ig_thumb = image;
        let data = {
          platform: "ig",
        };
        if (image instanceof File) {
          var reader = new FileReader();
          reader.onload = async (e) => {
            let imageUrl = e.target.result;
            if (image.size > MAX_IMAGE_SIZE) {
              this.$loader.show();
              let { base64, size } = await compressImage(image);
              // if (size > 104860) {
              //   image = base64;
              //   imageSize = size;
              // }
              this.$loader.hide();
              imageUrl = base64;
            }
            this.thumbnail = imageUrl;
            this.form.ig_thumb = imageUrl;
            data.thumbnail = imageUrl;
            this.$emit("thumbnailAdded", data);
          };
          // if (this.isRepost || this.isEditing || vm.isDraftPost) {
          //   this.$emit("thumbnailModified", {platform: 'ig', thumbnail: vm.thumbnail});
          // } else {
          // }
          reader.readAsDataURL(image);
        }
        this.$emit("input", this.form);
      }
    },
    removeThumb() {
      this.form.ig_thumb = null;
      this.thumbnail = "";
      this.$emit("thumbnailRemoved", { platform: "ig", removed: true });
      this.$emit("input", this.form);
    },
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
      if (!this.isEditing && this.users.length == 1) {
        this.form.ig_users = [this.users[0].value];
        this.$emit("input", this.form);
      }
    },
    async openEditAccountTypeDialog(userData) {
      this.showEditForm = true;
      this.selectedUserIdForDialog = userData.user_id;
      this.selectedUsernameForDialog = userData.name;

      this.account_type = userData.account_type;
      this.editUserAccountType.user_id = userData.user_id;
      this.editUserAccountType.account_type = userData.account_type;
      this.editUserAccountType.name = userData.name;
    },
    async editAccountType() {
      const connectedAppsRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps",
        "instagram"
      );
      const connectedAppsData = (await getDoc(connectedAppsRef)).data();
      const selectedUser = connectedAppsData.users.find(
        (user) => user.user_id === this.editUserAccountType.user_id
      );
      const userIndex = connectedAppsData.users.findIndex(
        (user) => user.user_id === this.editUserAccountType.user_id
      );

      if (userIndex !== -1) {
        const prevAccountType = connectedAppsData.users[userIndex].account_type;
        const isProfessional = prevAccountType === "creator";
        const isBusiness = prevAccountType === "business";
        connectedAppsData.users[userIndex].account_type = this.account_type;
        await updateDoc(connectedAppsRef, {
          users: connectedAppsData.users,
        });
        this.$emit("editAccountType", {
          userId: this.editUserAccountType.user_id,
          accountType: this.account_type,
        });
        // if account_type changed to business then remove it from error list (professionalAccounts)
        if (isProfessional && this.account_type === "business") {
          // Remove from professionalAccounts
          if (this.isUserIdInUsers(selectedUser.user_id)) {
            this.$delete(
              this.professionalAccounts,
              this.editUserAccountType.user_id
            );
            this.$emit("professionalAccounts", this.professionalAccounts);
          }
        } else if (isBusiness && this.account_type === "creator") {
          // if account_type changed to professional then add it to error list (professionalAccounts)
          // Add to professionalAccounts
          if (this.isUserIdInUsers(selectedUser.user_id)) {
            this.professionalAccounts[this.editUserAccountType.user_id] =
              selectedUser.name;
            this.$emit("professionalAccounts", this.professionalAccounts);
          }
          this.hasProfessionalAccounts = true;
          this.$emit("hasProfessionalAccounts", this.hasProfessionalAccounts);
        }
        this.$snackbar.show("Account Type updated successfully!");
        await new Promise((resolve) => setTimeout(resolve, 0));
        let accountType = {};
        accountType[this.editUserAccountType.user_id] = this.account_type;
        this.$emit("accountType", accountType);
      }
      this.showEditForm = false;
      this.selectedUserIdForDialog = null;
      this.selectedUsernameForDialog = null;
    },
    isUserIdInUsers(userId) {
      return this.form.ig_users.includes(userId);
    },
    closeDialog() {
      this.dialogClosedManually = true;
      this.$emit("dialogClosedManually", this.dialogClosedManually);
      this.$snackbar.show(
        "Mandatory to save account type. You can update it in future."
      );
    },
    async saveAccountType() {
      this.showIgForm = false;
      const selectedUserId = this.selectedUserIdForDialog;
      const connectedAppsRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps",
        "instagram"
      );
      const connectedAppsData = (await getDoc(connectedAppsRef)).data();
      const selectedUser = connectedAppsData.users.find(
        (user) => user.user_id === selectedUserId
      );
      const userIndex = connectedAppsData.users.findIndex(
        (user) => user.user_id === selectedUserId
      );
      if (userIndex !== -1) {
        connectedAppsData.users[userIndex].account_type = this.account_type;
        await updateDoc(connectedAppsRef, {
          users: connectedAppsData.users,
        });
        this.$emit("editAccountType", {
          userId: selectedUserId,
          accountType: this.account_type,
        });
        this.$snackbar.show("Account Type updated successfully!");
        await new Promise((resolve) => setTimeout(resolve, 0));
        if (selectedUser && selectedUser.account_type) {
          if (selectedUser.account_type === "creator") {
            this.$set(
              this.professionalAccounts,
              selectedUserId,
              selectedUser.name
            );
            this.$emit("professionalAccounts", this.professionalAccounts);
            this.hasProfessionalAccounts = true;
            this.$emit("hasProfessionalAccounts", this.hasProfessionalAccounts);
          }
        }
        let accountType = {};
        accountType[selectedUserId] = this.account_type;
        this.$emit("accountType", accountType);
      }
      this.selectedUserIdForDialog = null;
      this.selectedUsernameForDialog = null;

      this.showIgForm = false;
    },
    updateConfiguration() {
      this.form.ig_users = this.configuration.ig_users;
      if (this.configuration.ig_thumb) {
        this.form.ig_thumb = this.configuration.ig_thumb;
        this.thumbnail = this.configuration.ig_thumb;
      }
    },
  },
  beforeMount() {
    this.initForm();
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.updateConfiguration();
    }
  },
};
</script>

<style></style>
