<template>
  <div class="bg-slate-100 pa-6 h-full">
    <div class="max-w-[1276px] max-md:max-w-full mx-auto">
      <p class="text-base md:text-[22px] font-bold">Generate Image</p>
      <please-wait v-if="isLoading" />
      <template v-else>
        <div class="pa-6 bg-white rounded-xl mt-3">
          <v-row>
            <v-col cols="12" md="6">
              <v-img :src="imagePreview" class="m-3" height="500px" contain />
              <div class="text-center mt-6">
                <v-btn
                  class="!bg-violet-500 !text-white mt-"
                  large
                  @click="postWithFly()"
                  :loading="isPostWithFlyLoading"
                  depressed
                  :disabled="template.preview_url == imagePreview || loading"
                  >Post with Fly</v-btn
                >
              </div>
            </v-col>
            <v-col>
              <template v-for="field in fields">
                <file-input-field
                  :label="field.label"
                  accept="image/*"
                  v-model="form[`${field.key}`]"
                  v-if="field.type == 'image_url'"
                  :key="field.key"
                />
                <input-field
                  :label="field.label"
                  v-if="['target', 'text'].indexOf(field.type) != -1"
                  v-model="form[`${field.key}`]"
                  :key="field.key"
                />
              </template>
              <v-btn
                @click="submit()"
                depressed
                class="!bg-violet-500 !text-white"
                large
                :disabled="isPostWithFlyLoading"
                :loading="loading"
                >Generate Image</v-btn
              >
              <v-btn
                depressed
                :disabled="loading || isPostWithFlyLoading"
                large
                class="ml-3"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { bb_templates, functions, storage } from "@/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import InputField from "@/components/ui/form/InputField.vue";
import { capitalizeFirstLetter } from "@/utils/common.utils";
import FileInputField from "@/components/ui/form/FileInputField.vue";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import axios from "axios";

export default {
  components: {
    PleaseWait,
    InputField,
    FileInputField,
  },
  data() {
    return {
      isLoading: true,
      loading: false,
      isPostWithFlyLoading: false,
      form: {},
      template: {},
      imagePreview: "",
    };
  },
  computed: {
    fields() {
      let fields = this.template.available_modifications;
      let types = ["target", "image_url", "text"];
      let list = fields
        .map((i) => {
          let key = i.name;
          let label = i.name
            .split("_")
            .map((i) => capitalizeFirstLetter(i))
            .join(" ");
          let type = types.find((key) => key in i);
          return { key, type, label };
        })
        .filter((i) => i.type);
      return list;
    },
  },
  methods: {
    async postWithFly() {
      try {
        this.isPostWithFlyLoading = true;
        let uid = this.currentUID || this.uid;
        let url = `https://api.cloudinary.com/v1_1/dzoxbtkhq/upload`;
        let data = {
          folder: `users/${this.currentUID}/${this.WID}`,
          tags: [`${uid}_${this.WID}`],
          upload_preset: "demo_preset",
          file: this.imagePreview,
          source: "uw",
        };
        let response = await axios.post(url, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        this.$router.replace({
          name: "create-post",
          params: {
            cloudinary_url: response.data.url,
          },
        });

        this.isPostWithFlyLoading = false;
      } catch (error) {
        this.isPostWithFlyLoading = false;
        this.handleError(error);
      }
    },
    async fetchTemplate() {
      try {
        this.isLoading = true;
        let id = this.$route.params.id;
        this.template = (await getDoc(doc(bb_templates, id))).data();

        if (this.template == null) {
          this.$alert.show("Template not found.");
          this.$router.replace("/choose-template");
          return;
        }
        this.imagePreview = this.template.preview_url;
        if (this.uid) {
          let tempRef = ref(storage, `users/${this.uid}/temp`);
          let requests = (await listAll(tempRef)).items.map((i) =>
            deleteObject(ref(storage, i.fullPath))
          );
          await Promise.all(requests);
        }
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async submit() {
      try {
        this.loading = true;
        let request = {
          template: this.$route.params.id,
          modifications: [],
        };
        for (let name in this.form) {
          let value = this.form[name];
          if (value instanceof File) {
            let tempRef = ref(storage, `users/${this.uid}/temp/${value.name}`);
            await uploadBytes(tempRef, value);
            value = await getDownloadURL(tempRef);
          }
          let item = this.fields.find((i) => i.key == name);
          if (item) {
            request.modifications.push({
              name,
              [`${item.type}`]: value,
            });
          }
        }
        if (request.modifications.length == 0) {
          this.loading = false;
          return;
        }
        let generateImage = httpsCallable(functions, "generateBbImage");
        this.imagePreview = (await generateImage(request)).data;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchTemplate();
  },
};
</script>

<style></style>
