<template>
  <v-dialog v-model="updateExists" max-width="300px" persistent>
    <v-card>
      <v-card-text>
        <p class="text-subtitle-1 mt-5">New update available for Fly Social!</p>
        <v-btn depressed block class="primary mt-3" @click="refreshApp">
          Update Now
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import pwaUpdateMixin from "@/mixins/pwa.update.mixin";
export default {
  mixins: [pwaUpdateMixin],
};
</script>

<style></style>
