import PrivacyPolicy from "../pages/website/PrivacyPolicy.vue";
import Terms from "../pages/website/Terms.vue";
import CookiePolicy from "../pages/website/CookiePolicy.vue";
let routes = [
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-use",
    name: "terms",
    component: Terms,
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: CookiePolicy,
  },
];

let websiteRoutes = routes.map((i) => {
  let meta = i.meta || {};
  meta.isWebsite = true;
  return { ...i, meta };
});

export default websiteRoutes;
