<template>
  <div>
    <please-wait v-if="isLoading" class="mt-5"></please-wait>
    <PinturaEditor
      v-else
      v-bind="editorProps"
      ref="editor"
      :style="editerStyle"
      @pintura:process="handleProcess($event)"
    />
  </div>
</template>
<script>
import "@/components/pintura/pintura-image/pintura.css";
import { PinturaEditor } from "@/components/pintura/index.js";
import { getEditorDefaults } from "@/components/pintura/pintura-image/pintura";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colGallery, storage } from "@/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getDate } from "@/utils/common.utils";

import { compressImage, MAX_IMAGE_SIZE } from "@/utils/image.utils";


export default {
  name: "App",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    PinturaEditor,
    PleaseWait,
  },
  data() {
    return {
      editorProps: getEditorDefaults(),
      inlineResult: "",
      image: {},
      isLoading: false,
    };
  },
  computed: {
    editerStyle() {
      let height = window.innerHeight - 64 + "px";
      return { height };
    },
    galleryId() {
      return this.id || this.$route.params.id;
    },
  },
  methods: {
    async handleProcess(event) {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.galleryId;
        let imageRef = ref(storage, `gallery/${vm.image.uid}/${id}_thumb.png`);
        let imageSize = event.dest.size;
        let image = event.dest;
        if (image.size > MAX_IMAGE_SIZE) {
          let { base64, size } = await compressImage(image);
          image = base64;
          imageSize = size;
          console.log("File Compressed....");
        }
        if (image instanceof File) {
          await uploadBytes(imageRef, event.dest);
        } else {
          console.log("upload base64");
          await uploadString(imageRef, image, "data_url");
        }

        let thumbnail = await getDownloadURL(imageRef);
        let data = {
          thumbnail,
          size: imageSize,
          json: JSON.stringify(event.imageState),
          updated_at: getDate(),
        };

        await updateDoc(doc(colGallery, id), data);
        vm.isLoading = false;
        if (vm.$route.name == "update-gallery-image") {
          vm.$router.replace("/gallery");
        } else {
          vm.$emit("onEdit", {
            ...vm.image,
            id: vm.galleryId,
            ...data,
          });
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchImage() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.galleryId;
        let image = (await getDoc(doc(colGallery, id))).data();
        vm.image = image;
        vm.isLoading = false;
        vm.$nextTick(() => {
          let json = {};
          try {
            json = JSON.parse(image.json);
          } catch (error) {
            console.error(error);
          }
          vm.$refs.editor.editor.loadImage(image.url, json);
        });
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchImage();
  },
};
</script>
