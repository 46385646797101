<!-- <template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        YouTube Settings
      </div>
      <div class="px-7 py-3">
        <v-row>
          <v-col cols="12" md="4" class="pb-0">
            <radio-group
              @input="$emit('input', form)"
              label="Post Type"
              v-model="form.yt_post_type"
              row
              :items="postTypes"
            />
          </v-col>
          <v-col cols="12" md="8" class="pb-0">
            <radio-group
              @input="$emit('input', form)"
              label="Publish Type"
              v-model="form.yt_publish_type"
              row
              :items="publishTypes"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <input-field
              @input="$emit('input', form)"
              label="Video Title"
              rules="required"
              v-model="form.yt_title"
              counter="100"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <input-field
              @input="$emit('input', form)"
              label="Video Tags"
              v-model="form.yt_tags"
            />
          </v-col>
          <v-col cols="12" class="py-0" v-if="false">
            <file-input-field
              @input="$emit('input', form)"
              label="Thumbnail"
              accept="image/*"
              v-model="form.yt_thumbnail"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <select-field
              @input="$emit('input', form)"
              label="Category"
              :items="categoryTypes"
              v-model="form.yt_category"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <select-field
              @input="$emit('input', form)"
              label="Playlist"
              v-model="form.yt_playlist"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <select-field
              @input="$emit('input', form)"
              label="License"
              :items="liscenseTypes"
              v-model="form.yt_license"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-checkbox
              @change="$emit('input', form)"
              label="Embeddable"
              v-model="form.yt_embeddable"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-checkbox
              @change="$emit('input', form)"
              label="Notify Subscribers"
              v-model="form.yt_notify_subscribers"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-checkbox
              @change="$emit('input', form)"
              label="Made for kids"
              v-model="form.yt_made_for_kids"
            />
            <select-field
              @input="$emit('input', form)"
              label="Made for kids"
              :items="madeForKids"
              v-model="form.yt_made_for_kids"
            />
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template> -->
<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        YouTube Settings
      </div>
      <div class="px-3 py-2">
        <v-row>
          <v-col cols="12" md="6" class="pb-2">
            <radio-group
              @input="$emit('input', form)"
              label="Publish Type"
              v-model="form.yt_publish_type"
              row
              :items="publishTypes"
            />
          </v-col>
          <v-col cols="12" class="py-2">
            <input-field
              @input="$emit('input', form)"
              label="Video Title"
              rules="required"
              v-model="form.yt_title"
              counter="100"
            />
          </v-col>
          <v-col cols="12" class="py-2">
            <combo-box
              label="Video Tags"
              v-model="ytTags"
              :tagCount="tagCount"
            ></combo-box>
          </v-col>
          <v-col cols="12" md="4" class="py-2">
            <select-field
              @input="$emit('input', form)"
              label="Category"
              :items="categoryTypes"
              v-model="form.yt_category"
            />
          </v-col>
          <!-- <v-col cols="12" md="4" class="py-2">
            <select-field
              @input="$emit('input', form)"
              label="Playlist"
              v-model="form.yt_playlist"
            />
          </v-col> -->
          <v-col cols="12" md="6" class="py-2">
            <select-field
              @input="$emit('input', form)"
              label="License"
              :items="liscenseTypes"
              v-model="form.yt_license"
            />
          </v-col>
          <v-col v-if="vidDuration >= 60" cols="12" md="6" class="py-0">
            <!-- <v-btn
              depressed
              class="primary"
              height="40px"
              @click="$refs.image.click()"
              variant="outlined"
            >
              <v-icon>mdi-image</v-icon>
              <span class=""> Add Thumbnail </span>
            </v-btn> -->
            <div class="flex gap-3 items-center">
              <div class="flex flex-col gap-2">
                <span class="text-[14px] font-medium">Add Thumbnail (Optional)</span>
                <div class="flex flex-row gap-2 items-center">
                  <span class="h-[30px] w-[30px] border-2 my-1 mr-2">
                    <v-icon @click="$refs.image.click()">mdi-plus</v-icon>
                  </span>
                </div>
              </div>
            </div>
            <input
              accept="image/*"
              type="file"
              class="d-none"
              ref="image"
              @input="$emit('input', form)"
              @change="onImageChange"
            />
            <!-- <file-input-field
              @input="$emit('input', form)"
              label="Thumbnail"
              accept="image/*"
              v-model="form.yt_thumb"
            /> -->
          </v-col>
          <v-col
            v-if="form.yt_thumb || thumbnail"
            cols="12"
            class="py-2 px-3 d-flex flex-col gap-2"
          >
            <div class="d-flex w-1/2 flex-row justify-between">
              <span>{{ form.yt_thumb?.name }}</span>
              <v-icon @click="removeThumb" class="cursor-pointer" color="red"
                >mdi-close</v-icon
              >
            </div>
            <v-img class="w-1/2" :src="thumbnail"></v-img>
            <!-- <v-btn
              depressed
              class="danger mx-3"
              height="40px"
              variant="outlined"
            > -->
            <!-- </v-btn> -->
          </v-col>
          <v-col cols="12" md="4" class="py-1">
            <v-checkbox
              @change="$emit('input', form)"
              label="Embeddable"
              v-model="form.yt_embeddable"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-1">
            <v-checkbox
              @change="$emit('input', form)"
              label="Notify Subscribers"
              v-model="form.yt_notify_subscribers"
            />
          </v-col>
          <v-col cols="12" md="4" class="py-1">
            <v-checkbox
              @change="$emit('input', form)"
              label="Made for kids"
              v-model="form.yt_made_for_kids"
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FileInputField from "@/components/ui/form/FileInputField.vue";
import InputField from "../../ui/form/InputField.vue";
import RadioGroup from "../../ui/form/RadioGroup.vue";
import SelectField from "../../ui/form/SelectField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    vidDuration: Number,
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    yt_title: String,
    yt_tags: Array,
    validVideoTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { RadioGroup, InputField, SelectField, FileInputField, ComboBox },
  data() {
    return {
      form: {
        yt_post_type: "",
        yt_publish_type: "public",
        yt_title: "",
        yt_tags: "",
        yt_category: "",
        // yt_playlist: "",
        yt_license: "youtube",
        yt_embeddable: true,
        yt_notify_subscribers: true,
        yt_made_for_kids: false,
        yt_thumb: null,
      },
      tagCount: 0,
      hashtagsArray: [],
      tagChanged: false,
      tagsArray: [],
      thumbRemoved: false,
      thumbChanged: false,
      thumbnail: "",
      publishTypes: [
        {
          name: "Public",
          value: "public",
        },
        {
          name: "Private",
          value: "private",
        },
        {
          name: "Unlisted",
          value: "unlisted",
        },
      ],
      liscenseTypes: [
        { name: "Standard YouTube License", value: "youtube" },
        // {
        //   name: "Creative Commons - Attribution",
        //   value: "creativeCommon",
        // },
      ],
      madeForKids: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
      categoryTypes: [
        { name: "Cars & Vehicles", value: 2 },
        { name: "Comedy", value: 23 },
        { name: "Education", value: 27 },
        { name: "Entertainment", value: 24 },
        { name: "Film & Animation", value: 1 },
        { name: "Gaming", value: 20 },
        { name: "How-to & Style", value: 20 },
        { name: "Music", value: 10 },
        { name: "News & Politics", value: 25 },
        { name: "Non-profits & Activism", value: 29 },
        { name: "People & Blogs", value: 22 },
        { name: "Pets & Animals", value: 15 },
        { name: "Science & Technology", value: 29 },
        { name: "Sports", value: 17 },
        { name: "Travel & Events", value: 19 },
      ],
    };
  },
  watch: {
    isDraftPost: {
      handler(newVal) {
        if (newVal) {
          this.updateConfiguration();
          this.form.yt_title = this.yt_title;
        }
      },
      immediate: true,
    },
    isEditing: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isEditing) {
            this.updateConfiguration();
            this.form.yt_title = this.yt_title;
          }
        }
      },
      immediate: true,
    },
    isRepost: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isRepost) {
            this.updateConfiguration();
            this.form.yt_title = this.yt_title;
            // console.log("thumbbb", this.thumbnail)
            // this.form.yt_thumb = this.thumbnail;
          }
        }
      },
      immediate: true,
    },
    value: {
      handler(val) {
        // console.log(val);
        Object.keys(this.form).forEach((key) => {
          if (val[key] != undefined) {
            this.form[key] = val[key];
          }
        });
      },
      deep: true,
    },
    "form.yt_title": {
      handler(newValue) {
        const characterCount = newValue.length;
        this.$emit("characterCount", characterCount);
        this.$emit("titleChanged", this.form.yt_title);
      },
      deep: true,
    },
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "youtube");
      return platform || {};
    },
    tagsCharacterCount() {
      return this.tagsArray.join("").length;
    },
    ytTags: {
      get() {
        if (this.form.yt_tags.length) {
          const tagsArray = this.form.yt_tags
            .split(",")
            .map((tag) => tag.trim());
          return tagsArray;
        }
        return null;
      },
      set(newValue) {
        const flattenedTags = newValue
          .flatMap((tag) => tag.split(","))
          .map((tag) => tag.trim())
          .filter((tag) => tag.length > 0);

        this.tagsArray = flattenedTags;

        this.form.yt_tags = this.tagsArray.join(",");
        this.$emit("tagsAdded", this.form.yt_tags);
        this.$emit("input", this.form);
        this.tagCount = this.tagsCharacterCount;
        this.$emit("tagCharacterCount", this.tagCount);
        this.tagChanged = true;
      },
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
    },
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        this.thumbRemoved = false;
        this.$emit("thumbremoved", this.thumbRemoved);
        // console.log("img", image);
        this.thumbChanged = true;
        this.form.yt_thumb = image;
        // console.log("ty im", typeof(image), this.form.yt_thumb)
        if (image instanceof File) {
          var reader = new FileReader();
          reader.onload = function (e) {
            vm.thumbnail = e.target.result;
          };
          if (this.isRepost || this.isEditing || vm.isDraftPost) {
            this.$emit("thumbchanged", vm.thumbnail);
          }
          reader.readAsDataURL(image);
        }
        // this.$emit("input", this.form);
      }
    },
    removeThumb() {
      this.form.yt_thumb = null;
      this.thumbnail = "";
      this.thumbRemoved = true;
      this.$emit("thumbremoved", this.thumbRemoved);
    },
    handlePresetTags() {
      let tags = this.form.yt_tags;
      if (tags?.length) {
        this.tagsArray = tags.split(",");
      }
    },
    updateConfiguration() {
      this.form.yt_title = this.configuration.yt_title;
      // this.form.yt_tags = this.configuration.yt_tags;
      this.tagsArray = this.configuration.yt_tags?.length
        ? this.configuration.yt_tags.split(",")
        : "";
      this.form.yt_category = this.configuration.yt_category
        ? this.configuration.yt_category
        : "";
      this.form.yt_license = this.configuration.yt_license
        ? this.configuration.yt_license
        : "youtube";
      this.form.yt_embeddable = this.configuration.yt_embeddable
        ? this.configuration.yt_embeddable
        : true;
      this.form.yt_post_type = this.configuration.yt_post_type
        ? this.configuration.yt_post_type
        : "Video";
      this.form.yt_notify_subscribers = this.configuration.yt_notify_subscribers
        ? this.configuration.yt_notify_subscribers
        : true;
      this.form.yt_made_for_kids = this.configuration.yt_made_for_kids
        ? this.configuration.yt_made_for_kids
        : false;
      this.form.yt_publish_type = this.configuration.yt_publish_type
        ? this.configuration.yt_publish_type
        : "public";
      this.form.yt_thumb = this.configuration.yt_thumb;
      this.thumbnail = this.configuration.yt_thumb;
      if (!this.tagChanged) {
        this.tagCount = this.configuration?.yt_tags?.replace(/,/g, "").length;
        this.$emit("tagCharacterCount", this.tagCount);
      }
      // console.log("this yt thum chnaged", this.thumbChanged);
    },
  },
  beforeMount() {
    this.initForm();
    // to render tags after selecting preset
    this.handlePresetTags();
  },
};
</script>

<style></style>
