<template>
  <div class="text-center">
    <v-dialog v-model="loader" persistent :max-width="isStandBy ? 300 : 500">
      <v-card color="primary" dark class="overflow-y-hidden	">
        <v-card-text>
          <div v-if="type === 'ai'" class="pr-2 d-flex gap-2 items-center">
            <img
              src="./../../../assets/img/icons8-ai.gif"
              width="30px"
              max-height="30px"
            />
            <span>
              {{ message }}
            </span>
          </div>
          <span v-else>
            {{ message }}
          </span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoaderModal from "./index";
export default {
  data() {
    return {
      loader: false,
      message: "Please stand by",
      type: "",
    };
  },
  computed: {
    isStandBy() {
      return this.message == "Please stand by";
    },
  },
  beforeMount() {
    LoaderModal.EventBus.$on("show", (params) => {
      this.loader = true;
      if(params?.message) {
        this.message = params.message || "Please stand by";
        this.type = params.type || "";
      }
      else if(params) {
        this.message = params;
      }
      else {
        this.message = "Please stand by";
      }
    });
    LoaderModal.EventBus.$on("hide", () => {
      this.loader = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 70px;
}
.v-card__text {
  padding-top: 17px !important;
}
</style>
