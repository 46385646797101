<template>
    <div>
      <label class="input-label" v-if="label">{{ label }}</label>
      <v-text-field
        v-model="input"
        :dense="dense"
        ref="input"
        v-bind="$attrs"
        :value="input"
        @keyup.enter="$emit('enter')"
        
        :class="[
        label ? 'mt-1' : '',
        '',
        ,
        'pt-3',
        'pr-16',
        'pl-5',
        'mt-2.5',
        'whitespace-nowrap',
        'bg-slate-100',
        'rounded-[32px]',
        'text-slate-500',
        'max-md:pr-5',
      ]"
        label=""
        @blur="$emit('blur')"
        @click:clear="$emit('clear')"
        :rules="getRules"
        @input="$emit('input', input)"
      >
        <template v-slot:append>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </div>
  </template>
  
  <script>
  import inputMixin from "@/mixins/input.mixin.js";
  export default {
    mixins: [inputMixin],
  };
  </script>
  